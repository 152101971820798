const addParamsToFilters = (params) => {
  const filters = [];

  for (const key in params) {
    let filter = {
      type: '',
      label: '',
      slugs: [],
    };

    if (
      (key === 'experience' &&
        +params[key][0] === 0 &&
        +params[key][1] === 10) ||
      (key === 'price' && +params[key][0] === 0 && +params[key][1] === 300)
    )
      continue;

    if (
      params.hasOwnProperty(key) &&
      (params[key][0] !== '' || key === 'keywords')
    ) {
      filter.type = key;
      filter.label =
        key.substr(0, 1).toUpperCase() + key.substr(1, key.length - 1);
      filter.slugs = params[key];
      filters.push(filter);
    }
  }

  return filters;
};

export default addParamsToFilters;
