import React, { Component } from 'react';
import AppSearchDropdownKeywordsPopular from './AppSearchDropdownKeywordsPopular';
import AppSearchDropdownKeywordsSearch from './AppSearchDropdownKeywordsSearch';

import './AppSearchDropdownKeywords.scss';

class AppSearchDropdownKeywords extends Component {
  render() {
    const {
      moduleName,
      roles,
      companies,
      experience,
      skills,
      locations,
      addSlug,
      addKeywordSlug,
      clearSlugsList,
      inputValue,
      modal,
      modalClose,
      removeAllSlugs,
      handleFetch,
      handleClickOutside,
      // dropdownPopularOff,
      fetchNearestLocationByCoords,
      setNearestLocation,
      handleSubmit,
    } = this.props;

    return (
      <React.Fragment>
        {inputValue === '' || inputValue === undefined ? (
          <AppSearchDropdownKeywordsPopular
            moduleName={moduleName}
            modal={modal}
            modalClose={modalClose}
            roles={roles}
            experience={experience}
            skills={skills}
            locations={locations}
            fetchNearestLocationByCoords={fetchNearestLocationByCoords}
            setNearestLocation={setNearestLocation}
            addSlug={addSlug}
            addKeywordSlug={addKeywordSlug}
            removeAllSlugs={removeAllSlugs}
            handleFetch={handleFetch}
            handleClickOutside={handleClickOutside}
            handleSubmit={handleSubmit}
          />
        ) : (
          <AppSearchDropdownKeywordsSearch
            moduleName={moduleName}
            modal={modal}
            modalClose={modalClose}
            roles={roles}
            companies={companies}
            skills={skills}
            locations={locations}
            addSlug={addSlug}
            addKeywordSlug={addKeywordSlug}
            removeAllSlugs={removeAllSlugs}
            clearSlugsList={clearSlugsList}
            handleFetch={handleFetch}
          />
        )}
      </React.Fragment>
    );
  }
}

export default AppSearchDropdownKeywords;
