import React from 'react';
import classnames from 'classnames';
import AppSpinner from 'components/shared/AppSpinner';
import './AppPanelList.scss';

const AppPanelList = (props) => {
  const {
    children,
    isFetching,
    offset,
    column,
    flex,
    className,
    spinnerColor = '#fff',
  } = props;

  return (
    <ul
      className={classnames(
        'app-panel-list',
        {
          '--offset': offset,
          '--inline': flex,
          '--column': column,
          '--loading': isFetching,
        },
        className
      )}
    >
      {!isFetching ? (
        children
      ) : (
        <div className="app-search-panel__preloader">
          <AppSpinner
            size={25}
            spinnerColor={spinnerColor}
            spinnerWidth={2}
            visible={true}
          />
        </div>
      )}
    </ul>
  );
};

export default AppPanelList;
