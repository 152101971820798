import axios from 'axios';

import * as ActionTypes from 'constants/ActionTypes';
import * as SlugsTypes from 'constants/SlugsTypes';
import { API_URL } from 'constants/PathTypes';
// import { setNotification } from "./SystemActions";
import { setNearestLocation } from './LocationsActions';
import { querySlugByName, queryKeywordsSearch } from './querys';

export function setCoachingRolesByName(payload) {
  return {
    type: ActionTypes.SET_COACHINGROLES_BY_NAME,
    payload,
  };
}

export function setFetchingSlugs(payload) {
  return {
    type: ActionTypes.SET_FETCHING_SLUGS,
    payload,
  };
}

export function addSlug({ slugName, slug }) {
  let type;

  switch (slugName) {
    case SlugsTypes.ROLES:
      type = ActionTypes.ADD_ROLE_SLUG;
      break;
    case SlugsTypes.SKILLS:
      type = ActionTypes.ADD_SKILL_SLUG;
      break;
    case SlugsTypes.LOCATIONS:
      type = ActionTypes.ADD_LOCATION_SLUG;
      break;
    case SlugsTypes.SENIORITIES:
      type = ActionTypes.ADD_SENIORITY_SLUG;
      break;
    case SlugsTypes.COMPANIES:
      type = ActionTypes.ADD_COMPANY_SLUG;
      break;
    case SlugsTypes.EVENTTYPES:
      type = ActionTypes.ADD_EVENTTYPE_SLUG;
      break;
    case SlugsTypes.COACHINGTYPES:
      type = ActionTypes.ADD_COACHINGTYPE_SLUG;
      break;
    case SlugsTypes.COACHINGROLES:
      type = ActionTypes.ADD_COACHINGROLE_SLUG;
      break;
    case SlugsTypes.EXPERIENCE:
      type = ActionTypes.SET_EXPERIENCE;
      break;
    case SlugsTypes.PRICE:
      type = ActionTypes.SET_PRICE;
      break;
    case SlugsTypes.FROMUTC:
      type = ActionTypes.SET_FROMUTC;
      break;

    default:
      break;
  }

  return {
    type,
    payload: slug,
  };
}

export function removeSlug({ slugName, slug }) {
  let type;

  switch (slugName) {
    case SlugsTypes.ROLES:
      type = ActionTypes.REMOVE_ROLE_SLUG;
      break;
    case SlugsTypes.SKILLS:
      type = ActionTypes.REMOVE_SKILL_SLUG;
      break;
    case SlugsTypes.LOCATIONS:
      type = ActionTypes.REMOVE_LOCATION_SLUG;
      break;
    case SlugsTypes.SENIORITIES:
      type = ActionTypes.REMOVE_SENIORITY_SLUG;
      break;
    case SlugsTypes.COMPANIES:
      type = ActionTypes.REMOVE_COMPANY_SLUG;
      break;
    case SlugsTypes.EVENTTYPES:
      type = ActionTypes.REMOVE_EVENTTYPE_SLUG;
      break;
    case SlugsTypes.COACHINGTYPES:
      type = ActionTypes.REMOVE_COACHINGTYPE_SLUG;
      break;
    case SlugsTypes.COACHINGROLES:
      type = ActionTypes.REMOVE_COACHINGROLE_SLUG;
      break;
    case SlugsTypes.EXPERIENCE:
      type = ActionTypes.CLEAR_EXPERIENCE;
      break;
    case SlugsTypes.PRICE:
      type = ActionTypes.CLEAR_PRICE;
      break;
    case SlugsTypes.FROMUTC:
      type = ActionTypes.CLEAR_FROMUTC;
      break;

    default:
      break;
  }

  return {
    type,
    payload: slug,
  };
}

export function removeAllSlugs(slugName) {
  let type;

  switch (slugName) {
    case SlugsTypes.ROLES:
      type = ActionTypes.REMOVE_ALL_ROLES_SLUGS;
      break;
    case SlugsTypes.SKILLS:
      type = ActionTypes.REMOVE_ALL_SKILLS_SLUGS;
      break;
    case SlugsTypes.LOCATIONS:
      type = ActionTypes.REMOVE_ALL_LOCATIONS_SLUGS;
      break;
    case SlugsTypes.SENIORITIES:
      type = ActionTypes.REMOVE_ALL_SENIORITIES_SLUGS;
      break;
    case SlugsTypes.COMPANIES:
      type = ActionTypes.REMOVE_ALL_COMPANIES_SLUGS;
      break;
    case SlugsTypes.EVENTTYPES:
      type = ActionTypes.REMOVE_ALL_EVENTTYPES_SLUGS;
      break;
    case SlugsTypes.COACHINGTYPES:
      type = ActionTypes.REMOVE_ALL_COACHINGTYPES_SLUGS;
      break;
    case SlugsTypes.COACHINGROLES:
      type = ActionTypes.REMOVE_ALL_COACHINGROLES_SLUGS;
      break;
    case SlugsTypes.FROMUTC:
      type = ActionTypes.CLEAR_FROMUTC;
      break;
    case SlugsTypes.ALL:
      type = ActionTypes.REMOVE_ALL_SLUGS;
      break;
    default:
      break;
  }

  return {
    type,
  };
}

export function receiveSlugsList({ slugName, list }) {
  let type;

  switch (slugName) {
    case SlugsTypes.ROLES:
      type = ActionTypes.RECEIVE_ROLES;
      break;
    case SlugsTypes.SKILLS:
      type = ActionTypes.RECEIVE_SKILLS;
      break;
    case SlugsTypes.LOCATIONS:
      type = ActionTypes.RECEIVE_LOCATIONS;
      break;
    case SlugsTypes.SENIORITIES:
      type = ActionTypes.RECEIVE_SENIORITIES;
      break;
    case SlugsTypes.COMPANIES:
      type = ActionTypes.RECEIVE_COMPANIES;
      break;
    case SlugsTypes.EVENTTYPES:
      type = ActionTypes.RECEIVE_EVENTTYPES;
      break;
    case SlugsTypes.COACHINGTYPES:
      type = ActionTypes.RECEIVE_COACHINGTYPES;
      break;
    case SlugsTypes.COACHINGROLES:
      type = ActionTypes.RECEIVE_COACHINGROLES;
      break;

    default:
      break;
  }

  return {
    type,
    payload: list,
  };
}

export function clearSlugsList(slugName) {
  let type;

  switch (slugName) {
    case SlugsTypes.ROLES:
      type = ActionTypes.CLEAR_ROLES;
      break;
    case SlugsTypes.SKILLS:
      type = ActionTypes.CLEAR_SKILLS;
      break;
    case SlugsTypes.LOCATIONS:
      type = ActionTypes.CLEAR_LOCATIONS;
      break;
    case SlugsTypes.SENIORITIES:
      type = ActionTypes.CLEAR_SENIORITY;
      break;
    case SlugsTypes.COMPANIES:
      type = ActionTypes.CLEAR_COMPANIES;
      break;
    case SlugsTypes.EVENTTYPES:
      type = ActionTypes.CLEAR_EVENTTYPES;
      break;
    case SlugsTypes.COACHINGTYPES:
      type = ActionTypes.CLEAR_COACHINGTYPES;
      break;
    case SlugsTypes.COACHINGROLES:
      type = ActionTypes.CLEAR_COACHINGROLES;
      break;

    default:
      break;
  }

  return {
    type,
  };
}

export function setFetchingSlugsList({ slugName, fetch }) {
  let type;

  switch (slugName) {
    case SlugsTypes.ROLES:
      type = ActionTypes.SET_FETCHING_ROLES;
      break;
    case SlugsTypes.SKILLS:
      type = ActionTypes.SET_FETCHING_SKILLS;
      break;
    case SlugsTypes.LOCATIONS:
      type = ActionTypes.SET_FETCHING_LOCATIONS;
      break;
    case SlugsTypes.SENIORITIES:
      type = ActionTypes.SET_FETCHING_SENIORITIES;
      break;
    case SlugsTypes.COMPANIES:
      type = ActionTypes.SET_FETCHING_COMPANIES;
      break;
    case SlugsTypes.EVENTTYPES:
      type = ActionTypes.SET_FETCHING_EVENTTYPES;
      break;
    case SlugsTypes.COACHINGTYPES:
      type = ActionTypes.SET_FETCHING_COACHINGTYPES;
      break;
    case SlugsTypes.COACHINGROLES:
      type = ActionTypes.SET_FETCHING_COACHINGROLES;
      break;

    default:
      break;
  }

  return {
    type,
    payload: fetch,
  };
}

export function addVacanciesKeywordSlug(payload) {
  return {
    type: payload.type,
    payload: payload.value,
  };
}

export function addKeywordSlugByType(payload) {
  return {
    type: payload.type,
    payload: payload.value,
  };
}

export function removeVacanciesKeywordSlug() {
  return {
    type: ActionTypes.REMOVE_VACANCIE_KEYWORD_SLUG,
  };
}

export function clearAllSlugs() {
  return function (dispatch) {
    dispatch(removeAllSlugs(SlugsTypes.ALL));
  };
}

export function fetchSlugsByValueAndType({ slug, slugName, near }) {
  return async (dispatch) => {
    dispatch(setFetchingSlugs(true));
    const apiRequestName = getApiRequestName(slugName);
    const query = querySlugByName(slug);

    try {
      const response = await axios.get(
        `${API_URL}/${apiRequestName}?filter=${encodeURIComponent(query)}`
      );

      if (response.data[0]) {
        if (near) {
          dispatch(setNearestLocation(response.data[0]));
        } else {
          dispatch(addSlug({ slugName, slug: response.data[0] }));
        }
      }
      dispatch(setFetchingSlugs(false));
    } catch (error) {
      dispatch(setFetchingSlugs(false));
      throw error;
    }
  };
}

export function fetchSlugsListByNameAndType({ slugName, name, num }) {
  return async (dispatch) => {
    const apiRequestName = getApiRequestName(slugName);
    dispatch(setFetchingSlugsList({ slugName, fetch: true }));

    const query = queryKeywordsSearch({ name, num });

    try {
      const response = await axios.get(
        `${API_URL}/${apiRequestName}?filter=${encodeURIComponent(query)}`
      );

      dispatch(receiveSlugsList({ slugName, list: response.data }));
      dispatch(setFetchingSlugsList({ slugName, fetch: false }));
    } catch (error) {
      dispatch(setFetchingSlugsList({ slugName, fetch: false }));
      throw error;
    }
  };
}

export function receiveInitStatePopularSlugs({ slugName, slugs }) {
  let type;

  switch (slugName) {
    case SlugsTypes.ROLES:
      type = ActionTypes.RECEIVE_INIT_STATE_POPULAR_ROLES;
      break;
    case SlugsTypes.SKILLS:
      type = ActionTypes.RECEIVE_INIT_STATE_POPULAR_SKILLS;
      break;
    case SlugsTypes.LOCATIONS:
      type = ActionTypes.RECEIVE_INIT_STATE_POPULAR_LOCATIONS;
      break;
    case SlugsTypes.SENIORITIES:
      type = ActionTypes.RECEIVE_INIT_STATE_POPULAR_SENIORITIES;
      break;
    case SlugsTypes.COMPANIES:
      type = ActionTypes.RECEIVE_INIT_STATE_POPULAR_COMPANIES;
      break;
    case SlugsTypes.EVENTTYPES:
      type = ActionTypes.RECEIVE_INIT_STATE_POPULAR_EVENTTYPES;
      break;
    case SlugsTypes.COACHINGTYPES:
      type = ActionTypes.RECEIVE_INIT_STATE_POPULAR_COACHINGTYPES;
      break;
    case SlugsTypes.COACHINGROLES:
      type = ActionTypes.RECEIVE_INIT_STATE_POPULAR_COACHINGROLES;
      break;

    default:
      break;
  }

  return {
    type,
    payload: slugs,
  };
}

export function setFetchingInitStatePopularSlugs({ slugName, fetch }) {
  let type;

  switch (slugName) {
    case SlugsTypes.ROLES:
      type = ActionTypes.SET_FETCHING_INIT_STATE_POPULAR_ROLES;
      break;
    case SlugsTypes.SKILLS:
      type = ActionTypes.SET_FETCHING_INIT_STATE_POPULAR_SKILLS;
      break;
    case SlugsTypes.LOCATIONS:
      type = ActionTypes.SET_FETCHING_INIT_STATE_POPULAR_LOCATIONS;
      break;
    case SlugsTypes.SENIORITIES:
      type = ActionTypes.SET_FETCHING_INIT_STATE_POPULAR_SENIORITIES;
      break;
    case SlugsTypes.COMPANIES:
      type = ActionTypes.SET_FETCHING_INIT_STATE_POPULAR_COMPANIES;
      break;
    case SlugsTypes.EVENTTYPES:
      type = ActionTypes.SET_FETCHING_INIT_STATE_POPULAR_EVENTTYPES;
      break;
    case SlugsTypes.COACHINGTYPES:
      type = ActionTypes.SET_FETCHING_INIT_STATE_POPULAR_COACHINGTYPES;
      break;
    case SlugsTypes.COACHINGROLES:
      type = ActionTypes.SET_FETCHING_INIT_STATE_POPULAR_COACHINGROLES;
      break;

    default:
      break;
  }

  return {
    type,
    payload: fetch,
  };
}

export function fetchInitStatePopularSlugs(slugName) {
  return function (dispatch) {
    const apiRequestName = getApiRequestName(slugName);
    dispatch(setFetchingInitStatePopularSlugs({ slugName, fetch: true }));

    return (async () => {
      try {
        const response = await axios.get(`${API_URL}/${apiRequestName}/getTop`);

        dispatch(
          receiveInitStatePopularSlugs({ slugName, slugs: response.data })
        );
        dispatch(setFetchingInitStatePopularSlugs({ slugName, fetch: false }));
      } catch (error) {
        dispatch(setFetchingInitStatePopularSlugs({ slugName, fetch: false }));
        throw error;
      }
    })();
  };
}

export function fetchInitStatePopularEventtypes() {
  return function (dispatch) {
    dispatch(
      setFetchingInitStatePopularSlugs({
        slugName: SlugsTypes.EVENTTYPES,
        fetch: true,
      })
    );

    return (async () => {
      try {
        const response = await axios.get(`${API_URL}/eventtypes`);

        dispatch(
          receiveInitStatePopularSlugs({
            slugName: SlugsTypes.EVENTTYPES,
            slugs: response.data,
          })
        );
        dispatch(
          setFetchingInitStatePopularSlugs({
            slugName: SlugsTypes.EVENTTYPES,
            fetch: false,
          })
        );
      } catch (error) {
        dispatch(
          setFetchingInitStatePopularSlugs({
            slugName: SlugsTypes.EVENTTYPES,
            fetch: false,
          })
        );
        throw error;
      }
    })();
  };
}

export function fetchInitStatePopularCoachingtypes() {
  return function (dispatch) {
    dispatch(
      setFetchingInitStatePopularSlugs({
        slugName: SlugsTypes.COACHINGTYPES,
        fetch: true,
      })
    );

    return (async () => {
      try {
        const response = await axios.get(`${API_URL}/coach_types`);

        dispatch(
          receiveInitStatePopularSlugs({
            slugName: SlugsTypes.COACHINGTYPES,
            slugs: response.data,
          })
        );
        dispatch(
          setFetchingInitStatePopularSlugs({
            slugName: SlugsTypes.COACHINGTYPES,
            fetch: false,
          })
        );
      } catch (error) {
        dispatch(
          setFetchingInitStatePopularSlugs({
            slugName: SlugsTypes.COACHINGTYPES,
            fetch: false,
          })
        );
        throw error;
      }
    })();
  };
}

export function fetchInitStatePopularCoachingroles() {
  return function (dispatch) {
    dispatch(
      setFetchingInitStatePopularSlugs({
        slugName: SlugsTypes.COACHINGROLES,
        fetch: true,
      })
    );

    return (async () => {
      try {
        const response = await axios.get(
          `${API_URL}/${getApiRequestName(SlugsTypes.COACHINGROLES)}`
        );

        dispatch(
          receiveInitStatePopularSlugs({
            slugName: SlugsTypes.COACHINGROLES,
            slugs: response.data,
          })
        );
        dispatch(
          setFetchingInitStatePopularSlugs({
            slugName: SlugsTypes.COACHINGROLES,
            fetch: false,
          })
        );
      } catch (error) {
        dispatch(
          setFetchingInitStatePopularSlugs({
            slugName: SlugsTypes.COACHINGROLES,
            fetch: false,
          })
        );
        throw error;
      }
    })();
  };
}

function getApiRequestName(slugName) {
  switch (slugName) {
    case SlugsTypes.ROLES:
      return 'vacancy_roles';
    case SlugsTypes.SKILLS:
      return 'skills';
    case SlugsTypes.COMPANIES:
      return 'companies';
    case SlugsTypes.LOCATIONS:
      return 'locations';
    case SlugsTypes.EXPERIENCE:
      return 'experience';
    case SlugsTypes.PRICE:
      return 'price';
    case SlugsTypes.SENIORITIES:
      return 'vacancy_seniorities';
    case SlugsTypes.EVENTTYPES:
      return 'eventtypes';
    case SlugsTypes.COACHINGTYPES:
      return 'coach_types';
    case SlugsTypes.COACHINGROLES:
      return 'coach_roles';
    case SlugsTypes.FROMUTC:
      return 'fromUTC';

    default:
      return '';
  }
}
