import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import cn from 'classnames';
import Helmet from 'react-helmet';

import AppButton from 'components/shared/AppButton';
import {
  AppModal,
  AppModalHead,
  AppModalContent,
} from 'components/shared/AppModal';
import AppIcon from 'components/shared/AppIcon';
import AppSpinner from 'components/shared/AppSpinner';
import AppCoachDetailsCard from './AppCoachDetailsCard';
import CardCoachInterest from 'components/CardCoachInterest';
import AppCoachDetailsCardMobile from './AppCoachDetailsCardMobile';
import AppSharedMobile from 'components/shared/AppSharedMobile';
import AppHeader from 'components/AppHeader';
import AppHeaderMobile from 'components/AppHeaderMobile';
import AppCoachBookForm from './AppCoachBookForm';
import AppCoachingProfilePreviewServices from 'components/pages/AppCoachingSetup/AppCoachingProfilePreviewServices';
import AppCoachReviews from './AppCoachReviews';
import AdWrapper from 'components/AdWrapper';

import {
  receiveCoachById,
  fetchCoachById,
  fetchRelatedCoachesByData,
  setRelatedCoaches,
  fetchCoachByUrl,
  fetchGetCoachReviewsById,
} from 'actions/CoachesActions';
import { getInitState, setInitStateLoaded } from 'actions/StateActions'; //?

import {
  setModal,
  setNotification,
  clearNotification,
} from 'actions/SystemActions';

import * as MediaTypes from 'constants/MediaTypes';
import * as PathTypes from 'constants/PathTypes';
import { COACH_STATUSES } from 'constants/CoachingProfileTypes';

import history from 'history.js';
import store from 'store';
import {
  getStateFilters,
  buildUrl,
  // getFirstNumberInString
} from 'utils';

import './AppCoachPage.scss';

const mapDispatchToProps = {
  getInitState,
  setInitStateLoaded,

  setModal,
  setNotification,
  clearNotification,
  fetchCoachById,
  fetchCoachByUrl,
  receiveCoachById,
  fetchRelatedCoachesByData,
  setRelatedCoaches,
  fetchGetCoachReviewsById,
};

const mapStateToProps = (state) => ({
  coaches: state.coaches,
  user: state.user,
  location: state.location,
  isFetchingReviews: state.user.isFetching.reviews,
});

class AppCoachPage extends Component {
  constructor(props) {
    super(props);

    this.initState = {
      sharedModalIsOpen: true,
      bookIsActive:
        history.location.state && history.location.state.bookIsActive,
      isFetchingApply: false,
      title: document.title,
      metaJson: null,
      animationStartHandler: this.handlePageStartAnimation,
      selectedService: null,
      reviews: [],
      tabs: [],
      cardHeight: 0,
    };

    this.state = {
      ...this.initState,
    };

    this.contentScrolled = null;
    this.browserScrollbarWidth = null;
    this.sidebar = null;
    this.header = null;
    this.card = null;
    this.page = null;
    this.id = null;
    this.url = null;
    this.prevTitle = document.title;
    this.prevDescription = document
      .querySelector('meta[name="description"]')
      .getAttribute('content');
    this.unlisten = null;
    this.firstLoadRelated = null;
  }

  componentDidMount() {
    const {
      location: { state },
    } = history;
    const isFromFrirstLoad = state && state.fromFirstLoad;

    if (this.props.staticPage && !isFromFrirstLoad) {
      this.initStateHandler();
      this.handlePageOutAnimation();
    } else {
      this.handlePageStartAnimation();
    }

    this.handleCoachById();

    this.unlisten = history.listen((params, action) => {
      this.setState(this.initState);

      if (action === 'POP') {
        const coachById = params.state && params.state.coachById;
        const prevRelated = params.state && params.state.prevRelated;

        if (coachById) {
          this.props.receiveCoachById(null);
          this.props.receiveCoachById(coachById);
        }

        if (prevRelated) {
          this.props.setRelatedCoaches([]);
          this.props.setRelatedCoaches(prevRelated);
        } else {
          if (this.firstLoadRelated) {
            this.props.setRelatedCoaches([]);
            this.props.setRelatedCoaches(this.firstLoadRelated);
          }
        }
      }
    });
  }

  static getDerivedStateFromProps(props, state) {
    const { prevProps } = state;

    if (
      prevProps &&
      prevProps.staticPage !== props.staticPage &&
      props.staticPage === false
    ) {
      state.animationStartHandler();
      return {
        ...state,
        prevProps: props,
      };
    }

    return {
      prevProps: props,
    };
  }

  componentWillUnmount() {
    this.handlePageOutAnimation();
    this.props.receiveCoachById(null);
    this.unlisten();
    document.title = this.prevTitle;
    document
      .querySelector('meta[name="description"]')
      .setAttribute('content', this.prevDescription);
  }

  initStateHandler = () => {
    const { setInitStateLoaded, getInitState, isLoaded } = this.props;
    if (!isLoaded) getInitState().then(() => setInitStateLoaded());
  };

  handlePageStartAnimation = () => {
    this.browserScrollbarWidth = this.calcScrollbarBrowserWidth();

    document.querySelector('.js-blocker').classList.add('show');
    document.querySelector('.app-job-details__inner').classList.add('animate');
    document.body.classList.add('noscroll');
  };

  handlePageOutAnimation = () => {
    document.body.classList.remove('noscroll');
  };

  renderPageTitle = (coach) => {
    let title = `${coach.name} ${coach.surname}`;
    title += ' - ProductHired';
    return title;
  };

  handleCoachById = () => {
    const { fetchCoachByUrl, receiveCoachById, fetchGetCoachReviewsById } =
      this.props;
    const { location } = history;
    const coachUrl = location.pathname.slice(9);
    const from = location.state && location.state.from;

    coachUrl
      ? fetchCoachByUrl(coachUrl).then((coach) => {
          if (!coach || coach.status === COACH_STATUSES.OFF) {
            history.push(
              from ? PathTypes[from] || PathTypes.COACHING : PathTypes.COACHING
            );
            return false;
          }

          const title = this.renderPageTitle(coach.general);

          document
            .querySelector('meta[name="description"]')
            .setAttribute('content', coach.headline);
          document.title = title;

          this.setState({ title });
          receiveCoachById(coach);
          fetchGetCoachReviewsById(coach.id).then((reviews) => {
            this.getTabsModel(coach, reviews);
            this.setState({ reviews });
          });
          // fetchRelatedCoachesByData({ data: event }).then((related) => {
          //   if (this.firstLoadRelated === null) this.firstLoadRelated = related;
          // });
          this.contentScrolled && this.contentScrolled.scrollTo(0, 0);
        })
      : history.push(PathTypes.PAGE404);
  };

  calcScrollbarBrowserWidth = () => {
    var div = document.createElement('div');

    div.style.overflowY = 'scroll';
    div.style.width = '50px';
    div.style.height = '50px';
    div.style.visibility = 'hidden';

    document.body.appendChild(div);
    var scrollWidth = div.offsetWidth - div.clientWidth;
    document.body.removeChild(div);

    return scrollWidth;
  };

  handleSharedModalToggle = () =>
    this.setState({ sharedModalIsOpen: !this.state.sharedModalIsOpen });

  animationBackHandler = () => {
    this.page.classList.add('hide');
    document
      .querySelector('.app-job-details__inner')
      .classList.add('animateBack');
    document.querySelector('.js-blocker').classList.add('showOut');
    document.body.classList.remove('noscroll');
  };

  handlerGoBack = (e) => {
    const { location } = history;
    const state = getStateFilters(
      store.getState(),
      this.props.coaches.moduleName
    );

    if (
      this.state.bookIsActive &&
      location.state &&
      !location.state.bookIsActive
    ) {
      this.bookToggleHandler();
      return false;
    }

    if (e) e.stopPropagation();

    if (!this.props.staticPage) {
      this.page.classList.add('hide');
      document
        .querySelector('.app-job-details__inner')
        .classList.add('animateBack');
      document.querySelector('.js-blocker').classList.add('showOut');
    }

    setTimeout(() => {
      if (location.state && location.state.from) {
        if (
          location.state.from === PathTypes.FROMDASHBOARD ||
          location.state.from === PathTypes.FROMPROFILECOACHING ||
          location.state.from === PathTypes.FROMHOME
        )
          history.push(PathTypes[location.state.from], {
            frommodal: true,
            fetch: this.props.staticPage,
          });
        else
          history.push(buildUrl(state, PathTypes[location.state.from]), {
            frommodal: true,
            fetch: this.props.staticPage,
            filters: state,
          });
      } else {
        history.push(buildUrl(state, PathTypes.COACHES), {
          frommodal: false,
          fetch: true,
          filters: state,
        });
      }
    }, 1000);
  };

  navAnchorHandler = (e) => {
    const target = e.target;
    const targetSectionId = target.getAttribute('data-target');
    const targetSection = document.getElementById(targetSectionId);

    targetSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  renderHTMLMarkup = (markup) => ({ __html: markup });

  isIndeedCompany = (link) => {
    if (link && link.indexOf('indeed') >= 0) return true;
    return false;
  };

  bookToggleHandler = (noscroll) => {
    this.setState({ bookIsActive: !this.state.bookIsActive }, () => {
      this.contentScrolled && this.contentScrolled.scrollTo(0, 0);
      if (noscroll) return;
    });
  };

  onEditHandler = (id) => history.push(`${PathTypes.EMPLOYER_EDITAJOB}/${id}`);

  onDeleteHandler = (id) => {
    return this.props
      .fetchDeleteUserDashboardVacancie(id)
      .then(() => this.handlerGoBack());
  };

  checkCorrectUrl = (string) => {
    if (!string) return '';
    if (string.indexOf('http') === 0) return string;
    else return `${PathTypes.FILE_URL_CONTAINER}/${string}`;
  };

  renderCoachMeta = (coach) => {
    if (coach) {
      const title = `${coach.general.name} ${coach.general.surname}`;
      const imageUrl = coach.general.image.url;

      return (
        <Helmet>
          <meta name="description" content={coach.headline} />
          <meta name="og:description" content={coach.headline} />
          <meta name="twitter:description" content={coach.headline} />

          <meta property="og:title" content={title} />
          <meta name="twitter:title" content={title} />
          <meta property="og:site_name" content="ProductHired" />
          <meta name="twitter:site" content="@producthired" />
          <meta name="twitter:domain" content="producthired.com" />
          <meta
            property="og:url"
            content={`https://producthired.com/coach/${coach.url}`}
          />
          <meta
            name="twitter:url"
            content={`https://producthired.com/coach/${coach.url}`}
          />
          <meta property="og:image" content={imageUrl} />
          <meta property="og:image:secure_url" content={imageUrl} />
          <meta property="og:image:alt" content={coach.headline} />
          <meta property="og:image:width" content="600" />
          <meta property="og:image:height" content="600" />
          <meta name="twitter:image" content={imageUrl} />
          <meta name="twitter:image:alt" content={coach.headline} />
          <meta name="twitter:card" content="player" />
          <meta name="twitter:player" content={imageUrl} />
          <meta name="twitter:player:width" content="600" />
          <meta name="twitter:player:height" content="600" />
        </Helmet>
      );
    } else return '';
  };

  handleServiceClick = (service) => {
    this.bookToggleHandler(true);
    this.setSelectedService(service);
  };

  setSelectedService = (service) => {
    this.setState({ selectedService: service });
  };

  clearSelectedService = () => {
    this.setSelectedService(null);
  };

  getTabsModel = (coach, reviews) => {
    const tabsModel = [];
    const { details, services } = coach;

    if (services.length) {
      tabsModel.push({
        id: 'services',
        label: 'Services Offered',
      });
    }

    if (details) {
      tabsModel.push({
        id: 'bio',
        label: 'bio',
      });
    }

    if (reviews.length) {
      tabsModel.push({
        id: 'reviews',
        label: 'Reviews & Ratings',
      });
    }

    this.setState({ tabs: tabsModel });
  };

  render() {
    const {
      coaches,
      user,
      setModal,
      setNotification,
      clearNotification,
      location,
      staticPage,
      isFetchingReviews = false,
    } = this.props;

    const {
      sharedModalIsOpen = false,
      bookIsActive = false,
      selectedService,
      reviews,
      tabs,
      cardHeight,
    } = this.state;
    const { coachById } = coaches;

    const anchors = [{ id: 'overview', label: 'Overview' }];

    return (
      <>
        {staticPage ? (
          <MediaQuery maxWidth={MediaTypes.HEADER}>
            {(matches) => {
              if (matches) return <AppHeaderMobile />;
              else
                return (
                  <AppHeader
                    moduleName={coaches.moduleName}
                    panelSearch={true}
                  />
                );
            }}
          </MediaQuery>
        ) : (
          ''
        )}
        <div
          className={cn('app-job-details app-coach_page', {
            '--static': staticPage,
          })}
          ref={(ref) => (this.page = ref)}
        >
          <div
            className="app-job-details__backdoor js-blocker show"
            onClick={this.handlerGoBack}
          ></div>
          <div className="app-job-details__inner animate">
            {this.renderCoachMeta(coachById)}
            <MediaQuery maxWidth={MediaTypes.LT}>
              <React.Fragment>
                {anchors && (
                  <div
                    className={
                      'app-job-details-mobile__nav' +
                      (!bookIsActive ? ' show' : '')
                    }
                  >
                    <div className="app-job-details-mobile-nav">
                      <ul className="app-job-details-mobile-nav__list">
                        {anchors.map((anchor) => {
                          return (
                            <li
                              key={anchor.id}
                              className="app-job-details-mobile-nav__list-item"
                              onClick={this.navAnchorHandler}
                              data-target={anchor.id}
                            >
                              <a
                                href={'#' + anchor.id}
                                className="app-job-details-mobile-nav__list-item-btn"
                              >
                                {anchor.label}
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    </div>
                  </div>
                )}

                <AppModal
                  id="modal-shared"
                  className="app-modal-shared"
                  isActive={!sharedModalIsOpen}
                >
                  <div className="app-shared-menu-mobile">
                    <AppModalHead>
                      <AppButton
                        defaultStyle="round-icon"
                        className="app-modal-mobile-head__back app-modal-mobile-head__back--round"
                        onClick={this.handleSharedModalToggle}
                        icon="close-cross"
                      />

                      <div className="app-shared-menu-mobile__title">
                        Share Coach
                      </div>
                    </AppModalHead>

                    <AppModalContent>
                      <AppSharedMobile
                        user={user && user.auth}
                        setNotification={setNotification}
                        clearNotification={clearNotification}
                        jobTitle={coachById && coachById.name}
                      />
                    </AppModalContent>
                  </div>
                </AppModal>

                <div
                  className={
                    'app-job-details-mobile__actions-fixed' +
                    (!bookIsActive ? ' show' : '')
                  }
                >
                  {coachById ? (
                    <AppButton
                      title="Book a Session"
                      defaultStyle="primary"
                      height="medium"
                      reverse
                      onClick={this.bookToggleHandler}
                      isDisabled={coachById.status === COACH_STATUSES.PAUSED}
                    />
                  ) : (
                    ''
                  )}
                </div>
              </React.Fragment>
            </MediaQuery>

            <div
              className="app-job-details__close"
              onClick={this.handlerGoBack}
            >
              <MediaQuery maxWidth={MediaTypes.LT}>
                {(matches) => {
                  if (matches) {
                    return <AppIcon icon="arrow-left" />;
                  } else {
                    return (
                      <AppIcon icon={!bookIsActive ? 'close' : 'arrow-left'} />
                    );
                  }
                }}
              </MediaQuery>
            </div>

            {coaches.isFetching.coachById ? (
              <div className="app-job-details__preloader">
                <AppSpinner
                  size={25}
                  spinnerColor={'#333'}
                  spinnerWidth={2}
                  visible={true}
                  relative
                  height="60vh"
                />
              </div>
            ) : (
              <div
                className={
                  'app-job-details__content' +
                  (coaches.isFetching.coachById ? ' show' : '')
                }
                ref={(ref) => (this.contentScrolled = ref)}
              >
                {coachById && (
                  <React.Fragment>
                    <div className="app-job-details__content-left">
                      {staticPage ? (
                        <button
                          onClick={() =>
                            history.push(PathTypes.COACHES, { fetch: true })
                          }
                          className="app-button-back --top --p-left"
                        >
                          <AppIcon
                            className="app-button-back__icon"
                            color="acsent"
                            icon="arrow-left"
                          />
                          <div className="app-button-back__title">
                            Go to coaches search
                          </div>
                        </button>
                      ) : (
                        ''
                      )}
                      <div
                        className={cn('app-job-details-head', {
                          show: coaches.isFetching.coachById,
                        })}
                        ref={(ref) => (this.header = ref)}
                      >
                        <div
                          className={cn('app-job-details-head__banner', {
                            show: coachById.cover, // && coachById.plan_id,
                          })}
                          ref={(ref) => (this.banner = ref)}
                          style={{
                            backgroundImage: `url(${
                              coachById && coachById.cover // coachById.cover && coachById.plan_id
                                ? this.checkCorrectUrl(
                                    coachById.cover.name || coachById.cover
                                  )
                                : null
                            })`,
                          }}
                        ></div>

                        {!bookIsActive && (
                          <div
                            className="app-job-details__card"
                            ref={(ref) => (this.card = ref)}
                          >
                            <MediaQuery maxWidth={MediaTypes.LT}>
                              {(matches) => {
                                if (matches) {
                                  return (
                                    <React.Fragment>
                                      {coachById.plan_id > 1 ? (
                                        <div className="app-notification app-notification-motivated app-jobs-details-card-mobile__notification">
                                          <span>Motivated Coach</span>
                                        </div>
                                      ) : (
                                        ''
                                      )}

                                      <AppCoachDetailsCardMobile
                                        bookToggleHandler={
                                          this.bookToggleHandler
                                        }
                                        handleSharedModalToggle={
                                          this.handleSharedModalToggle
                                        }
                                        coach={coachById}
                                        details={true}
                                        search={true}
                                        onDeleteHandler={this.onDeleteHandler}
                                        onEditHandler={this.onEditHandler}
                                        animationBackHandler={
                                          this.animationBackHandler
                                        }
                                        setModal={setModal}
                                        motivatedList={[]}
                                      />
                                    </React.Fragment>
                                  );
                                } else {
                                  return (
                                    <AppCoachDetailsCard
                                      location={location}
                                      bookToggleHandler={this.bookToggleHandler}
                                      coach={coachById}
                                      details={true}
                                      search={true}
                                      onDeleteHandler={this.onDeleteHandler}
                                      onEditHandler={this.onEditHandler}
                                      setModal={setModal}
                                      animationBackHandler={
                                        this.animationBackHandler
                                      }
                                      motivatedList={[]}
                                      setNotification={setNotification}
                                      clearNotification={clearNotification}
                                      tabs={tabs}
                                    />
                                  );
                                }
                              }}
                            </MediaQuery>
                          </div>
                        )}
                      </div>

                      <div className="app-job-details__content-left-wr">
                        {bookIsActive ? (
                          <AppCoachBookForm
                            handlerGoBack={this.handlerGoBack}
                            staticPage={this.props.staticPage}
                            coach={coachById}
                            user={user}
                            selectedService={selectedService}
                            clearSelectedService={this.clearSelectedService}
                          />
                        ) : (
                          <React.Fragment>
                            <div className="app-job-details__info --b-top">
                              {coachById.services.length > 0 && (
                                <div className="app-job-details-info-section --blocks">
                                  <div
                                    style={{
                                      top: staticPage
                                        ? null
                                        : `-${cardHeight}px`,
                                    }}
                                    id="services"
                                    className="app-job-details-info-section-anchor"
                                  />
                                  <div className="app-job-details-info-section__title">
                                    Services Offered
                                  </div>
                                  <AppCoachingProfilePreviewServices
                                    services={coachById.services}
                                    roles={coachById.rt_tree.roles}
                                    onServiceClick={this.handleServiceClick}
                                  />
                                </div>
                              )}

                              {coachById.details && (
                                <div className="app-job-details-info-section --blocks">
                                  <div
                                    style={{
                                      top: staticPage
                                        ? null
                                        : `-${cardHeight}px`,
                                    }}
                                    id="bio"
                                    className="app-job-details-info-section-anchor"
                                  />
                                  <div className="app-job-details-info-section__title">
                                    Bio
                                  </div>
                                  <div
                                    className="app-job-details-info-section__text"
                                    dangerouslySetInnerHTML={this.renderHTMLMarkup(
                                      coachById.details
                                    )}
                                  ></div>
                                </div>
                              )}

                              {isFetchingReviews ? (
                                <AppSpinner relative height="200px" />
                              ) : reviews.length ? (
                                <div className="app-job-details-info-section --blocks">
                                  <div
                                    style={{
                                      top: staticPage
                                        ? null
                                        : `-${cardHeight}px`,
                                    }}
                                    id="reviews"
                                    className="app-job-details-info-section-anchor"
                                  />
                                  <div className="app-coach-details__reviews">
                                    <AppCoachReviews
                                      title="Ratings and Reviews"
                                      rating={coachById.rating}
                                      reviews={reviews}
                                    />
                                  </div>
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                    </div>

                    <div
                      id="interested"
                      ref={(ref) => (this.sidebar = ref)}
                      className="app-job-details__content-right"
                    >
                      {coaches &&
                      coaches.isFetching &&
                      coaches.isFetching.related ? (
                        <div className="app-job-details__preloader">
                          <AppSpinner
                            size={25}
                            spinnerColor={'#333'}
                            spinnerWidth={2}
                            visible={true}
                          />
                        </div>
                      ) : coaches.related.length > 0 ? (
                        <div
                          className="app-job-details__interest-list"
                          style={{ right: this.browserScrollbarWidth || 0 }}
                        >
                          <div className="app-jobs-interest-list__wr">
                            <AdWrapper
                              showMobileViewAlways={true}
                              sourceFilters={{
                                roles: coachById.general.roles,
                                companies: [coachById.general.company],
                                skills: coachById.general.skills,
                                locations: [coachById.general.location],
                              }}
                            />
                          </div>
                          <div className="app-jobs-interest-list__wr">
                            <div className="app-jobs-interest-list__title">
                              You may be interested
                            </div>
                            <ul className="app-jobs-interest-list">
                              {coaches.related.map((item) => (
                                <li
                                  className="app-jobs-interest-list__item"
                                  key={item.id}
                                >
                                  <CardCoachInterest
                                    data={item}
                                    onClick={() => {
                                      const from =
                                        (history.location.state &&
                                          history.location.state.from) ||
                                        'FROMCOACHDETAILS';
                                      history.push(
                                        `${PathTypes.COACHES}/${item.coach_url}`, //`${PathTypes.COACHES}/${item.slug}`,
                                        {
                                          modal: true,
                                          related: true,
                                          from,
                                          vacancieById: item,
                                          prevRelated: coaches.related, // prevRelated: coaches.related,
                                        }
                                      );

                                      // this.props.receiveEventById(null);  //this.props.receiveCoachById(null);
                                      // this.props.setRelatedEvents([]); //this.props.setRelatedCoaches([]);
                                      this.setState({ bookIsActive: false });
                                      this.handleCoachById();
                                    }}
                                  />
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="app-job-details__interest-list"
                          style={{ right: this.browserScrollbarWidth || 0 }}
                        >
                          <div className="app-jobs-interest-list__wr">
                            <AdWrapper
                              showMobileViewAlways={true}
                              sourceFilters={{
                                roles: coachById.general.roles,
                                companies: [coachById.general.company],
                                skills: coachById.general.skills,
                                locations: [coachById.general.location],
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                )}
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AppCoachPage));
