import * as ActionTypes from 'constants/ActionTypes';

export function setLoading(payload, event) {
  return {
    type: ActionTypes.SET_LOADING,
    payload,
    event: event || `SET_LOADING`,
  };
}

export function setNotification(payload) {
  return {
    type: ActionTypes.SET_NOTIFICATION,
    payload,
  };
}

export function clearNotification() {
  return {
    type: ActionTypes.CLEAR_NOTIFICATION,
  };
}

export function setTimezone(timezone) {
  return {
    type: ActionTypes.SET_TIMEZONE,
    payload: timezone,
  };
}

export function setModal(payload) {
  if (payload.active) document.body.classList.add('noscroll');
  else document.body.classList.remove('noscroll');

  return {
    type: ActionTypes.SET_MODAL,
    payload,
  };
}

export function setUploadProgress(payload) {
  return {
    type: ActionTypes.SET_PROGRESS,
    payload,
  };
}
