import React from 'react';
import classnames from 'classnames';
import './AppFlexLine.scss';

const AppFlexLine = (props) => {
  const {
    jc,
    ai,
    group,
    inline,
    lh,
    padded,
    marginBottom,
    wrap,
    className,
    column,
  } = props;

  return (
    <div
      className={classnames(
        'app-flex-line',
        {
          [`--jc-${jc}`]: jc,
          [`--ai-${ai}`]: ai,
          [`--lh-${lh}`]: lh,
          [`--mb-${marginBottom}`]: marginBottom,
          '--flex-wrap': wrap,
          '--group': group,
          '--padded': padded,
          '--inline': inline,
          '--column': column,
        },
        className
      )}
    >
      {props.children}
    </div>
  );
};

export default AppFlexLine;
