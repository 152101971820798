import React, { Component } from 'react';
import AppIcon from "components/shared/AppIcon";
import './AppModal.scss';

export default class AppModal extends Component {

    componentWillUnmount() {
        this.enableScroll();
    }

    componentDidMount() {
        this.disableScroll();
    }

    onCloseClick = () =>
        this.props.handleClickClose();

    disableScroll = () =>
        document.body.classList.add('noscroll');

    enableScroll = () =>
        document.body.classList.remove('noscroll');


    render() {
        const { children, className, btnClose, isActive } = this.props;

        return (
            <div id={this.props.id} className={"app-modal-mobile " + (className || '') + (!isActive ? ' hide' : '')}>
                { children }

                {
                    btnClose
                        ?
                            <div className="app-modal-mobile__close" onClick={this.onCloseClick}>
                                <AppIcon icon="close" />
                            </div>
                        : ''
                }
            </div>
        );

    }
}
