import * as types from "constants/ActionTypes";

const initialState = {
    filters: [],
    activeFilter: null,
    isFetching: false,
    query: '',
};

export default function filters(state = initialState, action) {
    switch (action.type) {

        case types.ADD_ALL_FILTERS:
            return Object.assign({}, state, {
                filters: action.payload
            });

        case types.REMOVE_ALL_FILTERS:
            return Object.assign({}, state, {
                filters: []
            });

        case types.SET_FETCHING_SLUGS:
            return Object.assign({}, state, {
                ...state,
                isFetching: action.payload
            });

        case types.SET_QUERY_FILTERS_SEARCH:
            return Object.assign({}, state, {
                ...state,
                query: action.payload
            });

        default:
            return state;
    }
}

