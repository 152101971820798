// parse url params function
const getJsonFromURL = (url) => {
    if(!url) url = window.location.search;
    if(url === '') return {};


    var query = url.substr(1);
    var result = {};

    query.split("&").forEach(function(part) {
      var item = part.split("=");
      result[item[0]] = decodeURIComponent(item[1]);
    });

    return result;
}

export default getJsonFromURL;