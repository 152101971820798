// vacancy sort
export const RECOMMENDED = 'recommended';
export const PUBLISHED_DESC = 'published DESC';
export const PUBLISHED_ASC = 'published ASC';

// coach sort
export const RATING = 'rating';
export const REVIEWS = 'reviews';
export const CREATED_DESC = 'created DESC';
export const CREATED_ASC = 'created ASC';

export const vacancyModel = [
  { value: RECOMMENDED, label: 'Recommended' },
  { value: PUBLISHED_DESC, label: 'Date Posted - Newest First' },
  { value: PUBLISHED_ASC, label: 'Date Posted - Oldest First' },
];

export const coachModel = [
  { value: CREATED_DESC, label: 'Registration Date - Newest First' },
  { value: CREATED_ASC, label: 'Registration Date - Oldest First' },
  { value: RATING, label: 'Rating' },
  { value: REVIEWS, label: 'Number of Reviews' },
];
