import React, { useMemo } from 'react';

import { COACH_SOCIALS_DATA } from 'constants/CoachingProfileTypes';
import { checkWebsiteUrl } from 'utils/Coach';

import './AppCoachSocials.scss';

const AppCoachSocials = ({ links = {} }) => {
  const socials = useMemo(
    () =>
      Object.keys(links).reduce((acc, key) => {
        if (links[key]) acc.push({ name: key, url: links[key] });
        return acc;
      }, []),
    [links]
  );

  return (
    <ul className="social-list">
      {socials.map((link) => (
        <li key={link.name}>{<AppSocialLink {...link} />}</li>
      ))}
    </ul>
  );
};

const AppSocialLink = ({ name, url }) => {
  const { prefix, icon: Icon } = COACH_SOCIALS_DATA[name];
  
  if (name === 'website') url = checkWebsiteUrl(url)
  else url = prefix + url

  return (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <Icon />
    </a>
  );
};

export default AppCoachSocials;
