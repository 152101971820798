import React from 'react';
import cn from 'classnames';

import AppIcon from 'components/shared/AppIcon';

import './CardCoachExperience.scss';

export const CardCoachExperience = function ({ experience }) {
  return (
    <div className="card-coach_experience" title={experience}>
      <div className="app-jobs-card__icon">
        <AppIcon icon="building" color="gray" />
      </div>
      <div className={cn("card-coach_experience-item")} >{experience}</div>
    </div>
  );
};

export default CardCoachExperience;
