import React from 'react';
import cn from 'classnames';

import './CardActions.scss';

export const CardActions = ({ children, full }) => (
  <div className={cn('card-actions', { '--full': full })}>
    <div className="card-actions__inner">{children}</div>
  </div>
);

export default CardActions;
