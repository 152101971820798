import axios from 'axios';

import {
  setNotification,
  clearNotification,
  setLoading,
  setModal,
} from './SystemActions';
import {
  addUserSearchAlarm,
  removeUserSearchAlarmBuId,
  changeUserSearchAlarmBuId,
  unauthUser,
} from './UserActions';

import * as ErrorsTypes from 'constants/ErrorsTypes';
import { API_URL } from 'constants/PathTypes';
// import * as ActionTypes from 'constants/ActionTypes';

export function fetchAddSearchAlarmByIdAndData({ id, data }) {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const response = await axios.post(
        `${API_URL}/users/${id}/search-alarms`,
        data,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      // analitics
      if (window.gtag) {
        window.gtag('event', 'Search Alarm');
      }

      dispatch(addUserSearchAlarm(response.data));
      dispatch(
        setNotification({
          message: 'Job Alert has been added to your list',
          type: 'searchAlarm',
          status: 'info',
        })
      );
      setTimeout(() => dispatch(clearNotification()), 5000);
      dispatch(setLoading(false));
      dispatch(setModal(false));

      return response.data;
    } catch (response) {
      const { error } = response.payload.data;

      if (
        (error.statusCode && error.statusCode === 401) ||
        error.code === ErrorsTypes.AUTHORIZATION_REQUIRED
      ) {
        dispatch(unauthUser());
        dispatch(
          setModal({
            active: 'signup',
            notification: true,
            title:
              'You need to be signed in to create a Job Alert. Sign in or Sign up now.',
          })
        );

        const lastRequest = {
          name: 'searchAlert',
          params: { data },
        };

        localStorage.setItem('ph_last_action', JSON.stringify(lastRequest));
      } else
        dispatch(
          setNotification({
            message: error.message,
            type: 'info',
            status: 'info',
          })
        );

      dispatch(setLoading(false));
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function fetchRemoveSearchAlarmById({ userId, alarmId }) {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const response = await axios.delete(
        `${API_URL}/users/${userId}/search-alarms/${alarmId}`,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(removeUserSearchAlarmBuId(alarmId));
      // dispatch(setNotification({ message: 'Search Alarm has been removed from your list', status:'info', type: "searchAlarm" }));
      // setTimeout(() => dispatch(clearNotification()), 5000);
      dispatch(setLoading(false));

      return response.data;
    } catch (error) {
      const { message } = error.payload.data.error;
      if (message.status && message.status === 401) {
        dispatch(unauthUser());
        dispatch(
          setNotification({
            message: 'Something went wrong.',
            type: 'info',
            status: 'info',
          })
        );
      } else
        dispatch(setNotification({ message, type: 'info', status: 'info' }));
      dispatch(setLoading(false));
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function fetchChangeSearchAlarmById({ userId, alarmId, data }) {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const response = await axios.put(
        `${API_URL}/users/${userId}/search-alarms/${alarmId}`,
        data,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(changeUserSearchAlarmBuId({ id: alarmId, data }));
      dispatch(setLoading(false));

      return response.data;
    } catch (error) {
      const { message } = error.payload.data.error;
      if (message.status && message.status === 401) {
        dispatch(unauthUser());
        dispatch(
          setNotification({
            message: 'Something went wrong.',
            type: 'info',
            status: 'info',
          })
        );
      } else
        dispatch(setNotification({ message, type: 'info', status: 'info' }));
      dispatch(setLoading(false));
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}
