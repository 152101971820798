import React, { useMemo } from 'react';

import AppSharedDropdown from 'components/shared/AppSharedDropdown';
import AppButton from 'components/shared/AppButton';

import {
  Card,
  CardImg,
  CardAddress,
  CardLabel,
  CardTitle,
  CardRow,
  CardContent,
  CardEventDate,
} from 'components/Card';

const AppEventDetailsCard = (props) => {
  const {
    data: {
      name,
      logo,
      address,
      date_start,
      date_end,
      utc_start,
      utc_end,
      locations,
      registration_link,
      id,
      types,
    },
  } = props;

  const isOnlineLocation = useMemo(() =>
    locations.find((location) => location.id === 1)
  );

  return (
    <Card>
      <CardRow flex jc="between">
        <CardImg size="small" id={id} img={logo} alt={name} />

        <CardRow flex marged jc="end">
          <React.Fragment>
            <AppSharedDropdown jobTitle={name} />

            {registration_link ? (
              <AppButton
                link={true}
                href={registration_link}
                onClick={() => {
                  if (window.gtag) {
                    window.gtag('event', 'Event Register', { label: id });
                  }
                }}
                target="_blank"
                title="Register"
                defaultStyle="primary"
                height="medium"
                icon="share"
                reverse
              />
            ) : (
              ''
            )}
          </React.Fragment>
        </CardRow>
      </CardRow>

      <CardContent>
        <CardRow padded>
          <CardTitle title={name} big>
            {name}
          </CardTitle>
          {types
            ? types.map((type) => <CardLabel key={type.id} title={type.name} />)
            : ''}
        </CardRow>

        <CardRow flex jc="between">
          <CardAddress address={address} />
          <CardEventDate
            row
            online={isOnlineLocation}
            dateStart={isOnlineLocation ? utc_start : date_start}
            dateEnd={isOnlineLocation ? utc_end : date_end}
          />
        </CardRow>
      </CardContent>
    </Card>
  );
};

export default AppEventDetailsCard;
