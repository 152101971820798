import React, { Component } from 'react';
import { Formik, Form, Field } from 'formik';
// import MediaQuery from 'react-responsive';
import { Link } from 'react-router-dom';
// import PropTypes from 'prop-types';
import classnames from 'classnames';

import AppDropzone from 'components/pages/AppJobDetailsPage/AppDropzone';
import AppTextarea from 'components/shared/AppTextarea';
import AppCheckbox from 'components/shared/AppCheckbox';
import AppButton from 'components/shared/AppButton';
import AppIcon from 'components/shared/AppIcon';

// import * as MediaTypes from 'constants/MediaTypes';
import * as PathTypes from 'constants/PathTypes';
import { validationSchemaApply } from 'constants/ValidationSchema';

import './AppJobDetailsApplyForm.scss';

class AppJobDetailsApplyForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isFileUploaded: null,
            isFetchingApply: false
        };
    }

    onFileUploadedHandler = (file) => {
        this.setState({ isFileUploaded: file });
    }

    clearUploadFileHandler = (callback) => {
        const input = document.querySelector('.app-dropzone input');

        input.value = null;
        callback(null);
    }

    render() {
        const {
            uploadVacancyApplyResume,
            user,
            progressUpload,
            setUploadProgress,
            applyVacancie,
            id,
            applyToggleHandler,
            toggleVacancieByIdAppliedProp,
            addAppliedVacancieToUserAppliedJobs,
            toggleVacancieSearchListAppliedPropById,
            toggleVacancieMotivatedListSavedPropById,
            company,
            motivatedList,
            jobName,
        } = this.props;

        return (
            <div className="app-jobs-apply-form">
                <div className="app-jobs-apply-form__title">
                    <h2 className="app-heading-section --small">Apply for this position</h2>
                </div>
                <div className="app-jobs-apply-form__content">
                    <Formik
                        initialValues={{
                            firstName: (user && user.profile && user.profile.name) || '',
                            lastName: (user && user.profile && user.profile.surname) || '',
                            email: (user && user.profile && user.profile.email) || '',
                            phone: '',
                            about: '',
                            terms: false,
                            resume: this.state.isFileUploaded,
                        }}
                        validationSchema={validationSchemaApply}
                        onSubmit={(values, { setSubmitting, setStatus }) => {
                            const data = {
                                name: values.firstName,
                                surname: values.lastName,
                                email: values.email,
                                phone: values.phone,
                                about: values.about,
                                container: this.state.isFileUploaded.container,
                                filename: this.state.isFileUploaded.name,
                            };

                            this.setState({ isFetchingApply: true });

                            applyVacancie({ id, data, loggedIn: user.profile, jobName, company })
                                .then((response) => {
                                    this.setState({ isFetchingApply: false });

                                    setSubmitting(false);
                                    toggleVacancieByIdAppliedProp();
                                    applyToggleHandler();
                                    toggleVacancieSearchListAppliedPropById({ id });

                                    if (user && user.auth) addAppliedVacancieToUserAppliedJobs(response);
                                    if (motivatedList) toggleVacancieMotivatedListSavedPropById({ id, applied: true });
                                })
                                .catch(() => this.setState({ isFetchingApply: false }));
                        }}
                    >
                        {({ isSubmitting, touched, errors, setFieldValue }) => (
                            <Form>
                                <div className="app-form__inner">
                                    <div className="app-form__content">
                                        <div style={{ display: user.auth ? 'none' : '' }} className="app-form-group">
                                            <div className="app-form-line app-form-line--item-flex">
                                                <div className="relative">
                                                    <div className="app-form-field-title">First Name</div>

                                                    <div className="app-form-field-wrap">
                                                        <Field
                                                            className={classnames(
                                                                "app-form-field",
                                                                {
                                                                    'error': errors.firstName && touched.firstName,
                                                                    'success': !errors.firstName && touched.firstName,
                                                                }
                                                            )}
                                                            type="text"
                                                            name="firstName"
                                                            placeholder="Enter your first name"
                                                        />
                                                        {
                                                            touched.firstName && errors.firstName && (
                                                                <AppIcon icon="close-cross" className="app-form-field-icon --error" />
                                                            )
                                                        }
                                                        {
                                                            touched.firstName && !errors.firstName &&
                                                                <AppIcon icon="check" className="app-form-field-icon --success" />
                                                        }
                                                    </div>
                                                    {
                                                        touched.firstName && errors.firstName && (
                                                            <small className="app-form-field-notification">{errors.firstName}</small>
                                                        )
                                                    }
                                                </div>

                                                <div className="relative">
                                                    <div className="app-form-field-title">Last Name</div>

                                                    <div className="app-form-field-wrap">
                                                        <Field
                                                            className={classnames(
                                                                "app-form-field",
                                                                {
                                                                    'error': errors.lastName && touched.lastName,
                                                                    'success': !errors.lastName && touched.lastName,
                                                                }
                                                            )}
                                                            type="text"
                                                            name="lastName"
                                                            placeholder="Enter your last name"
                                                        />
                                                        {
                                                            touched.lastName && errors.lastName && (
                                                                <AppIcon icon="close-cross" className="app-form-field-icon --error" />
                                                            )
                                                        }
                                                        {
                                                            touched.lastName && !errors.lastName &&
                                                                <AppIcon icon="check" className="app-form-field-icon --success" />
                                                        }
                                                    </div>
                                                    {
                                                        touched.lastName && errors.lastName && (
                                                                <small className="app-form-field-notification">{errors.lastName}</small>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div style={{ display: user.auth ? 'none' : '' }} className="app-form-group">
                                            <div className="app-form-field-title">Your E-Mail</div>

                                            <div className="app-form-field-wrap">
                                                <Field
                                                    className={classnames(
                                                        "app-form-field",
                                                        {
                                                            'error': errors.email && touched.email,
                                                            'success': !errors.email && touched.email,
                                                        }
                                                    )}
                                                    type="email"
                                                    name="email"
                                                    placeholder="Enter your e-mail address"
                                                />
                                                {
                                                    touched.email && errors.email && (
                                                        <AppIcon icon="close-cross" className="app-form-field-icon --error" />
                                                    )
                                                }
                                                {
                                                    touched.email && !errors.email &&
                                                        <AppIcon icon="check" className="app-form-field-icon --success" />
                                                }
                                            </div>
                                            {
                                                touched.email && errors.email && (
                                                    <small className="app-form-field-notification">{errors.email}</small>
                                                )
                                            }
                                        </div>

                                        <div className="app-form-group">
                                            <div className="app-form-field-title">Phone</div>

                                            <div className="app-form-field-wrap">
                                                <Field
                                                    className={classnames(
                                                        "app-form-field",
                                                        {
                                                            'error': errors.phone && touched.phone,
                                                            'success': !errors.phone && touched.phone,
                                                        }
                                                    )}
                                                    type="tel"
                                                    name="phone"
                                                    // pattern="/(\+[0-9]{2})([0-9]{2})([0-9]{4})([0-9]{4})/"
                                                    placeholder="Enter your phone number"
                                                />
                                                {
                                                    touched.phone && errors.phone && (
                                                        <AppIcon icon="close-cross" className="app-form-field-icon --error" />
                                                    )
                                                }
                                                {
                                                    touched.phone && !errors.phone &&
                                                        <AppIcon icon="check" className="app-form-field-icon --success" />
                                                }
                                            </div>
                                            {
                                                touched.phone && errors.phone && (
                                                    <small className="app-form-field-notification">{errors.phone}</small>
                                                )
                                            }
                                        </div>

                                        <div className="app-form-group">
                                            <div className="app-form-field-title">How do you know you are an amazing fit for this role and can make an impact at {company.name}?</div>

                                            <div className="app-form-field-wrap">
                                                <Field
                                                    className="app-form-field"
                                                    resize="none"
                                                    component={AppTextarea}
                                                    name="about"
                                                    placeholder="Please type your own thoughts."
                                                />
                                            </div>
                                        </div>

                                        <div className="app-form-group">
                                            <div className="app-form-field-title">Upload your Resume</div>

                                            <div className="app-form-field-wrap">
                                                <AppDropzone
                                                    onFileUploadedHandler={this.onFileUploadedHandler}
                                                    uploadFileHandler={uploadVacancyApplyResume}
                                                    className="--dotted"
                                                    isFileUploaded={this.state.isFileUploaded}
                                                    progress={progressUpload}
                                                    setUploadProgress={setUploadProgress}
                                                    setFieldValueHandler={setFieldValue}
                                                    fieldValue="resume"
                                                    fileIcon="file-pdf"
                                                    dropzoneTitle="Drag and drop your resume"
                                                    activeUpload="resume"
                                                />
                                                { this.state.isFileUploaded
                                                    ? <div onClick={() => this.clearUploadFileHandler(this.onFileUploadedHandler)} className="app-dropzone__title --clear">clear file</div>
                                                    : ''
                                                }
                                            </div>
                                            {
                                                touched.resume && errors.resume &&
                                                    <small className="app-form-field-notification">{errors.resume}</small>
                                            }
                                        </div>

                                        <div className="app-form-group">
                                            <div className="app-form-field-wrap">
                                                <div className="app-form-line app-form-line--fs app-form-line--checkbox">
                                                    <Field
                                                        name="terms"
                                                        component={AppCheckbox}
                                                        className="app-form-checkbox"
                                                    />
                                                    <p className="app-form-text">I have read & agreed the <Link target="_blank" className="app-form-link --lt-light" to={PathTypes.TERMS}>terms and conditions.</Link></p>
                                                </div>
                                            </div>
                                            {
                                                touched.terms && errors.terms &&
                                                    <small className="app-form-field-notification">{errors.terms}</small>
                                            }
                                        </div>

                                        <div className="app-form-group --right --submit">
                                            <AppButton
                                                defaultStyle="primary"
                                                type="submit"
                                                disabled={isSubmitting}
                                                bgColor="blue"
                                                size="medium"
                                                color="white"
                                                title="Submit Application"
                                                loader={true}
                                                isDisabled={this.state.isFetchingApply}
                                            />
                                        </div>
                                    </div>

                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>

            </div>
        );
    }
}

AppJobDetailsApplyForm.propTypes = {
    // company: PropTypes.object,
    // name: PropTypes.string,
}

export default AppJobDetailsApplyForm;
