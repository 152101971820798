import { Link } from 'react-router-dom';
import classnames from 'classnames';
import React, { Component } from 'react';
import MediaQuery from 'react-responsive';

import AppIcon from 'components/shared/AppIcon';
import AppUserAvatar from 'components/shared/AppUserAvatar';
import AppFlexLine from 'components/layout/AppFlexLine';

import * as PathTypes from 'constants/PathTypes';
import * as UserTypes from 'constants/UserTypes';
import * as MediaTypes from 'constants/MediaTypes';

import history from 'history.js';

import './AppUserProfileMenu.scss';

export default class AppUserProfileMenu extends Component {
  unlisten = null;

  linkHandler = () => {
    const { toggleMenuHandler } = this.props;
    if (toggleMenuHandler) toggleMenuHandler();
    if (this.props.state && this.props.state.toggleDropdown)
      this.props.state.toggleDropdown();
  };

  render() {
    const {
      sidebar,
      className,
      signOutHandler,
      user: { profile, notificationsCount },
    } = this.props;

    const { location } = history;

    return (
      <div className={classnames('app-menu app-menu-user', className)}>
        {profile && (
          <div className="app-menu-head app-menu-head--divider">
            <Link onClick={this.linkHandler} to={PathTypes.USER_PROFILE_MAIN}>
              <AppFlexLine jc="fs" ai="fs">
                <div className="app-flex-line__left">
                  <AppUserAvatar
                    img={profile.image.url}
                    icon={UserTypes.PERSONS[profile.image.person].icon}
                    color={UserTypes.COLORS[profile.image.color].value}
                    defaultPlaceholder={
                      UserTypes.PERSONS[profile.image.person].defaultPlaceholder
                    }
                  />
                </div>
                <div className="app-flex-line__right">
                  <div className="app-menu-title-wr">
                    <div className="app-menu-title">
                      {profile.name} {profile.surname}
                    </div>
                  </div>
                  <div className="app-menu-title-descr">My profile</div>
                </div>
              </AppFlexLine>
            </Link>
          </div>
        )}
        {profile && (
          <div className="app-menu-body">
            {sidebar ? (
              <div className="app-menu-row">
                <Link
                  onClick={this.linkHandler}
                  to={PathTypes.USER_PROFILE_NOTIFICATIONS}
                  className="app-menu-link"
                >
                  <div className="app-flex-line --inline --ai-center">
                    <div className="app-menu-icon">
                      <AppIcon icon="bell-solid" />
                    </div>
                    <AppFlexLine flex="inline" jc="center" ai="center">
                      <div className="app-menu-link__title">Notifications</div>
                      <div
                        className={classnames('app-menu-alarm', '--m-left', {
                          '--active':
                            notificationsCount && notificationsCount.new,
                        })}
                      >
                        <span>
                          {notificationsCount && notificationsCount.new}
                        </span>
                      </div>
                    </AppFlexLine>
                  </div>
                </Link>
              </div>
            ) : (
              ''
            )}
            <div className="app-menu-row">
              <Link
                onClick={this.linkHandler}
                to={PathTypes.USER_PROFILE_SEARCH_ALARMS}
                className="app-menu-link"
              >
                <div className="app-flex-line --inline --ai-center">
                  <div className="app-menu-icon">
                    <AppIcon icon="clock" />
                  </div>
                  <div className="app-menu-link__title">Job Alerts</div>
                </div>
              </Link>
            </div>
            <div className="app-menu-row">
              <Link
                onClick={this.linkHandler}
                to={PathTypes.USER_PROFILE_SAVED_JOBS}
                className="app-menu-link"
              >
                <div className="app-flex-line --inline --ai-center">
                  <div className="app-menu-icon">
                    <AppIcon icon="plus-circle" />
                  </div>
                  <div className="app-menu-link__title">Saved Jobs</div>
                </div>
              </Link>
            </div>
            <div className="app-menu-row">
              <Link
                onClick={this.linkHandler}
                to={PathTypes.USER_PROFILE_APPLIED_JOBS}
                className="app-menu-link"
              >
                <div className="app-flex-line --inline --ai-center">
                  <div className="app-menu-icon">
                    <AppIcon icon="paper-plane" />
                  </div>
                  <div className="app-menu-link__title">Applied Jobs</div>
                </div>
              </Link>
            </div>
          </div>
        )}
        {profile && (
          <div className="app-menu-body">
            <div className="app-menu-row">
              <Link
                onClick={this.linkHandler}
                to={PathTypes.COACHING_SETUP}
                className="app-menu-link"
              >
                <div className="app-flex-line --inline --ai-center">
                  <div className="app-menu-icon">
                    <AppIcon
                      icon={
                        profile.menuFlags.coachProfile ? 'settings' : 'plus'
                      }
                    />
                  </div>
                  <div className="app-menu-link__title --with-label">
                    {profile.menuFlags.coachProfile
                      ? 'Edit Coaching Profile'
                      : 'Create Coaching Profile'}
                  </div>
                </div>
              </Link>
            </div>
            {profile.menuFlags.sessionBooked ||
            profile.menuFlags.coachProfile ? (
              <div className="app-menu-row">
                <Link
                  onClick={this.linkHandler}
                  to={PathTypes.USER_PROFILE_COACHING}
                  className="app-menu-link"
                >
                  <div className="app-flex-line --inline --ai-center">
                    <div className="app-menu-icon">
                      <AppIcon icon="list" />
                    </div>
                    <div className="app-menu-link__title --with-label">
                      Coaching Dashboard
                    </div>
                  </div>
                </Link>
              </div>
            ) : (
              ''
            )}
            <div className="app-menu-row">
              <Link
                onClick={this.linkHandler}
                to={PathTypes.EMPLOYER_POSTAJOB}
                className="app-menu-link"
              >
                <div className="app-flex-line --inline --ai-center">
                  <div className="app-menu-icon">
                    <AppIcon icon="plus" />
                  </div>
                  <div className="app-menu-link__title --with-label">
                    Post a Job
                  </div>
                </div>
              </Link>
            </div>
            {profile.menuFlags.jobPosted ? (
              <div className="app-menu-row">
                <Link
                  onClick={this.linkHandler}
                  to={PathTypes.EMPLOYER_DASHBOARD}
                  className="app-menu-link"
                >
                  <div className="app-flex-line --inline --ai-center">
                    <div className="app-menu-icon">
                      <AppIcon icon="list" />
                    </div>
                    <div className="app-menu-link__title --with-label">
                      Posting Dashboard
                    </div>
                  </div>
                </Link>
              </div>
            ) : (
              ''
            )}
          </div>
        )}
        <MediaQuery maxWidth={MediaTypes.HEADER}>
          {(matches) => {
            if (matches)
              return (
                <div className="app-menu-body">
                  <div className="app-menu-row">
                    <Link
                      onClick={this.linkHandler}
                      className="app-menu-link"
                      to={{
                        pathname:
                          location.pathname === PathTypes.EVENTSHOME ||
                          location.pathname === PathTypes.HOME
                            ? PathTypes.JOBSHOME
                            : PathTypes.JOBS,
                        state: { fetch: true },
                      }}
                    >
                      <div className="app-flex-line --inline --ai-center">
                        <div className="app-menu-icon">
                          <AppIcon icon="search" />
                        </div>
                        <div className="app-menu-link__title">Jobs</div>
                      </div>
                    </Link>
                  </div>
                  <div className="app-menu-row">
                    <Link
                      onClick={this.linkHandler}
                      className="app-menu-link"
                      to={PathTypes.COACHING}
                    >
                      <div className="app-flex-line --inline --ai-center">
                        <div className="app-menu-icon">
                          <AppIcon icon="search" />
                        </div>
                        <div className="app-menu-link__title">Coaching</div>
                      </div>
                    </Link>
                  </div>
                  {!profile && (
                    <div className="app-menu-row">
                      <Link
                        onClick={this.linkHandler}
                        to={PathTypes.EMPLOYER_POSTAJOB}
                        className="app-menu-link"
                      >
                        <div className="app-flex-line --inline --ai-center">
                          <div className="app-menu-icon">
                            <AppIcon icon="plus" />
                          </div>
                          <div className="app-menu-link__title --with-label">
                            Post a Job
                          </div>
                        </div>
                      </Link>
                    </div>
                  )}
                </div>
              );
            else return '';
          }}
        </MediaQuery>
        <div className="app-menu-body">
          {profile ? (
            <div className="app-menu-row">
              <button onClick={signOutHandler} className="app-menu-link">
                <div className="app-flex-line --inline --ai-center">
                  <div className="app-menu-icon">
                    <AppIcon icon="sign-out" />
                  </div>
                  <div className="app-menu-link__title">Log out</div>
                </div>
              </button>
            </div>
          ) : (
            <>
              <div className="app-menu-row">
                <Link
                  onClick={this.linkHandler}
                  to={PathTypes.LOGIN}
                  className="app-menu-link"
                >
                  <div className="app-flex-line --inline --ai-center">
                    <div className="app-menu-icon">
                      <AppIcon icon="sign-in" />
                    </div>
                    <div className="app-menu-link__title --with-label">
                      Log in
                    </div>
                  </div>
                </Link>
              </div>
              <div className="app-menu-row">
                <Link
                  onClick={this.linkHandler}
                  to={PathTypes.SIGNUP}
                  className="app-menu-link"
                >
                  <div className="app-flex-line --inline --ai-center">
                    <div className="app-menu-icon">
                      <AppIcon icon="sign-in" />
                    </div>
                    <div className="app-menu-link__title --with-label">
                      Sign up
                    </div>
                  </div>
                </Link>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}
