import React from 'react';

import CardReview from 'components/CardReview';

import './AppCoachReviewsList.scss';

const AppCoachReviewsList = ({ reviews = [] }) => (
  <div className="coach-reviews-list">
    {reviews.map((review) => (
      <div key={review.id}>
        <CardReview {...review} />
      </div>
    ))}
  </div>
);

export default AppCoachReviewsList;
