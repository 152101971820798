import React from 'react';

import AppCardCompetitors from './AppCardCompetitors';

import './AppCraftCompetitors.scss';

const AppCraftCompetitors = ({ competitors }) => {
    return (
        <ul className="app-craft-competitors">
            {competitors.map((competitor) => {
                return (
                    <li
                        key={competitor.id}
                        className="app-craft-competitors__item"
                    >
                        <AppCardCompetitors
                            data={competitor}
                        />
                    </li>
                );
            })}
        </ul>
    );
};

export default AppCraftCompetitors;
