import React from 'react';
import { connect } from 'react-redux';
// import onClickOutside from "react-onclickoutside";
import {
  AppSerachField,
  AppSearchDropdownKeywords,
} from 'components/shared/AppSearch';
import { OutsideAlerter } from 'utils';

import {
  fetchNearestLocationByCoords,
  setNearestLocation,
  fetchLocationsByName,
} from 'actions/LocationsActions';

import { fetchRolesByName } from 'actions/RolesActions';
import { fetchSkillsByName } from 'actions/SkillsActions';
import { fetchCompaniesByName } from 'actions/CompaniesActions';

import { setModal } from 'actions/SystemActions';

import {
  addSlug,
  removeSlug,
  clearSlugsList,
  addVacanciesKeywordSlug,
  clearAllSlugs,
} from 'actions/SlugsActions';

import * as SearchTypes from 'constants/SearchTypes';
import * as ActionTypes from 'constants/ActionTypes';
import * as SlugsTypes from 'constants/SlugsTypes';

import { firstLetterUppercase } from 'utils/Strings';

import './AppSearchPanelKeywords.scss';

const mapDispatchToProps = (dispatch) => ({
  // search actions
  fetchRolesByName: (payload) => dispatch(fetchRolesByName(payload)),
  fetchSkillsByName: (payload) => dispatch(fetchSkillsByName(payload)),
  fetchCompaniesByName: (payload) => dispatch(fetchCompaniesByName(payload)),
  fetchLocationsByName: (payload) => dispatch(fetchLocationsByName(payload)),
  addVacanciesKeywordSlug: (payload) =>
    dispatch(addVacanciesKeywordSlug(payload)),

  // user actions
  setModal: (payload) => dispatch(setModal(payload)),

  // slugs actions
  clearAllSlugs: () => dispatch(clearAllSlugs()),
  clearSlugsList: (slugName) => dispatch(clearSlugsList(slugName)),
  addSlug: ({ slugName, slug }) => dispatch(addSlug({ slugName, slug })),
  removeSlug: ({ slugName, slug }) => dispatch(removeSlug({ slugName, slug })),

  fetchNearestLocationByCoords: (payload) =>
    dispatch(fetchNearestLocationByCoords(payload)),
  setNearestLocation: (payload) => dispatch(setNearestLocation(payload)),
});

const mapStateToProps = (state) => ({
  vacancies: state.vacancies,
  roles: state.roles,
  skills: state.skills,
  companies: state.companies,
  locations: state.locations,
  location: state.location,
  experience: state.experience,
  keyword: state.vacancies.keywords[0],
  user: state.user,
});

const AppSearchPanelKeywords = (props) => {
  const initState = {
    dropdownIsActive: false,
    searchValue: props.keyword,
    prevProps: {},
  };
  const [state, setState] = React.useState(initState);

  React.useEffect(() => {
    setState({ searchValue: props.keyword });
  }, []);

  React.useEffect(() => {
    if (state.prevProps && state.prevProps.keyword !== props.keyword) {
      setState({
        ...state,
        searchValue: props.keyword,
        prevProps: props,
      });
    } else {
      setState({
        ...state,
      });
    }
  }, [props.keyword]);

  const clearSearchLists = () => {
    props.clearSlugsList(SlugsTypes.ROLES);
    props.clearSlugsList(SlugsTypes.COMPANIES);
    props.clearSlugsList(SlugsTypes.SKILLS);
    props.clearSlugsList(SlugsTypes.LOCATIONS);
  };

  const fetchSearchLists = (value) => {
    props.fetchRolesByName({
      name: value,
      num: SearchTypes.DROPDOWN_MULTI_SEARCH_LIST_COUNT,
    });
    props.fetchSkillsByName({
      name: value,
      num: SearchTypes.DROPDOWN_MULTI_SEARCH_LIST_COUNT,
    });
    props.fetchCompaniesByName({
      name: value,
      num: SearchTypes.DROPDOWN_MULTI_SEARCH_LIST_COUNT,
    });
    props.fetchLocationsByName({
      name: value,
      num: SearchTypes.DROPDOWN_MULTI_SEARCH_LIST_COUNT,
    });
  };

  const onInputChange = (value) => {
    setState({
      ...state,
      dropdownIsActive: true,
      searchValue: value,
    });

    props.addVacanciesKeywordSlug({
      value: value,
      type: ActionTypes.ADD_VACANCIE_KEYWORD_SLUG,
    });

    if (props.dropdownMenu) {
      if (value.length > 0) fetchSearchLists(value);
      else if (value === '') clearSearchLists();
    }
  };

  const onInputFocus = () => {
    setTimeout(() => {
      setState({ ...state, dropdownIsActive: true });
    }, 300);
    if (props.onFocus) props.onFocus();
  };

  const closeHandler = () => setState({ ...state, dropdownIsActive: false });

  const onSubmit = (e) => {
    e && e.preventDefault();
    const {
      handleSubmit,
      addVacanciesKeywordSlug,
      skills,
      roles,
      companies,
      locations,
    } = props;

    if (
      !skills.isFetching.list &&
      !locations.isFetching.list &&
      !companies.isFetching.list &&
      !roles.isFetching.list
    ) {
      if (window.gtag) {
        window.gtag('event', 'Search Job', {
          category: 'Keyword',
          label: firstLetterUppercase(state.searchValue),
        });
      }

      e.preventDefault();
      addVacanciesKeywordSlug({
        value: state.searchValue,
        type: ActionTypes.ADD_VACANCIE_KEYWORD_SLUG,
      });
      setState({ ...state, dropdownIsActive: false });

      if (handleSubmit) handleSubmit(e);
    }
  };

  return (
    <OutsideAlerter className="app-search-panel" onClickOutside={closeHandler}>
      <div className="app-search-panel__inner">
        <div className="app-search-panel__field">
          <form className={'app-form'} onSubmit={onSubmit}>
            <AppSerachField
              deviceSize={props.deviceSize}
              modal={props.modal}
              onChangeHandler={onInputChange}
              value={state.searchValue}
              inputPlaceholder={props.placeholder}
              inputPlaceholderOnFocus="Keyword Search"
              onInputFocus={onInputFocus}
              onInputBlur={() => {}}
              btn={props.btn}
              btnTitle={props.btnTitle}
              inputSize={props.inputSize}
              handleSubmit={onSubmit}
              onCloseClick={closeHandler}
              isInputFocused={props.isInputFocused}
              btnIsFetching={{
                roles: props.roles.isFetching.list,
                companies: props.companies.isFetching.list,
                skills: props.skills.isFetching.list,
                locations: props.locations.isFetching.list,
              }}
              btnCleare={true}
              dropdownIsActive={state.dropdownIsActive}
              dropdownPopularOff={props.dropdownPopularOff}
              icon={props.icon}
            />

            {state.dropdownIsActive && props.dropdownMenu ? (
              <div className="app-search__dropdown">
                <AppSearchDropdownKeywords
                  moduleName={props.moduleName}
                  dropdownPopularOff={props.dropdownPopularOff}
                  modal={props.modal}
                  modalClose={props.modalClose}
                  roles={props.roles}
                  companies={props.companies}
                  skills={props.skills}
                  locations={props.locations}
                  fetchNearestLocationByCoords={
                    props.fetchNearestLocationByCoords
                  }
                  setNearestLocation={props.setNearestLocation}
                  addKeywordSlug={props.addVacanciesKeywordSlug}
                  addSlug={props.addSlug}
                  clearSlugsList={props.clearSlugsList}
                  removeAllSlugs={props.removeAllSlugs}
                  inputValue={state.searchValue}
                  handleFetch={props.handleFetch}
                  handleClickOutside={closeHandler}
                  handleSubmit={props.handleSubmit}
                />
              </div>
            ) : (
              ''
            )}
          </form>
        </div>
      </div>
    </OutsideAlerter>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppSearchPanelKeywords);
