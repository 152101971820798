import React, { Component } from 'react';

import AppSearchTag from 'components/shared/AppSearch/AppSearchTag';
import {
  AppPanelContainer,
  AppPanelList,
  AppPanelListItem,
  AppPanelTitle,
  AppPanelRow,
} from 'components/shared//AppPanel';

import * as FilterTypes from 'constants/FilterTypes';
import * as ActionTypes from 'constants/ActionTypes';
import * as SlugsTypes from 'constants/SlugsTypes';

import history from 'history.js';
import store from 'store';
import { getStateFilters } from 'utils';

import './AppEventsSearchDropdownKeywordsSearch.scss';

class AppEventsSearchDropdownKeywordsSearch extends Component {
  constructor(props) {
    super(props);
    this.state = { isLocationOn: false };
  }

  clearSearchLists = () => {
    const { clearSlugsList } = this.props;

    clearSlugsList(SlugsTypes.SKILLS);
    clearSlugsList(SlugsTypes.LOCATIONS);
  };

  render() {
    const {
      skills,
      locations,
      addKeywordSlug,
      addSlug,
      removeAllSlugs,
      modal,
      modalClose,
      handleFetch,
      moduleName,
    } = this.props;

    return (
      <AppPanelContainer>
        <div className="app-search-panel-dropdown-keywords-search">
          {(skills && skills.list && skills.list.length > 0) ||
          skills.isFetching.list ? (
            <AppPanelRow layout={'flex'} mb={20} mt={20}>
              <AppPanelTitle>{FilterTypes.SKILLS}</AppPanelTitle>
              <AppPanelList column={true} isFetching={skills.isFetching.list}>
                {skills.list.map((item) => (
                  <AppPanelListItem margin={'false'} key={item.id}>
                    <AppSearchTag
                      modal={modal}
                      modalClose={modalClose}
                      data={item}
                      onClick={() => {
                        this.clearSearchLists();
                        if (removeAllSlugs) removeAllSlugs();
                        addSlug({ slugName: SlugsTypes.SKILLS, slug: item });
                        addKeywordSlug({
                          value: '',
                          type: ActionTypes.ADD_EVENTS_KEYWORD_SLUG,
                        });

                        if (handleFetch) handleFetch();
                        else
                          history.push(
                            `/events?${FilterTypes.SKILLS}=${item.slug}`,
                            {
                              filters: getStateFilters(
                                store.getState(),
                                moduleName
                              ),
                              fetch: true,
                            }
                          );
                      }}
                      defaultStyle="list"
                    />
                  </AppPanelListItem>
                ))}
              </AppPanelList>
            </AppPanelRow>
          ) : (
            ''
          )}
          {/* 
          {(companies && companies.list && companies.list.length > 0) ||
          companies.isFetching.list ? (
            <AppPanelRow mb={20} mt={20} layout={'flex'}>
              <AppPanelTitle>{FilterTypes.COMPANIES}</AppPanelTitle>
              <AppPanelList
                column={true}
                isFetching={companies.isFetching.list}
              >
                {companies.list.map((item) => (
                  <AppPanelListItem margin={'false'} key={item.id}>
                    <AppSearchTag
                      modal={modal}
                      modalClose={modalClose}
                      data={item}
                      onClick={() => {
                        this.clearSearchLists();
                        if (removeAllSlugs) removeAllSlugs();
                        addSlug({ slugName: SlugsTypes.COMPANIES, slug: item });
                        addKeywordSlug({
                          value: '',
                          type: ActionTypes.ADD_VACANCIE_KEYWORD_SLUG,
                        });

                        if (handleFetch) handleFetch();
                        else
                          history.push(
                            `/events?${FilterTypes.COMPANIES}=${item.slug}`,
                            {
                              filters: getStateFilters(
                                store.getState(),
                                moduleName
                              ),
                              fetch: true,
                            }
                          );
                      }}
                      defaultStyle="list"
                    />
                  </AppPanelListItem>
                ))}
              </AppPanelList>
            </AppPanelRow>
          ) : (
            ''
          )} */}

          {(locations && locations.list && locations.list.length > 0) ||
          locations.isFetching.list ? (
            <AppPanelRow mb={20} mt={20} layout={'flex'}>
              <AppPanelTitle>{FilterTypes.LOCATIONS}</AppPanelTitle>
              <AppPanelList
                column={true}
                isFetching={locations.isFetching.list}
              >
                {locations.list.map((item) => (
                  <AppPanelListItem margin={'false'} key={item.id}>
                    <AppSearchTag
                      modal={modal}
                      modalClose={modalClose}
                      data={item}
                      onClick={() => {
                        this.clearSearchLists();
                        if (removeAllSlugs) removeAllSlugs();
                        addSlug({ slugName: SlugsTypes.LOCATIONS, slug: item });
                        addKeywordSlug({
                          value: '',
                          type: ActionTypes.ADD_EVENTS_KEYWORD_SLUG,
                        });

                        if (handleFetch) handleFetch();
                        else
                          history.push(
                            `/events?${FilterTypes.LOCATIONS}=${item.slug}`,
                            {
                              filters: getStateFilters(
                                store.getState(),
                                moduleName
                              ),
                              fetch: true,
                            }
                          );
                      }}
                      defaultStyle="list"
                    />
                  </AppPanelListItem>
                ))}
              </AppPanelList>
            </AppPanelRow>
          ) : (
            ''
          )}
        </div>
      </AppPanelContainer>
    );
  }
}

export default AppEventsSearchDropdownKeywordsSearch;
