import React from 'react';
import classnames from 'classnames';

import './AppPanelTitle.scss';

const AppPanelTitle = (props) => {
    const {
        title,
        children,
        defaultStyle,
        color,
        hidden,
        className,
     } = props;

    return (
        <React.Fragment>
        {
            defaultStyle === 'text'
                ?
                    <div className={classnames(
                        'app-panel-title',
                        {
                            [`--${defaultStyle}`]: defaultStyle,
                            [`--${color}`]: color,
                            '--hidden': hidden,
                        },
                        className,
                    )}>
                        { title || children }
                    </div>
                :
                    <h5 className={classnames(
                        'app-panel-title',
                        {
                            [`--${defaultStyle}`]: defaultStyle,
                            [`--${color}`]: color,
                            '--hidden': hidden
                        },
                        className,
                    )}>
                        { title || children }
                    </h5>
        }

        </React.Fragment>
    );
}

export default AppPanelTitle;
