import React from 'react';
import AppIcon from 'components/shared/AppIcon';
import './CardWork.scss';

const CardWork = ({ title }) => {
  return (
    <div className="app-jobs-card__experience">
      <div className="app-jobs-card__line">
        <div className="app-jobs-card__icon">
          <AppIcon icon="building" color="gray" />
        </div>
        <span>{title}</span>
      </div>
    </div>
  );
};

export default CardWork;
