import React, { Component } from 'react';
import AppEventsSearchDropdownKeywordsPopular from './AppEventsSearchDropdownKeywordsPopular';
import AppEventsSearchDropdownKeywordsSearch from './AppEventsSearchDropdownKeywordsSearch';

import './AppEventsSearchDropdownKeywords.scss';

class AppEventsSearchDropdownKeywords extends Component {
  render() {
    const {
      skills,
      locations,
      eventtypes,
      addSlug,
      addKeywordSlug,
      clearSlugsList,
      inputValue,
      modal,
      modalClose,
      removeAllSlugs,
      handleFetch,
      handleClickOutside,
      // dropdownPopularOff,
      fetchNearestLocationByCoords,
      setNearestLocation,
      handleSubmit,
    } = this.props;

    return (
      <React.Fragment>
        {(inputValue === '' || inputValue === undefined)
          ? (
            <AppEventsSearchDropdownKeywordsPopular
              modal={modal}
              modalClose={modalClose}
              skills={skills}
              locations={locations}
              eventtypes={eventtypes}
              fetchNearestLocationByCoords={fetchNearestLocationByCoords}
              setNearestLocation={setNearestLocation}
              addSlug={addSlug}
              addKeywordSlug={addKeywordSlug}
              removeAllSlugs={removeAllSlugs}
              handleFetch={handleFetch}
              handleClickOutside={handleClickOutside}
              handleSubmit={handleSubmit}
            />
          ) : (
            <AppEventsSearchDropdownKeywordsSearch
              modal={modal}
              modalClose={modalClose}
              skills={skills}
              locations={locations}
              addSlug={addSlug}
              addKeywordSlug={addKeywordSlug}
              removeAllSlugs={removeAllSlugs}
              clearSlugsList={clearSlugsList}
              handleFetch={handleFetch}
            />
          )}
      </React.Fragment>
    );
  }
}

export default AppEventsSearchDropdownKeywords;
