import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  AppSearchPanel,
  AppSearchListPopular,
  AppSearchListPicks,
} from 'components/shared/AppSearch';
import { AppPanelRow, AppPanelTitle } from 'components/shared/AppPanel';

import {
  addSlug,
  removeSlug,
  removeAllSlugs,
  clearSlugsList,
  fetchSlugsListByNameAndType,
} from 'actions/SlugsActions';

import * as SearchTypes from 'constants/SearchTypes';

import './AppFiltersPanelDropdown.scss';

const mapDispatchToProps = (dispatch) => ({
  addSlug: (slug) => dispatch(addSlug(slug)),
  removeSlug: (slug) => dispatch(removeSlug(slug)),
  removeAllSlugs: (slugName) => dispatch(removeAllSlugs(slugName)),
  clearSlugsList: (slugName) => dispatch(clearSlugsList(slugName)),
  fetchSlugsListByNameAndType: (slugName) =>
    dispatch(fetchSlugsListByNameAndType(slugName)),
});

class AppFiltersPanelDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchValue: '',
      dropdownIsActive: false,
    };
  }

  onInputChange = (value) => {
    const {
      slugName,
      clearSlugsList,
      fetchSlugsListByNameAndType,
    } = this.props;
    this.setState({ searchValue: value });

    if (value.length > 0) {
      this.setState({ dropdownIsActive: true });
      fetchSlugsListByNameAndType({
        slugName,
        name: value,
        num: SearchTypes.DROPDOWN_SINGLE_SEARCH_LIST_COUNT,
      });
    } else {
      this.setState({ dropdownIsActive: false });
      clearSlugsList(slugName);
    }
  };

  onInputFocus = () => this.setState({ dropdownIsActive: true });

  onClickOutside = () => this.setState({ dropdownIsActive: false });

  onClearFilter = () => {
    const {
      slugName,
      removeAllSlugs,
      closeFilterPanel,
      handleCount,
      handleSubmit,
    } = this.props;

    removeAllSlugs(slugName);
    closeFilterPanel && closeFilterPanel();
    handleCount && handleCount();
    handleSubmit && handleSubmit();
  };

  handlerClickAddTag = (item) => {
    const {
      clearSlugsList,
      addSlug,
      closeFilterPanel,
      handleCount,
      handleSubmit,
      slugName,
      analiticsTrigger,
    } = this.props;

    addSlug({ slugName, slug: item });
    this.setState({ dropdownIsActive: false, searchValue: '' });

    clearSlugsList && clearSlugsList(slugName);
    closeFilterPanel && closeFilterPanel();
    handleCount && handleCount();
    analiticsTrigger && analiticsTrigger();
    handleSubmit && setTimeout(() => handleSubmit(), 500);
  };

  handlerClickRemoveTag = (item) => {
    const {
      removeSlug,
      closeFilterPanel,
      handleCount,
      handleSubmit,
      slugName,
    } = this.props;

    removeSlug({ slugName, slug: item });
    closeFilterPanel && closeFilterPanel();
    handleCount && handleCount();
    handleSubmit && handleSubmit();
  };

  render() {
    const { searchValue, dropdownIsActive } = this.state;

    const {
      data: { isFetching, slugs, populars, list },
      title,
      search = null,
      slugName,
      top,
    } = this.props;

    return (
      <div className="app-filters-panel app-filters-panel-dropdown">
        {search && (
          <AppPanelRow mt={15} mb={15}>
            <AppSearchPanel
              list={list}
              isFetching={isFetching.list}
              onInputChange={this.onInputChange}
              onInputFocus={this.onInputFocus}
              onInputBlur={() => {}}
              onClickOutside={this.onClickOutside}
              inputValue={searchValue}
              dropdownIsActive={dropdownIsActive}
              handlerClickTag={this.handlerClickAddTag}
              inputPlaceholder={`Search ${slugName.toLowerCase()}`}
            />
          </AppPanelRow>
        )}

        {slugs && slugs.length > 0 ? (
          <AppPanelRow mt={15}>
            <AppSearchListPicks
              list={slugs}
              handlerClickTag={this.handlerClickRemoveTag}
            />
          </AppPanelRow>
        ) : (
          ''
        )}

        {top !== false ? (
          <AppPanelRow mt={15} mb={15}>
            <AppSearchListPopular
              list={populars}
              myPicks={slugs}
              title={title}
              isFetching={isFetching.populars}
              handlerClickTag={this.handlerClickAddTag}
              handlerRemoveTag={this.handlerClickRemoveTag}
            />
          </AppPanelRow>
        ) : (
          ''
        )}

        {slugs && slugs.length > 0 ? (
          <AppPanelRow>
            <button onClick={this.onClearFilter}>
              <AppPanelTitle color="warn">Clear selection</AppPanelTitle>
            </button>
          </AppPanelRow>
        ) : (
          ''
        )}
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(AppFiltersPanelDropdown);
