import React from 'react';
import classnames from 'classnames';

import './AppDropdownBlock.scss';

const AppDropdownBlock = function({ active, children, className, toggleDropdown, position }) {
    let DropdownMenuChild = React.cloneElement(children, { state: { toggleDropdown }});

    return (
        <div className={classnames(
            "app-dropdown-block",
            { 'active': active },
            className,
        )}>
            { active ? DropdownMenuChild : '' }
        </div>
    );
}

export default AppDropdownBlock;