import React from 'react';
import cn from 'classnames';

import './AppTab.scss';

const AppTab = ({ id, label, active, onClick }) => (
  <div
    onClick={() => onClick(id)}
    className={cn('app-tab', {
      '--active': active,
    })}
  >
    <span>{label}</span>
  </div>
);

export default AppTab;
