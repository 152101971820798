import React from 'react';
import classnames from 'classnames';

import AppIcon from 'components/shared/AppIcon';
import * as ColorsTypes from 'constants/ColorsTypes';

import './AppJobsCardPlaceholder.scss';

export const AppJobsCardPlaceholder = function({ name, size, id }) {
    const colors = renderColor(name[0].toLowerCase(), id);
    let randomColor;

    function renderColor(letter, id) {
        if (ColorsTypes.logoColors[letter]) return ColorsTypes.logoColors[letter];

        randomColor = randomColorHandle(ColorsTypes.logoColors, id)
        return false;
    }

    function randomColorHandle(obj, id) {
        const keys = Object.keys(obj);
        const idx = (keys.length / (1 + +(id).toString()[0])) << 0;

        if (idx >= 0 && idx < keys.length) return obj[keys[idx]];
        else return obj[keys[0]];
    };

    return (
        <div 
            style={{ 
                color: colors.color || randomColor.color,
                backgroundColor: colors.backgroundColor || randomColor.backgroundColor,
            }} 
            className={classnames(
                "app-jobs-card-placeholder", 
                {
                    [`--${size}`]: size,
                    '--left-top': !colors,
                }
            )}
        >
            { colors
                ? name.substr(0,2) 
                : <AppIcon icon="logo-placeholder" />
            }
        </div>
    )
}

export default AppJobsCardPlaceholder;
