import React from 'react';
import { connect } from 'react-redux';

import {
  AppSerachField,
  AppEventsSearchDropdownKeywords,
} from 'components/shared/AppSearch';

import { setModal } from 'actions/SystemActions';

import {
  fetchNearestLocationByCoords,
  setNearestLocation,
} from 'actions/LocationsActions';

import {
  addSlug,
  removeSlug,
  clearSlugsList,
  clearAllSlugs,
  addKeywordSlugByType,
  fetchSlugsListByNameAndType,
} from 'actions/SlugsActions';

import * as SearchTypes from 'constants/SearchTypes';
import * as ActionTypes from 'constants/ActionTypes';
import * as SlugsTypes from 'constants/SlugsTypes';
import { OutsideAlerter } from 'utils';

import { firstLetterUppercase } from 'utils/Strings';

import './AppEventsSearchPanelKeywords.scss';

const mapDispatchToProps = (dispatch) => ({
  // search actions
  fetchSlugsListByNameAndType: (payload) =>
    dispatch(fetchSlugsListByNameAndType(payload)),
  addKeywordSlugByType: (payload) => dispatch(addKeywordSlugByType(payload)),

  // user actions
  setModal: (payload) => dispatch(setModal(payload)),

  // slugs actions
  clearAllSlugs: () => dispatch(clearAllSlugs()),
  clearSlugsList: (slugName) => dispatch(clearSlugsList(slugName)),
  addSlug: ({ slugName, slug }) => dispatch(addSlug({ slugName, slug })),
  removeSlug: ({ slugName, slug }) => dispatch(removeSlug({ slugName, slug })),

  fetchNearestLocationByCoords: (payload) =>
    dispatch(fetchNearestLocationByCoords(payload)),
  setNearestLocation: (payload) => dispatch(setNearestLocation(payload)),
});

const mapStateToProps = (state) => ({
  events: state.events,
  keyword: state.events.keywords[0],
  skills: state.skills,
  locations: state.locations,
  eventtypes: state.eventtypes,
  user: state.user,
});

const AppEventsSearchPanelKeywords = (props) => {
  const initState = {
    dropdownIsActive: false,
    searchValue: props.keyword,
    prevProps: {},
  };
  const [state, setState] = React.useState(initState);

  React.useEffect(() => {
    setState({ searchValue: props.keyword });
  }, []);

  React.useEffect(() => {
    if (state.prevProps && state.prevProps.keyword !== props.keyword) {
      setState({
        ...state,
        searchValue: props.keyword,
        prevProps: props,
      });
    } else {
      setState({
        ...state,
        init: true,
      });
    }
  }, [props.keyword]);

  const clearSearchLists = () => {
    props.clearSlugsList(SlugsTypes.SKILLS);
    props.clearSlugsList(SlugsTypes.LOCATIONS);
    props.clearSlugsList(SlugsTypes.EVENTTYPES);
  };

  const fetchSearchLists = (value) => {
    props.fetchSlugsListByNameAndType({
      name: value,
      slugName: SlugsTypes.SKILLS,
      num: SearchTypes.DROPDOWN_MULTI_SEARCH_LIST_COUNT,
    });
    props.fetchSlugsListByNameAndType({
      name: value,
      slugName: SlugsTypes.LOCATIONS,
      num: SearchTypes.DROPDOWN_MULTI_SEARCH_LIST_COUNT,
    });
    props.fetchSlugsListByNameAndType({
      name: value,
      slugName: SlugsTypes.EVENTTYPES,
      num: SearchTypes.DROPDOWN_MULTI_SEARCH_LIST_COUNT,
    });
  };

  const onInputChange = (value) => {
    setState({
      ...state,
      dropdownIsActive: true,
      searchValue: value,
    });

    props.addKeywordSlugByType({
      value,
      type: ActionTypes.ADD_EVENTS_KEYWORD_SLUG,
    });

    if (props.dropdownMenu) {
      if (value.length > 0) fetchSearchLists(value);
      else if (value === '') clearSearchLists();
    }
  };

  const onInputFocus = () => {
    setTimeout(() => {
      setState({ ...state, dropdownIsActive: true });
    }, 300);

    if (props.onFocus) props.onFocus();
  };

  const closeHandler = () => setState({ ...state, dropdownIsActive: false });

  const onSubmit = (e) => {
    e && e.preventDefault();
    const { handleSubmit, addKeywordSlugByType, skills, locations } = props;

    if (!skills.isFetching.list && !locations.isFetching.list) {
      if (window.gtag) {
        window.gtag('event', 'Search Events', {
          category: 'Keyword',
          label: firstLetterUppercase(state.searchValue),
        });
      }

      addKeywordSlugByType({
        value: state.searchValue,
        type: ActionTypes.ADD_EVENTS_KEYWORD_SLUG,
      });
      setState({ ...state, dropdownIsActive: false });

      if (handleSubmit) handleSubmit(e);
    }
  };

  return (
    <OutsideAlerter className="app-search-panel" onClickOutside={closeHandler}>
      <div className="app-search-panel__inner">
        <div className="app-search-panel__field">
          <form className={'app-form'} onSubmit={onSubmit}>
            <AppSerachField
              deviceSize={props.deviceSize}
              modal={props.modal}
              onChangeHandler={onInputChange}
              value={state.searchValue}
              inputPlaceholder={props.placeholder}
              inputPlaceholderOnFocus="Keyword Search"
              onInputFocus={onInputFocus}
              onInputBlur={() => {}}
              btn={props.btn}
              btnTitle={props.btnTitle}
              inputSize={props.inputSize}
              handleSubmit={onSubmit}
              onCloseClick={closeHandler}
              isInputFocused={props.isInputFocused}
              btnIsFetching={{
                skills: props.skills.isFetching.list,
                locations: props.locations.isFetching.list,
              }}
              btnCleare={true}
              dropdownIsActive={state.dropdownIsActive}
              dropdownPopularOff={props.dropdownPopularOff}
              icon={props.icon}
            />

            {state.dropdownIsActive && props.dropdownMenu ? (
              <div className="app-search__dropdown">
                <AppEventsSearchDropdownKeywords
                  dropdownPopularOff={false}
                  modal={props.modal}
                  modalClose={props.modalClose}
                  eventtypes={props.eventtypes}
                  skills={props.skills}
                  locations={props.locations}
                  fetchNearestLocationByCoords={
                    props.fetchNearestLocationByCoords
                  }
                  setNearestLocation={props.setNearestLocation}
                  addKeywordSlug={props.addKeywordSlugByType}
                  addSlug={props.addSlug}
                  clearSlugsList={props.clearSlugsList}
                  removeAllSlugs={props.removeAllSlugs}
                  inputValue={state.searchValue}
                  handleFetch={props.handleFetch}
                  handleClickOutside={closeHandler}
                  handleSubmit={props.handleSubmit}
                />
              </div>
            ) : (
              ''
            )}
          </form>
        </div>
      </div>
    </OutsideAlerter>
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppEventsSearchPanelKeywords);
