import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const AppDatepicker = (props) => {
  const [startDate, setStartDate] = useState(props.date);

  const handleChange = (date) => {
    const { handleSubmit, handleCount, handlerDate } = props;


    const dateZero = new Date(date).setHours(0, 0, 0, 0)
    handlerDate(new Date(dateZero).toISOString());
    handleSubmit && handleSubmit();
    handleCount && handleCount();
    setStartDate(date);
  };

  return (
    <DatePicker
      calendarClassName="app-datepicker"
      placeholderText="Select a date"
      selected={startDate ? new Date(startDate) : new Date()}
      onChange={handleChange}
      minDate={new Date()}
      inline
    />
  );
};

export default AppDatepicker;
