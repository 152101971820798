import { LOCATION_CHANGE } from 'connected-react-router';
import * as ActionTypes from 'constants/ActionTypes';

// type:    system, settings, auth, user
// status:  error, success, warn
// message: null, 'string'

// const notification = {
//     type: '',
//     status: '',
//     message: '',
// }

const initState = {
  loading: false,
  error: false,
  notification: null,
  modal: {
    active: false,
  },
  timezone: null,
  loadFileProgress: 0,
  isFirstRendering: true,
};

export default function system(state = initState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      if (state.isFirstRendering) {
        return {
          ...state,
          isFirstRendering: action.payload.isFirstRendering,
        };
      } else return state;

    // case ActionTypes.DATA:
    //     return {...state, error: null, message: null, data: action.payload};

    // case ActionTypes.FILE:
    //     return {...state, error: null, message: null, file: action.payload};

    case ActionTypes.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case ActionTypes.SET_PROGRESS:
      return {
        ...state,
        loadFileProgress: action.payload,
      };

    case ActionTypes.SET_MODAL:
      return {
        ...state,
        modal: action.payload,
      };

    case ActionTypes.SET_NOTIFICATION:
      return {
        ...state,
        notification: { ...action.payload },
      };

    case ActionTypes.CLEAR_NOTIFICATION:
      return {
        ...state,
        notification: null,
      };

    case ActionTypes.SET_TIMEZONE:
      return {
        ...state,
        timezone: action.payload,
      };

    default:
      return state;
  }
}
