import React from 'react';
import AppIcon from 'components/shared/AppIcon';
import AppSpinner from 'components/shared/AppSpinner';

import './AppSuspenseLoader.scss';

const AppSuspenseLoader = () => (
  <div className="suspense-loader">
    <div className="suspense-loader-icon">
      <AppIcon icon="logo-responsive" />
    </div>
    <AppSpinner color="#fff" visible={true} size={60} />
  </div>
);

export default AppSuspenseLoader;
