import React, { Component } from 'react';
import AppSearchTag from 'components/shared/AppSearch/AppSearchTag';
import {
  AppPanelContainer,
  AppPanelList,
  AppPanelListItem,
  AppPanelTitle,
  AppPanelRow,
} from 'components/shared/AppPanel';

import * as FilterTypes from 'constants/FilterTypes';
import * as ActionTypes from 'constants/ActionTypes';
import * as SlugsTypes from 'constants/SlugsTypes';

import history from 'history.js';
import store from 'store';
import { getStateFilters } from 'utils';

import './AppCoachesSearchDropdownKeywordsPopular.scss';

class AppCoachesSearchDropdownKeywordsPopular extends Component {
  render() {
    const {
      coachingroles,
      coachingtypes,
      addSlug,
      removeAllSlugs,
      addKeywordSlug,
      modal,
      modalClose,
      handleFetch,
      handleClickOutside,
      moduleName,
    } = this.props;

    return (
      <AppPanelContainer>
        {(coachingroles &&
          coachingroles.populars &&
          coachingroles.populars.length > 0) ||
        coachingroles.isFetching.populars ? (
          <AppPanelRow mt={20} mb={20}>
            <AppPanelTitle>{FilterTypes.COACHINGROLES_LABEL}</AppPanelTitle>
            <AppPanelList
              offset={true}
              isFetching={coachingroles.isFetching.populars}
            >
              {coachingroles.populars.map((item) => (
                <AppPanelListItem key={item.id}>
                  <AppSearchTag
                    modal={modal}
                    modalClose={modalClose}
                    data={item}
                    onClick={() => {
                      if (removeAllSlugs) removeAllSlugs();
                      if (handleClickOutside) handleClickOutside();
                      addSlug({
                        slugName: SlugsTypes.COACHINGROLES,
                        slug: item,
                      });
                      addKeywordSlug({
                        value: '',
                        type: ActionTypes.ADD_COACHES_KEYWORD_SLUG,
                      });

                      if (handleFetch) handleFetch();
                      else
                        history.push(
                          `/coaches?${FilterTypes.COACHINGROLES}=${item.slug}`,
                          {
                            filters: getStateFilters(
                              store.getState(),
                              moduleName
                            ),
                            fetch: true,
                          }
                        );
                    }}
                  />
                </AppPanelListItem>
              ))}
            </AppPanelList>
          </AppPanelRow>
        ) : (
          ''
        )}

        {(coachingtypes &&
          coachingtypes.populars &&
          coachingtypes.populars.length > 0) ||
        coachingtypes.isFetching.populars ? (
          <AppPanelRow mb={20} mt={20}>
            <AppPanelTitle>SERVICES</AppPanelTitle>
            <AppPanelList
              offset={true}
              isFetching={coachingtypes.isFetching.populars}
            >
              {coachingtypes.populars.map((item) => (
                <AppPanelListItem key={item.id}>
                  <AppSearchTag
                    modal={modal}
                    modalClose={modalClose}
                    data={item}
                    onClick={() => {
                      if (removeAllSlugs) removeAllSlugs();
                      if (handleClickOutside) handleClickOutside();

                      addSlug({
                        slugName: SlugsTypes.COACHINGTYPES,
                        slug: item,
                      });
                      addKeywordSlug({
                        value: '',
                        type: ActionTypes.ADD_COACHES_KEYWORD_SLUG,
                      });

                      if (handleFetch) handleFetch();
                      else
                        history.push(`/coaches?types=${item.slug}`, {
                          filters: getStateFilters(
                            store.getState(),
                            moduleName
                          ),
                          fetch: true,
                        });
                    }}
                  />
                </AppPanelListItem>
              ))}
            </AppPanelList>
          </AppPanelRow>
        ) : (
          ''
        )}
      </AppPanelContainer>
    );
  }
}

export default AppCoachesSearchDropdownKeywordsPopular;
