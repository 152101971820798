import React from 'react';

import AppTab from './AppTab';
import navAnchorHandler from 'utils/navAnchorHandler';

import './AppTabs.scss';

const AppTabs = ({ items = [], activeTab = null, setActiveTab = null }) => {
  const handleTab = (id, idx) => {
    navAnchorHandler(null, id);

    if (setActiveTab) {
      setActiveTab(idx);
    }
  };

  return (
    <div className="app-tabs">
      {items.map((tab, idx) => (
        <div key={tab.id}>
          <AppTab
            onClick={() => handleTab(tab.id, idx)}
            active={idx === activeTab}
            {...tab}
          />
        </div>
      ))}
    </div>
  );
};

export default AppTabs;
