import React from 'react';
import { Link } from 'react-router-dom';
import classnames from 'classnames';

import AppFooter from 'components/AppFooter';
import AppUserProfileMenu from 'components/AppHeader/AppUserProfileMenu';

import * as PathTypes from 'constants/PathTypes';
import { trackAnaliticsEvent, analiticsCategoriesTypes } from 'utils/analitics';

import './AppSidebarMenu.scss';

const AppSidebarMenu = ({
  user,
  isOpen,
  signOut,
  toggleMenuHandler,
  moduleName,
}) => {
  const handleTriggerAnlalitics = (name) => {
    if (name === 'employer') {
      trackAnaliticsEvent({
        category: analiticsCategoriesTypes.JOB_POSTING,
        action: 'postjob_topnav_cta',
      });
    }
  };

  return (
    <div className={classnames('app-nav-mobile', { '--show': isOpen })}>
      <div className="app-nav-mobile__inner">
        <AppUserProfileMenu
          toggleMenuHandler={toggleMenuHandler}
          signOutHandler={signOut}
          user={user}
          sidebar={true}
          moduleName={moduleName}
          className="app-menu-user-sidebar"
        />
        <div className="app-nav-mobile__row --flex">
          <div className="app-nav-mobile__col --col-2">
            <ul className="app-nav-list">
              <li className="app-nav-list__item">
                <span className="app-nav-list__title">Company</span>
              </li>
              <li className="app-nav-list__item">
                <a
                  className="app-link --small"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="mailto:info@producthired.com"
                >
                  Contact Us
                </a>
              </li>
              <li className="app-nav-list__item">
                <Link
                  to={PathTypes.TERMS}
                  target="_blank"
                  className="app-link --small"
                >
                  Terms
                </Link>
              </li>
              <li className="app-nav-list__item">
                <Link
                  to={PathTypes.PRIVACY_POLICY}
                  target="_blank"
                  className="app-link --small"
                >
                  Privacy Policy
                </Link>
              </li>
            </ul>
          </div>
          <div className="app-nav-mobile__col --col-2">
            <ul className="app-nav-list">
              <li className="app-nav-list__item">
                <span className="app-nav-list__title">Hiring</span>
              </li>
              <li className="app-nav-list__item">
                <Link
                  onClick={() => handleTriggerAnlalitics('employer')}
                  className="app-link --small"
                  to={PathTypes.EMPLOYER_POSTAJOB}
                >
                  Post a Job
                </Link>
              </li>
            </ul>
          </div>
          <div className="app-nav-mobile__col --col-3">
            <ul className="app-nav-list">
              <li className="app-nav-list__item">
                <span className="app-nav-list__title">Resources</span>
              </li>
              <li className="app-nav-list__item">
                <Link className="app-link --small" to={PathTypes.GUIDES_HOME}>
                  Interview Guides
                </Link>
              </li>
              <li className="app-nav-list__item">
                <Link
                  className="app-link --small"
                  to={PathTypes.SLACK_BOT_PAGE}
                >
                  Slack Bot for Communities
                </Link>
              </li>
              {/* <li className="app-nav-list__item">
                                <Link className="app-link --small" to={PathTypes.SLACK_BOT_PAGE}>Open Product Managment</Link>
                            </li> */}
              <li className="app-nav-list__item">
                <a
                  className="app-link --small"
                  href="https://medium.com/open-product-management"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Blog
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <AppFooter />
    </div>
  );
};

export default AppSidebarMenu;
