import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import cn from 'classnames';
import Helmet from 'react-helmet';

import AppButton from 'components/shared/AppButton';
import {
  AppModal,
  AppModalHead,
  AppModalContent,
} from 'components/shared/AppModal';
import AppSearchTag from 'components/shared/AppSearch/AppSearchTag';
import AppIcon from 'components/shared/AppIcon';
import AppSpinner from 'components/shared/AppSpinner';
import AppJobsSmallCard from 'components/shared/AppJobsSmallCard';
import AppEventDetailsCard from './AppEventDetailsCard';
import CardEventInterest from 'components/CardEventInterest';
import AppEventDetailsCardMobile from './AppEventDetailsCardMobile';
import AppSharedMobile from 'components/shared/AppSharedMobile';
import AppHeader from 'components/AppHeader';
import AppHeaderMobile from 'components/AppHeaderMobile';
import AppMapBox from 'components/AppMapBox';
import AdWrapper from 'components/AdWrapper';

import {
  receiveEventById,
  fetchEventById,
  fetchRelatedEventsByData,
  setRelatedEvents,
} from 'actions/EventsActions';
import { getInitState, setInitStateLoaded } from 'actions/StateActions';

import {
  setModal,
  setNotification,
  clearNotification,
} from 'actions/SystemActions';

import * as MediaTypes from 'constants/MediaTypes';
import * as PathTypes from 'constants/PathTypes';

import history from 'history.js';
import store from 'store';
import { getStateFilters, buildUrl, getFirstNumberInString } from 'utils';

const mapDispatchToProps = (dispatch) => ({
  getInitState: (payload) => dispatch(getInitState(payload)),
  setInitStateLoaded: (payload) => dispatch(setInitStateLoaded(payload)),

  setModal: (payload) => dispatch(setModal(payload)),
  setNotification: (payload) => dispatch(setNotification(payload)),
  clearNotification: (payload) => dispatch(clearNotification(payload)),
  fetchEventById: (payload) => dispatch(fetchEventById(payload)),
  receiveEventById: (payload) => dispatch(receiveEventById(payload)),
  fetchRelatedEventsByData: (payload) =>
    dispatch(fetchRelatedEventsByData(payload)),
  setRelatedEvents: (payload) => dispatch(setRelatedEvents(payload)),
});

const mapStateToProps = (state) => ({
  events: state.events,
  user: state.user,
  location: state.location,
});

class AppEventPage extends Component {
  constructor(props) {
    super(props);

    this.initState = {
      fixed: false,
      paddingTop: 0,
      headOffsetRight: 0,
      headOffsetTop: 0,
      navFixed: false,
      sharedModalIsOpen: true,
      applyIsActive: false,
      isFetchingApply: false,
      title: document.title,
      metaJson: null,
      animationStartHandler: this.handlePageStartAnimation,
    };

    this.state = {
      ...this.initState,
    };

    this.contentScrolled = null;
    this.browserScrollbarWidth = null;
    this.sidebar = null;
    this.header = null;
    this.card = null;
    this.page = null;
    this.lastScrollTop = 0;
    this.id = null;
    this.prevTitle = document.title;
    this.prevDescription = document
      .querySelector('meta[name="description"]')
      .getAttribute('content');
    this.unlisten = null;
    this.firstLoadRelated = null;
  }

  componentDidMount() {
    const {
      location: { state },
    } = history;
    const isFromFrirstLoad = state && state.fromFirstLoad;

    if (this.props.staticPage && !isFromFrirstLoad) {
      this.initStateHandler();
      this.handlePageOutAnimation();
    } else {
      this.handlePageStartAnimation();
    }

    this.handleEventById();

    this.unlisten = history.listen((params, action) => {
      this.setState(this.initState);

      if (action === 'POP') {
        const eventById = params.state && params.state.eventById;
        const prevRelated = params.state && params.state.prevRelated;

        if (eventById) {
          this.props.receiveEventById(null);
          this.props.receiveEventById(eventById);
        }

        if (prevRelated) {
          this.props.setRelatedEvents([]);
          this.props.setRelatedEvents(prevRelated);
        } else {
          if (this.firstLoadRelated) {
            this.props.setRelatedEvents([]);
            this.props.setRelatedEvents(this.firstLoadRelated);
          }
        }
      }
    });
  }

  static getDerivedStateFromProps(props, state) {
    const { prevProps } = state;

    if (
      prevProps &&
      prevProps.staticPage !== props.staticPage &&
      props.staticPage === false
    ) {
      state.animationStartHandler();
      return {
        ...state,
        prevProps: props,
      };
    }

    return {
      prevProps: props,
    };
  }

  componentWillUnmount() {
    this.handlePageOutAnimation();
    this.props.receiveEventById(null);
    document.title = this.prevTitle;
    document
      .querySelector('meta[name="description"]')
      .setAttribute('content', this.prevDescription);
  }

  initStateHandler = () => {
    const { setInitStateLoaded, getInitState, isLoaded } = this.props;
    if (!isLoaded) getInitState().then(() => setInitStateLoaded());
  };

  handlePageStartAnimation = () => {
    this.browserScrollbarWidth = this.calcScrollbarBrowserWidth();

    document.querySelector('.js-blocker').classList.add('show');
    document.querySelector('.app-job-details__inner').classList.add('animate');
    document.body.classList.add('noscroll');

    if (this.contentScrolled != null && this.contentScrolled.scroll)
      this.contentScrolled.addEventListener('scroll', this.headerScrollHandler);
  };

  handlePageOutAnimation = () => {
    document.body.classList.remove('noscroll');
    if (this.contenScrolle != null)
      this.contentScrolled.removeEventListener(
        'scroll',
        this.headerScrollHandler
      );
  };

  renderPageTitle = (event) => {
    const { locations } = event;
    let title = `${event.name}`;

    if (locations) {
      if (locations.length === 1) {
        title +=
          locations[0].id === 1
            ? `, ${locations[0].name}`
            : ` in ${locations[0].name}`;
      } else if (locations.length === 2) {
        title +=
          locations[0].id === 1
            ? ` in ${locations[1].name} or ${locations[0]}`
            : ` in ${locations[0].name} or ${locations[1].name}`;
      }
    }

    title += ' - ProductHired';

    return title;
  };

  handleEventById = () => {
    const { fetchEventById, receiveEventById, fetchRelatedEventsByData } =
      this.props;
    const { location } = history;
    const eventId = (this.id = getFirstNumberInString(location.pathname));

    eventId
      ? fetchEventById(eventId).then((event) => {
          if (event.status === 'public') this.setEventMetaScript(event);

          // document.title = this.renderPageTitle(event);
          document
            .querySelector('meta[name="description"]')
            .setAttribute('content', event.description);

          this.setState({ title: this.renderPageTitle(event) });
          receiveEventById(event);
          fetchRelatedEventsByData({ data: event }).then((related) => {
            if (this.firstLoadRelated === null) this.firstLoadRelated = related;
          });
          this.contentScrolled && this.contentScrolled.scrollTo(0, 0);
        })
      : history.push(PathTypes.PAGE404);
  };

  setEventMetaScript = (event) => {
    let remote = false;
    let skills = '';
    let locations =
      event.locations.length > 0
        ? event.locations.filter((location) => {
            if (location.id === 1) {
              remote = true;
              return false;
            }
            return true;
          })
        : [];

    if (locations.length > 0) {
      locations = [
        ...locations.map((location) => ({
          '@type': 'Place',
          address: {
            '@type': 'PostalAddress',
            addressCountry: location.country,
            addressLocality: location.name,
            addressRegion: location.alias_region || '',
          },
        })),
      ];
    }

    if (event.skills.length > 0) {
      event.skills.forEach((skill, idx) => {
        skills += idx === 0 ? skill.name : `, ${skill.name}`;
      });
    }

    const json = {
      '@context': 'http://schema.org/',
      '@type': 'JobPosting',
      title: event.name,
      skills: skills,
      employmentType: 'FULL_TIME',
      description: event.details || '',
      datePosted: event.published,
      eventLocation: locations,
    };

    if (remote) json.eventLocationType = 'TELECOMMUTE';
    this.setState({ metaJson: json });
  };

  getMarkupIdArray = (events) => {
    const reg = /id="([\d\w\s-]+)"/g;
    let idArr = [];

    if (events && events.eventById) {
      const str = events.eventById.details;
      let match = reg.exec(str);

      while (match != null) {
        idArr.push(match[1]);
        match = reg.exec(str);
      }
    }

    return idArr;
  };

  handleSharedModalToggle = () =>
    this.setState({ sharedModalIsOpen: !this.state.sharedModalIsOpen });

  calcScrollbarBrowserWidth = () => {
    var div = document.createElement('div');

    div.style.overflowY = 'scroll';
    div.style.width = '50px';
    div.style.height = '50px';
    div.style.visibility = 'hidden';

    document.body.appendChild(div);
    var scrollWidth = div.offsetWidth - div.clientWidth;
    document.body.removeChild(div);

    return scrollWidth;
  };

  headerScrollHandler = (e) => {
    const { fixed } = this.state;
    const scrollTop = this.contentScrolled.scrollTop;
    const bannerHeight = this.banner.getBoundingClientRect().height;

    if (window.innerWidth > MediaTypes.LPX) {
      if (
        (scrollTop >= bannerHeight && !fixed) ||
        (scrollTop <= bannerHeight && fixed)
      ) {
        const paddingContent = !fixed
          ? this.card.getBoundingClientRect().height + scrollTop
          : 0;

        const headOffsetRight = !fixed
          ? window.innerWidth > 1100
            ? this.sidebar.getBoundingClientRect().width +
              this.browserScrollbarWidth
            : this.browserScrollbarWidth + 1
          : 0;

        this.handlerHeadFixed(paddingContent, headOffsetRight, -bannerHeight);
      }
    } else {
      if (scrollTop >= this.header.getBoundingClientRect().height) {
        this.setState({ navFixed: true });
      } else {
        this.setState({ navFixed: false, headOffsetTop: 0 });
      }
    }

    this.lastScrollTop = scrollTop;
  };

  handlerHeadFixed = (paddingTop, headOffsetRight, headOffsetTop) => {
    this.setState({
      fixed: !this.state.fixed,
      paddingTop,
      headOffsetRight,
      headOffsetTop: !this.state.fixed ? headOffsetTop : 0,
    });
  };

  animationBackHandler = () => {
    this.page.classList.add('hide');
    document
      .querySelector('.app-job-details__inner')
      .classList.add('animateBack');
    document.querySelector('.js-blocker').classList.add('showOut');
    document.body.classList.remove('noscroll');
    if (this.contenScrolle != null)
      this.contentScrolled.removeEventListener(
        'scroll',
        this.headerScrollHandler
      );
  };

  handlerGoBack = (e) => {
    const { location } = history;
    if (e) e.stopPropagation();
    this.page.classList.add('hide');
    document
      .querySelector('.app-job-details__inner')
      .classList.add('animateBack');
    document.querySelector('.js-blocker').classList.add('showOut');

    setTimeout(() => {
      const state = getStateFilters(
        store.getState(),
        this.props.events.moduleName
      );

      if (location.state && location.state.from) {
        if (
          location.state.from === PathTypes.FROMDASHBOARD ||
          location.state.from === PathTypes.FROMHOME
        )
          history.push(PathTypes[location.state.from], { frommodal: true });
        else
          history.push(buildUrl(state, PathTypes[location.state.from]), {
            frommodal: true,
            fetch: false,
            filters: state,
          });
      } else
        history.push(buildUrl(state, PathTypes.EVENTS), {
          frommodal: true,
          fetch: false,
          filters: state,
        });
    }, 1000);
  };

  navAnchorHandler = (e) => {
    const target = e.target;
    const targetSectionId = target.getAttribute('data-target');
    const targetSection = document.getElementById(targetSectionId);

    targetSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  renderHTMLMarkup = (markup) => ({ __html: markup });

  renderEventeApplyLink = (registration_link) => {
    if (registration_link) return registration_link;
    return false;
  };

  isIndeedCompany = (link) => {
    if (link && link.indexOf('indeed') >= 0) return true;
    return false;
  };

  applyToggleHandler = () => {
    this.contentScrolled.scrollTop = 0;
    this.setState({ applyIsActive: !this.state.applyIsActive });
  };

  onEditHandler = (id) => history.push(`${PathTypes.EMPLOYER_EDITAJOB}/${id}`);

  onDeleteHandler = (id) => {
    return this.props
      .fetchDeleteUserDashboardVacancie(id)
      .then(() => this.handlerGoBack());
  };

  checkCorrectUrl = (string) => {
    if (string.indexOf('http') === 0) return string;
    else return PathTypes.SERVER_URL + string;
  };

  render() {
    const {
      events,
      user,
      setModal,
      setNotification,
      clearNotification,
      location,
      staticPage,
    } = this.props;
    const { fixed, sharedModalIsOpen } = this.state;
    const { eventById } = events;

    // const anchors = this.getMarkupIdArray(events)
    const anchors = [{ id: 'overview', label: 'Overview' }];

    return (
      <>
        {staticPage ? (
          <MediaQuery maxWidth={MediaTypes.HEADER}>
            {(matches) => {
              if (matches) return <AppHeaderMobile />;
              else
                return (
                  <AppHeader
                    moduleName={events.moduleName}
                    panelSearch={true}
                  />
                );
            }}
          </MediaQuery>
        ) : (
          ''
        )}
        <div
          className={cn('app-job-details', {
            '--static': staticPage,
          })}
          ref={(ref) => (this.page = ref)}
        >
          <div
            className="app-job-details__backdoor js-blocker"
            onClick={this.handlerGoBack}
          ></div>
          <div className="app-job-details__inner">
            {this.state.metaJson && this.state.title ? (
              <Helmet
                title={this.state.title}
                script={[
                  {
                    type: 'application/ld+json',
                    innerHTML: `${JSON.stringify(this.state.metaJson)}`,
                  },
                ]}
              />
            ) : (
              ''
            )}
            <MediaQuery maxWidth={MediaTypes.LT}>
              <React.Fragment>
                <div
                  className={
                    'app-job-details-mobile__nav' +
                    (this.state.navFixed ? ' show' : '')
                  }
                >
                  <div className="app-job-details-mobile-nav">
                    <ul className="app-job-details-mobile-nav__list">
                      {anchors &&
                        anchors.map((anchor) => {
                          return (
                            <li
                              key={anchor.id}
                              className="app-job-details-mobile-nav__list-item"
                              onClick={this.navAnchorHandler}
                              data-target={anchor.id}
                            >
                              <a
                                href={'#' + anchor.id}
                                className="app-job-details-mobile-nav__list-item-btn"
                              >
                                {anchor.label}
                              </a>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                </div>

                <AppModal
                  id="modal-shared"
                  className="app-modal-shared"
                  isActive={!sharedModalIsOpen}
                >
                  <div className="app-shared-menu-mobile">
                    <AppModalHead>
                      <AppButton
                        defaultStyle="round-icon"
                        className="app-modal-mobile-head__back app-modal-mobile-head__back--round"
                        onClick={this.handleSharedModalToggle}
                        icon="close-cross"
                      />

                      <div className="app-shared-menu-mobile__title">
                        Share Event
                      </div>
                    </AppModalHead>

                    <AppModalContent>
                      <div className="app-shared-menu-mobile__card">
                        {eventById ? <AppJobsSmallCard data={eventById} /> : ''}
                      </div>
                      <AppSharedMobile
                        user={user && user.auth}
                        setNotification={setNotification}
                        clearNotification={clearNotification}
                        jobTitle={eventById && eventById.name}
                      />
                    </AppModalContent>
                  </div>
                </AppModal>

                <div
                  className={
                    'app-job-details-mobile__actions-fixed' +
                    (this.state.navFixed ? ' show' : '')
                  }
                >
                  {eventById && eventById.registration_link ? (
                    <AppButton
                      link={true}
                      href={this.renderEventeApplyLink(
                        eventById.registration_link
                      )}
                      onClick={() => {
                        if (window.gtag) {
                          window.gtag('event', 'Event registration', {
                            label: eventById.id,
                          });
                        }
                      }}
                      target="_blank"
                      title="Event registration"
                      defaultStyle="primary"
                      height="medium"
                      icon="share"
                      reverse
                    />
                  ) : (
                    ''
                  )}
                </div>
              </React.Fragment>
            </MediaQuery>

            <div
              className={cn('app-job-details__close', {
                '--fixed': this.state.navFixed,
              })}
              onClick={this.handlerGoBack}
            >
              <MediaQuery maxWidth={MediaTypes.LT}>
                {(matches) => {
                  if (matches) {
                    return <AppIcon icon="arrow-left" />;
                  } else {
                    return <AppIcon icon="close" />;
                  }
                }}
              </MediaQuery>
            </div>

            {events.isFetching.eventById ? (
              <div className="app-job-details__preloader">
                <AppSpinner
                  size={25}
                  spinnerColor={'#333'}
                  spinnerWidth={2}
                  visible={true}
                />
              </div>
            ) : (
              <div
                className={
                  'app-job-details__content' +
                  (events.isFetching.eventById ? ' show' : '')
                }
                ref={(ref) => (this.contentScrolled = ref)}
                style={{ paddingTop: this.state.paddingTop }}
              >
                {eventById && (
                  <React.Fragment>
                    <div className="app-job-details__content-left">
                      {staticPage ? (
                        <button
                          onClick={() =>
                            history.push(PathTypes.EVENTS, { fetch: true })
                          }
                          className="app-button-back --top --p-left"
                        >
                          <AppIcon
                            className="app-button-back__icon"
                            color="acsent"
                            icon="arrow-left"
                          />
                          <div className="app-button-back__title">
                            Go to events search
                          </div>
                        </button>
                      ) : (
                        ''
                      )}
                      <div
                        style={{
                          right: this.state.headOffsetRight,
                          top: this.state.headOffsetTop,
                        }}
                        className={cn('app-job-details-head', {
                          show: events.isFetching.eventById,
                          fixed: fixed,
                        })}
                        ref={(ref) => (this.header = ref)}
                      >
                        <div
                          className={cn('app-job-details-head__banner', {
                            show: eventById.cover && eventById.plan_id,
                          })}
                          ref={(ref) => (this.banner = ref)}
                          style={{
                            backgroundImage: `url(${
                              eventById.cover && eventById.plan_id
                                ? this.checkCorrectUrl(eventById.cover)
                                : null
                            })`,
                          }}
                        ></div>

                        <div
                          className="app-job-details__card"
                          ref={(ref) => (this.card = ref)}
                        >
                          <MediaQuery maxWidth={MediaTypes.LT}>
                            {(matches) => {
                              if (matches) {
                                return (
                                  <React.Fragment>
                                    {eventById.plan_id > 1 ? (
                                      <div className="app-notification app-notification-motivated app-jobs-details-card-mobile__notification">
                                        <span>Motivated Event</span>
                                      </div>
                                    ) : (
                                      ''
                                    )}

                                    <AppEventDetailsCardMobile
                                      applyToggleHandler={
                                        this.applyToggleHandler
                                      }
                                      handleSharedModalToggle={
                                        this.handleSharedModalToggle
                                      }
                                      hide={!fixed}
                                      data={eventById}
                                      details={true}
                                      search={true}
                                      onDeleteHandler={this.onDeleteHandler}
                                      onEditHandler={this.onEditHandler}
                                      animationBackHandler={
                                        this.animationBackHandler
                                      }
                                      setModal={setModal}
                                      motivatedList={[]}
                                    />
                                  </React.Fragment>
                                );
                              } else {
                                return (
                                  <AppEventDetailsCard
                                    location={location}
                                    applyToggleHandler={this.applyToggleHandler}
                                    fixed={fixed}
                                    data={eventById}
                                    details={true}
                                    search={true}
                                    onDeleteHandler={this.onDeleteHandler}
                                    onEditHandler={this.onEditHandler}
                                    setModal={setModal}
                                    animationBackHandler={
                                      this.animationBackHandler
                                    }
                                    motivatedList={[]}
                                    setNotification={setNotification}
                                    clearNotification={clearNotification}
                                  />
                                );
                              }
                            }}
                          </MediaQuery>
                        </div>
                      </div>

                      <div className="app-job-details__content-left-wr">
                        <React.Fragment>
                          <MediaQuery minWidth={MediaTypes.LT + 1}>
                            {eventById.lon && eventById.lat ? (
                              <div className="app-jobs-card__row">
                                <AppMapBox
                                  container="map-details"
                                  heightBig="200px"
                                  heightSmall="70px"
                                  isCollapsed={true}
                                  center={[eventById.lon, eventById.lat]}
                                />
                              </div>
                            ) : (
                              ''
                            )}
                            <div className="app-jobs-card__row">
                              <ul className="app-jobs-card__skills">
                                {eventById.skills &&
                                  eventById.skills.map((skill) => (
                                    <li
                                      className="app-jobs-card__skills-item"
                                      key={skill.id}
                                    >
                                      <AppSearchTag
                                        defaultStyle="card"
                                        data={skill}
                                        addSlug={() => {
                                          // history.push(`/jobs?${FilterTypes.SKILLS}=${skill.slug}`);
                                        }}
                                        removeSlug={() => {}}
                                      />
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          </MediaQuery>

                          <div className="app-job-details__info">
                            <div className="app-job-details-info-section">
                              <div
                                id="overview"
                                className="app-job-details-info-section-anchor"
                              ></div>
                              <div
                                className="app-job-details-info-section__text"
                                dangerouslySetInnerHTML={this.renderHTMLMarkup(
                                  eventById.details
                                )}
                              ></div>
                            </div>
                          </div>
                        </React.Fragment>
                      </div>
                    </div>

                    <div
                      id="interested"
                      ref={(ref) => (this.sidebar = ref)}
                      className="app-job-details__content-right"
                    >
                      {events &&
                      events.isFetching &&
                      events.isFetching.related ? (
                        <div className="app-job-details__preloader">
                          <AppSpinner
                            size={25}
                            spinnerColor={'#333'}
                            spinnerWidth={2}
                            visible={true}
                          />
                        </div>
                      ) : events.related.length > 0 ? (
                        <div
                          className="app-job-details__interest-list --relative"
                          style={{ marginTop: -this.state.paddingTop }}
                        >
                          <div className="app-jobs-interest-list__wr">
                            <AdWrapper
                              showMobileViewAlways={true}
                              sourceFilters={{
                                skills: eventById.skills,
                                locations: eventById.locations,
                                types: eventById.types,
                              }}
                            />
                          </div>
                          <div className="app-jobs-interest-list__wr">
                            <div className="app-jobs-interest-list__title">
                              You may be interested
                            </div>
                            <ul className="app-jobs-interest-list">
                              {events.related.map((item) => (
                                <li
                                  className="app-jobs-interest-list__item"
                                  key={item.id}
                                >
                                  <CardEventInterest
                                    data={item}
                                    onClick={() => {
                                      const from =
                                        (history.location.state &&
                                          history.location.state.from) ||
                                        'FROMEVENTDETAILS';
                                      history.push(
                                        `${PathTypes.EVENTS}/${item.slug}`,
                                        {
                                          modal: true,
                                          related: true,
                                          from,
                                          vacancieById: item,
                                          prevRelated: events.related,
                                        }
                                      );

                                      this.props.receiveEventById(null);
                                      this.props.setRelatedEvents([]);
                                      this.setState({ applyIsActive: false });
                                      this.handleEventById();
                                    }}
                                  />
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="app-job-details__interest-list"
                          style={{ right: this.browserScrollbarWidth || 0 }}
                        >
                          <div className="app-jobs-interest-list__wr">
                            <AdWrapper
                              showMobileViewAlways={true}
                              sourceFilters={{
                                skills: eventById.skills,
                                locations: eventById.locations,
                                types: eventById.types,
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                )}
              </div>
            )}
          </div>
        </div>
      </>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AppEventPage));
