import React, { Component } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';

import AppIcon from "components/shared/AppIcon";
import AppButton from "components/shared/AppButton";

import * as PathTypes from "constants/PathTypes";

import './AppCookieBanner.scss';

class AppCookieBanner extends Component {

    state = { show: false }

    componentDidMount() {
        const isFirstVisit = !Cookies.get('first_visit');
        isFirstVisit && this.setShowBanner(true);
    }

    setShowBanner = (value) => this.setState({ show: value })

    onCloseHandle = () => this.setShowBanner(false);

    onAcceptHandle = () => {
        Cookies.set('first_visit', 'true', { expires: 365 });
        this.onCloseHandle();
    }

    render() {
        return (
            <div className={classnames("app-cookie-banner", { 'show': this.state.show })}>
                <div className="app-modal-close-wrap" onClick={this.onCloseHandle}>
                    <div className="app-modal-close" >
                        <AppIcon icon="close-cross" />
                    </div>
                </div>
                <div className="app-cookie-banner__content">
                    <p className="app-text --big">
                        By using our site, you acknowledge that you have read and understand our <Link className="app-link --acsent --medium" target="_blank" to={PathTypes.TERMS}>Terms</Link> and <Link className="app-link --acsent --medium" target="_blank" to={PathTypes.PRIVACY_POLICY}>Privacy Policy</Link>.
                    </p>
                    <AppButton
                        title="I accept"
                        onClick={this.onAcceptHandle}
                    />
                </div>
            </div>
        )
    }
}

export default AppCookieBanner;
