import * as ActionTypes from 'constants/ActionTypes';
import * as PostJobTypes from 'constants/PostJobTypes';
import * as CoachingProfileTypes from 'constants/CoachingProfileTypes';
import { publishingPlans } from 'constants/EmployerTypes';

const initialState = {
  auth: false,
  token: null,
  profile: null,
  saved: [],
  applied: [],
  alarms: [],
  coachingSessions: {
    widgets: null,
    list: [],
    counts: null,
    isFetchingList: true,
    isFetchingWidgets: true,
  },
  notifications: [],
  related: [],
  topMatches: [],
  roles: [],
  notificationsCount: {
    count: null,
    new: null,
    notifications: [],
    isFetching: false,
  },
  post: { ...PostJobTypes.postJobInitState },
  coach: { ...CoachingProfileTypes.coachingProfileInitState },
  dashboard: {
    jobs: [],
    coachPayouts: [],
    isFetching: {
      jobs: false,
      users: false,
      status: false,
      coachPayouts: false,
    },
    publishingPlans: publishingPlans,
  },
  isFetching: {
    applied: false,
    saved: false,
    alarms: false,
    notifications: false,
    related: false,
    topMatches: false,
    coach: false,
    reviews: false,
  },
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.AUTH_USER:
      return {
        ...state,
        auth: true,
        profile: action.payload,
      };

    case ActionTypes.UNAUTH_USER:
      return {
        ...initialState,
      };

    case ActionTypes.AUTH_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case ActionTypes.ADD_USER_SKILL_SLUG:
      const skills = state.profile.skills.slice();

      if (!skills.some((skills) => action.payload.id === skills.id)) {
        skills.push(action.payload);

        return Object.assign({}, state, {
          ...state,
          profile: {
            ...state.profile,
            skills,
          },
        });
      } else return state;

    case ActionTypes.REMOVE_USER_SKILL_SLUG:
      return Object.assign({}, state, {
        ...state,
        profile: {
          ...state.profile,
          skills: state.profile.skills.filter(
            (item) => item.id !== action.payload.id
          ),
        },
      });

    case ActionTypes.REMOVE_ALL_USER_SKILLS_SLUGS:
      return Object.assign({}, state, {
        ...state,
        profile: {
          ...state.profile,
          skills: [],
        },
      });

    case ActionTypes.SET_USER_EXPERIENCE:
      return Object.assign({}, state, {
        ...state,
        profile: {
          ...state.profile,
          experience: action.payload,
        },
      });

    case ActionTypes.SET_USER_LOCATION_SLUG:
      return Object.assign({}, state, {
        ...state,
        profile: {
          ...state.profile,
          location: action.payload,
        },
      });

    case ActionTypes.SET_USER_SAVED_JOBS:
      return {
        ...state,
        saved: action.payload,
      };

    case ActionTypes.SET_FETCHING_USER_SAVED_JOBS_LIST:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          saved: action.payload,
        },
      };

    case ActionTypes.SET_FETCHING_USER_APPLIED_JOBS_LIST:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          applied: action.payload,
        },
      };

    case ActionTypes.SET_FETCHING_USER_COACHING_LIST:
      return {
        ...state,
        coachingSessions: {
          ...state.coachingSessions,
          isFetchingList: action.payload,
        },
      };

    case ActionTypes.SET_USER_COACHING_LIST_COUNTS:
      return {
        ...state,
        coachingSessions: {
          ...state.coachingSessions,
          counts: action.payload,
        },
      };

    case ActionTypes.SET_FETCHING_USER_COACHING_LIST_COUNTS:
      return {
        ...state,
        coachingSessions: {
          ...state.coachingSessions,
          isFetchingListCounts: action.payload,
        },
      };

    case ActionTypes.FETCH_CANCEL_BOOKING_SESSION_SUCCESS:
      return {
        ...state,
        coachingSessions: {
          ...state.coachingSessions,
          list: [
            ...state.coachingSessions.list.filter(
              (session) => session.id !== action.payload.id
            ),
          ],
          widgets: {
            ...state.coachingSessions.widgets,
            upcomingBookings:
              state.coachingSessions.widgets.upcomingBookings - 1,
            cancelledBookings:
              state.coachingSessions.widgets.cancelledBookings + 1,
          },
        },
      };

    case ActionTypes.FETCH_REQUEST_RESCHEDULE_BOOKING_SESSION_SUCCESS:
    case ActionTypes.FETCH_RESCHEDULE_BOOKING_SESSION_SUCCESS:
    case ActionTypes.FETCH_REPORT_NO_SHOW_BOOKING_SESSION_SUCCESS:
      return {
        ...state,
        coachingSessions: {
          ...state.coachingSessions,
          list: [
            ...state.coachingSessions.list.map((session) =>
              session.id === action.payload.id ? action.payload : session
            ),
          ],
        },
      };

    case ActionTypes.SET_USER_COACHING_LIST:
      return {
        ...state,
        coachingSessions: {
          ...state.coachingSessions,
          list: action.payload,
        },
      };

    case ActionTypes.SET_FETCHING_COACH_WIDGETS_INFO:
      return {
        ...state,
        coachingSessions: {
          ...state.coachingSessions,
          isFetchingWidgets: action.payload,
        },
      };

    case ActionTypes.FETCH_COACH_WIDGETS_INFO_SUCCESS:
      return {
        ...state,
        coachingSessions: {
          ...state.coachingSessions,
          widgets: action.payload,
        },
      };

    case ActionTypes.SET_USER_APPLIED_JOBS:
      return {
        ...state,
        applied: action.payload,
      };

    case ActionTypes.SET_FETCHING_USER_SEARCH_ALARMS_LIST:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          alarms: action.payload,
        },
      };

    case ActionTypes.SET_USER_SEARCH_ALARMS_LIST:
      return {
        ...state,
        alarms: action.payload,
      };

    case ActionTypes.SET_USER_NOTIFICATIONS_LIST:
      return {
        ...state,
        notifications: action.payload,
      };

    case ActionTypes.SET_FETCHING_USER_NOTIFICATIONS:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          notifications: action.payload,
        },
      };

    case ActionTypes.SET_FETCHING_USER_NOTIFICATIONS_COUNT:
      return {
        ...state,
        notificationsCount: {
          ...state.notificationsCount,
          isFetching: action.payload,
        },
      };

    case ActionTypes.SET_USER_NOTIFICATIONS_COUNT:
      return {
        ...state,
        notificationsCount: {
          ...state.notificationsCount,
          ...action.payload,
        },
      };

    case ActionTypes.UPDATE_USER_NOTIFICATIONS_LIST_BY_ID:
      const newNotifications = action.payload.filter(
        (newNotification) =>
          !state.notifications.some(
            (oldNotification) => oldNotification.id === newNotification.id
          )
      );

      if (newNotifications.length > 0) {
        return {
          ...state,
          notifications: [...action.payload, ...state.notifications],
        };
      }

      return {
        ...state,
      };

    case ActionTypes.SET_ALL_USER_NOTIFICATIONS_AS_READ:
      if (state.notifications.length > 0) {
        const readNotifications = state.notifications.map((notification) => {
          notification.new = false;
          return notification;
        });

        return {
          ...state,
          notifications: readNotifications,
          notificationsCount: {
            ...state.notificationsCount,
            new: 0,
          },
        };
      }

      return {
        ...state,
      };

    case ActionTypes.SET_USER_PROFILE:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload,
        },
      };

    case ActionTypes.REMOVE_USER_SAVED_JOBS_LIST_WITH_UNSAVED_PROP:
      return {
        ...state,
        saved: [...state.saved.filter((item) => item.vacancy.saved)],
      };

    case ActionTypes.ADD_SAVED_VACANCIE_TO_USER_SAVED_JOBS:
      if (
        state.saved.some(
          (item) => item.vacancy_id === action.payload.vacancy_id
        )
      )
        return state;

      return {
        ...state,
        saved: [action.payload, ...state.saved],
      };

    case ActionTypes.ADD_APPLIED_VACANCIE_TO_USER_APPLIED_JOBS:
      if (
        state.applied.some(
          (item) => item.vacancy_id === action.payload.vacancy_id
        )
      )
        return state;

      return {
        ...state,
        applied: [action.payload, ...state.applied],
      };

    case ActionTypes.ADD_USER_SEARCH_ALARM:
      return {
        ...state,
        alarms: [action.payload, ...state.alarms],
      };

    case ActionTypes.REMOVE_SAVED_VACANCIE_FROM_USER_SAVED_JOBS_BY_ID:
      return {
        ...state,
        saved: [
          ...state.saved.filter((item) => item.vacancy_id !== action.payload),
        ],
      };

    case ActionTypes.REMOVE_USER_SEARCH_ALARM_BY_ID:
      return {
        ...state,
        alarms: [...state.alarms.filter((item) => item.id !== action.payload)],
      };

    case ActionTypes.CHANGE_USER_SEARCH_ALARM_BY_ID:
      const { id, data } = action.payload;
      let listIdx;

      let clone = JSON.parse(
        JSON.stringify(
          ...state.alarms.filter((item, idx) => {
            if (item.id === id) {
              listIdx = idx;
              return true;
            } else return false;
          })
        )
      );

      if (clone) {
        clone = { ...clone, ...data };

        return {
          ...state,
          alarms: [
            ...state.alarms.slice(0, listIdx),
            clone,
            ...state.alarms.slice(listIdx + 1),
          ],
        };
      } else return state;

    case ActionTypes.TOGGLE_SAVED_JOBS_VACANCIE_SAVED_PROP_BY_ID:
      if (state.saved.length > 0) {
        const { id, saved } = action.payload;
        let listIdx;

        const clone = JSON.parse(
          JSON.stringify(
            ...state.saved.filter((item, idx) => {
              if (item.vacancy_id === id) {
                listIdx = idx;
                return true;
              } else return false;
            })
          )
        );

        if (clone) {
          clone.vacancy.saved = saved;

          return {
            ...state,
            saved: [
              ...state.saved.slice(0, listIdx),
              clone,
              ...state.saved.slice(listIdx + 1),
            ],
          };
        } else return state;
      } else return state;

    case ActionTypes.TOGGLE_TOP_MATCHES_VACANCIE_SAVED_PROP_BY_ID:
      if (state.topMatches.length > 0) {
        const { id, saved } = action.payload;
        let listIdx;

        const clone = Object.assign(
          {},
          ...state.topMatches.filter((item, idx) => {
            if (item.id === id) {
              listIdx = idx;
              return true;
            } else return false;
          })
        );

        if (clone && (clone.saved === true || clone.saved === false)) {
          clone.saved = saved;

          return {
            ...state,
            topMatches: [
              ...state.topMatches.slice(0, listIdx),
              clone,
              ...state.topMatches.slice(listIdx + 1),
            ],
          };
        } else return state;
      } else return state;

    case ActionTypes.SET_POST_JOB_COMPANY_TITLE:
      return {
        ...state,
        post: {
          ...state.post,
          companyNameTitle: action.payload,
        },
      };

    case ActionTypes.SET_POST_JOB_SELECTED_COMPANY:
      return {
        ...state,
        post: {
          ...state.post,
          selectedCompany: action.payload,
        },
      };

    case ActionTypes.SET_POST_JOB_ROLE:
      return {
        ...state,
        post: {
          ...state.post,
          jobRole: action.payload,
        },
      };

    case ActionTypes.SET_POST_JOB_TITLE:
      return {
        ...state,
        post: {
          ...state.post,
          jobTitle: action.payload,
        },
      };

    case ActionTypes.SET_POST_JOB_SKILLS_TITLE:
      return {
        ...state,
        post: {
          ...state.post,
          jobSkillsTitle: action.payload,
        },
      };

    case ActionTypes.SET_POST_JOB_DETAILS_HTML:
      return {
        ...state,
        post: {
          ...state.post,
          jobDetailsHtml: action.payload,
        },
      };

    case ActionTypes.SET_POST_JOB_EXPERIENCE:
      return {
        ...state,
        post: {
          ...state.post,
          jobExperience: action.payload,
        },
      };

    case ActionTypes.SET_POST_JOB_ROLES_LIST:
      return {
        ...state,
        post: {
          ...state.post,
          rolesList: action.payload,
        },
      };

    case ActionTypes.SET_POST_JOB_ACTIVE_ROLE:
      return {
        ...state,
        post: {
          ...state.post,
          jobRole: action.payload,
        },
      };

    case ActionTypes.SET_POST_JOB_APLICATION_LINK:
      return {
        ...state,
        post: {
          ...state.post,
          applicationLink: action.payload,
        },
      };

    case ActionTypes.SET_POST_JOB_LOCATION_TITLE:
      return {
        ...state,
        post: {
          ...state.post,
          jobLocationTitle: action.payload,
        },
      };

    case ActionTypes.SET_USER_POST_MAIN_LOGO:
      return {
        ...state,
        post: {
          ...state.post,
          logoMain: action.payload,
        },
      };

    case ActionTypes.SET_USER_POST_COVER_IMAGE:
      return {
        ...state,
        post: {
          ...state.post,
          coverImage: action.payload,
        },
      };

    case ActionTypes.REMOVE_POST_JOB_LOCATION_SLUG:
      return Object.assign({}, state, {
        ...state,
        post: {
          ...state.post,
          selectedLocations: state.post.selectedLocations.filter(
            (item) => item.id !== action.payload.id
          ),
        },
      });

    case ActionTypes.ADD_POST_JOB_LOCATION_SLUG:
      if (
        !state.post.selectedLocations.some(
          (slug) => action.payload.id === slug.id
        )
      ) {
        if (action.payload.id === 1)
          return Object.assign({}, state, {
            ...state,
            post: {
              ...state.post,
              remote: true,
              selectedLocations: [
                action.payload,
                ...state.post.selectedLocations,
              ],
            },
          });
        else {
          return Object.assign({}, state, {
            ...state,
            post: {
              ...state.post,
              selectedLocations: [
                ...state.post.selectedLocations,
                action.payload,
              ],
            },
          });
        }
      }

      return state;

    case ActionTypes.REMOVE_POST_JOB_SKILL_SLUG:
      return Object.assign({}, state, {
        ...state,
        post: {
          ...state.post,
          selectedSkills: state.post.selectedSkills.filter(
            (item) => item.id !== action.payload.id
          ),
        },
      });

    case ActionTypes.ADD_POST_JOB_SKILL_SLUG:
      if (
        !state.post.selectedSkills.some((slug) => action.payload.id === slug.id)
      ) {
        return Object.assign({}, state, {
          ...state,
          post: {
            ...state.post,
            selectedSkills: [...state.post.selectedSkills, action.payload],
          },
        });
      }

      return state;

    case ActionTypes.SET_POST_JOB_LOCATION_REMOTE_BY_VALUE_AND_DATE:
      if (action.payload.remote) {
        return Object.assign({}, state, {
          ...state,
          post: {
            ...state.post,
            remote: true,
            selectedLocations: [
              action.payload.item,
              ...state.post.selectedLocations,
            ],
          },
        });
      } else {
        return Object.assign({}, state, {
          ...state,
          post: {
            ...state.post,
            remote: false,
            selectedLocations: state.post.selectedLocations.filter(
              (item) => item.id !== 1
            ),
          },
        });
      }

    case ActionTypes.SET_DASHBOARD_JOB_USER_STATUS_BY_ID:
      let jobs = state.dashboard.jobs.slice(0);

      jobs.map((job) => {
        if (job.id === action.payload.jobId) {
          job['applied-jobs'].map(
            (user) =>
              user.id === action.payload.userId &&
              (user.status = action.payload.status)
          );
          return false;
        }
        return false;
      });

      return Object.assign({}, state, {
        ...state,
        dashboard: {
          ...state.dashboard,
          jobs,
        },
      });

    case ActionTypes.SET_FETCHING_USER_DASHBOARD_JOB_LIST_BY_ID:
      return Object.assign({}, state, {
        ...state,
        dashboard: {
          ...state.dashboard,
          isFetching: {
            ...state.dashboard.isFetching,
            jobs: action.payload,
          },
        },
      });

    case ActionTypes.SET_FETCHING_USER_DASHBOARD_USER_STATUS:
      return Object.assign({}, state, {
        ...state,
        dashboard: {
          ...state.dashboard,
          isFetching: {
            ...state.dashboard.isFetching,
            status: action.payload,
          },
        },
      });

    case ActionTypes.SET_POST_JOB_SENIORITY:
      return Object.assign({}, state, {
        ...state,
        post: {
          ...state.post,
          jobSeniority: action.payload,
        },
      });

    case ActionTypes.SET_USER_DASHBOARD_JOB_LIST:
      return Object.assign({}, state, {
        ...state,
        dashboard: {
          ...state.dashboard,
          jobs: action.payload,
        },
      });

    case ActionTypes.ADD_USER_POST_JOB_TO_DASHBOARD:
      return Object.assign({}, state, {
        ...state,
        dashboard: {
          ...state.dashboard,
          jobs: [action.payload, ...state.dashboard.jobs],
        },
      });

    case ActionTypes.SET_POST_JOB_ID:
      return Object.assign({}, state, {
        ...state,
        post: {
          ...state.post,
          id: action.payload,
        },
      });

    case ActionTypes.SET_POST_JOB_PLANS:
      return Object.assign({}, state, {
        ...state,
        post: {
          ...state.post,
          plans: action.payload,
          plan: 0,
        },
      });

    case ActionTypes.SET_POST_JOB:
      return Object.assign({}, state, {
        ...state,
        post: {
          ...state.post,
          id: action.payload.id,
          jobTitle: action.payload.name,
          companyNameTitle: action.payload.company.name,
          jobRole: action.payload.role,
          // logoMain: action.payload.company.logo,
          // coverImage: { url: action.payload.company.cover },
          jobSkillsTitle: action.payload.skills_string,
          jobDetailsHtml: action.payload.details,
          jobExperience: [
            action.payload.experience_from,
            action.payload.experience_up,
          ],
          selectedCompany: action.payload.company,
          selectedLocations: action.payload.locations,
          selectedSkills: action.payload.skills,
          applicationLink: action.payload.application_link,
          created: action.payload.created,
          status: action.payload.status,
          plans: action.payload.plans ? action.payload.plans : state.post.plans,
          plan: action.payload.plan,
          jobSeniority: action.payload.seniority,
        },
      });

    case ActionTypes.SET_COACH_SETUP:
      return Object.assign({}, state, {
        ...state,
        coach: {
          ...state.coach,
          id: action.payload.id,
          agreeTerms: action.payload.agreeTerms || null,
          coachName: action.payload.general.name,
          coachSurname: action.payload.general.surname,
          jobRole: action.payload.role,
          // logoMain: action.payload.company.logo,
          coverImage: action.payload.cover
            ? { url: action.payload.cover }
            : null,
          coachHeadline: action.payload.headline,
          coachDetailsHtml: action.payload.details || '',
          coachCurrentCompany: action.payload.general.company,
          coachCurrentCompanyTitle: action.payload.general.company.name,
          coachPreviousCompanies: action.payload.previous_companies,
          coachPreviousCompaniesTitle:
            action.payload.previous_companies_string || '',
          coachLocation: action.payload.general.location,
          coachUrl: action.payload.url,
          coachCachedUrl: action.payload.url,
          rolesTree: action.payload.rt_tree.roles,
          coachServicesOffered: action.payload.services,
          coachSocials: action.payload.links,
          status: action.payload.status,
          rt_tree: action.payload.rt_tree,
          availability_settings: action.payload.availability_settings || null,
          availability: action.payload.availability || [],
          calendars: action.payload.calendars || [],
          isLoaded: true,
          modified: action.payload.modified,
          paypal: action.payload.paypal || '',
          isCreated: action.payload.availability_settings ? true : false,
          rating: action.payload.rating,
          reviewsCount: action.payload.reviewsCount,
        },
      });

    case ActionTypes.CLEAR_USER_STORE_POST:
      return Object.assign({}, state, {
        ...state,
        post: PostJobTypes.postJobInitState,
      });

    case ActionTypes.SET_COACH_INIT_STATE:
      return Object.assign({}, state, {
        ...state,
        coach: {
          ...CoachingProfileTypes.coachingProfileInitState,
          isLoaded: state.coach.isLoaded,
          isCreated: state.coach.isCreated,
          coachUrl: state.coach.coachUrl,
          status: state.coach.status,
          agreeTerms: state.coach.agreeTerms,
          id: state.coach.id,
          paypal: state.coach.paypal,
          rating: state.coach.rating,
          reviewsCount: state.coach.reviewsCount,
        },
      });

    case ActionTypes.DELETE_USER_DASHBOARD_JOB_FROM_LIST_BY_ID:
      return Object.assign({}, state, {
        ...state,
        dashboard: {
          ...state.dashboard,
          jobs: [
            ...state.dashboard.jobs.filter((job) => job.id !== action.payload),
          ],
        },
      });

    case ActionTypes.SET_DASHBOARD_PUBLISHING_COASTS:
      return Object.assign({}, state, {
        ...state,
        dashboard: {
          ...state.dashboard,
          coasts: [
            ...state.dashboard.publishingPlans.map((plan) => {
              return action.payload.map((newCoast) => {
                if (plan.title === newCoast.name) plan.price = newCoast.price;
                return newCoast;
              });
            }),
          ],
        },
      });

    case ActionTypes.TOGGLE_PUBLISH_STATUS_DASHBOARD_PUBLISHED_JOB_BY_ID:
      if (state.dashboard.jobs.length > 0) {
        const { id, status } = action.payload;
        let listIdx;

        const clone = JSON.parse(
          JSON.stringify(
            ...state.dashboard.jobs.filter((item, idx) => {
              if (item.id === id) {
                listIdx = idx;
                return true;
              } else return false;
            })
          )
        );

        if (clone) {
          clone.status = status;

          return {
            ...state,
            dashboard: {
              ...state.dashboard,
              jobs: [
                ...state.dashboard.jobs.slice(0, listIdx),
                clone,
                ...state.dashboard.jobs.slice(listIdx + 1),
              ],
            },
          };
        } else return state;
      } else return state;

    case ActionTypes.UPDATE_USER_DASHBOARD_VACANCIE_BY_ID:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          jobs: [
            ...state.dashboard.jobs.map((job) =>
              job.id === action.payload.id ? action.payload.vacancy : job
            ),
          ],
        },
      };

    case ActionTypes.SET_USER_RELATED_JOBS:
      return {
        ...state,
        related: action.payload,
      };

    case ActionTypes.SET_LOADING_USER_RELATED_JOBS:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          related: action.payload,
        },
      };

    case ActionTypes.SET_FETCHING_COACH_UPDATE:
    case ActionTypes.SET_FETCHING_COACH_BY_ID_UPDATE:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          coach: action.payload,
        },
      };

    case ActionTypes.SET_FETCHING_USER_TOP_MATCHES_VACANCIES:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          topMatches: action.payload,
        },
      };

    case ActionTypes.RECEIV_USER_TOP_MATCHES_VACANCIES:
      return {
        ...state,
        topMatches: action.payload,
      };

    case ActionTypes.CLEAR_USER_TOP_MATCHES_VACANCIES:
      return {
        ...state,
        topMatches: [],
      };

    case ActionTypes.SET_USER_ROLES:
      return {
        ...state,
        roles: action.payload,
      };

    case ActionTypes.SET_COACH_HEADLINE:
      return {
        ...state,
        coach: {
          ...state.coach,
          coachHeadline: action.payload,
        },
      };

    case ActionTypes.FETCH_COACH_TERMS_UPDATE_SUCCESS:
      return {
        ...state,
        coach: {
          ...state.coach,
          agreeTerms: action.payload,
        },
      };

    case ActionTypes.SET_COACH_NAME:
      return {
        ...state,
        coach: {
          ...state.coach,
          coachName: action.payload,
        },
      };

    case ActionTypes.SET_COACH_SURNAME:
      return {
        ...state,
        coach: {
          ...state.coach,
          coachSurname: action.payload,
        },
      };

    case ActionTypes.SET_COACH_LOCATION:
      return {
        ...state,
        coach: {
          ...state.coach,
          coachLocation: action.payload,
        },
      };

    case ActionTypes.SET_USER_COACH_COVER_IMAGE:
      return {
        ...state,
        coach: {
          ...state.coach,
          coverImage: action.payload,
        },
      };

    case ActionTypes.SET_COACH_DETAILS_HTML:
      return {
        ...state,
        coach: {
          ...state.coach,
          coachDetailsHtml: action.payload,
        },
      };

    case ActionTypes.SET_COACH_CURRENT_COMPANY:
      return {
        ...state,
        coach: {
          ...state.coach,
          coachCurrentCompany: action.payload,
        },
      };

    case ActionTypes.SET_COACH_CURRENT_COMPANY_TITLE:
      return {
        ...state,
        coach: {
          ...state.coach,
          coachCurrentCompanyTitle: action.payload,
        },
      };

    case ActionTypes.REMOVE_COACH_PREVIOUS_COMPANY:
      return Object.assign({}, state, {
        ...state,
        coach: {
          ...state.coach,
          coachPreviousCompanies: state.coach.coachPreviousCompanies.filter(
            (item) => item.id !== action.payload.id
          ),
        },
      });

    case ActionTypes.SET_COACH_PREVIOUS_COMPANIES_TITLE:
      return {
        ...state,
        coach: {
          ...state.coach,
          coachPreviousCompaniesTitle: action.payload,
        },
      };

    case ActionTypes.ADD_COACH_PREVIOUS_COMPANY:
      if (
        !state.coach.coachPreviousCompanies.some(
          (slug) => action.payload.id === slug.id
        )
      ) {
        return Object.assign({}, state, {
          ...state,
          coach: {
            ...state.coach,
            coachPreviousCompanies: [
              ...state.coach.coachPreviousCompanies,
              action.payload,
            ],
          },
        });
      }

      return state;

    case ActionTypes.SET_COACH_URL:
      return {
        ...state,
        coach: {
          ...state.coach,
          coachUrl: action.payload,
        },
      };

    case ActionTypes.SET_COACH_CAHCED_URL:
      return {
        ...state,
        coach: {
          ...state.coach,
          coachCachedUrl: action.payload,
        },
      };

    case ActionTypes.FETCH_COACH_CREATE_SUCCESS:
      return {
        ...state,
        profile: {
          ...state.profile,
          coach_id: action.payload,
        },
        coach: {
          ...state.coach,
          id: action.payload,
        },
      };

    case ActionTypes.SET_COACH_SOCIAL_LINKS:
      return {
        ...state,
        coach: {
          ...state.coach,
          coachSocials: action.payload,
        },
      };

    case ActionTypes.SET_COACH_ROLES_TREE:
      return {
        ...state,
        coach: {
          ...state.coach,
          rolesTree: action.payload,
        },
      };

    case ActionTypes.SET_COACH_DATA:
      return {
        ...state,
        coach: {
          ...state.coach,
          ...action.payload,
        },
      };

    case ActionTypes.FETCH_DELETE_COACH_CALENDAR_SUCCESS:
      return {
        ...state,
        coach: {
          ...state.coach,
          calendars: state.coach.calendars.length
            ? [...state.coach.calendars.filter((c) => c.id !== action.payload)]
            : [],
        },
      };

    case ActionTypes.ADD_COACH_CALENDAR:
      return {
        ...state,
        coach: {
          ...state.coach,
          calendars: [...state.coach.calendars, action.payload],
        },
      };

    case ActionTypes.FETCH_SELECT_COACH_CALENDAR_SUCCESS:
    case ActionTypes.FETCH_REFRESH_COACH_CALENDAR_LIST_SUCCESS:
      return {
        ...state,
        coach: {
          ...state.coach,
          calendars: [
            ...state.coach.calendars.map((c) =>
              c.id === action.payload.id ? action.payload : c
            ),
          ],
        },
      };

    case ActionTypes.FETCH_COACHES_SERVICES_UPDATE_SUCCESS:
      return {
        ...state,
        coach: {
          ...state.coach,
          services: action.payload,
        },
      };

    case ActionTypes.FETCH_COACH_SETUP_STATUS_SUCCESS:
      return {
        ...state,
        coach: {
          ...state.coach,
          status: action.payload,
        },
      };

    case ActionTypes.SET_FETCHING_COACH_PAYOUTS:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          isFetching: {
            ...state.dashboard.isFetching,
            coachPayouts: action.payload,
          },
        },
      };

    case ActionTypes.FETCH_COACH_PAYOUTS_SUCCESS:
      return {
        ...state,
        dashboard: {
          ...state.dashboard,
          coachPayouts: action.payload,
        },
      };

    case ActionTypes.SET_FETCHING_GET_COACH_REVIEWS_BY_ID:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          reviews: action.payload,
        },
      };

    case ActionTypes.FETCH_SEND_SESSION_REVIEW_SUCCESS:
      return {
        ...state,
        coachingSessions: {
          ...state.coachingSessions,
          list: [
            ...state.coachingSessions.list.map((session) => {
              if (session.id === action.payload.sessionId) {
                return {
                  ...session,
                  reviews: [...session.reviews, action.payload],
                };
              }

              return session;
            }),
          ],
        },
      };

    default:
      return state;
  }
}
