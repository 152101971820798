import React from 'react';
import classnames from 'classnames';

import { ReactComponent as IconSearch } from 'assets/images/svg/icon-search.svg';
import { ReactComponent as IconIn } from 'assets/images/svg/socials/icon-in.svg';
import { ReactComponent as IconInSimple } from 'assets/images/svg/socials/icon-in-simple.svg';
import { ReactComponent as IconFb } from 'assets/images/svg/socials/icon-fb.svg';
import { ReactComponent as IconFbBlue } from 'assets/images/svg/socials/icon-fb-blue.svg';
import { ReactComponent as IconTw } from 'assets/images/svg/socials/icon-tw.svg';
import { ReactComponent as IconGoogle } from 'assets/images/svg/socials/icon-google.svg';
import { ReactComponent as IconDiamond } from 'assets/images/svg/icon-diamond.svg';
import { ReactComponent as IconClock } from 'assets/images/svg/icon-clock.svg';
import { ReactComponent as IconFire } from 'assets/images/svg/icon-fire.svg';
import { ReactComponent as IconGeo } from 'assets/images/svg/icon-geo.svg';
import { ReactComponent as IconBookmark } from 'assets/images/svg/icon-bookmark.svg';
import { ReactComponent as IconAlarm } from 'assets/images/svg/icon-alarm.svg';
import { ReactComponent as IconArrowLeft } from 'assets/images/svg/icon-arrow-left.svg';
import { ReactComponent as IconArrowDropdownUp } from 'assets/images/svg/icon-arrow-dropdown-up.svg';
import { ReactComponent as IconArrowReset } from 'assets/images/svg/icon-arrow-reset.svg';
import { ReactComponent as IconSceletonLogo } from 'assets/images/svg/icon-skeleton-logo.svg';
import { ReactComponent as IconShare } from 'assets/images/svg/icon-share.svg';
import { ReactComponent as IconEmojyHappy } from 'assets/images/svg/icon-emojy-happy.svg';
import { ReactComponent as IconMail } from 'assets/images/svg/icon-mail.svg';
import { ReactComponent as IconMessenger } from 'assets/images/svg/socials/icon-messenger.svg';
import { ReactComponent as IconWhatsApp } from 'assets/images/svg/socials/icon-whats-app.svg';
import { ReactComponent as IconCopy } from 'assets/images/svg/icon-copy.svg';
import { ReactComponent as IconCloseCross } from 'assets/images/svg/icon-close-cross.svg';
import { ReactComponent as IconCheck } from 'assets/images/svg/icon-check.svg';
import { ReactComponent as IconCheckCircle } from 'assets/images/svg/icon-check-circle.svg';
import { ReactComponent as IconLogoPlaceholder } from 'assets/images/svg/logo-placeholder.svg';
import { ReactComponent as IconBell } from 'assets/images/svg/icon-bell.svg';
import { ReactComponent as IconBellSolid } from 'assets/images/svg/icon-bell-solid.svg';
import { ReactComponent as IconSettings } from 'assets/images/svg/icon-settings.svg';
import { ReactComponent as IconPaperPlane } from 'assets/images/svg/icon-paper-plane.svg';
import { ReactComponent as IconPaperPlusCircle } from 'assets/images/svg/icon-plus-circle.svg';
import { ReactComponent as IconPaperSignOut } from 'assets/images/svg/icon-sign-out.svg';
import { ReactComponent as IconPaperSignIn } from 'assets/images/svg/icon-sign-in.svg';
import { ReactComponent as IconPlus } from 'assets/images/svg/icon-plus.svg';
import { ReactComponent as IconSuitcase } from 'assets/images/svg/icon-suitcase.svg';
import { ReactComponent as IconQuestionCircle } from 'assets/images/svg/icon-question-circle.svg';
import { ReactComponent as IconArrowSquareRight } from 'assets/images/svg/icon-arrow-square-right.svg';
import { ReactComponent as IconSync } from 'assets/images/svg/icon-sync.svg';
import { ReactComponent as IconLock } from 'assets/images/svg/icon-lock.svg';
import { ReactComponent as IconCloudUpload } from 'assets/images/svg/icon-cloud-upload.svg';
import { ReactComponent as UserAvatar0 } from 'assets/images/profile/avatar/avatar-0.svg';
import { ReactComponent as UserAvatar1 } from 'assets/images/profile/avatar/avatar-1.svg';
import { ReactComponent as UserAvatar2 } from 'assets/images/profile/avatar/avatar-2.svg';
import { ReactComponent as UserAvatar3 } from 'assets/images/profile/avatar/avatar-3.svg';
import { ReactComponent as UserAvatar4 } from 'assets/images/profile/avatar/avatar-4.svg';
import { ReactComponent as UserAvatar5 } from 'assets/images/profile/avatar/avatar-5.svg';
import { ReactComponent as UserAvatar6 } from 'assets/images/profile/avatar/avatar-6.svg';
import { ReactComponent as IconFilePDF } from 'assets/images/svg/icon-file-pdf.svg';
import { ReactComponent as IconThumbsUp } from 'assets/images/svg/icon-thumbs-up.svg';
import { ReactComponent as IconHourglass } from 'assets/images/svg/icon-hourglass.svg';
import { ReactComponent as IconInfo } from 'assets/images/svg/icon-info.svg';
import { ReactComponent as IconLink } from 'assets/images/svg/icon-link.svg';
import { ReactComponent as IconChevronLeft } from 'assets/images/svg/icon-chevron-left.svg';
import { ReactComponent as IconChevronRight } from 'assets/images/svg/icon-chevron-right.svg';
import { ReactComponent as IconChevronDown } from 'assets/images/svg/icon-chevron-down.svg';
import { ReactComponent as IconFileText } from 'assets/images/svg/icon-file-text.svg';
import { ReactComponent as IconClone } from 'assets/images/svg/icon-clone.svg';
import { ReactComponent as IconPencil } from 'assets/images/svg/icon-pencil.svg';
import { ReactComponent as IconTrash } from 'assets/images/svg/icon-trash.svg';
import { ReactComponent as IconPause } from 'assets/images/svg/icon-pause.svg';
import { ReactComponent as IconPlay } from 'assets/images/svg/icon-play.svg';
import { ReactComponent as IconArrowCircleUp } from 'assets/images/svg/icon-arrow-circle-up.svg';
import { ReactComponent as IconEye } from 'assets/images/svg/icon-eye.svg';
import { ReactComponent as IconDownload } from 'assets/images/svg/icon-download.svg';
import { ReactComponent as IconList } from 'assets/images/svg/icon-list.svg';
import { ReactComponent as IconLogoResponsive } from 'assets/images/svg/ph-responsive-logo.svg';
import { ReactComponent as IconCalendar } from 'assets/images/svg/icon-calendar.svg';
import { ReactComponent as IconStar } from 'assets/images/svg/icon-star.svg';
import { ReactComponent as IconBuilding } from 'assets/images/svg/icon-building.svg';
import { ReactComponent as IconZoom } from 'assets/images/svg/socials/icon-zoom.svg';
import { ReactComponent as IconOutlook } from 'assets/images/svg/socials/icon-outlook.svg';
import { ReactComponent as IconPaypal } from 'assets/images/svg/socials/icon-paypal.svg';
import { ReactComponent as IconGmail } from 'assets/images/svg/socials/icon-gmail.svg';

import './AppIcon.scss';

const AppIcon = ({ icon, onClick, size, margin, color, className }) => {
  const classes = `app-icon app-icon-${icon}`;

  return (
    <div
      className={classnames(
        'app-icon-wrapper',
        {
          [`--size-${size}`]: size,
          [`--margin-${margin}`]: margin,
          [`--color-${color}`]: color,
        },
        className
      )}
      onClick={onClick}
    >
      {renderIcon(icon, classes)}
    </div>
  );
};

function renderIcon(icon, classes) {
  switch (icon) {
    case 'avatar-0':
      return <UserAvatar0 className={classes} alt={icon} />;

    case 'avatar-1':
      return <UserAvatar1 className={classes} alt={icon} />;

    case 'avatar-2':
      return <UserAvatar2 className={classes} alt={icon} />;

    case 'avatar-3':
      return <UserAvatar3 className={classes} alt={icon} />;

    case 'avatar-4':
      return <UserAvatar4 className={classes} alt={icon} />;

    case 'avatar-5':
      return <UserAvatar5 className={classes} alt={icon} />;

    case 'avatar-6':
      return <UserAvatar6 className={classes} alt={icon} />;

    case 'question-circle':
      return <IconQuestionCircle className={classes} alt={icon} />;

    case 'cloud-upload':
      return <IconCloudUpload className={classes} alt={icon} />;

    case 'lock':
      return <IconLock className={classes} alt={icon} />;

    case 'sync':
      return <IconSync className={classes} alt={icon} />;

    case 'arrow-square-right':
      return <IconArrowSquareRight className={classes} alt={icon} />;

    case 'suitcase':
      return <IconSuitcase className={classes} alt={icon} />;

    case 'settings':
      return <IconSettings className={classes} alt={icon} />;

    case 'plus':
      return <IconPlus className={classes} alt={icon} />;

    case 'paper-plane':
      return <IconPaperPlane className={classes} alt={icon} />;

    case 'plus-circle':
      return <IconPaperPlusCircle className={classes} alt={icon} />;

    case 'sign-out':
      return <IconPaperSignOut className={classes} alt={icon} />;

    case 'sign-in':
      return <IconPaperSignIn className={classes} alt={icon} />;

    case 'bell':
      return <IconBell className={classes} alt={icon} />;

    case 'bell-solid':
      return <IconBellSolid className={classes} alt={icon} />;

    case 'logo-placeholder':
      return <IconLogoPlaceholder className={classes} alt={icon} />;

    case 'copy':
      return <IconCopy className={classes} alt={icon} />;

    case 'close-cross':
      return <IconCloseCross className={classes} alt={icon} />;

    case 'whats-app':
      return <IconWhatsApp className={classes} alt={icon} />;

    case 'messenger':
      return <IconMessenger className={classes} alt={icon} />;

    case 'mail':
      return <IconMail className={classes} alt={icon} />;

    case 'emojy-happy':
      return <IconEmojyHappy className={classes} alt={icon} />;

    case 'share':
      return <IconShare className={classes} alt={icon} />;

    case 'search':
      return <IconSearch className={classes} alt={icon} />;

    case 'in':
      return <IconIn className={classes} alt={icon} />;

    case 'in-simple':
      return <IconInSimple className={classes} alt={icon} />;

    case 'fb':
      return <IconFb className={classes} alt={icon} />;

    case 'fb-blue':
      return <IconFbBlue className={classes} alt={icon} />;

    case 'tw':
      return <IconTw className={classes} alt={icon} />;

    case 'google':
      return <IconGoogle className={classes} alt={icon} />;

    case 'clock':
      return <IconClock className={classes} alt={icon} />;

    case 'fire':
      return <IconFire className={classes} alt={icon} />;

    case 'bookmark':
      return <IconBookmark className={classes} alt={icon} />;

    case 'geo':
      return <IconGeo className={classes} alt={icon} />;

    case 'diamond':
      return <IconDiamond className={classes} alt={icon} />;

    case 'alarm':
      return <IconAlarm className={classes} alt={icon} />;

    case 'arrow-left':
      return <IconArrowLeft className={classes} alt={icon} />;

    case 'arrow-dropdown-up':
      return <IconArrowDropdownUp className={classes} alt={icon} />;

    case 'arrow-reset':
      return <IconArrowReset className={classes} alt={icon} />;

    case 'skeleton-logo':
      return <IconSceletonLogo className={classes} alt={icon} />;

    case 'check':
      return <IconCheck className={classes} alt={icon} />;

    case 'check-circle':
      return <IconCheckCircle className={classes} alt={icon} />;

    case 'file-pdf':
      return <IconFilePDF className={classes} alt={icon} />;

    case 'thumbs-up':
      return <IconThumbsUp className={classes} alt={icon} />;

    case 'hourglass':
      return <IconHourglass className={classes} alt={icon} />;

    case 'info':
      return <IconInfo className={classes} alt={icon} />;

    case 'link':
      return <IconLink className={classes} alt={icon} />;

    case 'chevron-left':
      return <IconChevronLeft className={classes} alt={icon} />;

    case 'chevron-down':
      return <IconChevronDown className={classes} alt={icon} />;

    case 'chevron-right':
      return <IconChevronRight className={classes} alt={icon} />;

    case 'file-text':
      return <IconFileText className={classes} alt={icon} />;

    case 'clone':
      return <IconClone className={classes} alt={icon} />;

    case 'pencil':
      return <IconPencil className={classes} alt={icon} />;

    case 'trash':
      return <IconTrash className={classes} alt={icon} />;

    case 'arrow-circle-up':
      return <IconArrowCircleUp className={classes} alt={icon} />;

    case 'play':
      return <IconPlay className={classes} alt={icon} />;

    case 'pause':
      return <IconPause className={classes} alt={icon} />;

    case 'eye':
      return <IconEye className={classes} alt={icon} />;

    case 'download':
      return <IconDownload className={classes} alt={icon} />;

    case 'list':
      return <IconList className={classes} alt={icon} />;

    case 'logo-responsive':
      return <IconLogoResponsive className={classes} alt={icon} />;

    case 'calendar':
      return <IconCalendar className={classes} alt={icon} />;

    case 'star':
      return <IconStar className={classes} alt={icon} />;

    case 'close':
      return <span className={classes} alt={icon} />;

    case 'sort':
      return <IconChevronDown className={classes} alt={icon} />;

    case 'building':
      return <IconBuilding className={classes} alt={icon} />;

    case 'gmail':
      return <IconGmail className={classes} alt={icon} />;

    case 'outlook':
      return <IconOutlook className={classes} alt={icon} />;

    case 'zoom':
      return <IconZoom className={classes} alt={icon} />;

    case 'paypal':
      return <IconPaypal className={classes} alt={icon} />;

    default:
      return null;
  }
}

export default AppIcon;
