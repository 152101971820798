import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import AppJobsCardSave from 'components/shared/AppJobsCard/AppJobsCardSave';
import AppSharedDropdown from 'components/shared/AppSharedDropdown';
import AppIcon from 'components/shared/AppIcon';
import AppButton from 'components/shared/AppButton';
import AppJobsCardDate from 'components/shared/AppJobsCard/AppJobsCardDate';
import AppJobsCardImg from 'components/shared/AppJobsCard/AppJobsCardImg';
import AppJobsCardLocations from 'components/shared/AppJobsCard/AppJobsCardLocations';
import AppJobsCardExperience from 'components/shared/AppJobsCard/AppJobsCardExperience';

import './AppJobsDetailsCard.scss';

class AppJobsDetailsCard extends Component {
  state = {
    isFetchingApply: false,
  };

  render() {
    const {
      fixed,
      data: {
        company,
        name,
        locations,
        published,
        experience_from,
        experience_up,
        application_link,
        id,
        saved,
        applied,
        status,
        plan_id,
      },
      search,
      details,
      undo,
      motivatedList,
      applyToggleHandler,
      applyIsActive,
      applyCardDetails,
      owner,
      onDeleteHandler,
      onEditHandler,
      setModal,
      animationBackHandler,
      isIndeed,
    } = this.props;

    return (
      <div className="app-jobs-card app-jobs-details-card">
        <div className="app-jobs-details-card__row">
          <div className="app-jobs-details-card__img">
            {company ? (
              <AppJobsCardImg
                size="small"
                id={id}
                img={company.logo}
                alt={company.name}
              />
            ) : (
              ''
            )}
          </div>

          <div className="app-jobs-details-card__actions">
            {applyIsActive ? (
              <button onClick={applyToggleHandler} className="app-button-back">
                <AppIcon
                  className="app-button-back__icon"
                  color="acsent"
                  icon="arrow-left"
                />
                <div className="app-button-back__title">
                  Back to job details
                </div>
              </button>
            ) : owner ? (
              <React.Fragment>
                <AppButton
                  title="Edit"
                  defaultStyle="secondary"
                  height="medium"
                  icon="pencil"
                  onClick={() => onEditHandler(id)}
                />
                <AppButton
                  title="Remove"
                  onClick={() => {
                    setModal({
                      active: 'confirm',
                      onYes: () => {
                        onDeleteHandler(id).then(() => animationBackHandler());
                      },
                      title: 'Are you sure you want to remove the Job?',
                    });
                  }}
                  defaultStyle="danger"
                  height="medium"
                  icon="trash"
                />
              </React.Fragment>
            ) : (
              <React.Fragment>
                <AppSharedDropdown jobTitle={name} />

                {!applyCardDetails ? (
                  <AppJobsCardSave
                    defaultStyle={'button'}
                    iconSaved="check"
                    title={saved ? 'Saved' : 'Save'}
                    className="app-jobs-card-save-details"
                    height="medium"
                    vacancieId={id}
                    saved={saved}
                    undo={undo}
                    search={search}
                    details={details}
                    motivated={motivatedList}
                  />
                ) : (
                  ''
                )}

                {application_link ? (
                  <AppButton
                    link={true}
                    href={application_link}
                    onClick={() => {
                      if (window.gtag) {
                        window.gtag('event', 'Apply Job', { label: id });
                      }
                    }}
                    target="_blank"
                    title={isIndeed ? 'Apply with Indeed' : 'Apply Job'}
                    defaultStyle="primary"
                    height="medium"
                    icon="share"
                    reverse
                  />
                ) : (
                  <AppButton
                    title={
                      applyCardDetails || applied ? 'Applied' : 'Apply Job'
                    }
                    onClick={applyToggleHandler}
                    className={applyCardDetails || applied ? '--applied' : ''}
                    defaultStyle="primary"
                    height="medium"
                    isDisabled={applyCardDetails || applied}
                    icon={applyCardDetails || applied ? 'paper-plane' : false}
                  />
                )}
              </React.Fragment>
            )}
          </div>
        </div>

        <div className="app-jobs-details-card__content">
          <div className="app-jobs-details-card__row">
            <div className="app-jobs-details-card__line">
              <div className="app-jobs-card__row --padded">
                <span title={name} className="app-jobs-card-title --vacancie">
                  {name}
                </span>
                <span className="app-text-dot"></span>
                <span
                  className={classnames('app-jobs-card-title', '--company', {
                    '--motivated': company.motivated || plan_id > 1,
                  })}
                >
                  {company.name}
                </span>
                {company.motivated || plan_id > 1 ? (
                  <span
                    className={classnames('app-jobs-card__label', '--inline', {
                      show: fixed,
                    })}
                    title="Motivated Jobs are jobs posted by companies who are actively engaged and seeking candidates."
                  >
                    <span>Motivated Company</span>
                  </span>
                ) : (
                  ''
                )}
              </div>

              <div className="app-jobs-card__row --flex">
                <AppJobsCardExperience
                  from={experience_from}
                  to={experience_up}
                />
                <AppJobsCardLocations locations={locations} />
                <AppJobsCardDate status={status} created={published} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AppJobsDetailsCard.propTypes = {
  company: PropTypes.object,
  name: PropTypes.string,
};

export default AppJobsDetailsCard;
