import React from 'react';
import classnames from 'classnames';
import './AppPanelListItem.scss';

const AppPanelListItem = (props) => {
    const { children, margin, double } = props;

    return (
        <li className={classnames(
            'app-panel-list-item',
            {
                [`app-panel-list-item--${margin}`] : margin,
                'app-panel-list-item--double' : double
            }
        )}>
            { children }
        </li>
    );
}

export default AppPanelListItem;
