import React, { Component } from 'react';
// import Moment from 'moment';
import PropTypes from 'prop-types';

import './AppJobsNewsCard.scss';

class AppJobsNewsCard extends Component {
    render() {
        const {
            title,
            descr,
            data,
            onClick,
        } = this.props;

        return (
            <div onClick={onClick} className={'app-jobs-news-card'}
            >
                <div className="app-jobs-news-card__data">{data}</div>
                <h4 className="app-jobs-news-card__title">{title}</h4>
                <div className="app-jobs-news-card__descr">{descr}</div>
            </div>
        );
    }
}

AppJobsNewsCard.propTypes = {
    company: PropTypes.object,
    name: PropTypes.string,
}

export default AppJobsNewsCard;
