import React from 'react';
import cn from 'classnames';
import Moment from 'moment';

import { renderEventDateAndTime, checkIsTodayDate } from 'utils/Dates';

import AppIcon from 'components/shared/AppIcon';

import './CardDate.scss';

function displayDataCreatedFromNow(date) {
  let dateFromNow = Moment(date).fromNow();

  if (checkIsTodayDate(dateFromNow)) {
    return (dateFromNow = 'today');
  }

  return dateFromNow[0].toUpperCase() + dateFromNow.slice(1);
}

function displayDateStart(date) {
  if (checkIsTodayDate(Moment(date).fromNow())) return 'today';
  return renderEventDateAndTime(date);
}

export const CardDate = function ({
  created,
  saved,
  applied,
  status,
  dateStart,
}) {
  let date;

  if (applied) date = 'Applied ' + Moment(applied).fromNow();
  else if (saved) {
    date =
      status === 'expired' ? 'Expired' : 'Saved ' + Moment(saved).fromNow();
  } else if (status) {
    if (status === 'expired') date = 'Expired';
    else if (status === 'draft') date = 'Draft';
    else if (status === 'paused') date = 'Paused';
    else if (status === 'public' && created)
      date = displayDataCreatedFromNow(created);
  } else if (dateStart) {
    date = displayDateStart(dateStart);
  } else date = displayDataCreatedFromNow(created);

  return (
    <div
      className={cn(
        'card-date',
        { '--acsent': date === 'today' },
        { '--acsent': status === 'expired' && !applied }
      )}
    >
      {date === 'today' && <AppIcon icon="fire" color="red" />}
      {status === 'expired' && !applied && (
        <AppIcon icon="hourglass" color="red" />
      )}
      {status === 'paused' && !applied && <AppIcon icon="pause" />}
      <span className="card-date__title">{date}</span>
    </div>
  );
};

export default CardDate;
