// SEARCH
export const REQUEST_INIT_STATE = 'REQUEST_INIT_STATE';
export const FETCH_INIT_STATE = 'FETCH_INIT_STATE';
export const SET_SEARCH_STATUS = 'SET_SEARCH_STATUS';

// INIT STATE
export const SET_EVENTS_INIT_STATE_LOADED = 'SET_EVENTS_INIT_STATE_LOADED';

// VACANCIES
export const GET_VACANCIES_BY_NAME = 'GET_VACANCIES_BY_NAME';
export const SET_FETCHING_VACANCIES = 'SET_FETCHING_VACANCIES';
export const SET_FETCHING_VACANCIE_BY_ID = 'SET_FETCHING_VACANCIE_BY_ID';
export const RECEIVE_VACANCIES = 'RECEIVE_VACANCIES';
export const UPDATE_VACANCIES = 'UPDATE_VACANCIES';
export const RECEIVE_VACANCIE_BY_ID = 'RECEIVE_VACANCIE_BY_ID';
export const RECEIVE_VACANCIES_COUNT_ALL = 'RECEIVE_VACANCIES_COUNT_ALL';
export const SET_FETCHING_VACANCIES_COUNT_ALL =
  'SET_FETCHING_VACANCIES_COUNT_ALL';
export const SET_VACANCIES_SORT = 'SET_VACANCIES_SORT';
export const FETCH_VACANCIE_BY_ID = 'FETCH_VACANCIE_BY_ID';
export const SET_FETCHING_VACANCIES_BY_ID = 'SET_FETCHING_VACANCIES_BY_ID';
export const TOGGLE_SAVED_JOBS_VACANCIE_SAVED_PROP_BY_ID =
  'TOGGLE_SAVED_JOBS_VACANCIE_SAVED_PROP_BY_ID';
export const TOGGLE_VACANCIE_SEARCH_LIST_SAVED_PROP_BY_ID =
  'TOGGLE_VACANCIE_SEARCH_LIST_SAVED_PROP_BY_ID';
export const TOGGLE_VACANCIE_BY_ID_SAVED_PROP =
  'TOGGLE_VACANCIE_BY_ID_SAVED_PROP';
export const REMOVE_USER_SAVED_JOBS_LIST_WITH_UNSAVED_PROP =
  'REMOVE_USER_SAVED_JOBS_LIST_WITH_UNSAVED_PROP';
export const REMOVE_SAVED_JOBS_USER_VACANCIE_BY_ID =
  'REMOVE_SAVED_JOBS_USER_VACANCIE_BY_ID';
export const ADD_SAVED_JOBS_USER_VACANCIE_BY_ID =
  'ADD_SAVED_JOBS_USER_VACANCIE_BY_ID';
export const ADD_SAVED_VACANCIE_TO_USER_SAVED_JOBS =
  'ADD_SAVED_VACANCIE_TO_USER_SAVED_JOBS';
export const REMOVE_SAVED_VACANCIE_FROM_USER_SAVED_JOBS_BY_ID =
  'REMOVE_SAVED_VACANCIE_FROM_USER_SAVED_JOBS_BY_ID';
export const SORT_VACANCIEST_DATA_NEWEST_FIRST =
  'SORT_VACANCIEST_DATA_NEWEST_FIRST';
export const SORT_VACANCIEST_DATA_OLDEST_FIRST =
  'SORT_VACANCIEST_DATA_OLDEST_FIRST';
export const SET_USER_POST_MAIN_LOGO = 'SET_USER_POST_MAIN_LOGO';
export const SET_USER_POST_COVER_IMAGE = 'SET_USER_POST_COVER_IMAGE';
export const TOGGLE_VACANCIE_MOTIVATED_LIST_SAVED_PROP_BY_ID =
  'TOGGLE_VACANCIE_MOTIVATED_LIST_SAVED_PROP_BY_ID';
export const SET_LOADING_RELATED_JOBS = 'SET_LOADING_RELATED_JOBS';
export const SET_RELATED_JOBS = 'SET_RELATED_JOBS';
export const SET_POST_JOB_SENIORITY = 'SET_POST_JOB_SENIORITY';
export const TOGGLE_VACANCIE_MOTIVATED_LIST_APPLIED_PROP_BY_ID =
  'TOGGLE_VACANCIE_MOTIVATED_LIST_APPLIED_PROP_BY_ID';
export const RECEIV_USER_TOP_MATCHES_VACANCIES =
  'RECEIV_USER_TOP_MATCHES_VACANCIES';
export const SET_FETCHING_USER_TOP_MATCHES_VACANCIES =
  'SET_FETCHING_USER_TOP_MATCHES_VACANCIES';
export const CLEAR_USER_TOP_MATCHES_VACANCIES =
  'CLEAR_USER_TOP_MATCHES_VACANCIES';
export const SET_FETCHING_JOB_DETAILS_CRAFT_DATA =
  'SET_FETCHING_JOB_DETAILS_CRAFT_DATA';
export const RECEIV_JOB_DETAILS_CRAFT_DATA = 'RECEIV_JOB_DETAILS_CRAFT_DATA';
export const TOGGLE_TOP_MATCHES_VACANCIE_SAVED_PROP_BY_ID =
  'TOGGLE_TOP_MATCHES_VACANCIE_SAVED_PROP_BY_ID';
export const SET_JOBS_PUBLISHING_PLANS = 'SET_JOBS_PUBLISHING_PLANS';

// POST A JOB
export const SET_POST_JOB_SELECTED_COMPANY = 'SET_POST_JOB_SELECTED_COMPANY';
export const SET_POST_JOB_COMPANY_TITLE = 'SET_POST_JOB_COMPANY_TITLE';
export const REMOVE_POST_JOB_SELECTED_COMPANY =
  'REMOVE_POST_JOB_SELECTED_COMPANY';
export const SET_POST_JOB_ROLE = 'SET_POST_JOB_ROLE';
export const SET_POST_JOB_TITLE = 'SET_POST_JOB_TITLE';
export const SET_POST_JOB_SKILLS_TITLE = 'SET_POST_JOB_SKILLS_TITLE';
export const SET_POST_JOB_DETAILS_HTML = 'SET_POST_JOB_DETAILS_HTML';
export const SET_POST_JOB_EXPERIENCE = 'SET_POST_JOB_EXPERIENCE';
export const SET_POST_JOB_ROLES_LIST = 'SET_POST_JOB_ROLES_LIST';
export const SET_POST_JOB_ACTIVE_ROLE = 'SET_POST_JOB_ACTIVE_ROLE';
export const SET_POST_JOB_APLICATION_LINK = 'SET_POST_JOB_APLICATION_LINK';
export const ADD_POST_JOB_LOCATION_SLUG = 'ADD_POST_JOB_LOCATION_SLUG';
export const ADD_POST_JOB_SKILL_SLUG = 'ADD_POST_JOB_SKILL_SLUG';
export const SET_POST_JOB_LOCATION_TITLE = 'SET_POST_JOB_LOCATION_TITLE';
export const REMOVE_POST_JOB_LOCATION_SLUG = 'REMOVE_POST_JOB_LOCATION_SLUG';
export const REMOVE_POST_JOB_SKILL_SLUG = 'REMOVE_POST_JOB_SKILL_SLUG';
export const SET_POST_JOB_LOCATION_REMOTE_BY_VALUE_AND_DATE =
  'SET_POST_JOB_LOCATION_REMOTE_BY_VALUE_AND_DATE';
export const SET_POST_JOB_ID = 'SET_POST_JOB_ID';
export const SET_POST_JOB_PLANS = 'SET_POST_JOB_PLANS';
export const SET_FETCHING_CHECK_POST_JOB_PROMOCODE = 'SET_FETCHING_CHECK_POST_JOB_PROMOCODE';
export const FETCH_CHECK_POST_JOB_PROMOCODE_SUCCESS = 'FETCH_CHECK_POST_JOB_PROMOCODE_SUCCESS';

// SKILLS
export const RECEIVE_SKILLS = 'RECEIVE_SKILLS';
export const RECEIVE_USER_SKILLS = 'RECEIVE_USER_SKILLS';
export const REQUEST_SKILLS = 'REQUEST_SKILLS';
export const REMOVE_ALL_USER_SKILLS_SLUGS = 'REMOVE_ALL_USER_SKILLS_SLUGS';
export const CLEAR_SKILLS = 'CLEAR_SKILLS';
export const SET_FETCHING_POPULAR_SKILLS = 'SET_FETCHING_POPULAR_SKILLS';
export const SET_FETCHING_SKILLS = 'SET_FETCHING_SKILLS';
export const SET_FETCHING_USER_SKILLS = 'SET_FETCHING_USER_SKILLS';
export const REMOVE_ALL_SKILLS_SLUGS = 'REMOVE_ALL_SKILLS_SLUGS';
export const SET_INIT_STATE_POPULAR_SKILLS = 'SET_INIT_STATE_POPULAR_SKILLS';
export const RECEIVE_INIT_STATE_POPULAR_SKILLS =
  'RECEIVE_INIT_STATE_POPULAR_SKILLS';
export const SET_FETCHING_INIT_STATE_POPULAR_SKILLS =
  'SET_FETCHING_INIT_STATE_POPULAR_SKILLS';
export const ADD_SKILL_SLUG = 'ADD_SKILL_SLUG';
export const REMOVE_SKILL_SLUG = 'REMOVE_SKILL_SLUG';
export const FETCH_SKILL_SLUG = 'FETCH_SKILL_SLUG';

// SENIORITY
export const RECEIVE_SENIORITIES = 'RECEIVE_SENIORITIES';
export const RECEIVE_USER_SENIORITY = 'RECEIVE_USER_SENIORITY';
export const REQUEST_SENIORITIES = 'REQUEST_SENIORITIES';
export const REMOVE_ALL_USER_SENIORITIES_SLUGS =
  'REMOVE_ALL_USER_SENIORITIES_SLUGS';
export const CLEAR_SENIORITY = 'CLEAR_SENIORITY';
export const SET_FETCHING_POPULAR_SENIORITIES =
  'SET_FETCHING_POPULAR_SENIORITIES';
export const SET_FETCHING_SENIORITIES = 'SET_FETCHING_SENIORITIES';
export const SET_FETCHING_USER_SENIORITY = 'SET_FETCHING_USER_SENIORITY';
export const REMOVE_ALL_SENIORITIES_SLUGS = 'REMOVE_ALL_SENIORITIES_SLUGS';
export const SET_INIT_STATE_POPULAR_SENIORITIES =
  'SET_INIT_STATE_POPULAR_SENIORITIES';
export const RECEIVE_INIT_STATE_POPULAR_SENIORITIES =
  'RECEIVE_INIT_STATE_POPULAR_SENIORITIES';
export const SET_FETCHING_INIT_STATE_POPULAR_SENIORITIES =
  'SET_FETCHING_INIT_STATE_POPULAR_SENIORITIES';
export const ADD_SENIORITY_SLUG = 'ADD_SENIORITY_SLUG';
export const REMOVE_SENIORITY_SLUG = 'REMOVE_SENIORITY_SLUG';
export const FETCH_SENIORITY_SLUG = 'FETCH_SENIORITY_SLUG';
export const SET_FETCHING_SENIORITIES_ALL = 'SET_FETCHING_SENIORITIES_ALL';
export const RECEIVE_SENIORITIES_ALL = 'RECEIVE_SENIORITIES_ALL';

// ROLES
export const RECEIVE_ROLES = 'RECEIVE_ROLES';
export const REQUEST_ROLES = 'REQUEST_ROLES';
export const CLEAR_ROLES = 'CLEAR_ROLES';
export const SET_FETCHING_POPULAR_ROLES = 'SET_FETCHING_POPULAR_ROLES';
export const SET_FETCHING_ROLES = 'SET_FETCHING_ROLES';
export const REMOVE_ALL_ROLES_SLUGS = 'REMOVE_ALL_ROLES_SLUGS';
export const SET_INIT_STATE_POPULAR_ROLES = 'SET_INIT_STATE_POPULAR_ROLES';
export const RECEIVE_INIT_STATE_POPULAR_ROLES =
  'RECEIVE_INIT_STATE_POPULAR_ROLES';
export const SET_FETCHING_INIT_STATE_POPULAR_ROLES =
  'SET_FETCHING_INIT_STATE_POPULAR_ROLES';
export const ADD_ROLE_SLUG = 'ADD_ROLE_SLUG';
export const REMOVE_ROLE_SLUG = 'REMOVE_ROLE_SLUG';
export const FETCH_ROLE_SLUG = 'FETCH_ROLE_SLUG';

// COACHINGROLES
export const RECEIVE_COACHINGROLES = 'RECEIVE_COACHINGROLES';
export const REQUEST_COACHINGROLES = 'REQUEST_COACHINGROLES';
export const CLEAR_COACHINGROLES = 'CLEAR_COACHINGROLES';
export const SET_FETCHING_POPULAR_COACHINGROLES =
  'SET_FETCHING_POPULAR_COACHINGROLES';
export const SET_FETCHING_COACHINGROLES = 'SET_FETCHING_COACHINGROLES';
export const REMOVE_ALL_COACHINGROLES_SLUGS = 'REMOVE_ALL_COACHINGROLES_SLUGS';
export const SET_INIT_STATE_POPULAR_COACHINGROLES =
  'SET_INIT_STATE_POPULAR_COACHINGROLES';
export const RECEIVE_INIT_STATE_POPULAR_COACHINGROLES =
  'RECEIVE_INIT_STATE_POPULAR_COACHINGROLES';
export const SET_FETCHING_INIT_STATE_POPULAR_COACHINGROLES =
  'SET_FETCHING_INIT_STATE_POPULAR_COACHINGROLES';
export const ADD_COACHINGROLE_SLUG = 'ADD_COACHINGROLE_SLUG';
export const REMOVE_COACHINGROLE_SLUG = 'REMOVE_COACHINGROLE_SLUG';
export const FETCH_COACHINGROLE_SLUG = 'FETCH_COACHINGROLE_SLUG';
export const SET_COACHINGROLES_BY_NAME = 'SET_COACHINGROLES_BY_NAME';

// COMPANIES
export const RECEIVE_COMPANIES = 'RECEIVE_COMPANIES';
export const REQUEST_COMPANIES = 'REQUEST_COMPANIES';
export const CLEAR_COMPANIES = 'CLEAR_COMPANIES';
export const SET_FETCHING_POPULAR_COMPANIES = 'SET_FETCHING_POPULAR_COMPANIES';
export const SET_FETCHING_COMPANIES = 'SET_FETCHING_COMPANIES';
export const REMOVE_ALL_COMPANIES_SLUGS = 'REMOVE_ALL_COMPANIES_SLUGS';
export const SET_INIT_STATE_POPULAR_COMPANIES =
  'SET_INIT_STATE_POPULAR_COMPANIES';
export const RECEIVE_INIT_STATE_POPULAR_COMPANIES =
  'RECEIVE_INIT_STATE_POPULAR_COMPANIES';
export const SET_FETCHING_INIT_STATE_POPULAR_COMPANIES =
  'SET_FETCHING_INIT_STATE_POPULAR_COMPANIES';
export const ADD_COMPANY_SLUG = 'ADD_COMPANY_SLUG';
export const REMOVE_COMPANY_SLUG = 'REMOVE_COMPANY_SLUG';
export const FETCH_COMPANY_SLUG = 'FETCH_COMPANY_SLUG';
export const SET_FETCHING_GET_COMPANIES_BY_NAME =
  'SET_FETCHING_GET_COMPANIES_BY_NAME';

// LOCATIONS
export const SET_NEAREST_LOCATION = 'SET_NEAREST_LOCATION';
export const SET_FETCHING_NEAREST_LOCATION = 'SET_FETCHING_NEAREST_LOCATION';
export const SET_MY_LOCATION = 'SET_MY_LOCATION';
export const RECEIVE_LOCATIONS = 'RECEIVE_LOCATIONS';
export const REQUEST_LOCATIONS = 'REQUEST_LOCATIONS';
export const CLEAR_LOCATIONS = 'CLEAR_LOCATIONS';
export const SET_FETCHING_POPULAR_LOCATIONS = 'SET_FETCHING_POPULAR_LOCATIONS';
export const SET_FETCHING_LOCATIONS = 'SET_FETCHING_LOCATIONS';
export const REMOVE_ALL_LOCATIONS_SLUGS = 'REMOVE_ALL_LOCATIONS_SLUGS';
export const SET_INIT_STATE_POPULAR_LOCATIONS =
  'SET_INIT_STATE_POPULAR_LOCATIONS';
export const RECEIVE_INIT_STATE_POPULAR_LOCATIONS =
  'RECEIVE_INIT_STATE_POPULAR_LOCATIONS';
export const SET_FETCHING_INIT_STATE_POPULAR_LOCATIONS =
  'SET_FETCHING_INIT_STATE_POPULAR_LOCATIONS';
export const ADD_LOCATION_SLUG = 'ADD_LOCATION_SLUG';
export const REMOVE_LOCATION_SLUG = 'REMOVE_LOCATION_SLUG';
export const FETCH_LOCATION_SLUG = 'FETCH_LOCATION_SLUG';

// EXPERIENCE
export const CLEAR_EXPERIENCE = 'CLEAR_EXPERIENCE';
export const SET_EXPERIENCE = 'SET_EXPERIENCE';
export const SET_MIN_EXPERIENCE = 'SET_MIN_EXPERIENCE';
export const SET_MAX_EXPERIENCE = 'SET_MAX_EXPERIENCE';

// FROMUTC
export const CLEAR_FROMUTC = 'CLEAR_FROMUTC';
export const SET_FROMUTC = 'SET_FROMUTC';

// PRICE
export const CLEAR_PRICE = 'CLEAR_PRICE';
export const SET_PRICE = 'SET_PRICE';
export const SET_MIN_PRICE = 'SET_MIN_PRICE';
export const SET_MAX_PRICE = 'SET_MAX_PRICE';

// INIT STATE
export const SET_INIT_STATE_LOADING = 'SET_INIT_STATE_LOADING';
export const SET_INIT_STATE_LOADED = 'SET_INIT_STATE_LOADED';
export const GET_INIT_STATE = 'GET_INIT_STATE';
export const SET_INIT_STATE_POPULARS = 'SET_INIT_STATE_POPULARS';
export const SET_FETCHING_INIT_STATE_MOTIVATED_VACANCIES =
  'SET_FETCHING_INIT_STATE_MOTIVATED_VACANCIES';
export const SET_FETCHING_MOTIVATED_VACANCIES =
  'SET_FETCHING_MOTIVATED_VACANCIES';
export const RECEIVE_INIT_STATE_MOTIVATED_VACANCIES =
  'RECEIVE_INIT_STATE_MOTIVATED_VACANCIES';

// KEYWORDS
export const ADD_VACANCIE_KEYWORD_SLUG = 'ADD_VACANCIE_KEYWORD_SLUG';
export const REMOVE_VACANCIE_KEYWORD_SLUG = 'REMOVE_VACANCIE_KEYWORD_SLUG';

// FILTER SLUGS
export const CLEAR_ALL_SLUGS = 'CLEAR_ALL_SLUGS';
export const SET_FETCHING_SLUGS = 'SET_FETCHING_SLUGS';
export const REMOVE_ALL_SLUGS = 'REMOVE_ALL_SLUGS';
export const SET_FILTER_STATE = 'SET_FILTER_STATE';

// NOTIFICATIONS
export const SET_LOADING = 'SET_LOADING';
export const CLEAR_NOTIFICATION = 'CLEAR_NOTIFICATION';
export const SET_NOTIFICATION = 'SET_NOTIFICATION';

export const ERROR = 'ERROR';
export const SUCCESS = 'SUCCESS';
export const DATA = 'DATA';
export const FILE = 'FILE';

// FILTERS
export const ADD_ALL_FILTERS = 'ADD_ALL_FILTERS';
export const REMOVE_ALL_FILTERS = 'REMOVE_ALL_FILTERS';
export const SET_QUERY_FILTERS_SEARCH = 'SET_QUERY_FILTERS_SEARCH';

// BROWSER LOCATION
export const LOCATION_CHANGE = 'LOCATION_CHANGE';

// USER
export const AUTH_USER = 'AUTH_USER';
export const UNAUTH_USER = 'UNAUTH_USER';
export const AUTH_ERROR = 'AUTH_ERROR';
export const SET_USER_PROFILE = 'SET_USER_PROFILE';
export const SET_USER_EXPERIENCE = 'SET_USER_EXPERIENCE';
export const CHANGE_PASSWORD_ERROR = 'CHANGE_PASSWORD_ERROR';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const ADD_USER_SKILL_SLUG = 'ADD_USER_SKILL_SLUG';
export const REMOVE_USER_SKILL_SLUG = 'REMOVE_USER_SKILL_SLUG';
export const REMOVE_USER_LOCATION_SLUG = 'REMOVE_USER_LOCATION_SLUG';
export const SET_USER_LOCATION_SLUG = 'SET_USER_LOCATION_SLUG';
export const SET_USER_APPLIED_JOBS = 'SET_USER_APPLIED_JOBS';
export const SET_USER_COACHING_LIST = 'SET_USER_COACHING_LIST';
export const SET_FETCHING_USER_COACHING_LIST =
  'SET_FETCHING_USER_COACHING_LIST';
export const SET_FETCHING_USER_COACHING_LIST_COUNTS =
  'SET_FETCHING_USER_COACHING_LIST_COUNTS';
export const SET_USER_COACHING_LIST_COUNTS = 'SET_USER_COACHING_LIST_COUNTS';
export const SET_FETCHING_USER_SEARCH_ALARMS_LIST =
  'SET_FETCHING_USER_SEARCH_ALARMS_LIST';
export const ADD_USER_SAVED_VACANCIE_BY_ID = 'ADD_USER_SAVED_VACANCIE_BY_ID';
export const REMOVE_USER_SAVED_VACANCIE_BY_ID =
  'REMOVE_USER_SAVED_VACANCIE_BY_ID';
export const SAVE_USER_VACANCIE = 'SAVE_USER_VACANCIE';
export const UNSAVE_USER_VACANCIE = 'UNSAVE_USER_VACANCIE';
export const SET_FETCHING_USER_SAVED_JOBS_LIST =
  'SET_FETCHING_USER_SAVED_JOBS_LIST';
export const SET_USER_SAVED_JOBS = 'SET_USER_SAVED_JOBS';
export const ADD_APPLIED_VACANCIE_TO_USER_APPLIED_JOBS =
  'ADD_APPLIED_VACANCIE_TO_USER_APPLIED_JOBS';
export const TOGGLE_VACANCIE_BY_ID_APPLIED_PROP =
  'TOGGLE_VACANCIE_BY_ID_APPLIED_PROP';
export const TOGGLE_VACANCIE_SEARCH_LIST_APPLIED_PROP_BY_ID =
  'TOGGLE_VACANCIE_SEARCH_LIST_APPLIED_PROP_BY_ID';
export const FETCH_USER_SEARCH_ALARMS_LIST_BY_ID =
  'FETCH_USER_SEARCH_ALARMS_LIST_BY_ID';
export const SET_USER_SEARCH_ALARMS_LIST = 'SET_USER_SEARCH_ALARMS_LIST';
export const SET_FETCHING_USER_APPLIED_JOBS_LIST =
  'SET_FETCHING_USER_APPLIED_JOBS_LIST';
export const ADD_USER_SEARCH_ALARM = 'ADD_USER_SEARCH_ALARM';
export const REMOVE_USER_SEARCH_ALARM_BY_ID = 'REMOVE_USER_SEARCH_ALARM_BY_ID';
export const CHANGE_USER_SEARCH_ALARM_BY_ID = 'CHANGE_USER_SEARCH_ALARM_BY_ID';
export const FETCH_USER_NOTIFICATIONS_LIST_BY_ID =
  'FETCH_USER_NOTIFICATIONS_LIST_BY_ID';
export const SET_USER_NOTIFICATIONS_LIST = 'SET_USER_NOTIFICATIONS_LIST';
export const FETCH_USER_NOTIFICATIONS_COUNT_BY_ID =
  'FETCH_USER_NOTIFICATIONS_COUNT_BY_ID';
export const SET_USER_NOTIFICATIONS_COUNT = 'SET_USER_NOTIFICATIONS_COUNT';
export const UPDATE_USER_NOTIFICATIONS_LIST_BY_ID =
  'UPDATE_USER_NOTIFICATIONS_LIST_BY_ID';
export const SET_FETCHING_USER_NOTIFICATIONS =
  'SET_FETCHING_USER_NOTIFICATIONS';
export const SET_FETCHING_USER_NOTIFICATIONS_COUNT =
  'SET_FETCHING_USER_NOTIFICATIONS_COUNT';
export const SET_ALL_USER_NOTIFICATIONS_AS_READ =
  'SET_ALL_USER_NOTIFICATIONS_AS_READ';
export const FETCH_USER_NOTIFICATIONS_AS_READ =
  'FETCH_USER_NOTIFICATIONS_AS_READ';
export const SET_USER_RELATED_JOBS = 'SET_USER_RELATED_JOBS';
export const SET_LOADING_USER_RELATED_JOBS = 'SET_LOADING_USER_RELATED_JOBS';
export const SET_USER_ROLES = 'SET_USER_ROLES';

// SYSTEM
export const SET_MODAL = 'SET_MODAL';
export const SET_PROGRESS = 'SET_PROGRESS';
export const SET_TIMEZONE = 'SET_TIMEZONE';

// USER DASHBOARD
export const SET_DASHBOARD_JOB_USER_STATUS_BY_ID =
  'SET_DASHBOARD_JOB_USER_STATUS_BY_ID';
export const SET_USER_DASHBOARD_JOB_LIST = 'SET_USER_DASHBOARD_JOB_LIST';
export const ADD_USER_POST_JOB_TO_DASHBOARD = 'ADD_USER_POST_JOB_TO_DASHBOARD';
export const SET_DASHBOARD_EDIT_VACANCIE_BY_ID =
  'SET_DASHBOARD_EDIT_VACANCIE_BY_ID';
export const SET_FETCHING_GET_DASHBOARD_EDIT_VACANCIE_BY_ID =
  'SET_FETCHING_GET_DASHBOARD_EDIT_VACANCIE_BY_ID';
export const UPDATE_USER_DASHBOARD_VACANCIE_BY_ID =
  'UPDATE_USER_DASHBOARD_VACANCIE_BY_ID';
export const SET_POST_JOB = 'SET_POST_JOB';
export const CLEAR_USER_STORE_POST = 'CLEAR_USER_STORE_POST';
export const SET_FETCHING_USER_DASHBOARD_JOB_LIST_BY_ID =
  'SET_FETCHING_USER_DASHBOARD_JOB_LIST_BY_ID';
export const DELETE_USER_DASHBOARD_JOB_FROM_LIST_BY_ID =
  'DELETE_USER_DASHBOARD_JOB_FROM_LIST_BY_ID';
export const SET_DASHBOARD_PUBLISHING_COASTS =
  'SET_DASHBOARD_PUBLISHING_COASTS';
export const SET_FETCHING_USER_DASHBOARD_USER_STATUS =
  'SET_FETCHING_USER_DASHBOARD_USER_STATUS';
export const TOGGLE_PUBLISH_STATUS_DASHBOARD_PUBLISHED_JOB_BY_ID =
  'TOGGLE_PUBLISH_STATUS_DASHBOARD_PUBLISHED_JOB_BY_ID';

// EVENTS
export const RECEIVE_EVENTS = 'RECEIVE_EVENTS';
export const SET_FETCHING_EVENTS = 'SET_FETCHING_EVENTS';
export const ADD_EVENTS_KEYWORD_SLUG = 'ADD_EVENTS_KEYWORD_SLUG';
export const UPDATE_EVENTS = 'UPDATE_EVENTS';
export const RECEIVE_EVENTS_COUNT_ALL = 'RECEIVE_EVENTS_COUNT_ALL';
export const SET_FETCHING_EVENTS_COUNT_ALL = 'SET_FETCHING_EVENTS_COUNT_ALL';
export const SET_FETCHING_EVENT_BY_ID = 'SET_FETCHING_EVENT_BY_ID';
export const RECEIVE_EVENT_BY_ID = 'RECEIVE_EVENT_BY_ID';

// EVENTS TYPES
export const RECEIVE_EVENTTYPES = 'RECEIVE_EVENTTYPES';
export const SET_FETCHING_EVENTTYPES = 'SET_FETCHING_EVENTTYPES';
export const CLEAR_EVENTTYPES = 'CLEAR_EVENTTYPES';
export const REMOVE_ALL_EVENTTYPES_SLUGS = 'REMOVE_ALL_EVENTTYPES_SLUGS';
export const ADD_EVENTTYPE_SLUG = 'ADD_EVENTTYPE_SLUG';
export const REMOVE_EVENTTYPE_SLUG = 'REMOVE_EVENTTYPE_SLUG';
export const FETCH_EVENTTYPE_SLUG = 'FETCH_EVENTTYPE_SLUG';
export const RECEIVE_EVENTTYPES_ALL = 'RECEIVE_EVENTTYPES_ALL';
export const RECEIVE_INIT_STATE_POPULAR_EVENTTYPES =
  'RECEIVE_INIT_STATE_POPULAR_EVENTTYPES';
export const SET_FETCHING_EVENTTYPES_ALL = 'SET_FETCHING_EVENTTYPES_ALL';
export const SET_FETCHING_INIT_STATE_POPULAR_EVENTTYPES =
  'SET_FETCHING_INIT_STATE_POPULAR_EVENTTYPES';
export const SET_FETCHING_POPULAR_EVENTTYPES =
  'SET_FETCHING_POPULAR_EVENTTYPES';
export const SET_RELATED_EVENTS = 'SET_RELATED_EVENTS';
export const SET_LOADING_RELATED_EVENTS = 'SET_LOADING_RELATED_EVENTS';

// COACHING
export const SEND_EMAIL_COACHING = 'SEND_EMAIL_COACHING';

//COACHES
export const RECEIVE_COACHES = 'RECEIVE_COACHES';
export const SET_FETCHING_COACHES = 'SET_FETCHING_COACHES';
export const ADD_COACHES_KEYWORD_SLUG = 'ADD_COACHES_KEYWORD_SLUG';
export const UPDATE_COACHES = 'UPDATE_COACHES';
export const RECEIVE_COACHES_COUNT_ALL = 'RECEIVE_COACHES_COUNT_ALL';
export const SET_FETCHING_COACHES_COUNT_ALL = 'SET_FETCHING_COACHES_COUNT_ALL';
export const SET_FETCHING_COACH_BY_ID = 'SET_FETCHING_COACH_BY_ID';
export const RECEIVE_COACH_BY_ID = 'RECEIVE_COACH_BY_ID';
export const SET_RELATED_COACHES = 'SET_RELATED_COACHES';
export const SET_LOADING_RELATED_COACHES = 'SET_LOADING_RELATED_COACHES';
export const SET_FETCHING_COACH_BY_URL = 'SET_FETCHING_COACH_BY_URL';
export const RECEIVE_COACHES_ROLES = 'RECEIVE_COACHES_ROLES';
export const CLEAR_COACHES_ROLES = 'CLEAR_COACHES_ROLES';
export const SET_FETCHING_COACHES_ROLES = 'SET_FETCHING_COACHES_ROLES';
export const FETCH_COACH_GET_BOOKING_TIMES_SUCCESS =
  'FETCH_COACH_GET_BOOKING_TIMES_SUCCESS';
export const SET_FETCHING_COACH_GET_BOOKING_TIMES =
  'SET_FETCHING_COACH_GET_BOOKING_TIMES';
export const SET_COACH_DATA = 'SET_COACH_DATA';
export const FETCH_CANCEL_BOOKING_SESSION_SUCCESS =
  'FETCH_CANCEL_BOOKING_SESSION_SUCCESS';
export const SET_FETCHING_CANCEL_BOOKING_SESSION =
  'SET_FETCHING_CANCEL_BOOKING_SESSION';
export const FETCH_REQUEST_RESCHEDULE_BOOKING_SESSION_SUCCESS =
  'FETCH_REQUEST_RESCHEDULE_BOOKING_SESSION_SUCCESS';
export const SET_FETCHING_REQUEST_RESCHEDULE_BOOKING_SESSION =
  'SET_FETCHING_REQUEST_RESCHEDULE_BOOKING_SESSION';
export const FETCH_RESCHEDULE_BOOKING_SESSION_SUCCESS =
  'FETCH_RESCHEDULE_BOOKING_SESSION_SUCCESS';
export const SET_FETCHING_RESCHEDULE_BOOKING_SESSION =
  'SET_FETCHING_RESCHEDULE_BOOKING_SESSION';
export const SET_FETCHING_REPORT_NO_SHOW_BOOKING_SESSION =
  'SET_FETCHING_REPORT_NO_SHOW_BOOKING_SESSION';
export const FETCH_REPORT_NO_SHOW_BOOKING_SESSION_SUCCESS =
  'FETCH_REPORT_NO_SHOW_BOOKING_SESSION_SUCCESS';

// COACHING TYPES
export const RECEIVE_COACHINGTYPES = 'RECEIVE_COACHINGTYPES';
export const SET_FETCHING_COACHINGTYPES = 'SET_FETCHING_COACHINGTYPES';
export const CLEAR_COACHINGTYPES = 'CLEAR_COACHINGTYPES';
export const REMOVE_ALL_COACHINGTYPES_SLUGS = 'REMOVE_ALL_COACHINGTYPES_SLUGS';
export const ADD_COACHINGTYPE_SLUG = 'ADD_COACHINGTYPES_SLUG';
export const REMOVE_COACHINGTYPE_SLUG = 'REMOVE_COACHINGTYPES_SLUG';
export const FETCH_COACHINGTYPE_SLUG = 'FETCH_COACHINGTYPES_SLUG';
export const RECEIVE_COACHINGTYPES_ALL = 'RECEIVE_COACHINGTYPES_ALL';
export const RECEIVE_INIT_STATE_POPULAR_COACHINGTYPES =
  'RECEIVE_INIT_STATE_POPULAR_COACHINGTYPES';
export const SET_FETCHING_COACHINGTYPES_ALL = 'SET_FETCHING_COACHINGTYPES_ALL';
export const SET_FETCHING_INIT_STATE_POPULAR_COACHINGTYPES =
  'SET_FETCHING_INIT_STATE_POPULAR_COACHINGTYPES';
export const SET_FETCHING_POPULAR_COACHINGTYPES =
  'SET_FETCHING_POPULAR_COACHINGTYPES';
export const SET_RELATED_COACHINGTYPES = 'SET_RELATED_COACHINGTYPES';
export const SET_LOADING_RELATED_COACHINGTYPES =
  'SET_LOADING_RELATED_COACHINGTYPES';

// COACHING SETUP
export const SET_COACH_HEADLINE = 'SET_COACH_HEADLINE';
export const SET_COACH_NAME = 'SET_COACH_NAME';
export const SET_COACH_SURNAME = 'SET_COACH_SURNAME';
export const SET_COACH_LOGO = 'SET_COACH_LOGO';
export const SET_COACH_LOCATION = 'SET_COACH_LOCATION';
export const SET_USER_COACH_COVER_IMAGE = 'SET_USER_COACH_COVER_IMAGE';
export const SET_USER_COACH_LOGO = 'SET_USER_COACH_LOGO';
export const SET_COACH_DETAILS_HTML = 'SET_COACH_DETAILS_HTML';
export const SET_COACH_CURRENT_COMPANY = 'SET_COACH_CURRENT_COMPANY';
export const SET_COACH_CURRENT_COMPANY_TITLE =
  'SET_COACH_CURRENT_COMPANY_TITLE';
export const REMOVE_COACH_PREVIOUS_COMPANY = 'REMOVE_COACH_PREVIOUS_COMPANY';
export const SET_COACH_PREVIOUS_COMPANIES_TITLE =
  'SET_COACH_PREVIOUS_COMPANIES_TITLE';
export const ADD_COACH_PREVIOUS_COMPANY = 'ADD_COACH_PREVIOUS_COMPANY';
export const SET_COACH_URL = 'SET_COACH_URL';
export const RECEIVE_IS_COACH_URL_EXIST = 'RECEIVE_IS_COACH_URL_EXIST';
export const SET_FETCHING_IS_COACH_URL_EXIST =
  'SET_FETCHING_IS_COACH_URL_EXIST';
export const SET_FETCHING_COACH_CREATE = 'SET_FETCHING_COACH_CREATE';
export const FETCH_COACH_CREATE_SUCCESS = 'FETCH_COACH_CREATE_SUCCESS';
export const FETCH_COACHES_SERVICES_UPDATE_SUCCESS =
  'FETCH_COACHES_SERVICES_UPDATE_SUCCESS';
export const SET_COACH_SOCIAL_LINKS = 'SET_COACH_SOCIAL_LINKS';
export const SET_COACH_SETUP = 'SET_COACH_SETUP';
export const SET_COACH_INIT_STATE = 'SET_COACH_INIT_STATE';
export const SET_COACH_CAHCED_URL = 'SET_COACH_CAHCED_URL';
export const SET_COACH_ROLES_TREE = 'SET_COACH_ROLES_TREE';
export const FETCH_COACH_TERMS_UPDATE_SUCCESS =
  'FETCH_COACH_TERMS_UPDATE_SUCCESS';
export const SET_FETCHING_COACH_TERMS_UPDATE =
  'SET_FETCHING_COACH_TERMS_UPDATE';
export const SET_FETCHING_COACHES_SERVICES_UPDATE =
  'SET_FETCHING_COACHES_SERVICES_UPDATE';
export const FETCH_COACH_UPDATE_SUCCESS = 'FETCH_COACH_UPDATE_SUCCESS';
export const SET_FETCHING_COACH_UPDATE = 'SET_FETCHING_COACH_UPDATE';
export const RECEIVE_COACH_BY_ID_UPDATE = 'RECEIVE_COACH_BY_ID_UPDATE';
export const SET_FETCHING_COACH_VISIBILITY = 'SET_FETCHING_COACH_VISIBILITY';
export const SET_COACH_STATUS = 'SET_COACH_STATUS';
export const SET_FETCHING_COACH_BY_ID_UPDATE =
  'SET_FETCHING_COACH_BY_ID_UPDATE';
export const SET_FETCHING_SELECT_COACH_CALENDAR_SUCCESS =
  'SET_FETCHING_SELECT_COACH_CALENDAR_SUCCESS';
export const FETCH_SELECT_COACH_CALENDAR_SUCCESS =
  'FETCH_SELECT_COACH_CALENDAR_SUCCESS';
export const SET_FETCHING_DELETE_COACH_CALENDAR =
  'SET_FETCHING_DELETE_COACH_CALENDAR';
export const FETCH_DELETE_COACH_CALENDAR_SUCCESS =
  'FETCH_DELETE_COACH_CALENDAR_SUCCESS';
export const ADD_COACH_CALENDAR = 'ADD_COACH_CALENDAR';
export const FETCH_REFRESH_COACH_CALENDAR_LIST_SUCCESS =
  'FETCH_REFRESH_COACH_CALENDAR_LIST_SUCCESS';
export const SET_FETCHING_REFRESH_COACH_CALENDAR_LIST =
  'SET_FETCHING_REFRESH_COACH_CALENDAR_LIST';
export const SET_FETCHING_COACH_SETUP_STATUS =
  'SET_FETCHING_COACH_SETUP_STATUS';
export const FETCH_COACH_SETUP_STATUS_SUCCESS =
  'FETCH_COACH_SETUP_STATUS_SUCCESS';
export const FETCH_COACH_PAYOUTS_SUCCESS = 'FETCH_COACH_PAYOUTS_SUCCESS';
export const SET_FETCHING_COACH_PAYOUTS = 'SET_FETCHING_COACH_PAYOUTS';
export const SET_FETCHING_COACH_WIDGETS_INFO =
  'SET_FETCHING_COACH_WIDGETS_INFO';
export const FETCH_COACH_WIDGETS_INFO_SUCCESS =
  'FETCH_COACH_WIDGETS_INFO_SUCCESS';
export const SET_FETCHING_COACHES_BY_KEYWORD =
  'SET_FETCHING_COACHES_BY_KEYWORD';
export const FETCH_COACHES_BY_KEYWORD_SUCCESS =
  'FETCH_COACHES_BY_KEYWORD_SUCCESS';
export const CLEAR_COACHES_BY_KEYWORD = 'CLEAR_COACHES_BY_KEYWORD';
export const SET_COACHES_SORT = 'SET_COACHES_SORT';

// BOOK COACH SESSION
export const CLEAR_BOOK_SESSION = 'CLEAR_BOOK_SESSION';
export const SET_BOOK_SESSION_TIME = 'SET_BOOK_SESSION_TIME';
export const SET_BOOK_SESSION_DATE = 'SET_BOOK_SESSION_DATE';
export const SET_BOOK_SESSION_SERVICE = 'SET_BOOK_SESSION_SERVICE';
export const FETCH_CHECK_BOOK_SESSION_PROMOCODE_SUCCESS =
  'FETCH_CHECK_BOOK_SESSION_PROMOCODE_SUCCESS';
export const SET_FETCHING_CHECK_BOOK_SESSION_PROMOCODE =
  'SET_FETCHING_CHECK_BOOK_SESSION_PROMOCODE';

// SESSION REVIEWS
export const FETCH_SEND_SESSION_REVIEW_SUCCESS =
  'FETCH_SEND_SESSION_REVIEW_SUCCESS';
export const SET_FETCHING_SEND_SESSION_REVIEW =
  'SET_FETCHING_SEND_SESSION_REVIEW';
export const SET_FETCHING_GET_COACH_REVIEWS_BY_ID =
  'SET_FETCHING_GET_COACH_REVIEWS_BY_ID';
export const FETCH_GET_COACH_REVIEWS_BY_ID_SUCCESS =
  'FETCH_GET_COACH_REVIEWS_BY_ID_SUCCESS';
