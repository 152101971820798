import React, { Component } from 'react';
import {
    AppPanelList,
    AppPanelListItem,
    AppPanelTitle,
} from "components/shared/AppPanel";
import { AppSearchTag } from "components/shared/AppSearch";
import './AppSearchListPicks.scss';

class AppSearchListPicks extends Component {
    render() {
        const { list, handlerClickTag, label } = this.props;

        return (
            <div className="app-search-list app-search-list-picks">
                <AppPanelTitle>Your picks</AppPanelTitle>
                <AppPanelList offset={true}>
                    {
                        list.length > 0 && list.map((item) => {
                            if (label === "locations" && item.id === 1) return false
                            else return (
                            <AppPanelListItem key={item.id}>
                                <AppSearchTag
                                    data={item}
                                    onClick={() => handlerClickTag(item)}
                                    defaultStyle={'acsent'}
                                />
                            </AppPanelListItem>
                        )})
                    }
                </AppPanelList>
            </div>
        );
    }
}

export default AppSearchListPicks;