import React from 'react';
import classnames from 'classnames';
import './AppContainer.scss';

const AppContainer = ({ relative, children, className }) => { 
    return (
        <div className={classnames(
            "app-container",
            { '--relative': relative},
            className,
        )}>
            { children }
        </div>
)}

export default AppContainer;
