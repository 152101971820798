import React, { Component } from 'react';

import AppButton from 'components/shared/AppButton';

import './ErrorBoundary.scss';


export default class ErrorBoundary extends Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, info) {
        // You can also log the error to an error reporting service
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return (
                <div className="app-page app-page-error">
                    <div className="app-page-error__content">
                        <div className="app-heading-section">Error! Something went wrong...</div>
                        <AppButton
                            defaultStyle="primary"
                            title="Reload Page"
                            onClick={() => window.location.reload()}
                        />
                    </div>
                </div>
            )
        }

        return this.props.children;
    }
  }