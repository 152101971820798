import React from 'react';
import cn from 'classnames';

import './CardLabel.scss';

export const CardLabel = ({
  children,
  title,
  motivated,
  company,
  big,
  headline,
  inline,
  role,
  fs = '',
}) => (
  <div
    className={cn('card-label', {
      '--company': company,
      '--big': big,
      [`--fs-${fs}`]: fs,
      '--motivated': motivated,
      '--headline': headline,
      '--inline': inline,
      '--role': role,
    })}
  >
    {children || (
      <>
        <span className="card-label-dot"></span>
        <span>{title}</span>
      </>
    )}
  </div>
);

export default CardLabel;
