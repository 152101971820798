import React from 'react';

import { Card } from 'components/Card';
import CardReviewUser from './CardReviewUser';
import ReadMore from 'components/ReadMore';

import './CardReview.scss';

export const CardReview = (props) => {
  const {
    note = '',
    created = '',
    user = null,
    rating = 2.5,
    border = true,
    shadowLight = true,
    service = {},
  } = props;

  return (
    <Card border={border} shadowLight={shadowLight} className="card-review">
      {user && (
        <div className="card-review__user">
          <CardReviewUser
            service={service}
            user={user}
            created={created}
            rating={rating}
          />
        </div>
      )}
      {note && (
        <div className="card-review__text">
          <ReadMore>{note}</ReadMore>
        </div>
      )}
    </Card>
  );
};

export default CardReview;
