import React, { useState, useCallback, useEffect } from 'react';
import moment from 'moment';
import cn from 'classnames';
import AppIcon from 'components/shared/AppIcon';

import './AppCoachBookCalendar.scss';

const WEEK = [
  { id: 0, name: 'sun' },
  { id: 1, name: 'mon' },
  { id: 2, name: 'tue' },
  { id: 3, name: 'wed' },
  { id: 4, name: 'thu' },
  { id: 5, name: 'fri' },
  { id: 6, name: 'sat' },
];

const AppCoachBookCalendar = (props) => {
  const {
    activeDays,
    handleDay,
    activeDay,
    timezone,
    dates,
    service,
  } = props;
  const [date, setDate] = useState(new Date());
  const [month, setMonth] = useState([]);

  useEffect(() => {
    getMonth(date);
  }, [date]);

  useEffect(() => {
    if (service) {
      if (dates && dates[0]) setDate(new Date(dates[0]));
      else setDate(new Date());
    }
  }, [service, dates]);

  const toggleDate = (direction) => {
    if (direction === 'next')
      setDate(new Date(moment(date).add(1, 'M').format('YYYY-MM-DD')));
    else if (direction === 'prev')
      setDate(new Date(moment(date).add(-1, 'M').format('YYYY-MM-DD')));
    else return false;
  };

  const getMonth = useCallback(
    (date) => {
      
      const daysInMonth = moment(date).daysInMonth();
      const daysInMonthArray = new Array(daysInMonth)
        .fill('')
        .map((_, i) => ++i);

      const firstDayInMonth = moment(date).startOf('month').format('ddd');

      const offsetDays = WEEK.findIndex(
        (weekDay) => weekDay.name === firstDayInMonth.toLowerCase()
      );

      const calendar =
        offsetDays > 0
          ? [...new Array(offsetDays).fill(null), ...daysInMonthArray]
          : [...daysInMonthArray];

      setMonth(calendar);
    },
    [setMonth]
  );

  const renderCalendar = useCallback(
    (activeDays) => {
      const monthNumber = moment(date).month() + 1;
      const YearNumber = moment(date).year();

      return month.map((day, idx) => {
        const dayDate = `${YearNumber}-${monthNumber}-${day}`;
        const hasOffer = checkActiveDate(dayDate, activeDays);

        return (
          <button
            type="button"
            className={cn('app-coach-book-calendar__day', {
              '--empty': day === null,
              '--offer': hasOffer,
              '--active': activeDay === dayDate,
            })}
            key={idx}
            onClick={hasOffer ? () => handleDay(activeDay, dayDate) : null}
          >
            {day}
          </button>
        );
      });
    },
    [date, month, activeDay, timezone]
  );

  const checkActiveDate = (day, activeDays) => activeDays.includes(day);

  return (
    <div className="app-coach-book-calendar">
      <div className="app-coach-book-calendar__head">
        <div className="app-coach-book-calendar__month">
          {moment(date).format('MMMM YYYY')}
        </div>
        <div className="app-coach-book-calendar__nav">
          <button
            type="button"
            className="app-coach-book-calendar__nav-btn --prev"
            onClick={() => toggleDate('prev')}
          >
            <AppIcon icon="chevron-left" />
          </button>
          <button
            type="button"
            className="app-coach-book-calendar__nav-btn --next"
            onClick={() => toggleDate('next')}
          >
            <AppIcon icon="chevron-right" />
          </button>
        </div>
      </div>
      <div className="app-coach-book-calendar__week">
        {WEEK.map((weekDay) => (
          <div key={weekDay.id}>{weekDay.name}</div>
        ))}
      </div>
      <div className="app-coach-book-calendar__days">
        {renderCalendar(activeDays)}
      </div>
    </div>
  );
};

AppCoachBookCalendar.propTypes = {
  // company: PropTypes.object,
  // name: PropTypes.string,
};

export default AppCoachBookCalendar;
