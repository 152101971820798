import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import smoothscroll from 'smoothscroll-polyfill';

import Routes from './routes';
import ErrorBoundary from 'components/containers/ErrorBoundary';

import * as ActionTypes from 'constants/ActionTypes';
import { getSession, getUserInitState, authUser } from 'actions/UserActions';
import MatomoProvider from 'providers/MatomoProvider';

import history from 'history.js';
import * as serviceWorker from 'serviceWorker';
import store from 'store';

// kick off the polyfill!
smoothscroll.polyfill();

// axios beautifire response logger
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    // 500 handler
    // if (
    //   (error && error.response && error.response.status === 500) ||
    //   (error.message && error.message.status === 500)
    // ) {
    //   history.push('/error');
    // }

    return Promise.reject(
      error && error.response
        ? { type: ActionTypes.ERROR, payload: error.response }
        : errorBeautifier(error)
    );
  }
);

getSession((user) => {
  if (user) {
    store.dispatch(authUser(user));
    store.dispatch(getUserInitState(user));
  }

  ReactDOM.render(
    <MatomoProvider userId={user && user.id}>
      <Provider store={store}>
        <ConnectedRouter history={history}>
          <ErrorBoundary>
            <Routes />
          </ErrorBoundary>
        </ConnectedRouter>
      </Provider>
    </MatomoProvider>,
    document.getElementById('root')
  );
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

const errorBeautifier = (error) => {
  return error.hasOwnProperty('payload')
    ? error
    : {
        type: ActionTypes.ERROR,
        payload: {
          data: {
            error: {
              name: 'Error',
              message: error.message,
              status: error.message.status,
            },
          },
        },
      };
};
