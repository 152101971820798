import React from 'react';
import cn from 'classnames';

import CardRate from '../CardRate';

import './CardReviewHead.scss';

export const CardReviewHead = ({
  offsetBottom = true,
  title = '',
  rating = null,
  fs = '',
  reviewsCount = 0,
  ...otherProps
}) => (
  <div className={cn('card-review-head', { '--offset-b': offsetBottom })}>
    <div className={cn('card-review-title', { [`--${fs}`]: fs })}>{title}</div>
    {!!reviewsCount && (
      <div className="card-review-stars ml-3">
        <CardRate rating={rating} {...otherProps} />
      </div>
    )}
  </div>
);

export default CardReviewHead;
