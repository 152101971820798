import axios from 'axios';

import { API_URL } from 'constants/PathTypes';
import {
  setNotification,
  setLoading,
  clearNotification,
} from './SystemActions';
import { unauthUser } from './UserActions';
import * as ActionTypes from 'constants/ActionTypes';

export function setFetchingUserNotifications(payload) {
  return {
    type: ActionTypes.SET_FETCHING_USER_NOTIFICATIONS,
    payload,
  };
}

export function setFetchingUserNotificationsCount(payload) {
  return {
    type: ActionTypes.SET_FETCHING_USER_NOTIFICATIONS_COUNT,
    payload,
  };
}

export function setUserNotificationsList(payload) {
  return {
    type: ActionTypes.SET_USER_NOTIFICATIONS_LIST,
    payload,
  };
}

export function setUserNotificationsCount(payload) {
  return {
    type: ActionTypes.SET_USER_NOTIFICATIONS_COUNT,
    payload,
  };
}

export function updateUserNotificationsListById(payload) {
  return {
    type: ActionTypes.UPDATE_USER_NOTIFICATIONS_LIST_BY_ID,
    payload,
  };
}

export function setAllUserNotificationsAsRead() {
  return {
    type: ActionTypes.SET_ALL_USER_NOTIFICATIONS_AS_READ,
  };
}

export function fetchUserNotificationsListById(userId) {
  return async (dispatch) => {
    dispatch(setFetchingUserNotifications(true));

    dispatch({
      type: ActionTypes.FETCH_USER_NOTIFICATIONS_LIST_BY_ID,
    });

    try {
      const response = await axios.get(
        `${API_URL}/users/${userId}/notifications`,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(setUserNotificationsList(response.data));
      dispatch(setFetchingUserNotifications(false));
      return response.data;
    } catch (error) {
      const { message, statusCode } = error.payload.data.error;

      dispatch(setFetchingUserNotifications(false));

      if (statusCode && statusCode === 401) {
        dispatch(unauthUser());
        dispatch(
          setNotification({
            message: 'Something went wrong.',
            type: 'info',
            status: 'info',
          })
        );
      } else
        dispatch(setNotification({ message, type: 'info', status: 'info' }));

      setTimeout(() => dispatch(clearNotification()), 5000);
      throw error;
    }
  };
}

export function fetchUseNotificationsCountById(userId) {
  return async (dispatch) => {
    dispatch(setFetchingUserNotificationsCount(true));

    dispatch({
      type: ActionTypes.FETCH_USER_NOTIFICATIONS_COUNT_BY_ID,
    });

    try {
      const response = await axios.get(
        `${API_URL}/users/${userId}/notifications/count`,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(setUserNotificationsCount(response.data));
      if (response.data.notifications.length > 0)
        dispatch(updateUserNotificationsListById(response.data.notifications));
      dispatch(setFetchingUserNotificationsCount(false));

      return response.data;
    } catch (error) {
      dispatch(setFetchingUserNotificationsCount(false));
      const { message = '', statusCode } = error.payload.data.error;

      if (statusCode && statusCode === 401) {
        dispatch(unauthUser());
        dispatch(
          setNotification({
            message: 'Something went wrong.',
            type: 'info',
            status: 'info',
          })
        );
      } else {
        dispatch(setNotification({ message, type: 'info', status: 'info' }));
      }
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function fetchUseNotificationsAsRead(userId) {
  return async (dispatch) => {
    dispatch(setLoading(true));

    dispatch({
      type: ActionTypes.FETCH_USER_NOTIFICATIONS_AS_READ,
    });

    try {
      const response = await axios.post(
        `${API_URL}/users/${userId}/notifications/markAsRead`,
        {},
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(setAllUserNotificationsAsRead(response.data));
      dispatch(setLoading(false));

      return response.data;
    } catch (error) {
      dispatch(setLoading(false));
      const { message, statusCode } = error.payload.data.error;
      if (statusCode && statusCode === 401) {
        dispatch(unauthUser());
        dispatch(
          setNotification({
            message: 'Something went wrong.',
            type: 'info',
            status: 'info',
          })
        );
      } else
        dispatch(setNotification({ message, type: 'info', status: 'info' }));
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}
