import { LOCATION_CHANGE } from 'connected-react-router';
import _ from 'lodash';

import * as ActionTypes from 'constants/ActionTypes';
import * as SortTypes from 'constants/SortTypes';
import * as PathTypes from 'constants/PathTypes';

const initialState = {
  moduleName: 'jobs',
  routeName: PathTypes.JOBS,
  list: [],
  populars: [],
  motivated: [],
  related: [],
  keywords: [''],
  sort: [SortTypes.vacancyModel[0]],
  isFetching: {
    list: false,
    populars: false,
    related: false,
    motivated: false,
    count: false,
    vacancieById: false,
  },
  count: 0,
  vacancieById: null,
  publishingPlans: [],
};

export default function vacancies(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      if (
        action.payload &&
        action.payload.location.state &&
        action.payload.location.state.filters &&
        window.location.pathname.includes('/jobs/')
      ) {
        return Object.assign({}, state, {
          keywords: action.payload.location.state.filters.keywords,
        });
      }

      return state;

    case ActionTypes.SET_VACANCIES_SORT:
      return Object.assign({}, state, {
        sort: [action.payload],
      });

    case ActionTypes.RECEIVE_VACANCIES:
      return Object.assign({}, state, {
        list: action.payload,
      });

    case ActionTypes.SET_FETCHING_INIT_STATE_MOTIVATED_VACANCIES:
      return Object.assign({}, state, {
        isFetching: {
          ...state.isFetching,
          motivated: action.payload,
        },
      });

    case ActionTypes.RECEIVE_INIT_STATE_MOTIVATED_VACANCIES:
      return Object.assign({}, state, {
        motivated: action.payload,
      });

    case ActionTypes.UPDATE_VACANCIES:
      return Object.assign({}, state, {
        list: [...state.list.slice(), ...action.payload],
      });

    case ActionTypes.SET_FETCHING_VACANCIE_BY_ID:
      return Object.assign({}, state, {
        isFetching: {
          ...state.isFetching,
          vacancieById: action.payload,
        },
      });

    case ActionTypes.RECEIVE_VACANCIE_BY_ID:
      return Object.assign({}, state, {
        vacancieById: action.payload,
      });

    case ActionTypes.RECEIVE_VACANCIES_COUNT_ALL:
      return Object.assign({}, state, {
        count: action.payload,
      });

    case ActionTypes.SET_FETCHING_VACANCIES:
      return Object.assign({}, state, {
        isFetching: {
          ...state.isFetching,
          list: action.payload,
        },
      });

    case ActionTypes.SET_FETCHING_VACANCIES_COUNT_ALL:
      return Object.assign({}, state, {
        isFetching: {
          ...state.isFetching,
          count: action.payload,
        },
      });

    case ActionTypes.ADD_VACANCIE_KEYWORD_SLUG:
      return Object.assign({}, state, {
        keywords: [action.payload],
      });

    case ActionTypes.REMOVE_VACANCIE_KEYWORD_SLUG:
      return Object.assign({}, state, {
        keywords: [],
      });

    case ActionTypes.TOGGLE_VACANCIE_SEARCH_LIST_SAVED_PROP_BY_ID:
      if (state.list.length > 0) {
        const { id, saved } = action.payload;
        let listIdx;

        const clone = Object.assign(
          {},
          ...state.list.filter((item, idx) => {
            if (item.id === id) {
              listIdx = idx;
              return true;
            } else return false;
          })
        );

        if (clone && (clone.saved === true || clone.saved === false)) {
          clone.saved = saved;

          return {
            ...state,
            list: [
              ...state.list.slice(0, listIdx),
              clone,
              ...state.list.slice(listIdx + 1),
            ],
          };
        } else return state;
      } else return state;

    case ActionTypes.TOGGLE_VACANCIE_MOTIVATED_LIST_SAVED_PROP_BY_ID:
      if (state.motivated.length > 0) {
        const { id, applied } = action.payload;
        let listIdx;

        const clone = JSON.parse(
          JSON.stringify(
            ...state.motivated.filter((item, idx) => {
              if (item.id === id) {
                listIdx = idx;
                return true;
              } else return false;
            })
          )
        );

        if (clone) {
          clone.applied = applied;

          return {
            ...state,
            motivated: [
              ...state.motivated.slice(0, listIdx),
              clone,
              ...state.motivated.slice(listIdx + 1),
            ],
          };
        } else return state;
      } else return state;

    case ActionTypes.TOGGLE_VACANCIE_BY_ID_SAVED_PROP:
      return {
        ...state,
        vacancieById: {
          ...state.vacancieById,
          saved: !state.vacancieById.saved,
        },
      };

    case ActionTypes.TOGGLE_VACANCIE_SEARCH_LIST_APPLIED_PROP_BY_ID:
      if (state.list.length > 0) {
        const { id } = action.payload;
        let listIdx;

        const clone = _.clone(state.list, true).filter((item, idx) => {
          if (item.id === id) {
            listIdx = idx;
            return true;
          } else return false;
        });

        if (clone[0]) {
          clone[0].applied = true;

          return {
            ...state,
            list: [
              ...state.list.slice(0, listIdx),
              ...clone,
              ...state.list.slice(listIdx + 1),
            ],
          };
        } else return state;
      } else return state;

    case ActionTypes.TOGGLE_VACANCIE_MOTIVATED_LIST_APPLIED_PROP_BY_ID:
      if (state.motivated.length > 0) {
        const { id } = action.payload;
        let listIdx;

        const clone = _.clone(state.motivated, true).filter((item, idx) => {
          if (item.id === id) {
            listIdx = idx;
            return true;
          } else return false;
        });

        if (clone[0]) {
          clone[0].applied = true;

          return {
            ...state,
            list: [
              ...state.list.slice(0, listIdx),
              ...clone,
              ...state.list.slice(listIdx + 1),
            ],
          };
        } else return state;
      } else return state;

    case ActionTypes.TOGGLE_VACANCIE_BY_ID_APPLIED_PROP:
      return {
        ...state,
        vacancieById: {
          ...state.vacancieById,
          applied: true,
        },
      };

    case ActionTypes.SET_RELATED_JOBS:
      return {
        ...state,
        related: action.payload,
      };

    case ActionTypes.SET_LOADING_RELATED_JOBS:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          related: action.payload,
        },
      };

    case ActionTypes.SET_JOBS_PUBLISHING_PLANS:
      return {
        ...state,
        publishingPlans: action.payload,
      };

    default:
      return state;
  }
}
