import React from 'react';
import cn from 'classnames';

import './Card.scss';

const Card = ({
  children,
  type,
  block,
  hover,
  border,
  flex,
  center,
  motivated,
  round,
  shadow,
  bg,
  stretch,
  column,
  className,
  shadowLight = false,
  noMinHeight = false,
  ...otherProps
}) => (
  <div
    {...otherProps}
    className={cn(
      'card',
      {
        [`--${type}`]: type,
        [`--bg-${bg}`]: bg,
        '--flex': flex,
        '--stretch': stretch,
        '--hover': hover,
        '--border': border,
        '--round': round,
        '--shadow': shadow,
        '--shadow-l': shadowLight,
        '--center': center,
        '--motivated': motivated,
        '--column': column,
        '--no-min-height': noMinHeight,
      },
      className
    )}
  >
    {children}
  </div>
);

export default Card;
