import React from 'react';

import AppJobsNewsCard from 'components/shared/AppJobsNewsCard';

import './AppCraftNews.scss';

const AppCraftNews = ({ news }) => {
    return (
        <ul className="app-craft-news">
            {news.map((item, idx) => {
                return (
                    <li key={idx} className="app-craft-news__item">
                        <a
                            href={item.url}
                            rel="noopener noreferrer"
                            target="_blank"
                        >
                            <AppJobsNewsCard
                                title={item.headline}
                                data={item.publishedAt}
                            />
                        </a>
                    </li>
                );
            })}
        </ul>
    );
};

export default AppCraftNews;
