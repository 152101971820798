import React, { useMemo } from 'react';
import cn from 'classnames';

import { getServiceWithPriceWIthPercents } from 'utils/Coach';

import './AppCoachingProfilePreviewServices.scss';

const AppCoachingProfilePreviewServices = ({ 
  onServiceClick = null,
  services, 
  roles 
}) => {
  const mappedServices = useMemo(
    () =>
      roles.map((role) => {
        return {
          ...role,
          services: services.filter((service) => service.role.id === role.id),
        };
      }),
    [services, roles]
  );

  return (
    <div className="app-coach-preview-services">
      {mappedServices.map((role) => (
        <div key={role.id} className="app-coach-preview-services__item">
          <div className="app-coach-preview-services__role">{role.name}</div>
          <div className="app-coach-preview-services__role-services">
            {role.services.map((service) => (
              <div
                onClick={onServiceClick ? () => onServiceClick({role, service}) : null}
                key={service.type.id}
                className={cn("app-coach-preview-services__role-service", {
                  '--action': onServiceClick
                })}
              >
                {`${service.type.name} (${service.duration} mins.) - `}
                {service.price ? (
                  <span>
                    ${getServiceWithPriceWIthPercents(service.price / 100)}
                  </span>
                ) : (
                  <span className="--acsent">Free!</span>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default AppCoachingProfilePreviewServices;
