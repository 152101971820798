import * as ActionTypes from 'constants/ActionTypes';
import * as PathTypes from 'constants/PathTypes';
import * as SortTypes from 'constants/SortTypes';

import { LOCATION_CHANGE } from 'connected-react-router';

const BOOK_INIT_STATE = {
  service: null,
  date: null,
  time: null,
};

const initialState = {
  moduleName: 'coaches',
  routeName: PathTypes.COACHES,
  roles: [],
  list: [],
  search: [],
  related: [],
  keywords: [''],
  motivated: [],
  isFetching: {
    list: false,
    search: false,
    populars: false,
    related: false,
    count: false,
    coachById: false,
    urlIsExist: false,
  },
  count: 0,
  sort: SortTypes.coachModel[2],
  coachById: null,
  book: {
    ...BOOK_INIT_STATE,
  },
};

export default function coaches(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE: {
      if (
        action.payload &&
        action.payload.location.state &&
        action.payload.location.state.filters &&
        window.location.pathname.includes('/coaches/')
      ) {
        return Object.assign({}, state, {
          keywords: action.payload.location.state.filters.keywords,
        });
      } else return state;
    }

    case ActionTypes.SET_COACHES_SORT:
      return Object.assign({}, state, {
        sort: action.payload,
      });

    case ActionTypes.SET_FETCHING_COACHES:
      return Object.assign({}, state, {
        isFetching: {
          ...state.isFetching,
          list: action.payload,
        },
      });

    case ActionTypes.RECEIVE_COACHES:
      return Object.assign({}, state, {
        list: action.payload,
      });

    case ActionTypes.UPDATE_COACHES:
      return Object.assign({}, state, {
        list: [...state.list.slice(), ...action.payload],
      });

    case ActionTypes.SET_FETCHING_COACHES_COUNT_ALL:
      return Object.assign({}, state, {
        isFetching: {
          ...state.isFetching,
          count: action.payload,
        },
      });

    case ActionTypes.RECEIVE_COACHES_COUNT_ALL:
      return Object.assign({}, state, {
        count: action.payload,
      });

    case ActionTypes.ADD_COACHES_KEYWORD_SLUG:
      return Object.assign({}, state, {
        keywords: [action.payload],
      });

    case ActionTypes.SET_FETCHING_COACH_BY_ID:
      return Object.assign({}, state, {
        isFetching: {
          ...state.isFetching,
          coachById: action.payload,
        },
      });

    case ActionTypes.RECEIVE_COACH_BY_ID:
      return Object.assign({}, state, {
        coachById: action.payload,
      });

    case ActionTypes.SET_RELATED_COACHES:
      return {
        ...state,
        related: action.payload,
      };

    case ActionTypes.SET_LOADING_RELATED_COACHES:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          related: action.payload,
        },
      };

    case ActionTypes.SET_FETCHING_COACH_BY_URL:
      return Object.assign({}, state, {
        isFetching: {
          ...state.isFetching,
          coachById: action.payload,
        },
      });

    case ActionTypes.FETCH_COACHES_BY_KEYWORD_SUCCESS:
      return Object.assign({}, state, {
        search: action.payload,
      });

    case ActionTypes.CLEAR_COACHES_BY_KEYWORD:
      return Object.assign({}, state, {
        search: [],
      });

    case ActionTypes.SET_FETCHING_COACHES_BY_KEYWORD:
      return Object.assign({}, state, {
        isFetching: {
          ...state.isFetching,
          search: action.payload,
        },
      });

    case ActionTypes.SET_FETCHING_IS_COACH_URL_EXIST:
      return Object.assign({}, state, {
        isFetching: {
          ...state.isFetching,
          urlIsExist: action.payload,
        },
      });

    case ActionTypes.SET_BOOK_SESSION_SERVICE:
      return Object.assign({}, state, {
        book: {
          ...state.book,
          service: action.payload,
        },
      });

    case ActionTypes.SET_BOOK_SESSION_DATE:
      return Object.assign({}, state, {
        book: {
          ...state.book,
          date: action.payload,
        },
      });

    case ActionTypes.SET_BOOK_SESSION_TIME:
      return Object.assign({}, state, {
        book: {
          ...state.book,
          time: action.payload,
        },
      });

    case ActionTypes.CLEAR_BOOK_SESSION:
      return Object.assign({}, state, {
        book: {
          ...BOOK_INIT_STATE,
        },
      });

    default: {
      return state;
    }
  }
}
