import React from 'react';
import cn from 'classnames';

import './CardTitle.scss';

export const CardTitle = ({
  title = '',
  big = false,
  small = false,
  children,
}) => (
  <span
    title={title}
    className={cn('card-title', {
      '--big': big,
      '--small': small,
    })}
  >
    {children ? children : title}
  </span>
);

export default CardTitle;
