import React, { useCallback, useState, useEffect } from 'react';
import classnames from 'classnames';
import { useDropzone } from 'react-dropzone';
import MediaQuery from 'react-responsive';

import AppIcon from 'components/shared/AppIcon';
import AppButton from 'components/shared/AppButton';

import * as MediaTypes from 'constants/MediaTypes';

import './AppDropzone.scss';

const AppDropzone = ({
  className,
  uploadFileHandler,
  onFileUploadedHandler,
  isFileUploaded,
  progress,
  setUploadProgress,
  setFieldValueHandler,
  fieldValue,
  fileIcon,
  dropzoneTitle,
  activeUpload,
  edit,
}) => {
  const [fileName, setFileName] = useState('');

  useEffect(() => {
    if (edit && isFileUploaded) setFileName(isFileUploaded.name);
  }, [isFileUploaded]);

  const onDrop = useCallback((acceptedFiles) => {
    if (acceptedFiles && acceptedFiles[0]) {
      let formData = new FormData();

      formData.append('file', acceptedFiles[0]);
      setFileName(acceptedFiles[0].name);

      uploadFileHandler(formData).then((response) => {
        onFileUploadedHandler(response);
        setUploadProgress(0);
        setFieldValueHandler(fieldValue, response);
      });
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div
      className={classnames(
        'app-dropzone',
        { '--disabled': isFileUploaded },
        className
      )}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      {!isFileUploaded ? (
        <MediaQuery maxWidth={MediaTypes.LT}>
          {(matches) => {
            if (matches) {
              return (
                <AppButton
                  icon="file-pdf"
                  className="app-dropzone__btn"
                  defaultStyle="secondary"
                  title="Choose File"
                />
              );
            } else {
              return (
                <div className="app-dropzone__line">
                  {isDragActive ? (
                    <div className="app-dropzone__title">
                      Drop the files here ...
                    </div>
                  ) : (
                    <React.Fragment>
                      <span className="app-dropzone__title">
                        {dropzoneTitle || 'Drop'} here or{' '}
                      </span>
                      <span className="app-dropzone__title --acsent">
                        choose file
                      </span>

                      {fileIcon === 'file-pdf' ? (
                        <span className="app-dropzone__title --icon">
                          <AppIcon icon="file-pdf" /> .pdf
                        </span>
                      ) : (
                        ''
                      )}
                    </React.Fragment>
                  )}
                </div>
              );
            }
          }}
        </MediaQuery>
      ) : (
        <div className="app-dropzone__title --result">{fileName}</div>
      )}
      {activeUpload === fieldValue ? (
        <div
          style={{ width: progress + '%' }}
          className="app-dropzone-progress"
        ></div>
      ) : (
        ''
      )}
    </div>
  );
};

export default AppDropzone;
