import React, { useMemo } from 'react';
import cn from 'classnames';

import { SESSION_STARTS_FORMATTER } from 'constants/CoachingProfileTypes';
import { formatDate } from 'utils/Dates';

import './CardNextAvailability.scss';

const CardNextAvailability = ({ date = null, right = false }) => {
  const avaulability = useMemo(() => {
    return date
      ? formatDate({
          date,
          formatter: SESSION_STARTS_FORMATTER,
        })
      : null;
  }, [date]);

  return (
    <div className={cn('card-next-availability', { '--right': right })}>
      <div className="card-next-availability__title app-title-second">
        Next Availability
      </div>
      <div className="card-next-availability__date app-text --bold --medium --nowrap">
        {avaulability || 'None. Check again soon.'}
      </div>
    </div>
  );
};

export default CardNextAvailability;
