import axios from 'axios';
import Cookies from 'js-cookie';

import { API_URL } from 'constants/PathTypes';
import {
  COACHING_SESSION_FILTERS,
  COACHING_SESSION_STATUSES,
} from 'constants/CoachingProfileTypes';
import {
  setNotification,
  setLoading,
  clearNotification,
  setModal,
} from './SystemActions';

import {
  fetchUserNotificationsListById,
  fetchUseNotificationsCountById,
} from './NotificationsActions';

import {
  fetchUserSavedVacancieListById,
  fetchUserAppliedVacancieListById,
  fetchSaveUserSavedVacancieById,
  addSavedVacancieToUserSavedJobs,
  fetchUserTopMatchesVacanciesById,
} from 'actions/VacanciesActions';

import {
  fetchUserDashboardJobListById,
  fetchDashboardPublishingCoasts,
} from 'actions/DashboardActions';

import { fetchAddSearchAlarmByIdAndData } from 'actions/SearchAlarmsActions';
import { fetchgetSenioritiesAll } from 'actions/SenioritiesActions';

import * as UserTypes from 'constants/UserTypes';
import * as PathTypes from 'constants/PathTypes';
import * as ActionTypes from 'constants/ActionTypes';
import * as NotificationsTypes from 'constants/NotificationsTypes';

import history from 'history.js';
import store from 'store';
import { getRandomInt } from 'utils/Numbers';

let TOKEN = localStorage.getItem('token');
let UID = localStorage.getItem('uid');

export function authUser(payload) {
  return {
    type: ActionTypes.AUTH_USER,
    payload,
  };
}

export function unauthUser() {
  return {
    type: ActionTypes.UNAUTH_USER,
  };
}

export function setUserProfile(payload) {
  return {
    type: ActionTypes.SET_USER_PROFILE,
    payload,
  };
}

export function addUserSkillSlug(payload) {
  return {
    type: ActionTypes.ADD_USER_SKILL_SLUG,
    payload,
  };
}

export function removeUserSkillSlug(payload) {
  return {
    type: ActionTypes.REMOVE_USER_SKILL_SLUG,
    payload,
  };
}

export function removeUserAllSkillsSlugs() {
  return {
    type: ActionTypes.REMOVE_ALL_USER_SKILLS_SLUGS,
  };
}

export function setUserLocationSlug(payload) {
  return {
    type: ActionTypes.SET_USER_LOCATION_SLUG,
    payload,
  };
}

export function setUserExperience(payload) {
  return {
    type: ActionTypes.SET_USER_EXPERIENCE,
    payload,
  };
}

export function removeUserSavedJobsListWithUnsavedProp() {
  return {
    type: ActionTypes.REMOVE_USER_SAVED_JOBS_LIST_WITH_UNSAVED_PROP,
  };
}

export function addSavedJobsUserVacancieById(payload) {
  return {
    type: ActionTypes.ADD_SAVED_JOBS_USER_VACANCIE_BY_ID,
    payload,
  };
}

export function removeSavedJobsUserVacancieById(payload) {
  return {
    type: ActionTypes.REMOVE_SAVED_JOBS_USER_VACANCIE_BY_ID,
    payload,
  };
}

export function addUserSearchAlarm(payload) {
  return {
    type: ActionTypes.ADD_USER_SEARCH_ALARM,
    payload,
  };
}

export function removeUserSearchAlarmBuId(payload) {
  return {
    type: ActionTypes.REMOVE_USER_SEARCH_ALARM_BY_ID,
    payload,
  };
}

export function changeUserSearchAlarmBuId(payload) {
  return {
    type: ActionTypes.CHANGE_USER_SEARCH_ALARM_BY_ID,
    payload,
  };
}

export function setUserSearchAlarmsList(payload) {
  return {
    type: ActionTypes.SET_USER_SEARCH_ALARMS_LIST,
    payload,
  };
}

export function setFetchingUserSearchAlarmsList(payload) {
  return {
    type: ActionTypes.SET_FETCHING_USER_SEARCH_ALARMS_LIST,
    payload,
  };
}

export function setUserRoles(payload) {
  return {
    type: ActionTypes.SET_USER_ROLES,
    payload,
  };
}

export const getUserInitState = () => {
  return (dispatch) => {
    dispatch(fetchUserSavedVacancieListById(UID));
    dispatch(fetchUserAppliedVacancieListById(UID));
    dispatch(fetchUserSearchAlarmsListById(UID));
    dispatch(fetchUserDashboardJobListById(UID));
    dispatch(fetchUserTopMatchesVacanciesById(UID));
    dispatch(fetchDashboardPublishingCoasts());
    dispatch(fetchUserRoles());
    dispatch(fetchgetSenioritiesAll());

    dispatch(fetchUserNotificationsListById(UID)).then(() => {
      dispatch(fetchUseNotificationsCountById(UID));

      const notificationsTimer = setInterval(() => {
        if (store.getState().user.auth)
          dispatch(fetchUseNotificationsCountById(UID));
        else clearInterval(notificationsTimer);
      }, UserTypes.NOTIFICATIONS_INTERVAL);
    });
  };
};

export const fetchUserRoles = () => {
  return async (dispatch) => {
    dispatch(clearNotification());

    try {
      const response = await axios.get(`${API_URL}/user_roles`, {
        headers: { authorization: localStorage.getItem('token') },
      });

      dispatch(setUserRoles(response.data));
      return response.data;
    } catch (error) {
      throw error;
    }
  };
};

export const signUp = (data) => {
  return async (dispatch) => {
    dispatch(clearNotification());
    dispatch(setLoading(true));

    try {
      const response = await axios.post(`${API_URL}/users`, data);
      const { location } = history;

      dispatch(setLoading(false));
      dispatch(setModal({ active: false }));
      dispatch(
        setNotification({
          message: `We just sent you a verification email, ${data.name}!  Give it a few min.`,
          type: 'info',
          status: 'info',
          duration: 'long',
        })
      );
      setTimeout(() => dispatch(clearNotification()), 10000);

      let closeModal = Cookies.get('ph_action_modal_close');

      if (closeModal) {
        Cookies.remove('ph_action_modal_close');
        dispatch(setModal({ active: false }));
      } else {
        history.push(location.pathname, { fetch: true });
      }

      return response;
    } catch (error) {
      const { message, statusCode } = error.payload.data.error;

      if (message.status === 401) dispatch(unauthUser());
      if (statusCode === 422) {
        dispatch(
          setNotification({
            message:
              'Oh! Looks like someone is already using this email. Perhaps you already created an account? Try logging in.',
            type: 'info',
            status: 'info',
            duration: 'long',
          })
        );
      } else {
        dispatch(
          setNotification({ message: message, type: 'info', status: 'info' })
        );
      }

      dispatch(setLoading(false));
      setTimeout(() => dispatch(clearNotification()), 10000);

      throw error;
    }
  };
};

export const confirmEmail = (params) => {
  return async (dispatch) => {
    dispatch(clearNotification());
    dispatch(setLoading(true));

    try {
      const response = await axios.get(`${API_URL}/users/confirm${params}`);

      if (response.status === 204) {
        dispatch(setLoading(false));
        dispatch(
          setNotification({
            message: 'Email confirmation success.',
            type: 'auth',
            status: 'success',
          })
        );
        if (window.gtag) window.gtag('event', 'Sign Up');

        setTimeout(() => {
          dispatch(clearNotification());
          history.push(PathTypes.LOGIN);
        }, 3000);
      }
    } catch (error) {
      const { message } = error.payload.data.error;

      if (message.status === 401) dispatch(unauthUser());

      dispatch(setLoading(false));
      dispatch(
        setNotification({
          message: 'Email confirmation error.',
          type: 'system',
          status: 'error',
        })
      );
      setTimeout(() => dispatch(clearNotification()), 5000);
      throw error;
    }
  };
};

export const changeUserProfileEmailNotifications = (data) => {
  return async (dispatch) => {
    dispatch(clearNotification());

    try {
      const response = await axios.post(
        `${API_URL}/users/profile/${UID}`,
        data,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );
      dispatch(setUserProfile(setSession(response.data.user)));
      dispatch(
        setNotification({
          message: 'Profile settings changed successfully.',
          type: 'info',
          status: 'success',
        })
      );
      return response;
    } catch (error) {
      const { message } = error.payload.data.error;

      if (message.status === 401) dispatch(unauthUser());

      dispatch(
        setNotification({
          message: 'An error occured saving email.',
          type: 'system',
          status: 'error',
        })
      );
      setTimeout(() => dispatch(clearNotification()), 5000);
      throw error;
    }
  };
};

export const setSession = (response) => {
  sessionStorage.setItem('user', JSON.stringify(response));
  return response;
};

export const signIn = (data) => {
  return async (dispatch) => {
    dispatch(clearNotification());
    dispatch(setLoading(true));

    try {
      const response = await axios.post(`${API_URL}/users/login`, data);

      localStorage.setItem('token', response.data.id);
      localStorage.setItem('uid', response.data.userId);
      localStorage.setItem('ttl', response.data.ttl);
      TOKEN = localStorage.getItem('token');
      UID = localStorage.getItem('uid');

      dispatch(setLoading(false));

      getUserProfile(UID)
        .then((response) => {
          const { location } = history;
          let urlRedirect = history.location.pathname;

          if (
            location.pathname !== PathTypes.HOME &&
            location.pathname !== PathTypes.JOBSHOME &&
            location.pathname !== PathTypes.EVENTS &&
            location.pathname !== PathTypes.EVENTSHOME &&
            location.pathname !== PathTypes.JOBS &&
            location.pathname !== PathTypes.COACHES &&
            location.pathname !== PathTypes.EMPLOYER_HOME &&
            !location.pathname.includes(PathTypes.GUIDES_HOME)
          )
            urlRedirect = PathTypes.HOME;

          if (location && location.state && location.state.from)
            urlRedirect = location.state.from;
          if (data.action === 'notLogedInApply')
            urlRedirect = PathTypes.USER_PROFILE_APPLIED_JOBS;

          const message = getUserLoginMessage(UID);

          if (window.gtag) {
            window.gtag('event', 'signin', {
              email: response.email,
              firstName: response.name,
              lastName: response.surname,
            });
          }

          dispatch(setLoading(false));
          dispatch(setModal({ active: false }));

          if (response.user_role_id === null) {
            setTimeout(
              () => dispatch(setModal({ active: 'userOnboarding' })),
              2000
            );
          }

          dispatch(authUser(setSession(response)));
          dispatch(getUserInitState(response));
          setTimeout(() => {
            dispatch(clearNotification());
            if (data.action === 'notLogedInApply') {
              setTimeout(() => {
                dispatch(
                  setNotification({
                    message: 'Job has been saved to your apply list.',
                    status: 'info',
                    type: 'applyJob',
                    data: data.notificationData,
                  })
                );
                setTimeout(() => dispatch(clearNotification()), 5000);
              }, 500);
            } else checkNotLogedInLasAction(dispatch, response);
          }, 5000);

          let notLogedFromUrl = Cookies.get('ph_action_redirect');
          let closeModal = Cookies.get('ph_action_modal_close');

          if (closeModal) {
            Cookies.remove('ph_action_modal_close');
            dispatch(setModal({ active: false }));
          } else if (notLogedFromUrl) {
            notLogedFromUrl = JSON.parse(notLogedFromUrl);

            if (notLogedFromUrl.pathname) {
              history.push(
                notLogedFromUrl.pathname,
                notLogedFromUrl.state || {}
              );
            } else if (response.user_role_id !== null) {
              history.push(notLogedFromUrl.from + notLogedFromUrl.search, {
                fetch: true,
              });
            } else history.push(notLogedFromUrl.from, { fetch: true });
            Cookies.remove('ph_action_redirect');
          } else history.push(urlRedirect, { fetch: true });
          dispatch(setNotification({ message, type: 'info', status: 'info' }));
        })
        .catch((error) => {
          const { message } = error.payload.data.error;

          if (message.status === 401) dispatch(unauthUser());

          dispatch(setNotification({ message, type: 'info', status: 'info' }));
          setTimeout(() => dispatch(clearNotification()), 5000);
        });
    } catch (error) {
      const { message } = error.payload.data.error;

      if (message.status === 401) dispatch(unauthUser());

      dispatch(setLoading(false));
      dispatch(setNotification({ message, type: 'info', status: 'info' }));
      setTimeout(() => dispatch(clearNotification()), 5000);
      throw error;
    }
  };
};

export function toggleTopMatchesVacancieByIdSavedProp(payload) {
  return {
    type: ActionTypes.TOGGLE_TOP_MATCHES_VACANCIE_SAVED_PROP_BY_ID,
    payload,
  };
}

function getUserLoginMessage(userId) {
  let message = NotificationsTypes.firstNotifications;

  if (userId) {
    if (Cookies.get(`isNotFirstLogin_${userId}`)) {
      message =
        NotificationsTypes.loginNotifications[
          getRandomInt(0, NotificationsTypes.loginNotifications.length - 1)
        ];
    } else Cookies.set(`isNotFirstLogin_${userId}`, 'true', { expires: 365 });
  }

  return message;
}

export const connectWithSocial = ({ token, userId }) => {
  return async (dispatch) => {
    dispatch(clearNotification());
    dispatch(setLoading(true));

    localStorage.setItem('token', token);
    localStorage.setItem('uid', userId);
    TOKEN = localStorage.getItem('token');
    UID = localStorage.getItem('uid');

    dispatch(setLoading(false));

    getUserProfile(+userId, token)
      .then((response) => {
        const { location } = history;
        let urlRedirect = history.location.pathname;

        // track user fullstory
        if (window.gtag) {
          window.gtag('event', 'signin', {
            email: response.email,
            firstName: response.name,
            lastName: response.surname,
          });
        }

        if (
          location.pathname !== PathTypes.HOME &&
          location.pathname !== PathTypes.JOBS &&
          location.pathname !== PathTypes.JOBSHOME &&
          location.pathname !== PathTypes.EVENTS &&
          location.pathname !== PathTypes.EVENTSHOME &&
          location.pathname !== PathTypes.COACHES &&
          location.pathname !== PathTypes.EMPLOYER_HOME &&
          !location.pathname.includes(PathTypes.GUIDES_HOME)
        )
          urlRedirect = PathTypes.HOME;

        if (location && location.state && location.state.from)
          urlRedirect = location.state.from;

        const message = getUserLoginMessage(userId);

        dispatch(setLoading(false));
        dispatch(setModal({ active: false }));
        dispatch(authUser(setSession(response)));
        dispatch(getUserInitState(response));
        dispatch(
          setNotification({
            message,
            status: 'info',
            type: 'info',
            duration: 'long',
          })
        );
        setTimeout(() => {
          dispatch(clearNotification());
          checkNotLogedInLasAction(dispatch, response);
        }, 10000);

        if (response.user_role_id === null) {
          setTimeout(() => {
            dispatch(setModal({ active: 'userOnboarding' }));
          }, 2000);
        }

        let notLogedFromUrl = Cookies.get('ph_action_redirect');

        if (notLogedFromUrl) {
          notLogedFromUrl = JSON.parse(notLogedFromUrl);

          if (notLogedFromUrl.pathname) {
            history.push(notLogedFromUrl.pathname, notLogedFromUrl.state || {});
          } else if (response.user_role_id !== null) {
            history.push(notLogedFromUrl.from + notLogedFromUrl.search, {
              fetch: true,
            });
          } else history.push(notLogedFromUrl.from, { fetch: true });
          Cookies.remove('ph_action_redirect');
        } else history.push(urlRedirect, { fetch: true });
      })
      .catch((error) => {
        const { message, statusCode } = error.payload.data.error;
        if (statusCode === 401) {
          dispatch(unauthUser());
          history.push(PathTypes.LOGIN);
        }

        dispatch(setNotification({ message, type: 'info', status: 'info' }));
        setTimeout(() => dispatch(clearNotification()), 5000);
      });
  };
};

export const signOut = () => {
  return async (dispatch) => {
    dispatch(clearNotification());
    dispatch(setLoading(true));

    try {
      await axios.post(`${API_URL}/users/logout?access_token=${TOKEN}`);

      localStorage.removeItem('token');
      sessionStorage.removeItem('user');
      TOKEN = null;

      const message =
        NotificationsTypes.logoutNotifications[
          getRandomInt(0, NotificationsTypes.logoutNotifications.length - 1)
        ];

      dispatch(
        setNotification({
          message,
          type: 'info',
          status: 'info',
          data: store.getState().user.profile,
        })
      );
      dispatch(unauthUser());
      dispatch(setLoading(false));
      setTimeout(() => dispatch(clearNotification()), 5000);

      const { location } = history;
      if (
        location.pathname !== PathTypes.EMPLOYER_HOME &&
        location.pathname !== PathTypes.HOME &&
        location.pathname !== PathTypes.JOBS &&
        location.pathname !== PathTypes.JOBSHOME &&
        location.pathname !== PathTypes.EVENTS &&
        location.pathname !== PathTypes.EVENTSHOME &&
        !location.pathname.includes(PathTypes.GUIDES_HOME)
      ) {
        history.push(PathTypes.HOME, { fetch: true });
      } else history.push(history.location.pathname, { fetch: true });
    } catch (error) {
      const { message } = error.payload.data.error;

      if (message.status === 401) dispatch(unauthUser());

      dispatch(setLoading(false));
      dispatch(
        setNotification({
          message,
          type: 'auth',
          status: error.type.toLowerCase(),
        })
      );
      throw error;
    }
  };
};

function computeUser(user) {
  // user = user || {roles:[]};
  // user.isAdmin = user.roles.find(x => x.name === 'admin');
  // user.isManager = user.roles.find(x => x.name === 'manager');

  return user;
}

export const getUserProfile = (uid) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${API_URL}/users/profile/${uid}`, {
        headers: { authorization: localStorage.getItem('token') },
      })
      .then((response) => {
        resolve(computeUser(response.data.user));
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const changeUserProfile = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${API_URL}/users/profile/${data.id}`,
        data,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(setUserProfile(setSession(response.data.user)));
      dispatch(setLoading(false));

      if (data.email) {
        dispatch(
          setNotification({
            type: 'info',
            status: 'info',
            message:
              'Profile saved. Please check your email, we send a confirmation link',
          })
        );
      } else {
        dispatch(
          setNotification({
            type: 'info',
            status: 'info',
            message: 'Profile saved',
          })
        );
      }

      setTimeout(() => dispatch(clearNotification()), 5000);
    } catch (error) {
      const { message } = error.payload.data.error;

      if (message.status === 401) dispatch(unauthUser());

      dispatch(setLoading(false));
      dispatch(
        setNotification({ message: message, type: 'info', status: 'info' })
      );
      setTimeout(() => dispatch(clearNotification()), 5000);
      throw error;
    }
  };
};

export const uploadProfileImage = (file) => {
  return async (dispatch) => {
    dispatch(clearNotification());
    dispatch(setLoading(true));

    try {
      const response = await axios.post(
        `${API_URL}/users/${UID}/uploadImage`,
        file,
        {
          headers: {
            authorization: TOKEN,
          },
          onUploadProgress: (progressEvent) => {
            // do something with proggress
          },
        }
      );

      dispatch(setLoading(false));
      dispatch(
        setNotification({
          message: 'Image uploaded success',
          type: 'info',
          status: 'info',
        })
      );
      setTimeout(() => dispatch(clearNotification()), 5000);

      return response.data.file.url;
    } catch (error) {
      const { message } = error.payload.data.error;

      if (message.status === 401) dispatch(unauthUser());

      dispatch(setLoading(false));
      dispatch(
        setNotification({ message: message, type: 'info', status: 'info' })
      );
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
};

export const getSession = (callback) => {
  const token = localStorage.getItem('token');
  // const user = sessionStorage.getItem('user');

  if (token) {
    // if (user) {
    //     callback(JSON.parse(user));
    // } else {

    getUserProfile(localStorage.getItem('uid'))
      .then((response) => {
        callback(setSession(response));
      })
      .catch((error) => {
        localStorage.removeItem('token');
        localStorage.removeItem('uid');
        localStorage.removeItem('ttl');

        window.location.reload(true);
        throw error;
      });
    // }
  } else callback(null);
};

export function fetchUserSearchAlarmsListById(userId) {
  return async (dispatch) => {
    dispatch(setFetchingUserSearchAlarmsList(true));

    dispatch({
      type: 'FETCH_USER_SEARCH_ALARMS_LIST_BY_ID',
    });

    try {
      const response = await axios.get(
        `${API_URL}/users/${userId}/search-alarms`,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(setUserSearchAlarmsList(response.data));
      dispatch(setFetchingUserSearchAlarmsList(false));
      return response.data;
    } catch (error) {
      const { message } = error.payload.data.error;

      if (message.status === 401) dispatch(unauthUser());

      dispatch(setFetchingUserSearchAlarmsList(false));
      dispatch(
        setNotification({
          message: error.message,
          type: 'info',
          status: 'error',
        })
      );
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function fetchOnUserChangePassword(data) {
  return async (dispatch) => {
    dispatch(setLoading(true));

    dispatch({
      type: 'FETCH_ON_USER_CHANGE_PASSWORD',
    });

    try {
      const response = await axios.post(`${API_URL}/users/reset`, data);

      dispatch(setLoading(false));
      dispatch(
        setNotification({
          message: 'Check your email, we have sent a link to change password.',
          type: 'info',
          status: 'info',
        })
      );
      setTimeout(() => dispatch(clearNotification()), 5000);
      return response.data;
    } catch (error) {
      const { message } = error.payload.data.error;

      if (message.status === 401) dispatch(unauthUser());

      dispatch(setLoading(false));
      dispatch(
        setNotification({
          message: error.message,
          type: 'info',
          status: 'error',
        })
      );
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function fetchUserNewPassword(data) {
  return async (dispatch) => {
    dispatch(setLoading(true));

    dispatch({
      type: 'FETCH_USER_NEW_PASSWORD',
    });

    try {
      const response = await axios.post(
        `${API_URL}/users/change-password`,
        data,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(setLoading(false));
      dispatch(
        setNotification({
          message: 'Password changed!',
          type: 'info',
          status: 'info',
        })
      );
      setTimeout(() => dispatch(clearNotification()), 5000);
      return response.data;
    } catch (error) {
      const { message } = error.payload.data.error;

      if (message.status === 401) dispatch(unauthUser());

      dispatch(setLoading(false));
      dispatch(
        setNotification({ message: message, type: 'info', status: 'info' })
      );
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function fetchUserConfirmEmailChange(token) {
  return async (dispatch) => {
    dispatch(setLoading(true));

    dispatch({
      type: 'FETCH_USER_CONFIRM_EMAIL_CHANGE',
    });

    try {
      const response = await axios.get(
        `${API_URL}/users/changeEmail?token=${token}`
      );

      dispatch(setLoading(false));
      dispatch(unauthUser());
      dispatch(
        setNotification({
          message: 'Email changed successfully.',
          type: 'info',
          status: 'success',
        })
      );
      setTimeout(() => dispatch(clearNotification()), 5000);
      return response.data;
    } catch (error) {
      const { message } = error.payload.data.error;

      if (message.status === 401) dispatch(unauthUser());

      dispatch(setLoading(false));
      dispatch(setNotification({ message, type: 'info', status: 'info' }));
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function fetchOnUserResetPassword({ token, data }) {
  return async (dispatch) => {
    dispatch(setLoading(true));

    dispatch({
      type: 'FETCH_ON_USER_RESET_PASSWORD',
    });

    try {
      const response = await axios.post(
        `${API_URL}/users/reset-password?access_token=${token}`,
        data,
        {
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      );

      dispatch(setLoading(false));
      history.push(PathTypes.LOGIN);
      dispatch(
        setNotification({
          message: 'Password reset successfully, login with your new password.',
          type: 'info',
          status: 'info',
        })
      );
      setTimeout(() => dispatch(clearNotification()), 5000);
      return response.data;
    } catch (error) {
      const { message } = error.payload.data.error;

      if (message.status === 401) dispatch(unauthUser());

      dispatch(setLoading(false));
      dispatch(
        setNotification({
          message: error.message,
          type: 'info',
          status: 'error',
        })
      );
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function setLoadingUserRelatedJobs(payload) {
  return {
    type: ActionTypes.SET_LOADING_USER_RELATED_JOBS,
    payload,
  };
}

export function setUserRelatedJobs(payload) {
  return {
    type: ActionTypes.SET_USER_RELATED_JOBS,
    payload,
  };
}

export function fetchUserRelatedJobsByData({ data }) {
  return async (dispatch) => {
    dispatch(setLoadingUserRelatedJobs(true));

    try {
      const response = await axios.post(`${API_URL}/vacancies/related`, data, {
        headers: { authorization: localStorage.getItem('token') },
      });

      dispatch(setUserRelatedJobs(response.data));
      dispatch(setLoadingUserRelatedJobs(false));

      return response.data;
    } catch (response) {
      const { error } = response.payload.data;

      if (error.statusCode === 401) dispatch(unauthUser());

      dispatch(setLoadingUserRelatedJobs(false));
      dispatch(
        setNotification({
          message: error.message,
          status: 'info',
          type: 'info',
        })
      );
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function setFetchingUserCoachingList(payload) {
  return {
    type: ActionTypes.SET_FETCHING_USER_COACHING_LIST,
    payload,
  };
}

export function setUserCoachingList(payload) {
  return {
    type: ActionTypes.SET_USER_COACHING_LIST,
    payload,
  };
}

export function fetchUserCoachingListByName({ name, filter }) {
  return async (dispatch) => {
    dispatch(setFetchingUserCoachingList(true));

    const actionUrl = name === 'user' ? 'getSessions' : 'getSessionsForCoach';

    try {
      const response = await axios.get(
        `${API_URL}/coach_sessions/${actionUrl}${
          filter ? `?filter=${filter.id}` : ''
        }`,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(setUserCoachingList(response.data));
      dispatch(setFetchingUserCoachingList(false));

      return response.data;
    } catch (response) {
      const { error } = response.payload.data;

      if (error.statusCode === 401) dispatch(unauthUser());

      dispatch(setFetchingUserCoachingList(false));
      dispatch(
        setNotification({
          message: error.message || 'Error',
          status: 'info',
          type: 'info',
        })
      );
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function setFetchingUserCoachingListCounts(payload) {
  return {
    type: ActionTypes.SET_FETCHING_USER_COACHING_LIST_COUNTS,
    payload,
  };
}

export function setUserCoachingListCounts(payload) {
  return {
    type: ActionTypes.SET_USER_COACHING_LIST_COUNTS,
    payload,
  };
}

export function fetchUserCoachingListCounts() {
  return async (dispatch) => {
    dispatch(setFetchingUserCoachingListCounts(true));

    try {
      const response = await axios.get(
        `${API_URL}/coach_sessions/getSessionsForCoach`,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      const counts = {
        all: response.data.length,
        canceled: response.data.filter(
          (session) =>
            session.sessionStatus === COACHING_SESSION_STATUSES.CANCELED
        ).length,
        upcoming: response.data.filter(
          (session) =>
            session.sessionStatus ===
              COACHING_SESSION_FILTERS.OCCURRED_SUCCESSUFUL ||
            session.sessionStatus ===
              COACHING_SESSION_FILTERS.OCCURRED_SUCCESSUFUL ||
            session.sessionStatus === COACHING_SESSION_FILTERS.SCHEDULED
        ).length,
      };

      dispatch(setUserCoachingListCounts(counts));
      dispatch(setFetchingUserCoachingListCounts(false));

      return response.data;
    } catch (response) {
      const { error } = response.payload.data;

      if (error.statusCode === 401) dispatch(unauthUser());

      dispatch(setFetchingUserCoachingListCounts(false));
      dispatch(
        setNotification({
          message: error.message || 'Error',
          status: 'info',
          type: 'info',
        })
      );
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function fetchRemoveAccountByUserId(id) {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const response = await axios.post(
        `${API_URL}/users/${id}/close`,
        {},
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(unauthUser());
      dispatch(setLoading(false));

      history.push(PathTypes.HOME, { fetch: true });
      dispatch(
        setNotification({
          message: 'Account removed.',
          type: 'info',
          status: 'info',
        })
      );
      setTimeout(() => dispatch(clearNotification()), 5000);

      return response.data;
    } catch (error) {
      const { message } = error.payload.data.error;
      if (message.status && message.status === 401) {
        dispatch(unauthUser());
        dispatch(
          setNotification({
            message: 'Something went wrong.',
            type: 'info',
            status: 'info',
          })
        );
      } else
        dispatch(setNotification({ message, type: 'info', status: 'info' }));
      dispatch(setLoading(false));
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export const checkNotLogedInLasAction = (dispatch, data) => {
  const lastAction = JSON.parse(localStorage.getItem('ph_last_action'));

  if (lastAction !== null) {
    switch (lastAction.name) {
      case 'saveJob':
        history.push(PathTypes.USER_PROFILE_SAVED_JOBS);
        dispatch(fetchSaveUserSavedVacancieById(lastAction.params.id)).then(
          (response) => dispatch(addSavedVacancieToUserSavedJobs(response))
        );
        break;

      case 'searchAlert':
        history.push(PathTypes.USER_PROFILE_SEARCH_ALARMS);
        dispatch(
          fetchAddSearchAlarmByIdAndData({
            id: data.id,
            data: lastAction.params.data,
          })
        );
        break;

      case 'postJob':
        history.push(PathTypes.EMPLOYER_POSTAJOB, {
          planId: lastAction.params.planId,
        });
        break;

      case 'applyJob':
        history.push(PathTypes.USER_PROFILE_APPLIED_JOBS);
        dispatch(
          setNotification({
            message: 'Job has been saved to your apply list.',
            status: 'info',
            type: 'info',
            notificationData: data,
          })
        );
        setTimeout(() => dispatch(clearNotification()), 5000);
        break;

      default:
        break;
    }

    localStorage.removeItem('ph_last_action');
  }
};
