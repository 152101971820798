import * as ActionTypes from 'constants/ActionTypes';
import { LOCATION_CHANGE } from 'connected-react-router';

const initialState = {
  name: 'price',
  min: 0,
  max: 300,
  slugs: [0, 300],
};

export default function price(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      if (
        action.payload &&
        action.payload.location.state &&
        action.payload.location.state.filters
      ) {
        return Object.assign({}, state, {
          ...state,
          slugs: action.payload.location.state.filters.price,
        });
      }

      return state;

    case ActionTypes.SET_MAX_PRICE:
      return Object.assign({}, state, {
        max: action.payload,
      });

    case ActionTypes.SET_FILTER_STATE:
      return Object.assign({}, state, {
        ...state,
        slugs: action.payload.price,
      });

    case ActionTypes.SET_MIN_PRICE:
      return Object.assign({}, state, {
        min: action.payload,
      });

    case ActionTypes.SET_PRICE:
      return Object.assign({}, state, {
        slugs: action.payload,
      });

    case ActionTypes.CLEAR_PRICE:
    case ActionTypes.REMOVE_ALL_SLUGS:
      return Object.assign({}, state, {
        slugs: [0, 300],
      });

    default:
      return state;
  }
}
