export const postJobInitState = {
    jobTitle: '',
    companyNameTitle: '',
    jobRole: null,
    logoMain: null,
    coverImage: null,
    jobLocationTitle: '',
    jobSeniority: null,
    jobSkillsTitle: '',
    jobDetailsHtml: '',
    jobExperience: [0, 10],
    selectedCompany: null,
    selectedLocations: [],
    selectedSkills: [],
    applicationLink: '',
    rolesList: [],
    status: null,
}