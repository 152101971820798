import React from 'react';
import Truncate from 'react-truncate';

import './CardDescription.scss';

export const CardDescription = ({ text = '', children, truncate = false }) => (
  <div title={text} className="card-description">
    {truncate ? (
      <Truncate lines={truncate}>{text || children}</Truncate>
    ) : (
      text || children
    )}
  </div>
);

export default CardDescription;
