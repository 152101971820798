import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import cn from 'classnames';

import { AppSerachField, AppSearchDropdown } from 'components/shared/AppSearch';
import AppButton from 'components/shared/AppButton';

import './AppSearchPanel.scss';

class AppSearchPanel extends Component {
  handleClickOutside = (e) => this.props.onClickOutside();

  render() {
    const {
      onInputChange,
      inputValue,
      dropdownIsActive,
      isFetching,
      handlerClickTag,
      list,
      inputPlaceholder,
      inputPlaceholderOnFocus,
      inputDisabled,
      onInputFocus,
      onInputBlur,
      title,
      handleClickAddBtn = () => {},
      onKeyDown = () => {},
      addBtn = null,
      addBtnIsFetching = false,
    } = this.props;

    const onKeyDownWithAdd = (e) => {
      if (e && e.key === 'Enter') {
        e.preventDefault();
        handleClickAddBtn();
      }
    };

    return (
      <div className="app-search-panel" onBlur={onInputBlur}>
        {title ? <div className="app-search-panel__title">{title}</div> : ''}

        <div
          className={cn('app-search-panel__inner', { '--with-btn': addBtn })}
        >
          <div className="app-search-panel__field">
            <AppSerachField
              onChangeHandler={onInputChange}
              value={inputValue}
              inputPlaceholder={inputPlaceholder}
              inputPlaceholderOnFocus={
                inputPlaceholderOnFocus
                  ? inputPlaceholderOnFocus
                  : inputPlaceholder
              }
              inputDisabled={inputDisabled}
              onInputFocus={onInputFocus}
              onInputBlur={onInputBlur}
              onKeyDown={addBtn ? onKeyDownWithAdd : onKeyDown}
              inputSize={'small'}
            />

            {dropdownIsActive ? (
              <div
                className={
                  'app-search__dropdown' +
                  (!dropdownIsActive || inputDisabled
                    ? ' app-search__dropdown--hide'
                    : '')
                }
              >
                <AppSearchDropdown
                  list={list}
                  isFetching={isFetching}
                  handlerClickTag={handlerClickTag}
                />
              </div>
            ) : (
              ''
            )}
          </div>
          {addBtn && (
            <AppButton
              title="Add"
              size="small"
              width="w-xs"
              onClick={handleClickAddBtn}
              isFetching={addBtnIsFetching}
              isDisabled={addBtnIsFetching || !inputValue}
              loader={addBtnIsFetching}
            />
          )}
        </div>
      </div>
    );
  }
}

export default onClickOutside(AppSearchPanel);
