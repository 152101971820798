import * as Yup from 'yup';
import { regURL, regURLNoProtocol, regCoachURL } from 'constants/RegExp';

const FIRST_NAME_MIN_LENGTH = 1;
const LAST_NAME_MIN_LENGTH = 2;

function isNoStreemLinksValidation() {
  return this.test("isNoStreemLinksValidation", 'No zoom or meet.google.com links', function (value) {
    const { path, createError } = this;

    if (!value) {
      return true
    }

    if (value.includes('zoom.us') || value.includes('meet.google.com')) {
      return createError({ path, message: 'No live stream video services links allowed.' });
    }

    return true;
  });
}

Yup.addMethod(Yup.string, "isNoStreemLinksValidation", isNoStreemLinksValidation);

export const validationSchemaApply = Yup.object().shape({
  firstName: Yup.string()
    .min(FIRST_NAME_MIN_LENGTH, 'Too Short!')
    .max(20, 'Too Long!')
    .required('First name is required'),
  lastName: Yup.string()
    .min(LAST_NAME_MIN_LENGTH, 'Too Short!')
    .max(20, 'Too Long!')
    .required('Last name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  terms: Yup.bool().test('match', 'please check terms', function (terms) {
    return terms === true;
  }),
  phone: Yup.string().required('Phone is required'),
  resume: Yup.mixed().test('match', 'Resume is required', function (file) {
    return file !== null;
  }),
});

export const validationSchemaApplyLogin = Yup.object().shape({
  password: Yup.string()
    .min(4, 'Password must be 4 characters or longer')
    .required('Password is required'),
});

export const validationSchemaCoachBooking = Yup.object().shape({
  note: Yup.string().max(300, 'Message must be no more than 300 characters long').nullable().isNoStreemLinksValidation(),
  terms: Yup.bool().test(
    'match',
    'Please accept our terms before booking a session.',
    function (terms) {
      return terms === true;
    }
  ),
});

export const validationSchemaCoachBookingCancelation = Yup.object().shape({
  note: Yup.string().max(140, 'Message must be no more than 140 characters long').nullable().isNoStreemLinksValidation(),
});

export const validationSchemaSignup = Yup.object().shape({
  firstName: Yup.string()
    .min(FIRST_NAME_MIN_LENGTH, 'Too Short!')
    .max(20, 'Too Long!')
    .required('Required'),
  lastName: Yup.string()
    .min(LAST_NAME_MIN_LENGTH, 'Too Short!')
    .max(20, 'Too Long!')
    .required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  password: Yup.string()
    .min(4, 'Password must be 4 characters or longer')
    .required('Required'),
  passwordConfirm: Yup.mixed()
    .test('match', 'Passwords do not match', function (passwordConfirm) {
      return passwordConfirm === this.parent.password;
    })
    .required('Password confirm is required'),
  terms: Yup.bool().test('match', 'please check terms', function (terms) {
    return terms === true;
  }),
});

export const validationSchemaChangeEmail = Yup.object().shape({
  firstName: Yup.string()
    .min(FIRST_NAME_MIN_LENGTH, 'Too Short!')
    .max(20, 'Too Long!')
    .required('Required'),
  lastName: Yup.string()
    .min(LAST_NAME_MIN_LENGTH, 'Too Short!')
    .max(20, 'Too Long!')
    .required('Required'),
  email: Yup.string().email('Invalid email'),
});

export const validationSchemaLogin = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string()
    .min(4, 'Password must be 4 characters or longer')
    .required('Password is required'),
});

export const validationSchemaChangePassword = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Email is required'),
});

export const validationSchemaNewPassword = Yup.object().shape({
  newPassword: Yup.string()
    .min(4, 'Password must be 4 characters or longer')
    .required('New password is required'),
});

export const validationSchemaModalNewPassword = Yup.object().shape({
  oldPassword: Yup.string()
    .min(4, 'Password must be 4 characters or longer')
    .required('Old Password is required'),
  newPassword: Yup.string()
    .min(4, 'Password must be 4 characters or longer')
    .required('New Password is required'),
});

export const validationSchemaAddSearchAlarm = Yup.object().shape({
  alarmName: Yup.string().required('Alarm name is required'),
});

export const validationSchemaCompanyInfo = Yup.object().shape({
  companyName: Yup.string().required('Company name is required'),
});

export const validationSchemaJobInfo = Yup.object().shape({
  jobRole: Yup.object().nullable().required('Job role is required'),
  jobTitle: Yup.string().required('Job title is required'),
  jobLocations: Yup.array().required('Job location is required').nullable(),
  jobPositionDetails: Yup.string().required('Job details is required'),
  // eslint-disable-next-line
  jobApplicationLink: Yup.string().matches(
    regURL,
    'Please enter a URL to your job application.'
  ),
});

export const validationSchemaJobDetails = Yup.object().shape({
  jobSkills: Yup.array()
    .min(2, 'Minimum 2 Job skills is required')
    .required('Minimum 2 Job skills is required')
    .nullable(),
});

export const validationSchemaProfileStepOne = Yup.object().shape({
  coachHeadline: Yup.string().required('Headline is required'),
  coachCompany: Yup.string().required('Current company is required'),
  coachDetails: Yup.string().required('Coaching Details is required'),
  coachUrl: Yup.string()
    .matches(regCoachURL, 'Invalid URL')
    .required('Coach page URL is required'),
  coachLocation: Yup.object().nullable().required('Location is required'),
  coachSocials: Yup.object().shape({
    linkedin: Yup.string().nullable(),
    github: Yup.string().nullable(),
    dribbble: Yup.string().nullable(),
    behance: Yup.string().nullable(),
    website: Yup.string().matches(regURLNoProtocol, 'Invalid URL').nullable(),
  }),
});

export const validationSchemaProfileStepThree = Yup.object().shape({
  paypal: Yup.string().email('Invalid email').nullable(),
});
