import moment from 'moment-timezone';
import { ZONES_OPTIONS } from 'constants/TimezonesTypes';

// export const timezoneUtcOffset = moment().utcOffset() / 60;
export const getCurrentTimezone = () => {
  const timezoneId = moment.tz.guess();
  let curTimezone = ZONES_OPTIONS.find((option) => option.id === timezoneId);

  if (!curTimezone) {
    const curUTC = moment.tz(timezoneId).format('Z');
    curTimezone = ZONES_OPTIONS.find((option) => option.utc === curUTC);

    if (!curTimezone) curTimezone = ZONES_OPTIONS[0]
  }

  return curTimezone;
};
