import React from 'react';
import cn from 'classnames';
import Moment from 'moment';

import {
  renderEventDateAndTime,
  checkIsTodayDate,
  renderTime,
  checkIsStartAndEndThisDay,
  convertDateToRightFormat,
} from 'utils/Dates';

import AppIcon from 'components/shared/AppIcon';

import './CardEventDate.scss';

function displayDateStartEnd(dateStart, dateEnd) {
  if (checkIsStartAndEndThisDay(dateStart, dateEnd)) {
    return `${renderEventDateAndTime(dateStart)} - ${renderTime(dateEnd)}`;
  }
  return `${renderEventDateAndTime(dateStart)} - ${renderEventDateAndTime(
    dateEnd
  )}`;
}

function displayDateStart(date) {
  if (checkIsTodayDate(Moment(date).fromNow())) return 'today';
  return renderEventDateAndTime(date);
}

export const CardEventDate = function ({ dateStart, dateEnd, online, row }) {
  let date = '';

  if (dateEnd) {
    date = displayDateStartEnd(
      convertDateToRightFormat(dateStart, online),
      convertDateToRightFormat(dateEnd, online)
    );
  } else date = displayDateStart(convertDateToRightFormat(dateStart, online));

  return (
    <div
      className={cn('card-event-date', {
        '--acsent': date === 'today',
        '--online': online,
        '--row': row,
      })}
    >
      {!dateEnd && date === 'today' && <AppIcon icon="fire" color="red" />}
      <span className="card-event-date__title">{date}</span>{' '}
      <span style={{ marginLeft: 'auto' }} className="card-event-date__title">
        {' '}
        ({online ? 'your' : 'local'} time)
      </span>
    </div>
  );
};

export default CardEventDate;
