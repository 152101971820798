export default function loadScript(src, id, callback) {
    var head = document.getElementsByTagName("head")[0]
    var script = document.createElement("script")

    script.setAttribute("type", "text/javascript")
    script.setAttribute("src", src)
    script.setAttribute("id", id)

    head.addEventListener(
        "load",
        (e) => {
            if (e.target.nodeName === "SCRIPT" && e.target.id === id) callback()
        },
        true
    )

    head.appendChild(script)
}
