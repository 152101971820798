const navAnchorHandler = (e, id) => {
  if (e) e.preventDefault();

  const target = e ? e.target : null;
  const targetSectionId = target ? target.getAttribute('data-target') : id;
  const targetSection = document.getElementById(targetSectionId);

  if (targetSection) {
    targetSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};

export default navAnchorHandler;
