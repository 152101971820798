import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import cn from 'classnames';
import Moment from 'moment';

import AppLogo from 'components/shared/AppLogo';
import AppFlexLine from 'components/layout/AppFlexLine';
import AppImg from 'components/shared/AppImg';
import AppButton from 'components/shared/AppButton';
import AppUserNav from 'components/AppUserNav';
import AppUserAvatar from 'components/shared/AppUserAvatar';
import AppUserProfileMenu from 'components/AppHeader/AppUserProfileMenu';
import { AppDropdown, AppDropdownBlock } from 'components/shared/AppDropdown';
import {
  AppSearchPanelKeywords,
  AppEventsSearchPanelKeywords,
  AppCoachesSearchPanelKeywords,
} from 'components/shared/AppSearch';
import AppFiltersPanel from 'components/AppFiltersPanel';
import AppUserNotificationsToggle from 'components/shared/AppUserNotificationsToggle';

import { setModal } from 'actions/SystemActions';
import { signOut } from 'actions/UserActions';
import { trackAnaliticsEvent, analiticsCategoriesTypes } from 'utils/analitics';

import * as PathTypes from 'constants/PathTypes';
import * as UserTypes from 'constants/UserTypes';

import store from 'store';
import history from 'history.js';
import { getStateFilters, buildUrl } from 'utils';

import './AppHeader.scss';

const mapDispatchToProps = (dispatch) => ({
  signOut: (payload) => dispatch(signOut(payload)),
  setModal: (payload) => dispatch(setModal(payload)),
});

const mapStateToProps = (state) => ({
  user: state.user,
});

class AppHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSticky: false,
    };
  }

  header = null;
  headerTop = null;
  content = null;
  lastScrollTop = 0;
  scrollDirection = null;

  componentDidMount() {
    this.header = document.querySelector('.app-header');
    this.headerTop = this.header.querySelector('.app-header__top');
    this.content = document.querySelector('.app-content');

    if (!this.props.absolute) {
      window.addEventListener('scroll', this.headerScrollHandler);
      this.headerScrollHandler();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.headerScrollHandler);
  }

  showHeaderHandler = () => {
    this.header.classList.remove('--scrolled');
  };

  hideHeaderHandler = () => {
    this.header.classList.add('--scrolled');
  };

  headerScrollHandler = () => {
    const scrollTop = this.getScrollTopPosition();

    if (scrollTop > 0) {
      this.content.style.paddingTop =
        this.header.getBoundingClientRect().height + 'px';
      this.header.classList.add('--sticky');
      this.setState({ isSticky: true });
    } else {
      this.content.style.paddingTop = 0 + 'px';
      this.header.classList.remove('--sticky');
      this.setState({ isSticky: false });
    }

    if (this.props.panelFilters) {
      if (this.lastScrollTop - scrollTop < 0 && scrollTop > 100) {
        if (this.sscrollDirection !== 'down') {
          this.hideHeaderHandler();
          this.sscrollDirection = 'down';
        }
      } else {
        if (this.sscrollDirection === 'down') {
          this.showHeaderHandler();
          this.sscrollDirection = 'up';
        }
      }
    }

    this.lastScrollTop = scrollTop;
  };

  getScrollTopPosition = () => {
    return window.pageYOffset || document.documentElement.scrollTop;
  };

  handleSubmit = (e) => {
    setTimeout(() => {
      const filters = getStateFilters(store.getState(), this.props.moduleName);

      history.push(
        buildUrl(filters, PathTypes[this.props.moduleName.toUpperCase()]),
        {
          filters,
          fetch: true,
        }
      );
    }, 300);
    if (e) e.preventDefault();
  };

  toggleModalHandler = (modal, notification) =>
    this.props.setModal({ active: modal, notification: notification });

  renderPanelSearch() {
    const { panelSearch, moduleName, dropdownPopularOff, searchBtn } =
      this.props;

    if (!panelSearch) return undefined;
    switch (moduleName) {
      case 'jobs':
        return (
          <AppSearchPanelKeywords
            moduleName={moduleName}
            modal={false}
            dropdownMenu={true}
            dropdownPopularOff={dropdownPopularOff}
            inputSize={'header'}
            handleSubmit={this.handleSubmit}
            handleFetch={this.handleSubmit}
            btn={searchBtn}
            btnTitle="Find Jobs"
            placeholder={'Search Jobs'}
          />
        );
      case 'events':
        return (
          <AppEventsSearchPanelKeywords
            moduleName={moduleName}
            modal={false}
            dropdownMenu={true}
            dropdownPopularOff={dropdownPopularOff}
            inputSize={'header'}
            handleSubmit={this.handleSubmit}
            handleFetch={this.handleSubmit}
            btn={searchBtn}
            btnTitle="Find Events"
            placeholder="Search Events"
          />
        );
      case 'coaches':
        return (
          <AppCoachesSearchPanelKeywords
            moduleName={moduleName}
            modal={false}
            dropdownMenu={true}
            dropdownPopularOff={dropdownPopularOff}
            inputSize={'header'}
            handleSubmit={this.handleSubmit}
            handleFetch={this.handleSubmit}
            btn={searchBtn}
            btnTitle="Find Coaches"
            placeholder="Search Coaches"
          />
        );
      default:
        return undefined;
    }
  }

  handleTriggerAnlalitics = (name) => {
    if (name === 'employer') {
      trackAnaliticsEvent({
        category: analiticsCategoriesTypes.JOB_POSTING,
        action: 'postjob_topnav_cta',
      });
    }
  };

  render() {
    const {
      user,
      className,
      panelFilters,
      activeUserPage,
      signOut,
      profileNav,
      nav,
      moduleName,
      bg = null,
      absolute = false,
    } = this.props;
    const { isSticky } = this.state;
    const { location } = history;

    return (
      <header
        className={cn(
          'app-header',
          { [`--bg-${bg}`]: bg, '--absolute': absolute },
          className
        )}
      >
        <div className="app-header__top">
          <div className="app-header__nav">
            <div className="app-container">
              <div className="app-header__inner">
                <div className="app-header__left">
                  <AppLogo
                    bg={bg}
                    isSticky={isSticky}
                    onClick={() => history.push('/', { fetch: true })}
                  />

                  {nav === 'home-main' && (
                    <div className="app-header__left-links">
                      <Link
                        className="app-link"
                        to={{
                          pathname: PathTypes.JOBSHOME,
                          state: { fetch: true },
                        }}
                      >
                        Find a Job
                      </Link>
                      <Link
                        className="app-link"
                        to={{
                          pathname: PathTypes.COACHING,
                          state: { fetch: true },
                        }}
                      >
                        Coaching
                      </Link>
                    </div>
                  )}
                </div>

                <div className="app-header__right">
                  {this.renderPanelSearch()}

                  {nav === 'default' || nav === undefined ? (
                    <React.Fragment>
                      {moduleName === 'coaches' ? (
                        <>
                          <Link
                            className="app-link"
                            to={{
                              pathname:
                                moduleName === 'coaches'
                                  ? PathTypes.JOBS
                                  : PathTypes.JOBSHOME,
                              state: { fetch: true },
                            }}
                          >
                            Jobs
                          </Link>
                        </>
                      ) : (
                        ''
                      )}
                      {moduleName === 'jobs' ? (
                        <>
                          <Link
                            className="app-link"
                            to={{
                              pathname:
                                location.pathname === PathTypes.JOBSHOME
                                  ? PathTypes.COACHING
                                  : PathTypes.COACHES,
                              state: { fetch: true },
                            }}
                          >
                            {location.pathname === PathTypes.JOBSHOME
                              ? 'Coaching'
                              : 'Coaches'}
                          </Link>
                        </>
                      ) : (
                        ''
                      )}
                      {moduleName === 'full' ? (
                        <>
                          <Link
                            className="app-link"
                            to={{
                              pathname: PathTypes.COACHES,
                              state: { fetch: true },
                            }}
                          >
                            Coaches
                          </Link>
                          <Link
                            className="app-link"
                            to={{
                              pathname: PathTypes.JOBS,
                              state: { fetch: true },
                            }}
                          >
                            Jobs
                          </Link>
                        </>
                      ) : (
                        ''
                      )}
                      {moduleName === 'events' ? (
                        <>
                          <Link
                            className="app-link"
                            to={{
                              pathname:
                                location.pathname === PathTypes.EVENTSHOME
                                  ? PathTypes.JOBSHOME
                                  : PathTypes.JOBS,
                              state: { fetch: true },
                            }}
                          >
                            Jobs
                          </Link>
                          <Link
                            className="app-link"
                            to={{
                              pathname:
                                location.pathname === PathTypes.EVENTSHOME
                                  ? PathTypes.COACHING
                                  : PathTypes.COACHES,
                              state: { fetch: true },
                            }}
                          >
                            {location.pathname === PathTypes.EVENTSHOME
                              ? 'Coaching'
                              : 'Coaches'}
                          </Link>
                        </>
                      ) : (
                        ''
                      )}
                      {moduleName !== 'coaching' &&
                      moduleName !== 'coaches-home' ? (
                        <Link className="app-link" to={PathTypes.EMPLOYER_HOME}>
                          Post a Job
                        </Link>
                      ) : (
                        ''
                      )}
                    </React.Fragment>
                  ) : (
                    ''
                  )}
                  {nav === 'home-main' && (
                    <Link
                      className="app-link --acsent"
                      to={PathTypes.EMPLOYER_HOME}
                      onClick={() => this.handleTriggerAnlalitics('employer')}
                    >
                      For Employers
                    </Link>
                  )}

                  {!user.auth &&
                  location.pathname !== PathTypes.LOGIN &&
                  location.pathname !== PathTypes.EMPLOYER_HOME &&
                  location.pathname !== PathTypes.COACHING &&
                  location.pathname !== PathTypes.COACHES_HOME &&
                  location.pathname !== PathTypes.SIGNUP ? (
                    <React.Fragment>
                      {nav === 'home-main' ? (
                        <AppButton
                          title="Log in"
                          defaultStyle="secondary"
                          onClick={() => this.toggleModalHandler('login')}
                        />
                      ) : (
                        <div
                          className="app-link --acsent"
                          onClick={() => this.toggleModalHandler('login')}
                        >
                          Log in
                        </div>
                      )}
                      <AppButton
                        title="Sign up"
                        defaultStyle="primary"
                        onClick={() => this.toggleModalHandler('signup')}
                      />
                    </React.Fragment>
                  ) : (
                    ''
                  )}

                  {nav && nav === 'employer' ? (
                    <React.Fragment>
                      <Link
                        className={cn('app-link --light-bold', {
                          '--underline-menu':
                            PathTypes.EMPLOYER_DASHBOARD ===
                            history.location.pathname,
                        })}
                        to={PathTypes.EMPLOYER_DASHBOARD}
                      >
                        Dashboard
                      </Link>
                      <Link
                        className="app-link --light-bold"
                        to={PathTypes.EMPLOYER_HOME}
                      >
                        Employers
                      </Link>
                      <Link
                        className={cn('app-link --light-bold', {
                          '--acsent':
                            PathTypes.EMPLOYER_HOME ===
                            history.location.pathname,
                        })}
                        to={PathTypes.JOBS}
                      >
                        Search Jobs
                      </Link>
                      <Link
                        className="app-link"
                        to={{
                          pathname: PathTypes.COACHING,
                          state: { fetch: true },
                        }}
                      >
                        Coaching
                      </Link>
                      {/* <Link
                        className="app-link"
                        to={{
                          pathname: user.auth
                            ? PathTypes.COACHES
                            : PathTypes.COACHING,
                          state: { fetch: true },
                        }}
                      >
                        Coaching
                      </Link> */}
                      
                      {!user.auth ? (
                        <AppButton
                          title="Log in"
                          defaultStyle="secondary"
                          onClick={() => this.toggleModalHandler('login')}
                        />
                      ) : (
                        ''
                      )}
                    </React.Fragment>
                  ) : (
                    ''
                  )}

                  {moduleName === 'coaching' ||
                  moduleName === 'coaches-home' ? (
                    <React.Fragment>
                      <Link
                        className="app-link"
                        to={{
                          pathname: PathTypes.JOBSHOME,
                          state: { fetch: true },
                        }}
                      >
                        Jobs
                      </Link>
                      {moduleName === 'coaches-home' && (
                        <Link className="app-link" to={PathTypes.COACHING}>
                          Coaching
                        </Link>
                      )}
                      <Link className="app-link" to={PathTypes.EMPLOYER_HOME}>
                        Post a Job
                      </Link>
                      {!user.auth ? (
                        <>
                          <AppButton
                            title="Log in"
                            defaultStyle={
                              moduleName === 'coaching'
                                ? 'green-light'
                                : 'blue-light'
                            }
                            onClick={() => this.toggleModalHandler('login')}
                          />
                          <AppButton
                            title="Sign up"
                            defaultStyle="primary"
                            onClick={() => this.toggleModalHandler('signup')}
                          />
                        </>
                      ) : (
                        ''
                      )}
                    </React.Fragment>
                  ) : (
                    ''
                  )}

                  {user.auth ? (
                    <div className="app-header-user-wr">
                      <div className="app-header-user">
                        <AppDropdown>
                          <AppUserNotificationsToggle
                            newCount={
                              user.notificationsCount &&
                              user.notificationsCount.new
                            }
                          />
                          <AppDropdownBlock className="--right">
                            <div className="app-menu app-menu-notifications --dash">
                              {/* <div className="app-menu-block"> // TODO MESSAGE NOTIFICATIONS
                                                                        <div className="app-menu-head">
                                                                            <AppFlexLine jc="sb" ai="center">
                                                                                <div className="app-menu-head__left">
                                                                                    <AppFlexLine flex="inline" jc="center" ai="center">
                                                                                        <div className="app-menu-title-descr">Notifications</div>
                                                                                        <div className="app-menu-alarm app-menu-alarm--active app-menu-alarm--m-left"><span>1</span></div>
                                                                                    </AppFlexLine>
                                                                                </div>
                                                                                <div className="app-menu-head__right">
                                                                                    <Link to={PathTypes.USER_PROFILE_NOTIFICATIONS} className="app-menu-link">See All <span>5</span></Link>
                                                                                </div>
                                                                            </AppFlexLine>
                                                                        </div>
                                                                        <div className="app-menu-body app-menu-body--pt-0">
                                                                            <div className="app-notification-profile">
                                                                                <div className="app-flex-line --inline --ai-fs">
                                                                                    <AppImg icon="bell" className="app-flex-line__left" />
                                                                                    <div className="app-flex-line__right">
                                                                                        <div className="app-menu-text">We’ve found a great product manager jobs for you. <Link className="app-menu-link app-menu-link--bold" to="/profile/notifications">Check them out!</Link></div>
                                                                                        <div className="app-menu-text app-menu-text--date">12 days ago</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                              <div className="app-menu-block">
                                <div className="app-menu-head">
                                  <AppFlexLine jc="sb" ai="center">
                                    <div className="app-menu-head__left">
                                      <AppFlexLine
                                        flex="inline"
                                        jc="center"
                                        ai="center"
                                      >
                                        <div className="app-menu-title-descr">
                                          Notifications
                                        </div>
                                        <div
                                          className={cn(
                                            'app-menu-alarm',
                                            '--m-left',
                                            {
                                              '--active':
                                                user.notificationsCount &&
                                                user.notificationsCount.new,
                                            }
                                          )}
                                        >
                                          <span>
                                            {user.notificationsCount &&
                                              user.notificationsCount.new}
                                          </span>
                                        </div>
                                      </AppFlexLine>
                                    </div>
                                    <div className="app-menu-head__right">
                                      <Link
                                        to={
                                          PathTypes.USER_PROFILE_NOTIFICATIONS
                                        }
                                        className="app-menu-link --inline"
                                      >
                                        See All{' '}
                                        <span>
                                          {' '}
                                          {user.notificationsCount &&
                                            user.notificationsCount.count}
                                        </span>
                                      </Link>
                                    </div>
                                  </AppFlexLine>
                                </div>
                                {user.notificationsCount &&
                                user.notificationsCount.notifications.length > 0
                                  ? user.notificationsCount.notifications.map(
                                      (notification, i) => (
                                        <div
                                          key={notification.id}
                                          className={cn('app-menu-body', {
                                            '--link': notification.link,
                                            '--border-top': i === 0,
                                          })}
                                          onClick={() => {
                                            if (notification.link) {
                                              history.push(
                                                notification.link.url,
                                                { fetch: true }
                                              );
                                            } else return false;
                                          }}
                                        >
                                          <div className="app-notification-profile">
                                            <div className="app-flex-line --inline --ai-fs">
                                              <AppImg
                                                icon={notification.type}
                                                // bgColor="blue"
                                                className="app-flex-line__left"
                                              />
                                              <div className="app-flex-line__right">
                                                <div className="app-menu-text">
                                                  {notification.text}
                                                </div>
                                                <div className="app-menu-text app-menu-text--date">
                                                  {Moment(
                                                    notification.created
                                                  ).fromNow()}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    )
                                  : ''}
                              </div>
                            </div>
                          </AppDropdownBlock>
                        </AppDropdown>

                        <AppDropdown>
                          <AppUserAvatar
                            img={user.profile.image.url}
                            defaultPlaceholder={
                              UserTypes.PERSONS[user.profile.image.person]
                                .defaultPlaceholder
                            }
                            icon={
                              UserTypes.PERSONS[user.profile.image.person].icon
                            }
                            color={
                              UserTypes.COLORS[user.profile.image.color].value
                            }
                            size="small"
                          />
                          <AppDropdownBlock className="--right">
                            <AppUserProfileMenu
                              signOutHandler={signOut}
                              user={user}
                              className="--dash"
                            />
                          </AppDropdownBlock>
                        </AppDropdown>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {panelFilters ? (
          <div className="app-header__filters-panel">
            <div className="app-container">
              <AppFiltersPanel moduleName={moduleName} />
            </div>
          </div>
        ) : (
          ''
        )}

        {user.auth && profileNav ? (
          <div className="app-header__user-nav">
            <AppUserNav activeLink={activeUserPage} user={user} />
          </div>
        ) : (
          ''
        )}
      </header>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader);
