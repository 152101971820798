import React from 'react';
import cn from 'classnames';

import './CardRow.scss';

export const CardRow = ({
  children,
  jc,
  ai,
  flex,
  padded,
  marged,
  column,
  childrenFlex,
  line,
  inline,
  wrap,
  ma = null,
  offset = null,
  noPadding = false,
  wrapTop = false,
}) => (
  <div
    className={cn('card-row', {
      '--flex': flex,
      '--wrap': wrap,
      '--wrap-top': wrapTop,
      '--column': column,
      '--inline': inline,
      '--padded': padded,
      '--no-padded': noPadding,
      '--marged': marged,
      '--ma': ma,
      '--children-flex': childrenFlex,
      '--children-line': line,
      [`--jc-${jc}`]: jc,
      [`--ai-${ai}`]: ai,
      [`--offset-${offset}`]: offset,
    })}
  >
    {children}
  </div>
);

export default CardRow;
