import * as ActionTypes from 'constants/ActionTypes';
import { LOCATION_CHANGE } from 'connected-react-router';

const initialState = {
  name: 'fromUTC',
  slugs: [],
};

export default function fromUTC(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      if (
        action.payload &&
        action.payload.location.state &&
        action.payload.location.state.filters
      ) {
        return Object.assign({}, state, {
          ...state,
          slugs: action.payload.location.state.filters.fromUTC,
        });
      }

      return state;

    case ActionTypes.SET_FILTER_STATE:
      return Object.assign({}, state, {
        ...state,
        slugs: action.payload.fromUTC,
      });

    case ActionTypes.SET_FROMUTC:
      return Object.assign({}, state, {
        slugs: [action.payload],
      });

    case ActionTypes.CLEAR_FROMUTC:
    case ActionTypes.REMOVE_ALL_SLUGS:
      return Object.assign({}, state, {
        slugs: [],
      });

    default:
      return state;
  }
}
