import React from 'react';
import cn from 'classnames';

import AppSharedDropdown from 'components/shared/AppSharedDropdown';
import AppCoachSocials from './AppCoachSocials';
import AppButton from 'components/shared/AppButton';
import AppIcon from 'components/shared/AppIcon';
import { CardRate, CardNextAvailability } from 'components/Card';
import AppUserAvatar from 'components/shared/AppUserAvatar';
import AppTabs from 'components/AppTabs';

import * as UserTypes from 'constants/UserTypes';
import { COACH_STATUSES } from 'constants/CoachingProfileTypes';

import navAnchorHandler from 'utils/navAnchorHandler';

import './AppCoachDetailsCard.scss';

const AppCoachDetailsCard = ({ coach, bookToggleHandler, tabs }) => {
  const { general, next, status, rating = 2.5, reviewsCount = 0 } = coach;

  const previousCompanyes = () => {
    if (coach.previous_companies.length)
      return (
        <>
          . Previously worked at{' '}
          <span className="--bold">
            {coach.previous_companies.reduce((acc, company) => {
              if (acc.length) acc += ', ';
              acc += company.name;
              return acc;
            }, '')}
          </span>
        </>
      );
    else return null;
  };

  const handleScrollReviews = () => {
    if (tabs.length) {
      const isReviews = tabs.find((t) => t.id === 'reviews');

      if (isReviews) {
        navAnchorHandler(null, isReviews.id);
      }
    }
  };

  return (
    <>
      <div className="card-header">
        <div className="card-content_logo">
          {general.image.url !== null ? (
            <AppUserAvatar
              img={general.image.url}
              icon={UserTypes.PERSONS[0].icon}
              color={UserTypes.COLORS[0].value}
              defaultPlaceholder={UserTypes.PERSONS[0].defaultPlaceholder}
              size="xl"
            />
          ) : (
            <AppUserAvatar
              img={general.image.url}
              icon={UserTypes.PERSONS[general.image.person].icon}
              color={UserTypes.COLORS[general.image.color].value}
              defaultPlaceholder={
                UserTypes.PERSONS[general.image.person].defaultPlaceholder
              }
              size="xl"
            />
          )}
        </div>
        <div className="card-content_name">
          <div className="name">
            {general.name} {general.surname}
          </div>
          <div className="headline">{coach.headline}</div>
          {!!reviewsCount && (
            <div className="card-rating-row" onClick={handleScrollReviews}>
              <CardRate
                size="big"
                rating={rating}
                reviewsCount={reviewsCount}
              />
              {/* // TODO //  · ${coachingSessionsCount} coaching sessions  */}
            </div>
          )}
          <div>
            <AppCoachSocials links={coach.links} />
          </div>
        </div>
        <div className="card-content_right">
          <AppSharedDropdown jobTitle={general.name} />
          <AppButton
            target="_blank"
            title="Book a Session"
            defaultStyle="primary"
            height="medium"
            onClick={bookToggleHandler}
            isDisabled={status === COACH_STATUSES.PAUSED}
          />
        </div>
      </div>
      <div className="card-footer_container">
        <div className="card-footer">
          <div className="card-footer_inner">
            <div className="app-icon">
              <AppIcon icon="building" color="gray" />
            </div>
            <span>
              Works at <span className="--bold">{general.company.name}</span>
              {previousCompanyes()}
            </span>
          </div>
          <div className="card-footer_inner">
            <div className="app-icon">
              <AppIcon icon="geo" color="gray" />
            </div>
            <span>{general.location.name}</span>
          </div>
        </div>
        <div className="card-footer_time">
          <CardNextAvailability
            date={status === COACH_STATUSES.PAUSED ? null : next}
          />
          {status === COACH_STATUSES.PAUSED && (
            <span
              className={cn('card-footer_time-status', {
                [`--${status}`]: status,
              })}
            >
              {COACH_STATUSES.PAUSED.toUpperCase()}
            </span>
          )}
        </div>
      </div>

      {tabs && (
        <div className="pt-6">
          <AppTabs items={tabs} />
        </div>
      )}
    </>
  );
};

export default AppCoachDetailsCard;
