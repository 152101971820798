import React, { Component } from 'react';
import AppSearchTag from 'components/shared/AppSearch/AppSearchTag';
import AppSwitcher from 'components/shared/AppSwitcher';
import {
  AppPanelContainer,
  AppPanelList,
  AppPanelListItem,
  AppPanelTitle,
  AppPanelRow,
} from 'components/shared/AppPanel';

import * as FilterTypes from 'constants/FilterTypes';
import * as ActionTypes from 'constants/ActionTypes';
import * as SlugsTypes from 'constants/SlugsTypes';

import history from 'history.js';
import store from 'store';
import { getStateFilters } from 'utils';

import './AppSearchDropdownKeywordsPopular.scss';

class AppSearchDropdownKeywordsPopular extends Component {
  constructor(props) {
    super(props);

    this.experienceTags = [
      { id: 0, name: 'None', slug: [0, 10] },
      { id: 1, name: '1-3 years', slug: [1, 3] },
      { id: 2, name: '3-6 years', slug: [3, 6] },
      { id: 3, name: '7-10 years', slug: [7, 10] },
      { id: 4, name: '10+ years', slug: [10, 10] },
    ];
  }

  handlerLocationOn = () => {
    this.props.fetchNearestLocationByCoords().then(() => {
      this.props.handleClickOutside();
      this.props.handleSubmit();
    });
  };

  handlerLocationOff = () => {
    this.props.setNearestLocation(null);
    this.props.handleClickOutside();
    this.props.handleSubmit();
  };

  render() {
    const {
      roles,
      skills,
      locations: { nearest },
      addSlug,
      removeAllSlugs,
      addKeywordSlug,
      modal,
      modalClose,
      handleFetch,
      handleClickOutside,
      moduleName,
    } = this.props;

    return (
      <AppPanelContainer>
        {(roles && roles.populars && roles.populars.length > 0) ||
        roles.isFetching.populars ? (
          <AppPanelRow mt={20}>
            <AppPanelTitle>{FilterTypes.ROLES}</AppPanelTitle>
            <AppPanelList offset={true} isFetching={roles.isFetching.populars}>
              {roles.populars.map((item) => (
                <AppPanelListItem key={item.id}>
                  <AppSearchTag
                    modal={modal}
                    modalClose={modalClose}
                    data={item}
                    onClick={() => {
                      if (removeAllSlugs) removeAllSlugs();
                      if (handleClickOutside) handleClickOutside();
                      addSlug({ slugName: SlugsTypes.ROLES, slug: item });
                      addKeywordSlug({
                        value: '',
                        type: ActionTypes.ADD_VACANCIE_KEYWORD_SLUG,
                      });

                      if (handleFetch) handleFetch();
                      else
                        history.push(
                          `/jobs?${FilterTypes.ROLES}=${item.slug}`,
                          {
                            filters: getStateFilters(
                              store.getState(),
                              moduleName
                            ),
                            fetch: true,
                          }
                        );
                    }}
                  />
                </AppPanelListItem>
              ))}
            </AppPanelList>
          </AppPanelRow>
        ) : (
          ''
        )}

        {(skills && skills.populars && skills.populars.length > 0) ||
        skills.isFetching.populars ? (
          <AppPanelRow mb={20}>
            <AppPanelTitle>{FilterTypes.SKILLS}</AppPanelTitle>
            <AppPanelList offset={true} isFetching={skills.isFetching.populars}>
              {skills.populars.map((item) => (
                <AppPanelListItem key={item.id}>
                  <AppSearchTag
                    modal={modal}
                    modalClose={modalClose}
                    data={item}
                    onClick={() => {
                      if (removeAllSlugs) removeAllSlugs();
                      if (handleClickOutside) handleClickOutside();

                      addSlug({ slugName: SlugsTypes.SKILLS, slug: item });
                      addKeywordSlug({
                        value: '',
                        type: ActionTypes.ADD_VACANCIE_KEYWORD_SLUG,
                      });

                      if (handleFetch) handleFetch();
                      else
                        history.push(
                          `/jobs?${FilterTypes.SKILLS}=${item.slug}`,
                          {
                            filters: getStateFilters(
                              store.getState(),
                              moduleName
                            ),
                            fetch: true,
                          }
                        );
                    }}
                  />
                </AppPanelListItem>
              ))}
            </AppPanelList>
          </AppPanelRow>
        ) : (
          ''
        )}

        {this.experienceTags ? (
          <AppPanelRow>
            <AppPanelTitle>{FilterTypes.EXPERIENCE}</AppPanelTitle>
            <AppPanelList offset={true} isFetching={false}>
              {this.experienceTags.map((item) => (
                <AppPanelListItem key={item.id}>
                  <AppSearchTag
                    modal={modal}
                    modalClose={modalClose}
                    data={item}
                    onClick={() => {
                      if (removeAllSlugs) removeAllSlugs();
                      if (handleClickOutside) handleClickOutside();

                      addSlug({
                        slugName: SlugsTypes.EXPERIENCE,
                        slug: item.slug,
                      });
                      addKeywordSlug({
                        value: '',
                        type: ActionTypes.ADD_VACANCIE_KEYWORD_SLUG,
                      });

                      if (handleFetch) handleFetch();
                      else
                        history.push(
                          `/jobs?${FilterTypes.EXPERIENCE}=${item.slug}`,
                          {
                            filters: getStateFilters(
                              store.getState(),
                              moduleName
                            ),
                            fetch: true,
                          }
                        );
                    }}
                  />
                </AppPanelListItem>
              ))}
            </AppPanelList>
          </AppPanelRow>
        ) : (
          ''
        )}

        <AppPanelRow pb={20}>
          <AppPanelTitle>Search my location</AppPanelTitle>
          <AppSwitcher
            isOn={nearest}
            handlerClickOn={this.handlerLocationOn}
            handlerClickOff={this.handlerLocationOff}
          />
        </AppPanelRow>
      </AppPanelContainer>
    );
  }
}

export default AppSearchDropdownKeywordsPopular;
