export const publishingPlans = [
  {
    id: 1,
    value: 'free',
    title: 'Free',
    listTitle: null,
    list: [
      {
        label: 'Targeted Audience',
        hint: 'Promote your job to targeted tech professions in Product, Design, and Data.',
      },
      {
        label: 'Standard Posting',
        hint: 'Your job posting is served-up in job search results when it matches search criteria for any candidate.',
      },
    ],
    price: 0,
    upgrade: 'silver',
  },
  // {
  //   id: 2,
  //   value: 'bronze',
  //   title: 'Bronze',
  //   listTitle: 'Free features +',
  //   list: [
  //     {
  //       label: 'Social Media Distribution',
  //       hint: 'Your job is sent out through the ProductHired social media channels getting you even more exposure.',
  //     },
  //     {
  //       label: 'Priority Slack community posting',
  //       hint: "Because we're limited on how many jobs we can post into a Slack channel (4 per day), we prioritize which jobs get shown.",
  //     },
  //     {
  //       label: 'Inline Featured Promotion',
  //       hint: "Your posting stands out by being highlighted and labeled 'Motivated' in our search results, letting job seekers know you're eager to hire a candidate. Featured postings also rank higher in our 'Recommended' sorting.",
  //     },
  //     {
  //       label: 'Customized Job Detail page',
  //       hint: "Your job posting stands out with a full width cover photo that you can use to make your company shine showing off your company's personality.",
  //     },
  //   ],
  //   price: 199,
  //   upgrade: 'silver',
  // },
  {
    id: 3,
    value: 'silver',
    title: 'Silver',
    listTitle: 'Free features +',
    list: [
      {
        label: 'Inline Featured Placement',
        hint: "Your posting stands out by being highlighted and labeled 'Motivated' in our search results. This way job seekers know that you're eager to hire a candidate. Featured postings also rank higher in our 'Recommended' sorting and get favored prioritization in community and social channels.",
        bold: true,
      },
      {
        label: 'Custom Branding',
        hint: 'Your job gets to proudly display your company logo and banner assets to distinguish your identity from other employers we pull from the web.',
      },
      {
        label: 'Analytics',
        hint: '# of times in search results (impressions), # of Job details views (pageviews), and # of candidate applications.',
        bold: true,
      },
    ],
    price: 299,
    upgrade: 'gold',
  },
  {
    id: 4,
    value: 'gold',
    title: 'Gold',
    listTitle: 'Silver features +',
    list: [
      {
        label: 'Jobs Home Featured Placement',
        hint: "Your posting stands in front of all listings by being sticky and highlighted as 'Motivated' on the Home Page. This way job seekers know that you're eager to hire a candidate.",
        bold: true,
      },
      {
        label: 'White-Glove Treatment',
        hint: 'You get an account manager to promote your jobs, work exclusively with you to attract talent, and help strategize how to fill the role better.',
      },
    ],
    price: 499,
    upgrade: null,
  },
];
