import React from 'react';
import classnames from 'classnames';

import AppIcon from 'components/shared/AppIcon';

import './AppUserAvatar.scss';

const AppUserAvatar = function ({
  icon,
  img,
  size,
  className,
  color,
  fill,
  defaultPlaceholder,
}) {
  return (
    <div
      style={{ color, backgroundColor: color }}
      className={classnames(
        'app-user-avatar',
        {
          '--icon': icon,
          '--default': defaultPlaceholder,
          [`--size-${size}`]: size,
        },
        className
      )}
    >
      {icon && !img ? <AppIcon color={fill} icon={icon} /> : ''}
      {img ? (
        <img
          className="app-user-change-avatar-upload-img"
          src={
            process.env.NODE_ENV === 'development'
              ? process.env.REACT_APP_SERVER_URL + img
              : img
          }
          alt="avatar"
        />
      ) : (
        ''
      )}
    </div>
  );
};

export default AppUserAvatar;
