import React from 'react';
import cn from 'classnames';

import AppJobsCardPlaceholder from 'components/shared/AppJobsCard/AppJobsCardPlaceholder';

import * as PathTypes from 'constants/PathTypes';

import './CardImg.scss';

const CardImg = (props) => {
  const { id, alt, size, className, children, stretch, paddedVertical } = props;
  const [img, setImg] = React.useState(props.img);

  const onImgUrlError = () => setImg(null);

  const checkCorrectUrl = (string) => {
    if (string.indexOf('http') === 0) return string;
    else return PathTypes.SERVER_URL + string;
  };

  return (
    <div
      className={cn(
        'card-img',
        {
          [`--${size}`]: size,
          '--stretch': stretch,
          '--padded-v': paddedVertical,
        },
        className
      )}
    >
      {children ? (
        children
      ) : img ? ( // remove after done
        <img
          src={checkCorrectUrl(img)}
          onError={onImgUrlError}
          alt={alt}
          title={alt}
        />
      ) : (
        <AppJobsCardPlaceholder size={size} id={id} name={alt} />
      )}
    </div>
  );
};

export default CardImg;
