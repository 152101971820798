import React from 'react';
import AppCraftChart from './AppCraftChart';
import AppCraftNews from './AppCraftNews';
import AppCraftCompetitors from './AppCraftCompetitors';
import AppCraftMetrics from './AppCraftMetrics';

import './AppJobCraft.scss';

const AppJobCraft = ({ data, companyName }) => {
    const {
        employees,
        news,
        competitors,
        foundedYear,
        totalFunding,
        investors,
        fundingRounds,
        companyType,
        homepage
    } = data;

    return (
        <div className="app-jobs-craft">
            {employees && employees.length > 0 ? (
                <div className="app-job-details-info-section">
                    <a href="https://craft.co/" title="https://craft.co/" className="app-jobs-craft__logo">
                        <span>powered by</span>
                        <span>Craft</span>
                    </a>
                    <AppCraftChart
                        employees={employees}
                        type={companyType}
                        website={homepage}
                        founded={foundedYear}
                    />
                </div>
            ) : (
                ''
            )}
            {foundedYear || totalFunding || investors || fundingRounds || companyName ? (
                <div className="app-job-details-info-section" id="metrics">
                    <div className="app-job-details-info-section__title">
                        Summary Metrics
                    </div>
                    <div className="app-job-details-info-section__list-wrap">
                        <AppCraftMetrics
                            founded={foundedYear}
                            total={totalFunding}
                            investors={investors}
                            rounds={fundingRounds}
                            companyName={companyName}
                        />
                    </div>
                </div>
            ) : (
                ''
            )}
            {competitors && competitors.length > 0 ? (
                <div className="app-job-details-info-section" id="competitors">
                    <div className="app-job-details-info-section__title">
                        Top Competitors
                    </div>
                    <div className="app-job-details-info-section__list-wrap">
                        <AppCraftCompetitors competitors={competitors} />
                    </div>
                </div>
            ) : (
                ''
            )}
            {news && news.length > 0 ? (
                <div className="app-job-details-info-section" id="news">
                    <div className="app-job-details-info-section__title">
                        {companyName} News & Updates
                    </div>
                    <AppCraftNews news={news} />
                </div>
            ) : (
                ''
            )}
        </div>
    );
};

export default AppJobCraft;
