import React, { Component } from 'react';
import AppCoachesSearchDropdownKeywordsPopular from './AppCoachesSearchDropdownKeywordsPopular';
import AppCoachesSearchDropdownKeywordsSearch from './AppCoachesSearchDropdownKeywordsSearch';

import './AppCoachesSearchDropdownKeywords.scss';

class AppCoachesSearchDropdownKeywords extends Component {
  render() {
    const {
      coachingroles,
      companies,
      coachingtypes,
      addSlug,
      coachesBySearch,
      coachesIsFetching,
      addKeywordSlug,
      clearSlugsList,
      inputValue,
      modal,
      modalClose,
      removeAllSlugs,
      handleFetch,
      handleClickOutside,
      handleSubmit,
    } = this.props;

    return (
      <React.Fragment>
        {inputValue === '' || inputValue === undefined ? (
          <AppCoachesSearchDropdownKeywordsPopular
            modal={modal}
            modalClose={modalClose}
            coachingroles={coachingroles}
            coachingtypes={coachingtypes}
            addSlug={addSlug}
            addKeywordSlug={addKeywordSlug}
            removeAllSlugs={removeAllSlugs}
            handleFetch={handleFetch}
            handleClickOutside={handleClickOutside}
            handleSubmit={handleSubmit}
          />
        ) : (
          <AppCoachesSearchDropdownKeywordsSearch
            modal={modal}
            modalClose={modalClose}
            coachingroles={coachingroles}
            companies={companies}
            coachesBySearch={coachesBySearch}
            coachesIsFetching={coachesIsFetching}
            addSlug={addSlug}
            addKeywordSlug={addKeywordSlug}
            removeAllSlugs={removeAllSlugs}
            clearSlugsList={clearSlugsList}
            handleFetch={handleFetch}
          />
        )}
      </React.Fragment>
    );
  }
}

export default AppCoachesSearchDropdownKeywords;
