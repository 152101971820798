import React, { Component } from 'react';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
  WhatsappShareButton,
} from 'react-share';

import AppIcon from 'components/shared/AppIcon';

import * as PathTypes from 'constants/PathTypes';

import './AppSharedMobile.scss';

class AppSharedMobile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sharedIsOpen: false,
    };
  }

  onCloseClick = () => {
    this.setState({ sharedIsOpen: false });
  };

  handleSharedMenuToggle = () => {
    this.setState({ sharedIsOpen: true });
  };

  copyTextToClipboard = (text) => {
    const { setNotification, clearNotification } = this.props;
    const textArea = document.createElement('textarea');

    textArea.style.position = 'fixed';
    textArea.style.top = '-9999px';
    textArea.style.left = '-9999px';
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = 0;
    textArea.style.background = 'rgba(0, 0, 0, 0)';

    textArea.value = text;

    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
      setNotification({
        message: 'Copy Link success',
        type: 'info',
        status: 'info',
      });
    } catch (err) {
      setNotification({
        message: 'Copy Link error',
        type: 'info',
        status: 'info',
      });
    }
    setTimeout(() => clearNotification(), 5000);

    document.body.removeChild(textArea);
  };

  renderShareBtn = (item) => {
    switch (item.title) {
      case 'Facebook':
        return (
          <FacebookShareButton quote={item.jobTitle} url={item.url}>
            <SharedButton item={item} />
          </FacebookShareButton>
        );

      case 'Twitter':
        return (
          <TwitterShareButton title={item.jobTitle} url={item.url}>
            <SharedButton item={item} />
          </TwitterShareButton>
        );

      case 'Linkedin':
        return (
          <LinkedinShareButton url={item.url}>
            <SharedButton item={item} />
          </LinkedinShareButton>
        );

      case 'E-mail':
        return (
          <EmailShareButton subject={item.jobTitle} url={item.url}>
            <SharedButton item={item} />
          </EmailShareButton>
        );

      case 'WhatsApp':
        return (
          <WhatsappShareButton title={item.jobTitle} url={item.url}>
            <SharedButton item={item} />
          </WhatsappShareButton>
        );

      case 'Copy Link':
        return (
          <SharedButton
            onClick={() => this.copyTextToClipboard(item.url)}
            item={item}
          />
        );

      default:
        break;
    }
  };

  render() {
    const sharedMenuMobile = [
      {
        icon: 'fb',
        title: 'Facebook',
        jobTitle: this.props.jobTitle,
        url: PathTypes.SERVER_URL + window.location.pathname,
        color: '#335597',
      },
      {
        icon: 'tw',
        title: 'Twitter',
        jobTitle: this.props.jobTitle,
        url: PathTypes.SERVER_URL + window.location.pathname,
        color: '#49c0e6',
      },
      {
        icon: 'in',
        title: 'Linkedin',
        jobTitle: this.props.jobTitle,
        url: PathTypes.SERVER_URL + window.location.pathname,
        color: '#257da6',
      },
      // { icon: 'messenger', title: 'Messenger', jobTitle: this.props.jobTitle,  url: PathTypes.SERVER_URL + window.location.pathname, color: '#3095ff'},
      {
        icon: 'whats-app',
        title: 'WhatsApp',
        jobTitle: this.props.jobTitle,
        url: PathTypes.SERVER_URL + window.location.pathname,
        color: '#6ac855',
      },
      {
        icon: 'mail',
        title: 'E-mail',
        jobTitle: this.props.jobTitle,
        url: PathTypes.SERVER_URL + window.location.pathname,
        color: '#a0a6b8',
      },
      {
        icon: 'copy',
        title: 'Copy Link',
        jobTitle: this.props.jobTitle,
        url: PathTypes.SERVER_URL + window.location.pathname,
        color: '#a0a6b8',
      },
    ];

    return (
      <ul className="app-shared-menu-mobile__list">
        {sharedMenuMobile.map((item, idx) => (
          <li key={idx}>{this.renderShareBtn(item)}</li>
        ))}
      </ul>
    );
  }
}

const SharedButton = ({ item, onClick }) => {
  return (
    <div
      onClick={() => {
        if (window.gtag) {
          window.gtag('event', 'Share Job', { label: item.title });
        }
        onClick && onClick();
      }}
      className={`app-shared-menu-mobile__item app-shared-menu__item--${item.title}`}
      style={{ color: item.color }}
    >
      <span className="app-shared-menu-mobile__item-icon">
        <AppIcon icon={item.icon} />
      </span>
      <span className="app-shared-menu-mobile__item-title">{item.title}</span>
    </div>
  );
};

export default AppSharedMobile;
