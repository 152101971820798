import React from 'react';
import cn from 'classnames';
import Spinner from 'react-spinner-material';
import './AppSpinner.scss';

const AppSpinner = ({
  relative,
  height,
  size = 30,
  spinnerColor = '#333',
  spinnerWidth = 2,
  visible,
  className,
  fit,
}) => {
  return (
    <div
      style={{ height: height || 'initial' }}
      className={cn(
        'app-spinner',
        { '--relative': relative, '--fit': fit },
        className
      )}
    >
      <Spinner
        radius={size}
        color={spinnerColor}
        stroke={spinnerWidth}
        visible={visible}
      />
    </div>
  );
};

export default AppSpinner;
