import React from 'react';

import AppIcon from 'components/shared/AppIcon';

import './CardClock.scss';

export const CardClock = function ({ title }) {
  return (
    <div className="card-clock">
      <div className="app-jobs-card__icon">
        <AppIcon icon="clock" color="gray" />
      </div>
      <span>{title}</span>
    </div>
  );
};

export default CardClock;
