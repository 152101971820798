import React, { useMemo } from 'react';
import cn from 'classnames';

import { ReactComponent as IconStar } from './icon-star.svg';
import { ReactComponent as IconStarHalf } from './icon-star-half.svg';

import './CardRateStar.scss';

export const STAR_TYPES = {
  HALF: 'half',
  FULL: 'full',
  EMPTY: 'empty',
};

export const CardRateStar = ({
  size = '',
  type = false,
  defaultStyle = '',
}) => {
  const Icon = useMemo(() => {
    switch (type) {
      case STAR_TYPES.HALF:
        return IconStarHalf;

      case STAR_TYPES.EMPTY:
      case STAR_TYPES.FULL:
      default:
        return IconStar;
    }
  }, [type]);

  return (
    <div
      className={cn('card-rate-star', {
        [`--size-${size}`]: size,
        [`--s-${defaultStyle}`]: defaultStyle,
        '--empty': type === STAR_TYPES.EMPTY,
      })}
    >
      <Icon />
    </div>
  );
};

export default CardRateStar;
