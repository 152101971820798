import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Truncate from 'react-truncate';

import './ReadMore.scss';

const ACTION_STATES = {
  MORE: 'MORE',
  LESS: 'LESS',
};

const ReadMore = (props) => {
  const {
    children = null,
    more = 'Read more',
    less = 'Collapse',
    lines = 5,
  } = props;

  const [expanded, setExpanded] = useState(false);
  const [truncated, setTruncated] = useState(false);

  const handleTruncate = (t) => {
    if (truncated !== t) {
      setTruncated(t);
    }
  };

  const toggleLines = (e) => {
    e.preventDefault();
    setExpanded(!expanded);
  };

  const renderAction = useCallback(
    (action) => {
      const isLess = action === ACTION_STATES.LESS;

      return (
        <span>
          {isLess ? '' : '...  '}{' '}
          <a
            className="read-more-action app-link ml-2"
            href="/#"
            onClick={toggleLines}
          >
            {isLess ? less : more}
          </a>
        </span>
      );
    },
    [toggleLines, less, more]
  );

  return (
    <div className="read-more-t">
      <Truncate
        lines={!expanded && lines}
        ellipsis={renderAction(ACTION_STATES.MORE)}
        onTruncate={handleTruncate}
      >
        {children}
      </Truncate>
      {!truncated && expanded && renderAction(ACTION_STATES.LESS)}
    </div>
  );
};

ReadMore.propTypes = {
  children: PropTypes.node.isRequired,
  lines: PropTypes.number,
  less: PropTypes.string,
  more: PropTypes.string,
};

export default ReadMore;
