import React, { Component } from 'react';

import AppSearchTag from 'components/shared/AppSearch/AppSearchTag';
import {
  AppPanelContainer,
  AppPanelList,
  AppPanelListItem,
  AppPanelTitle,
  AppPanelRow,
} from 'components/shared//AppPanel';

import * as FilterTypes from 'constants/FilterTypes';
import * as ActionTypes from 'constants/ActionTypes';
import * as SlugsTypes from 'constants/SlugsTypes';
import * as PathTypes from 'constants/PathTypes';

import history from 'history.js';
import store from 'store';
import { getStateFilters } from 'utils';

import './AppCoachesSearchDropdownKeywordsSearch.scss';

class AppCoachesSearchDropdownKeywordsSearch extends Component {
  constructor(props) {
    super(props);
    this.state = { isLocationOn: false };
  }

  clearSearchLists = () => {
    const { clearSlugsList } = this.props;

    clearSlugsList(SlugsTypes.COACHINGROLES);
    clearSlugsList(SlugsTypes.COMPANIES);
  };

  render() {
    const {
      coachingroles,
      companies,
      addKeywordSlug,
      addSlug,
      removeAllSlugs,
      modal,
      modalClose,
      handleFetch,
      moduleName,
      coachesBySearch,
      coachesIsFetching,
    } = this.props;

    return (
      <AppPanelContainer>
        <div className="app-search-panel-dropdown-keywords-search">
          {(coachingroles &&
            coachingroles.list &&
            coachingroles.list.length > 0) ||
          coachingroles.isFetching.list ? (
            <AppPanelRow layout={'flex'} mt={20} mb={20}>
              <AppPanelTitle>{FilterTypes.COACHINGROLES_LABEL}</AppPanelTitle>
              <AppPanelList
                column={true}
                isFetching={coachingroles.isFetching.list}
              >
                {coachingroles.list.map((item) => (
                  <AppPanelListItem margin={'false'} key={item.id}>
                    <AppSearchTag
                      modal={modal}
                      modalClose={modalClose}
                      data={item}
                      onClick={() => {
                        this.clearSearchLists();
                        if (removeAllSlugs) removeAllSlugs();
                        addSlug({
                          slugName: SlugsTypes.COACHINGROLES,
                          slug: item,
                        });
                        addKeywordSlug({
                          value: '',
                          type: ActionTypes.ADD_COACHES_KEYWORD_SLUG,
                        });

                        if (handleFetch) handleFetch();
                        else
                          history.push(
                            `/coaches?${FilterTypes.COACHINGROLES}=${item.slug}`,
                            {
                              filters: getStateFilters(
                                store.getState(),
                                moduleName
                              ),
                              fetch: true,
                            }
                          );
                      }}
                      defaultStyle="list"
                    />
                  </AppPanelListItem>
                ))}
              </AppPanelList>
            </AppPanelRow>
          ) : (
            ''
          )}

          {(companies && companies.list && companies.list.length > 0) ||
          companies.isFetching.list ? (
            <AppPanelRow mb={20} mt={20} layout={'flex'}>
              <AppPanelTitle>{FilterTypes.COMPANIES}</AppPanelTitle>
              <AppPanelList
                column={true}
                isFetching={companies.isFetching.list}
              >
                {companies.list.map((item) => (
                  <AppPanelListItem margin={'false'} key={item.id}>
                    <AppSearchTag
                      modal={modal}
                      modalClose={modalClose}
                      data={item}
                      onClick={() => {
                        this.clearSearchLists();
                        if (removeAllSlugs) removeAllSlugs();
                        addSlug({ slugName: SlugsTypes.COMPANIES, slug: item });
                        addKeywordSlug({
                          value: '',
                          type: ActionTypes.ADD_COACHES_KEYWORD_SLUG,
                        });

                        if (handleFetch) handleFetch();
                        else
                          history.push(
                            `/coaches?${FilterTypes.COMPANIES}=${item.slug}`,
                            {
                              filters: getStateFilters(
                                store.getState(),
                                moduleName
                              ),
                              fetch: true,
                            }
                          );
                      }}
                      defaultStyle="list"
                    />
                  </AppPanelListItem>
                ))}
              </AppPanelList>
            </AppPanelRow>
          ) : (
            ''
          )}

          {(coachesBySearch && coachesBySearch.length > 0) ||
          coachesIsFetching ? (
            <AppPanelRow mb={20} mt={20} layout={'flex'}>
              <AppPanelTitle>Coaches</AppPanelTitle>
              <AppPanelList column={true} isFetching={coachesIsFetching}>
                {coachesBySearch.map((item) => (
                  <AppPanelListItem margin={'false'} key={item.url}>
                    <AppSearchTag
                      modal={modal}
                      modalClose={modalClose}
                      data={{ name: `${item.name} ${item.surname}` }}
                      onClick={() => {
                        history.push(`${PathTypes.COACHES}/${item.url}`, {
                          modal: true,
                          fetch: !history.location.pathname.includes('coaches')
                            ? false
                            : true,
                          from: !history.location.pathname.includes('coaches')
                            ? PathTypes.FROMCOACHING
                            : PathTypes.FROMCOACHES,
                          filters: getStateFilters(
                            store.getState(),
                            moduleName
                          ),
                        });
                      }}
                      defaultStyle="list"
                    />
                  </AppPanelListItem>
                ))}
              </AppPanelList>
            </AppPanelRow>
          ) : (
            ''
          )}
        </div>
      </AppPanelContainer>
    );
  }
}

export default AppCoachesSearchDropdownKeywordsSearch;
