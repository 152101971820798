import React, { Component } from 'react';
import AppIcon from "components/shared/AppIcon";
import './AppModalHead.scss';

export default class AppModalHead extends Component {
    onClearClick = () => {
        this.props.handleClickClear();
    }

    onBackClick = () => {
        this.props.handleClickBack();
    }

    render() {
        const {
            children,
            btnClear,
            btnBack,
            btnClearIsActive,
        } = this.props;

        return (
            <div className="app-modal-mobile-head">

                {
                    btnBack
                        ?
                            <div className="app-modal-mobile-head__back" onClick={this.onBackClick}>
                                <AppIcon icon="arrow-left" />
                            </div>
                        : ''
                }

                <div className="app-modal-mobile-head__content">
                    { children }
                </div>

                {
                    btnClear
                        ?
                            <div className={'app-modal-mobile-head__clear' + (btnClearIsActive ? ' app-modal-mobile-head__clear--active' : '')} onClick={this.onClearClick}>
                                Clear
                            </div>
                        : ''
                }

            </div>
        );
    }
}
