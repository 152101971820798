import React, { useCallback } from 'react';
import cn from 'classnames';

import CardRateStar from './CardRateStar';
import { DISPLAY_RATING_STARS } from 'constants/CoachingRatesTypes';

import { pluralizeWorld } from 'utils/Pluralize';

import './CardRate.scss';

const CardRate = ({
  rating = 2.5,
  reviewsCount = null,
  defaultStyle = 'init',
  offset = null,
  size = null,
}) => {
  const getStarType = useCallback(
    (num) => {
      if (rating >= num - 0.5) return 'full';
      else if (rating < num - 0.5 && num - rating < 1) return 'half';
      return 'empty';
    },
    [rating]
  );

  return (
    <div
      className={cn('card-rate', {
        [`--s-${size}`]: size,
        [`--offset-${offset}`]: offset,
      })}
    >
      <div className="card-rate__display">
        {DISPLAY_RATING_STARS.map((num) => (
          <span key={num}>
            <CardRateStar
              size={size}
              defaultStyle={defaultStyle}
              type={getStarType(num)}
            />
          </span>
        ))}
        {typeof reviewsCount === 'number' && (
          <span className="card-rate__rev-count">
            ({reviewsCount} {pluralizeWorld(reviewsCount, 'review')})
          </span>
        )}
      </div>
    </div>
  );
};

export default CardRate;
