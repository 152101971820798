import React from 'react';

import {
  Card,
  CardTitle,
  CardRow,
  CardRate,
  CardContent,
  CardLabel,
} from 'components/Card';
import AppUserAvatar from 'components/shared/AppUserAvatar/AppUserAvatar';
import { CardDateFormated } from 'components/Card';
import AppFlexLine from 'components/layout/AppFlexLine/AppFlexLine';

import * as UserTypes from 'constants/UserTypes';

import './CardReviewUser.scss';

const CardReviewUser = (props) => {
  const {
    created = '',
    rating = 2.5,
    user: { name, image },
    service = {},
  } = props;

  return (
    <Card>
      <CardRow flex ai="center">
        <AppUserAvatar
          img={image.url}
          icon={UserTypes.PERSONS[image.person].icon}
          color={UserTypes.COLORS[image.color].value}
        />
        <CardContent className="ml-5">
          <CardRow flex ai="center">
            <CardTitle size="small">{name}</CardTitle>
            <CardRate rating={rating} />
          </CardRow>
          <div className="mt-1">
            <AppFlexLine>
              <CardDateFormated date={created} />{' '}
              <CardLabel fs="small" headline>
                &nbsp;&middot; {service.type} &middot; {service.role}
              </CardLabel>
            </AppFlexLine>
          </div>
        </CardContent>
      </CardRow>
    </Card>
  );
};

export default CardReviewUser;
