import React from 'react';

import AppIcon from 'components/shared/AppIcon';

import './AppJobsCardExperience.scss';

const renderCardExperienceTitle = (from, to) => {
    if (from === 0 && to === 10) return 'Any Experience';
    else if (from !== 0 && to === 10) return `${from}+ Years Experience`;
    else if (from === to) return `${from} Years Experience`;
    else return `${from} - ${to} Years Experience`;
}

export const AppJobsCardExperience = function({ from ,to }) {

    return (
        <div className="app-jobs-card__experience">
            <div className="app-jobs-card__line">
                <div className="app-jobs-card__icon">
                    <AppIcon icon="diamond" color="gray" />
                </div>
                <span>{renderCardExperienceTitle(from, to)}</span>
            </div>
        </div>
    )
}

export default AppJobsCardExperience;
