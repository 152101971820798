import * as ActionTypes from 'constants/ActionTypes';

const initialState = {
  isLoaded: false,
  // events: {
  //   isLoaded: false,
  // },
};

export default function initState(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.SET_INIT_STATE_LOADED:
      return Object.assign({}, state, {
        isLoaded: true,
      });

    // case ActionTypes.SET_EVENTS_INIT_STATE_LOADED:
    //   return Object.assign({}, state, {
    //     events: {
    //       isLoaded: true,
    //     },
    //   });

    default:
      return state;
  }
}
