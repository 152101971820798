import axios from 'axios';

import * as types from 'constants/ActionTypes';
import { fetchTopRoles } from './RolesActions';
import {
  fetchInitStatePopularSlugs,
  fetchInitStatePopularEventtypes,
  fetchInitStatePopularCoachingtypes,
  fetchInitStatePopularCoachingroles,
} from './SlugsActions';

import * as SlugsTypes from 'constants/SlugsTypes';

export function setInitStateLoaded(payload) {
  return {
    type: types.SET_INIT_STATE_LOADED,
    payload,
  };
}

export function setFilterState(payload) {
  return {
    type: types.SET_FILTER_STATE,
    payload,
  };
}

export function setSerachStatus(payload) {
  return {
    type: types.SET_SEARCH_STATUS,
    payload,
  };
}

export function getInitState() {
  return function (dispatch) {
    return axios.all([
      dispatch(fetchTopRoles()),
      dispatch(fetchInitStatePopularSlugs(SlugsTypes.SKILLS)),
      dispatch(fetchInitStatePopularSlugs(SlugsTypes.COMPANIES)),
      dispatch(fetchInitStatePopularSlugs(SlugsTypes.LOCATIONS)),
      dispatch(fetchInitStatePopularSlugs(SlugsTypes.SENIORITIES)),
      // dispatch(fetchInitStatePopularSlugs(SlugsTypes.COACHINGROLES)),
      dispatch(fetchInitStatePopularEventtypes()),
      dispatch(fetchInitStatePopularCoachingtypes()),
      dispatch(fetchInitStatePopularCoachingroles()),
    ]);
  };
}
