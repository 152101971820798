import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import AppFlexLine from "components/layout/AppFlexLine";
import AppImg from "components/shared/AppImg";
import AppUserAvatar from "components/shared/AppUserAvatar";
import AppJobsCardImg from 'components/shared/AppJobsCard/AppJobsCardImg';

import * as UserTypes from 'constants/UserTypes';
import * as PathTypes from 'constants/PathTypes';

import './AppNotification.scss';

const mapStateToProps = state => ({
    notification: state.system && state.system.notification,
    user: state.user,
});

const AppNotification = ({ notification, className, user }) => 
    <div className={cn("app-notification-container", { 
        "show": notification,
        "--long": notification && notification.duration === 'long'
    })}>
        <div className={cn(
                "app-notification --info",
                {
                    // [notification.status ? `--${notification.status}` : '']: true,
                    className,
                },
            )}
        >
            { notification ?
                    <React.Fragment>
                        { notification.type === 'info' ?
                            <React.Fragment>
                                <div className="app-notification-row">
                                    <AppFlexLine flex="inline" jc="fs" ai="center">
                                        <div className="app-flex-line__left">
                                            { user && user.profile
                                                ?
                                                    <AppUserAvatar
                                                        img={user.profile.image.url}
                                                        icon={UserTypes.PERSONS[user.profile.image.person].icon}
                                                        color={UserTypes.COLORS[user.profile.image.color].value}
                                                        defaultPlaceholder={UserTypes.PERSONS[user.profile.image.person].defaultPlaceholder}
                                                    />
                                                :
                                                    <AppUserAvatar
                                                        icon={UserTypes.PERSONS[0].icon}
                                                        color={UserTypes.COLORS[0].value}
                                                        defaultPlaceholder={UserTypes.PERSONS[0].defaultPlaceholder}
                                                    />
                                            }
                                        </div>
                                        <div>
                                            <div className="app-text">
                                                {notification.message}
                                                <br />
                                                {user.profile ? user.profile.name : ((notification.data && notification.data.name) || '')}
                                            </div>
                                        </div>
                                    </AppFlexLine>
                                </div>
                            </React.Fragment> : ''
                        }

                        {
                            notification.type === 'job' && notification.data
                                ?
                                    <React.Fragment>
                                        <div className="app-notification-row">
                                            <AppFlexLine flex="inline" jc="fs" ai="center">
                                                <div className="app-flex-line__left">
                                                    <AppJobsCardImg
                                                        size="small"
                                                        id={notification.data.id}
                                                        img={notification.data.company.logo}
                                                        alt={notification.data.company.name}
                                                    />
                                                </div>
                                                <div>
                                                    <div className="app-text">{notification.message}</div>
                                                    {
                                                        notification.link
                                                            ?
                                                                <Link to={notification.link.path} className="app-link --acsent --up">{notification.link.text}</Link>
                                                            : ''

                                                    }
                                                </div>
                                            </AppFlexLine>
                                        </div>
                                    </React.Fragment>
                                : ''
                        }
                        {
                            notification.type === 'searchAlarm'
                                ?
                                    <React.Fragment>
                                        <div className="app-notification-row">
                                            <AppFlexLine flex="inline" jc="fs" ai="center">
                                                <div className="app-flex-line__left">
                                                    <AppImg icon="bell" />
                                                </div>
                                                <div className="app-flex-line__right">
                                                    <div className="app-text">{notification.message}</div>
                                                    <Link to={PathTypes.USER_PROFILE_SEARCH_ALARMS} className="app-link --acsent --up">See all Job Alerts</Link>
                                                </div>
                                            </AppFlexLine>
                                        </div>
                                    </React.Fragment>
                                : ''
                        }
                    </React.Fragment>
                : ''
            }
        </div>
    </div>

export default connect(mapStateToProps, null)(AppNotification);
