import React from 'react';
import Truncate from 'react-truncate';

import AppIcon from 'components/shared/AppIcon';

import './CardLocations.scss';

const renderCardLocationsTitle = (locations) => {
  let result = '';

  if (Array.isArray(locations)) {
    locations.forEach((location, idx) => {
      if (location.alias_region)
        result += `${location.name}, ${location.alias_region}`;
      else result += location.name;

      if (idx !== locations.length - 1 && locations.length > 0) result += '; ';
    });
  }

  return result;
};

export const CardLocations = function ({
  locations,
  truncate = false,
  address = null,
}) {
  const locationsTitle = locations ? renderCardLocationsTitle(locations) : null;

  return (
    <div className="card-locations" title={locationsTitle}>
      <div className="app-jobs-card__line">
        <div className="app-jobs-card__icon">
          <AppIcon icon="geo" color="gray" />
        </div>
        {locations && (
          <div className="app-jobs-card__locations-item">
            {truncate && locationsTitle ? (
              <Truncate lines={1}>{locationsTitle}</Truncate>
            ) : (
              locationsTitle
            )}
          </div>
        )}
        {address && (
          <div className="card-locations-item">
            {truncate ? <Truncate lines={1}>{address}</Truncate> : address}
          </div>
        )}
      </div>
    </div>
  );
};

export default CardLocations;
