import React from 'react';
import { connect } from 'react-redux';
import AnimateHeight from 'react-animate-height';

import {
  AppSearchPanel,
  AppSearchListPopular,
  AppSearchListPicks,
} from 'components/shared/AppSearch';
import AppSpinner from '../../shared/AppSpinner/index';
import AppSwitcher from 'components/shared/AppSwitcher';
import {
  AppPanelRow,
  AppPanelTitle,
  AppPanelFlexLine,
} from 'components/shared/AppPanel';

import {
  addSlug,
  removeSlug,
  removeAllSlugs,
  clearSlugsList,
  fetchSlugsListByNameAndType,
} from 'actions/SlugsActions';

import {
  fetchNearestLocationByCoords,
  setNearestLocation,
} from 'actions/LocationsActions';

import * as SearchTypes from 'constants/SearchTypes';
import { remoteSlug } from 'constants/LocationsTypes';

import './AppFiltersPanelLocations.scss';

const mapDispatchToProps = (dispatch) => ({
  addSlug: (slug) => dispatch(addSlug(slug)),
  removeSlug: (slug) => dispatch(removeSlug(slug)),
  removeAllSlugs: (slugName) => dispatch(removeAllSlugs(slugName)),
  clearSlugsList: (slugName) => dispatch(clearSlugsList(slugName)),
  fetchSlugsListByNameAndType: (payload) =>
    dispatch(fetchSlugsListByNameAndType(payload)),
  fetchNearestLocationByCoords: () => dispatch(fetchNearestLocationByCoords()),
  setNearestLocation: (payload) => dispatch(setNearestLocation(payload)),
});

const AppFiltersPanelLocations = (props) => {
  const [search, setSearch] = React.useState('');
  const [dropdown, setDropdown] = React.useState(false);

  const handlerLocationOn = () => {
    props.fetchNearestLocationByCoords().then(() => {
      if (props.handleSubmit) props.handleSubmit();
      if (props.handleCount) props.handleCount();
    });
  };

  const handlerLocationOff = () => {
    props.setNearestLocation(null);
    if (props.handleSubmit) props.handleSubmit();
    if (props.handleCount) props.handleCount();
  };

  const onInputChange = (value) => {
    const { slugName, clearSlugsList, fetchSlugsListByNameAndType } = props;

    setSearch(value);

    if (value.length) {
      setDropdown(true);
      fetchSlugsListByNameAndType({
        slugName,
        name: value,
        num: SearchTypes.DROPDOWN_SINGLE_SEARCH_LIST_COUNT,
      });
    } else {
      setDropdown(false);
      clearSlugsList(slugName);
    }
  };

  const onInputFocus = () => setDropdown(true);

  const onClickOutside = () => setDropdown(false);

  const onClearFilter = () => {
    const {
      slugName,
      removeAllSlugs,
      closeFilterPanel,
      handleCount,
      handleSubmit,
    } = props;

    removeAllSlugs(slugName);
    closeFilterPanel && closeFilterPanel();
    handleCount && handleCount();
    handleSubmit && handleSubmit();
  };

  const handlerClickAddTag = (item) => {
    const {
      clearSlugsList,
      addSlug,
      closeFilterPanel,
      handleCount,
      handleSubmit,
      slugName,
      analiticsTrigger,
    } = props;

    clearSlugsList(slugName);
    addSlug({ slugName, slug: item });

    if (dropdown) onClickOutside();
    if (closeFilterPanel) closeFilterPanel();
    if (handleCount) handleCount();
    if (analiticsTrigger) analiticsTrigger();
    if (handleSubmit) handleSubmit();
  };

  const handlerClickRemoveTag = (item) => {
    const {
      removeSlug,
      closeFilterPanel,
      handleCount,
      handleSubmit,
      slugName,
    } = props;

    removeSlug({ slugName, slug: item });
    closeFilterPanel && closeFilterPanel();
    handleCount && handleCount();
    handleSubmit && handleSubmit();
  };

  const { isFetching, list, slugs, populars, nearest } = props.data;

  return (
    <div className="app-filters-panel app-filters-panel-locations">
      <AppPanelRow mt={15} mb={15}>
        <AppPanelFlexLine>
          <AppPanelTitle defaultStyle="second">
            Search my location
          </AppPanelTitle>
          <AppSwitcher
            isOn={nearest ? true : false}
            handlerClickOn={handlerLocationOn}
            handlerClickOff={handlerLocationOff}
          />
          <AppPanelTitle
            hidden={!nearest && !isFetching.nearest}
            defaultStyle="text"
            color="green"
            className="--middle"
          >
            <span>Searching around </span>
            <span>
              {' '}
              {nearest && `${nearest.name}, ${nearest.alias_region}`}
            </span>
            <span className="app-filters-panel-locations__preloader">
              {isFetching.nearest ? (
                <AppSpinner
                  size={15}
                  spinnerColor={'#333'}
                  spinnerWidth={2}
                  visible={true}
                />
              ) : (
                  ''
                )}
            </span>
          </AppPanelTitle>
        </AppPanelFlexLine>
      </AppPanelRow>

      <AppPanelRow mt={0} mb={20}>
        <AppSearchPanel
          list={list}
          isFetching={isFetching.list}
          onInputChange={onInputChange}
          onInputFocus={onInputFocus}
          onInputBlur={() => { }}
          onClickOutside={onClickOutside}
          inputValue={search}
          dropdownIsActive={dropdown}
          handlerClickTag={handlerClickAddTag}
          inputPlaceholder="Search Locations"
          inputPlaceholderOnFocus="Search City"
          inputDisabled={nearest}
        />
      </AppPanelRow>

      <AnimateHeight duration={300} height={nearest ? 0 : 'auto'}>
        <AppPanelRow mt={15} mb={15}>
          <AppPanelFlexLine>
            <AppPanelTitle defaultStyle="second">Remote/Online</AppPanelTitle>
            <AppSwitcher
              isOn={slugs.find((s) => s.id === 1) ? true : false}
              handlerClickOn={() => handlerClickAddTag(remoteSlug)}
              handlerClickOff={() => handlerClickRemoveTag(remoteSlug)}
            />
          </AppPanelFlexLine>
        </AppPanelRow>
        {(slugs && slugs.length > 1) ||
          (slugs.length === 1 && slugs[0].id !== 1) ? (
            <AppPanelRow mb={15}>
              <AppSearchListPicks
                list={slugs}
                handlerClickTag={handlerClickRemoveTag}
                label="locations"
              />
            </AppPanelRow>
          ) : (
            ''
          )}
        <div className="app-filters-panel-location__dropdown">
          <AppPanelRow mb={15}>
            <AppSearchListPopular
              list={populars}
              myPicks={slugs}
              title="Top Locations"
              isFetching={isFetching.populars}
              handlerClickTag={handlerClickAddTag}
              handlerRemoveTag={handlerClickRemoveTag}
            />
          </AppPanelRow>

          {slugs && slugs.length > 0 ? (
            <AppPanelRow>
              <button onClick={onClearFilter}>
                <AppPanelTitle color="warn">Clear selection</AppPanelTitle>
              </button>
            </AppPanelRow>
          ) : (
              ''
            )}
        </div>
      </AnimateHeight>
    </div>
  );
};

export default connect(null, mapDispatchToProps)(AppFiltersPanelLocations);
