import React from 'react';
import './AppPanelFlexLine.scss';

const AppPanelFlexLine = (props) => {
    const { children } = props;
    
    return (
        <div className="app-panel-flex-line">
            { children }
        </div>
    ); 
}

export default AppPanelFlexLine;
