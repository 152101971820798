import React, { Component } from 'react';
import AppSearchTag from 'components/shared/AppSearch/AppSearchTag';
import AppSwitcher from 'components/shared/AppSwitcher';
import {
  AppPanelContainer,
  AppPanelList,
  AppPanelListItem,
  AppPanelTitle,
  AppPanelRow,
} from 'components/shared/AppPanel';

import * as FilterTypes from 'constants/FilterTypes';
import * as ActionTypes from 'constants/ActionTypes';
import * as SlugsTypes from 'constants/SlugsTypes';

import history from 'history.js';
import store from 'store';
import { getStateFilters } from 'utils';

import './AppEventsSearchDropdownKeywordsPopular.scss';

class AppEventsSearchDropdownKeywordsPopular extends Component {
  handlerLocationOn = () => {
    this.props.fetchNearestLocationByCoords().then(() => {
      this.props.handleClickOutside();
      this.props.handleSubmit();
    });
  };

  handlerLocationOff = () => {
    this.props.setNearestLocation(null);
    this.props.handleClickOutside();
    this.props.handleSubmit();
  };

  render() {
    const {
      skills,
      locations: { nearest },
      eventtypes,
      addSlug,
      removeAllSlugs,
      addKeywordSlug,
      modal,
      modalClose,
      handleFetch,
      handleClickOutside,
      moduleName,
    } = this.props;

    return (
      <AppPanelContainer>
        {(skills && skills.populars && skills.populars.length > 0) ||
          skills.isFetching.populars ? (
            <AppPanelRow pt={20} mb={20}>
              <AppPanelTitle>{FilterTypes.SKILLS}</AppPanelTitle>
              <AppPanelList offset={true} isFetching={skills.isFetching.populars}>
                {skills.populars.map((item) => (
                  <AppPanelListItem key={item.id}>
                    <AppSearchTag
                      modal={modal}
                      modalClose={modalClose}
                      data={item}
                      onClick={() => {
                        if (removeAllSlugs) removeAllSlugs();
                        if (handleClickOutside) handleClickOutside();

                        addSlug({ slugName: SlugsTypes.SKILLS, slug: item });
                        addKeywordSlug({
                          value: '',
                          type: ActionTypes.ADD_EVENTS_KEYWORD_SLUG,
                        });

                        if (handleFetch) handleFetch();
                        else
                          history.push(
                            `/events?${FilterTypes.SKILLS}=${item.slug}`,
                            {
                              filters: getStateFilters(
                                store.getState(),
                                moduleName
                              ),
                              fetch: true,
                            }
                          );
                      }}
                    />
                  </AppPanelListItem>
                ))}
              </AppPanelList>
            </AppPanelRow>
          ) : (
            ''
          )}

        {(eventtypes &&
          eventtypes.populars &&
          eventtypes.populars.length > 0) ||
          eventtypes.isFetching.populars ? (
            <AppPanelRow mb={20}>
              <AppPanelTitle>EVENT TYPES</AppPanelTitle>
              <AppPanelList
                offset={true}
                isFetching={eventtypes.isFetching.populars}
              >
                {eventtypes.populars.map((item) => (
                  <AppPanelListItem key={item.id}>
                    <AppSearchTag
                      modal={modal}
                      modalClose={modalClose}
                      data={item}
                      onClick={() => {
                        if (removeAllSlugs) removeAllSlugs();
                        if (handleClickOutside) handleClickOutside();

                        addSlug({ slugName: SlugsTypes.EVENTTYPES, slug: item });
                        addKeywordSlug({
                          value: '',
                          type: ActionTypes.ADD_EVENTS_KEYWORD_SLUG,
                        });

                        if (handleFetch) handleFetch();
                        else
                          history.push(`/events?types=${item.slug}`, {
                            filters: getStateFilters(
                              store.getState(),
                              moduleName
                            ),
                            fetch: true,
                          });
                      }}
                    />
                  </AppPanelListItem>
                ))}
              </AppPanelList>
            </AppPanelRow>
          ) : (
            ''
          )}

        <AppPanelRow pb={20}>
          <AppPanelTitle>Search my location</AppPanelTitle>
          <AppSwitcher
            isOn={nearest}
            handlerClickOn={this.handlerLocationOn}
            handlerClickOff={this.handlerLocationOff}
          />
        </AppPanelRow>
      </AppPanelContainer>
    );
  }
}

export default AppEventsSearchDropdownKeywordsPopular;
