import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import AppSelect from 'components/shared/AppSelect';
import AppFlexLine from 'components/layout/AppFlexLine';
import { ZONES_OPTIONS } from 'constants/TimezonesTypes';
import { getCurrentTimezone } from 'utils/Timezones';

import { setTimezone } from 'actions/SystemActions';

const AppTimezonesSelect = ({ timezone, setTimezone }) => {
  useEffect(() => {
    if (!timezone) setTimezone(getCurrentTimezone());
  }, []);

  return (
    <AppFlexLine ai="center">
      <label
        className="app-form-field-title --second --mb-0"
        htmlFor="timezone"
      >
        Time Zone:&nbsp;
      </label>
      <AppSelect
        id="timezone"
        className="--timezone"
        name="timezone"
        options={ZONES_OPTIONS}
        selectedOption={timezone}
        handleChange={(timezone) => setTimezone(timezone)}
        isSearchable={true}
        hideSelectedOptions={true}
      />
    </AppFlexLine>
  );
};

const mapStateToProps = ({ system }) => ({
  timezone: system.timezone,
});

const dispatchStateToProps = (dispatch) => ({
  setTimezone: (timezone) => dispatch(setTimezone(timezone)),
});

export default connect(
  mapStateToProps,
  dispatchStateToProps
)(AppTimezonesSelect);
