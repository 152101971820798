import React from 'react';
import cn from 'classnames';
import Truncate from 'react-truncate';

import AppIcon from 'components/shared/AppIcon';
import AppJobsCardImg from 'components/shared/AppJobsCard/AppJobsCardImg';
import { CardAddress } from 'components/Card';
import Moment from 'moment';
import { convertDateToRightFormat } from 'utils/Dates';

import './CardCoachInterest.scss';

const CardCoachInterest = (props) => {
  const {
    data: { logo, name, address, role, id, next_availability },
    size,
    // hoverOff,
    onClick,
  } = props;

  const date = Moment(convertDateToRightFormat(next_availability)).format(
    'ddd, MMMM D - HH:mm'
  );

  return (
    <div
      onClick={onClick}
      className={cn('app-jobs-card card-interest', {
        '--align-center': size,
      })}
    >
      <div className="app-jobs-card-interest__content">
        <div className="app-jobs-card-interest__content-top">
          <div className="app-jobs-card-interest__img">
            <AppJobsCardImg size="small" id={id} img={logo} alt={name} />
          </div>

          <div className="app-jobs-card-interest__titles">
            <div title={name} className="app-jobs-card-title --vacancie">
              <Truncate lines={2} width={250}>
                {name}
              </Truncate>
              <br></br>
              <Truncate lines={2} width={250}>
                {role}
              </Truncate>
            </div>
          </div>
        </div>

        {size === 'min' ? (
          ''
        ) : (
          <div className="app-jobs-card-interest__content-bottom">
            <div className="app-jobs-card__row --small">
              <CardAddress address={address} />
            </div>
            <div className="app-jobs-card__row --small --flex-bw">
              <div className="app-jobs-card__line">
                <div className="card-interest__icon">
                  <AppIcon icon="clock" color="gray" />
                </div>
                <label>{`${date} GMT`}</label>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardCoachInterest;
