import React, { Component } from 'react';
import Moment from 'moment';
import PropTypes from 'prop-types';

import AppJobsCardImg from 'components/shared/AppJobsCard/AppJobsCardImg';

import './AppJobsSmallCard.scss';

class AppJobsSmallCard extends Component {    
    displayDataCreatedFromNow = (data) => {
        let dateFromNow = Moment(data).fromNow();

        if (dateFromNow.toLowerCase().indexOf('minutes') >= 0 || dateFromNow.toLowerCase().indexOf('seconds') >= 0) dateFromNow = 'Today';
        return dateFromNow[0].toUpperCase() + dateFromNow.slice(1);
    }

    render() {
        const { data: { company, name, id } } = this.props;

        return (
            <div className='app-jobs-small-card'>
                <div className="app-jobs-small-card__inner">
                    <div className="app-jobs-small-card__row">
                
                        <div className="app-jobs-small-card__content">
                            <div title={name} className="app-jobs-card-title app-jobs-card-title--vacancie">
                                {name}
                            </div>
                            <div title={name} className="app-jobs-card-title app-jobs-card-title--company">
                                {company ? company.name : ''}
                            </div>
                        </div>

                        <div className="app-jobs-card-img">
                            { company ? <AppJobsCardImg size="small" id={id} img={company.logo} alt={company.name} /> : '' }
                        </div>

                    </div>
                </div>
            </div>
        ); 
    }
}

AppJobsSmallCard.propTypes = {
    company: PropTypes.object,
    name: PropTypes.string,
}

export default AppJobsSmallCard;
