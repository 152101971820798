import React from 'react';
import Moment from 'moment';
import Numeral from 'numeral';

import './AppCraftMetrics.scss';
const AppCraftMetrics = ({
    investors,
    founded,
    total,
    rounds,
    companyName
}) => {
    return (
        <div>
            <ul className="app-craft-metrics">
                {founded ? (
                    <li>
                        <div className="app-craft-metrics-item">
                            <div className="app-craft-metrics-item__title">
                                Founding Date
                            </div>
                            <div className="app-craft-metrics-item__descr">
                                {founded}
                            </div>
                        </div>
                    </li>
                ) : (
                    ''
                )}
                {total ? (
                    <li>
                        <div className="app-craft-metrics-item">
                            <div className="app-craft-metrics-item__title">
                                {companyName} Total Funding
                            </div>
                            <div className="app-craft-metrics-item__descr --up">
                                {`${Numeral(total).format('$0.[0] a')}`}
                            </div>
                        </div>
                    </li>
                ) : (
                    ''
                )}
                {rounds && rounds.length > 0 ? (
                    <React.Fragment>
                        <li>
                            <div className="app-craft-metrics-item">
                                <div className="app-craft-metrics-item__title">
                                    {companyName} Latest Funding Size
                                </div>
                                <div className="app-craft-metrics-item__descr --up">
                                    {`${Numeral(rounds[0].amount.value).format(
                                        '$0.[0] a'
                                    )}`}
                                </div>
                            </div>
                        </li>
                        <li>
                            <div className="app-craft-metrics-item">
                                <div className="app-craft-metrics-item__title">
                                    Last Funded
                                </div>
                                <div className="app-craft-metrics-item__descr">
                                    {Moment(rounds[0].date).fromNow()}
                                </div>
                            </div>
                        </li>
                    </React.Fragment>
                ) : (
                    ''
                )}
            </ul>
            {Array.isArray(investors) && investors.length > 0 ? (
                <div className="app-craft-metrics-investors">
                    <h5>{companyName} Investors</h5>
                    <p>{investors.length === 1 ? investors[0] : investors.join(', ')}</p>
                </div>
            ) : (
                ''
            )}
        </div>
    );
};

export default AppCraftMetrics;
