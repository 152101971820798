import React, { useMemo } from 'react';
import cn from 'classnames';
import Truncate from 'react-truncate';

import AppIcon from 'components/shared/AppIcon';
import AppJobsCardImg from 'components/shared/AppJobsCard/AppJobsCardImg';
import { CardAddress } from 'components/Card';

import './CardEventInterest.scss';
import { CardEventDate } from '../Card/index';

const CardEventInterest = (props) => {
  const {
    data: { logo, name, address, utc_start, date_start, locations, id },
    size,
    // hoverOff,
    onClick,
  } = props;

  const isOnlineLocation = useMemo(() =>
    locations.find((location) => location.id === 1)
  );

  return (
    <div
      onClick={onClick}
      className={cn('app-jobs-card card-interest', {
        '--align-center': size,
      })}
    >
      <div className="app-jobs-card-interest__content">
        <div className="app-jobs-card-interest__content-top">
          <div className="app-jobs-card-interest__img">
            <AppJobsCardImg size="small" id={id} img={logo} alt={name} />
          </div>

          <div className="app-jobs-card-interest__titles">
            <div title={name} className="app-jobs-card-title --vacancie">
              <Truncate lines={2} width={250}>
                {name}
              </Truncate>
            </div>
          </div>
        </div>

        {size === 'min' ? (
          ''
        ) : (
          <div className="app-jobs-card-interest__content-bottom">
            <div className="app-jobs-card__row --small">
              <CardAddress address={address} />
            </div>
            <div className="app-jobs-card__row --small --flex-bw">
              <div className="app-jobs-card__line">
                <div className="card-interest__icon">
                  <AppIcon icon="clock" color="gray" />
                </div>
                <CardEventDate
                  online={isOnlineLocation}
                  dateStart={isOnlineLocation ? utc_start : date_start}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default CardEventInterest;
