import * as ActionTypes from 'constants/ActionTypes';
import { LOCATION_CHANGE } from 'connected-react-router';

const initialState = {
  name: 'locations',
  list: [],
  populars: [],
  userSlugs: [],
  slugs: [],
  nearest: null,
  isFetching: {
    list: false,
    populars: false,
    slugs: false,
    nearest: false,
  },
};

export default function search(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      if (
        action.payload &&
        action.payload.location.state &&
        action.payload.location.state.filters
      ) {
        return Object.assign({}, state, {
          slugs: action.payload.location.state.filters.locations,
        });
      }

      return state;

    case ActionTypes.SET_NEAREST_LOCATION:
      return Object.assign({}, state, {
        nearest: action.payload,
      });

    case ActionTypes.SET_FILTER_STATE:
      return Object.assign({}, state, {
        slugs: action.payload.locations,
      });

    case ActionTypes.RECEIVE_INIT_STATE_POPULAR_LOCATIONS:
      return Object.assign({}, state, {
        populars: action.payload,
      });

    case ActionTypes.SET_FETCHING_INIT_STATE_POPULAR_LOCATIONS:
      return Object.assign({}, state, {
        isFetching: {
          ...state.isFetching,
          populars: action.payload,
        },
      });

    case ActionTypes.SET_FETCHING_LOCATIONS:
      return Object.assign({}, state, {
        isFetching: {
          ...state.isFetching,
          list: action.payload,
        },
      });

    case ActionTypes.SET_FETCHING_NEAREST_LOCATION:
      return Object.assign({}, state, {
        isFetching: {
          ...state.isFetching,
          nearest: action.payload,
        },
      });

    case ActionTypes.SET_FETCHING_POPULAR_LOCATIONS:
      return Object.assign({}, state, {
        isFetching: {
          ...state.isFetching,
          populars: action.payload,
        },
      });

    case ActionTypes.RECEIVE_LOCATIONS:
      return Object.assign({}, state, {
        list: action.payload,
      });

    case ActionTypes.CLEAR_LOCATIONS:
      return Object.assign({}, state, {
        list: [],
      });

    case ActionTypes.ADD_LOCATION_SLUG:
      const slug = state.slugs.slice();

      if (!slug.some((slug) => action.payload.id === slug.id))
        slug.push(action.payload);

      return Object.assign({}, state, {
        slugs: slug,
      });

    case ActionTypes.REMOVE_LOCATION_SLUG:
      return Object.assign({}, state, {
        slugs: state.slugs.filter((item) => item.id !== action.payload.id),
      });

    case ActionTypes.REMOVE_ALL_LOCATIONS_SLUGS:
    case ActionTypes.REMOVE_ALL_SLUGS:
      return Object.assign({}, state, {
        slugs: [],
      });

    default:
      return state;
  }
}
