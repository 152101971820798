import React from 'react';
import cn from 'classnames';

import './CardContent.scss';

export const CardContent = ({
  children,
  bottom = false,
  padded = false,
  noTop = false,
  npBottom = false,
  paddedSide = false,
  layout = '',
  nooverflow = false,
  className = '',
}) => (
  <div
    className={cn(
      'card-content',
      {
        [`--${layout}`]: layout,
        '--padded': padded,
        '--padded-side': paddedSide,
        '--no-top': noTop,
        '--no-bottom': npBottom,
        '--bottom': bottom,
        '--no-overflow': nooverflow,
      },
      className
    )}
  >
    {children}
  </div>
);

export default CardContent;
