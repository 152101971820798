export const PERSONS = [ // avatar person types
    { icon: 'avatar-0', type: 0, defaultPlaceholder: true },  // default
    { icon: 'avatar-1', type: 1 },
    { icon: 'avatar-2', type: 2 },
    { icon: 'avatar-3', type: 3 },
    { icon: 'avatar-4', type: 4 },
    { icon: 'avatar-5', type: 5 },
    { icon: 'avatar-6', type: 6 },
];

export const COLORS = [ // avatar bg color palette
    { id: 0, value: '#f1d7d2' }, // default light brown
    { id: 1, value: '#97e4c9' }, // green
    { id: 2, value: '#ffdfb6' }, // orange
    { id: 3, value: '#95c9ff' }, // blue
    { id: 4, value: '#e9edf3' }, // grey-300
];

export const NOTIFICATIONS_INTERVAL = 60000;