import React from 'react';
import cn from 'classnames';

import AppFooter from 'components/AppFooter';
import AppNotification from 'components/shared/AppNotification';
import AppCookieBanner from 'components/AppCookieBanner';
import AppModalWrapper from 'components/AppModalWrapper';

import * as PathTypes from 'constants/PathTypes';
import history from 'history.js';

import 'assets/scss/App.scss';

const App = ({ children }) => {
  const { location } = history;

  return (
    <div className="app">
      <main
        className={cn('app-content', {
          '--bg': PathTypes.EMPLOYER_HOME === (location && location.pathname),
        })}
      >
        {children}
      </main>

      <AppFooter location={location} />
      <AppNotification />
      <AppCookieBanner />
      <AppModalWrapper />
    </div>
  );
};

export default App;
