export const analiticsCategoriesTypes = {
  COACHING: 'coaching',
  JOB_POSTING: 'job-posting',
  BE_A_COACH: 'be-a-coach',
};

const trackMatomoEvent = ({
  action = '',
  category = '',
  name = null,
  value = null,
}) => {
  if (window._paq) {
    const data = ['trackEvent', category, action];

    if (name) {
      data.push(name);
    }

    if (value) {
      data.push(value);
    }
    // trackEvent(data);
    window._paq.push(data);
  }
};

const trackGtagEvent = (action, data) => {
  if (window.gtag) {
    window.gtag('event', action, data);
  }
};

export const trackAnaliticsEvent = ({
  action = 'undefined', // string (required)
  category = 'undefined', // string (required)
  value = null, // number (optional)
  name = null, // string (optional)
}) => {
  // if (process.env.NODE_ENV === 'production') {
    const data = {
      category,
    };

    if (name) {
      data.name = name;
    }

    if (value) {
      data.value = value;
    }

    trackMatomoEvent({ action, ...data });
    trackGtagEvent(action, data);
  // }
};
