import React from 'react';
import classnames from 'classnames';
import './AppTextarea.scss';

const AppTextarea = ({
  field: { name, value, onChange, onBlur },
  id,
  className,
  minHeight = 'unset',
  resize,
  hint,
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  return (
    <textarea
      name={name}
      onChange={onChange}
      value={value}
      checked={value}
      onBlur={onBlur}
      style={{ minHeight }}
      className={classnames('app-textarea', { '--resize': resize }, className)}
      {...props}
    >
      {value}
    </textarea>
  );
};

export default AppTextarea;
