import React from 'react';
import './AppPanelContainer.scss';

const AppPanelContainer = (props) => {
    const { children, pad } = props;
    
    return (
        <div className={ 'app-panel-container' + (pad ? ` app-panel-container--${pad}` : '') }>
            <div className="app-panel-container__inner">
                { children }
            </div>
        </div>
    ); 
}

export default AppPanelContainer;
