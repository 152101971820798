import React, { Component } from 'react';
import {
    AppPanelList,
    AppPanelListItem,
    AppPanelTitle,
} from "components/shared/AppPanel";
import { AppSearchTag } from "components/shared/AppSearch";
import './AppSearchListPopularCompanies.scss';

class AppSearchListPopularCompanies extends Component {

    renderTagCondition = (picks, slug) => {
        if (slug && picks[0] !== undefined) return picks.some((item) => item.id === slug.id);
        else return false;
    }

    render() {
        const {
            list,
            isFetching,
            handlerClickAddTag,
            title,
            myPicks,
            handlerRemoveTag,
        } = this.props;

        return (
            <div className="app-search-list app-search-list-popular">
                <AppPanelTitle>Motivated {title || 'Title'}</AppPanelTitle>
                <AppPanelList className="--companies" offset={true} isFetching={isFetching}>
                    { list && list.length > 0
                        ?
                            list.map((item) => (
                                <AppPanelListItem double={true} key={item.id}>
                                    <AppSearchTag
                                        picks={myPicks}
                                        data={item}
                                        onClick={() => handlerClickAddTag(item)}
                                        title={this.renderTagCondition(myPicks, item)}
                                        double={item && this.renderTagCondition(myPicks, item)}
                                        img={item.logo}
                                        onRemove={() => handlerRemoveTag(item)}
                                    />
                                </AppPanelListItem>
                            ))
                        : ''
                    }
                </AppPanelList>
            </div>
        );
    }
}

export default AppSearchListPopularCompanies;