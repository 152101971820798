import React, { Component } from 'react';
import onClickOutside from "react-onclickoutside";

import AppButton from 'components/shared/AppButton';
import AppShared from 'components/shared/AppShared';

import * as PathTypes from "constants/PathTypes";

import './AppSharedDropdown.scss';

class AppSharedDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sharedIsOpen: false
        };
    }

    componentDidMount() {
        this.props.disableOnClickOutside();
    }

    handleSharedMenuToggle = () => {
        this.state.sharedIsOpen
            ? this.props.disableOnClickOutside()
            : this.props.enableOnClickOutside();

        this.setState({ sharedIsOpen: !this.state.sharedIsOpen });
    }

    handleClickOutside = () => {
        this.setState({ sharedIsOpen: false });
        this.props.disableOnClickOutside();
    }

    render() {
        const {
            onClick,
            defaultStyle,
            sharedDefaultStyle,
            title,
            jobTitle,
        } = this.props;

        const list = [
            { icon: 'fb', title: 'Facebook', shareTitle: jobTitle,  url: PathTypes.SERVER_URL + window.location.pathname,  color: '#335597'},
            { icon: 'tw', title: 'Twitter', shareTitle: jobTitle, url: PathTypes.SERVER_URL + window.location.pathname,  color: '#49c0e6'},
            { icon: 'in', title: 'Linkedin', shareTitle: jobTitle, url: PathTypes.SERVER_URL + window.location.pathname,  color: '#257da6'},
            { icon: 'mail', title: 'E-mail', shareTitle: jobTitle,  url: PathTypes.SERVER_URL + window.location.pathname, color: '#a0a6b8'},
            { icon: 'copy', title: 'Copy Link', shareTitle: jobTitle,  url: PathTypes.SERVER_URL + window.location.pathname,color: '#a0a6b8'},
        ];

        return (
            <AppButton
                onClick={onClick || this.handleSharedMenuToggle}
                defaultStyle={defaultStyle || 'secondary'}
                icon="share"
                height="medium"
                title={title || ''}
            >
                { this.state.sharedIsOpen
                    ? <AppShared list={list} defaultStyle={sharedDefaultStyle || "menu"} />
                    : ""
                }
            </AppButton>
        );
    }
}

export default onClickOutside(AppSharedDropdown);