import React, { Component, Suspense } from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';

import AppSuspenseLoader from 'components/AppSuspenseLoader';
import { fetchAddSearchAlarmByIdAndData } from 'actions/SearchAlarmsActions';
import {
  clearNotification,
  setModal,
  setNotification,
} from 'actions/SystemActions';
import {
  signIn,
  changeUserProfile,
  changeUserProfileEmailNotifications,
  uploadProfileImage,
  fetchOnUserChangePassword,
  fetchUserNewPassword,
} from 'actions/UserActions';

import './AppModalWrapper.scss';

const AppLogin = React.lazy(() => import('components/shared/AppLogin'));
const AppSignup = React.lazy(() => import('components/shared/AppSignup'));
const AppUserChangeAvatar = React.lazy(
  () =>
    import(
      'components/pages/AppUserProfilePage/AppUserProfileMain/AppUserChangeAvatar'
    )
);
const AppUserChangeNameAndEmail = React.lazy(
  () =>
    import(
      'components/pages/AppUserProfilePage/AppUserProfileMain/AppUserChangeNameAndEmail'
    )
);
const AppUserChangePassword = React.lazy(
  () =>
    import(
      'components/pages/AppUserProfilePage/AppUserProfileMain/AppUserChangePassword'
    )
);
const AppUserNewPassword = React.lazy(
  () =>
    import(
      'components/pages/AppUserProfilePage/AppUserProfileMain/AppUserNewPassword'
    )
);
const AppAddSearchAlarm = React.lazy(
  () => import('components/pages/AppJobsPage/AppAddSearchAlarm')
);
const AppConfirmModal = React.lazy(() => import('components/AppConfirmModal'));
const AppSessionConfirmModal = React.lazy(() => import('components/AppSessionConfirmModal'));
const AppJobCreatedModal = React.lazy(
  () => import('components/AppJobCreatedModal')
);
const AppCoachBookSessionModal = React.lazy(
  () => import('components/AppCoachBookSessionModal')
);
const AppCoachBookPaydSessionModal = React.lazy(
  () => import('components/AppCoachBookPaydSessionModal')
);
const AppApplyLogin = React.lazy(() => import('components/AppApplyLogin'));
const AppUserOnboardingModal = React.lazy(
  () => import('components/AppUserOnboardingModal')
);
const AppUserEmailNotificationsModal = React.lazy(
  () => import('components/AppUserEmailNotificationsModal')
);
const AppRescheduleSessionModal = React.lazy(
  () => import('components/AppRescheduleSessionModal')
);
const AppCoachVisibilityModal = React.lazy(
  () => import('components/AppCoachVisibilityModal')
);
const AppCoachingRewievsModal = React.lazy(
  () => import('components/AppCoachingRewievsModal')
);

// const AppCoachPreVisibilityModal = React.lazy(
//   () => import('components/AppCoachPreVisibilityModal')
// );

const mapDispatchToProps = (dispatch) => ({
  changeUserProfile: (payload) => dispatch(changeUserProfile(payload)),
  changeUserProfileEmailNotifications: (payload) =>
    dispatch(changeUserProfileEmailNotifications(payload)),
  clearNotification: () => dispatch(clearNotification()),
  setModal: (payload) => dispatch(setModal(payload)),
  uploadProfileImage: (payload) => dispatch(uploadProfileImage(payload)),
  fetchAddSearchAlarmByIdAndData: (payload) =>
    dispatch(fetchAddSearchAlarmByIdAndData(payload)),
  fetchOnUserChangePassword: (payload) =>
    dispatch(fetchOnUserChangePassword(payload)),
  fetchUserNewPassword: (payload) => dispatch(fetchUserNewPassword(payload)),
  signIn: (payload) => dispatch(signIn(payload)),
  setNotification: (payload) => dispatch(setNotification(payload)),
});

const mapStateToProps = (state) => ({
  system: state.system,
  user: state.user,
  location: state.location,
  roles: state.roles,
  skills: state.skills,
  seniorities: state.seniorities,
  companies: state.companies,
  locations: state.locations,
  experience: state.experience,
  vacancies: state.vacancies,
});

class AppModalWrapper extends Component {
  state = { isFirstVisit: false };

  toggleModalHandler = (modal, notification, props) =>
    this.props.setModal({ active: modal, notification, props });

  render() {
    const {
      system: { modal, loading },
      user,
      changeUserProfile,
      changeUserProfileEmailNotifications,
      uploadProfileImage,
      roles,
      skills,
      seniorities,
      companies,
      locations,
      experience,
      vacancies,
      fetchAddSearchAlarmByIdAndData,
      fetchOnUserChangePassword,
      fetchUserNewPassword,
      signIn,
      clearNotification,
      setNotification,
    } = this.props;

    return (
      <div className={cn('app-modal-animate', { active: modal.active })}>
        <React.Fragment>
          <div
            className="app-modal-animate__backdrop"
            onClick={() => this.toggleModalHandler(false)}
          ></div>
          <div
            className={cn('app-modal-animate__inner', {
              '--no-padding': modal && modal.active === 'session-review',
            })}
          >
            <Suspense fallback={<AppSuspenseLoader />}>
              {modal.active === 'changeAvatar' ? (
                <AppUserChangeAvatar
                  profile={user.profile}
                  submitHandler={changeUserProfile}
                  onCloseClick={() => this.toggleModalHandler(false)}
                  uploadImageHandler={uploadProfileImage}
                />
              ) : (
                ''
              )}
              {modal.active === 'changePassword' ? (
                <AppUserChangePassword
                  profile={user.profile}
                  toggleModalHandler={this.toggleModalHandler}
                  submitHandler={fetchOnUserChangePassword}
                  onCloseClick={() => this.toggleModalHandler(false)}
                />
              ) : (
                ''
              )}
              {modal.active === 'changeNameAndEmail' ? (
                <AppUserChangeNameAndEmail
                  profile={user.profile}
                  submitHandler={changeUserProfile}
                  onCloseClick={() => this.toggleModalHandler(false)}
                />
              ) : (
                ''
              )}
              {modal.active === 'notification-settings' ? (
                <AppUserEmailNotificationsModal
                  profile={user.profile}
                  submitHandler={changeUserProfileEmailNotifications}
                  onCloseClick={() => this.toggleModalHandler(false)}
                />
              ) : (
                ''
              )}
              {modal.active === 'login' ? (
                <AppLogin
                  onSignupLinkClick={() => this.toggleModalHandler('signup')}
                  onCloseClick={() => this.toggleModalHandler(false)}
                  isModal={true}
                  data={modal.data || {}}
                />
              ) : (
                ''
              )}
              {modal.active === 'signup' ? (
                <AppSignup
                  onLoginLinkClick={() => this.toggleModalHandler('login')}
                  onCloseClick={() => this.toggleModalHandler(false)}
                  isModal={true}
                  notification={modal.notification || null}
                  title={modal.title || null}
                  data={modal.data || {}}
                />
              ) : (
                ''
              )}
              {modal.active === 'searchAlarm' ? (
                <AppAddSearchAlarm
                  loading={loading}
                  onCloseClick={() => this.toggleModalHandler(false)}
                  roles={roles}
                  userId={user && user.profile && user.profile.id}
                  skills={skills}
                  seniorities={seniorities}
                  companies={companies}
                  locations={locations}
                  experience={experience}
                  keywords={vacancies.keywords}
                  fetchAddSearchAlarmByIdAndData={
                    fetchAddSearchAlarmByIdAndData
                  }
                />
              ) : (
                ''
              )}
              {modal.active === 'confirm' ? (
                <AppConfirmModal
                  title={modal.title}
                  onYes={modal.onYes}
                  subtitle={modal.subtitle}
                  onCloseClick={() => this.toggleModalHandler(false)}
                />
              ) : (
                ''
              )}
              {modal.active === 'createJob' && modal.data ? (
                <AppJobCreatedModal
                  user={user}
                  jobName={modal.data.name}
                  companyName={modal.data.company.name}
                  onCloseClick={() => this.toggleModalHandler(false)}
                />
              ) : (
                ''
              )}
              {modal.active === 'createBookSession' && modal.data ? (
                <AppCoachBookSessionModal
                  data={modal.data}
                  onCloseClick={() => this.toggleModalHandler(false)}
                />
              ) : (
                ''
              )}
              {modal.active === 'payBookSession' && modal.data ? (
                <AppCoachBookPaydSessionModal
                  data={modal.data}
                  onCloseClick={() => this.toggleModalHandler(false)}
                />
              ) : (
                ''
              )}
              {modal.active === 'coachVisibility' ? (
                <AppCoachVisibilityModal
                  user={user}
                  onCloseClick={() => this.toggleModalHandler(false)}
                />
              ) : (
                /* Pre start modal */
                // <AppCoachPreVisibilityModal
                //   coach={user.coach}
                //   onCloseClick={() => this.toggleModalHandler(false)}
                // />
                ''
              )}
              {modal.active === 'newPassword' ? (
                <AppUserNewPassword
                  onCloseClick={() => this.toggleModalHandler(false)}
                  submitHandler={fetchUserNewPassword}
                />
              ) : (
                ''
              )}
              {modal.active === 'applyLogin' && modal.data ? (
                <AppApplyLogin
                  loading={loading}
                  onCloseClick={() => this.toggleModalHandler(false)}
                  data={modal.data}
                  signIn={signIn}
                  setNotification={setNotification}
                  clearNotification={clearNotification}
                />
              ) : (
                ''
              )}
              {modal.active === 'userOnboarding' ? (
                <AppUserOnboardingModal
                  onCloseClick={() => this.toggleModalHandler(false)}
                  user={user}
                  locations={locations}
                  skills={skills}
                />
              ) : (
                ''
              )}
              {modal.active === 'rescheduleSession' ? (
                <AppRescheduleSessionModal
                  onCloseClick={() => this.toggleModalHandler(false)}
                  data={modal.data}
                />
              ) : (
                ''
              )}
              {modal.active === 'session-review' ? (
                <AppCoachingRewievsModal
                  onCloseClick={() => this.toggleModalHandler(false)}
                  data={modal.data}
                />
              ) : (
                ''
              )}
              {modal.active === 'session-confirm' ? (
                <AppSessionConfirmModal
                  onCloseClick={() => this.toggleModalHandler(false)}
                  title={modal.title}
                  onYes={modal.onYes}
                  sendToUserType={modal.sendToUserType}
                />
              ) : (
                ''
              )}
            </Suspense>
          </div>
        </React.Fragment>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppModalWrapper);
