import * as FilterTypes from 'constants/FilterTypes';

export const queryKeywordsSearch = ({ name = '', num = 4 }) => {
  return JSON.stringify({
    where: { name: { like: `%${name}%` } },
    limit: num,
  });
};

export const querySkillsKeywordsSearch = ({ name = '', num = 4 }) => {
  return JSON.stringify({
    where: { markers: { like: `%${name}%` } },
    limit: num,
  });
};

export const querySlugByName = (slug) => {
  return JSON.stringify({
    where: { slug },
  });
};

export const createSearchQuery = (
  { filters, limit, sort, skip, near },
  fetchFuncName
) => {
  let query = '';

  for (let idx = 0; idx < filters.length; idx++) {
    let filter = filters[idx];

    if (
      filter.type === FilterTypes.EXPERIENCE ||
      filter.type === FilterTypes.PRICE
    ) {
      const [gte, lte] = filter.slugs;

      query += `${filter.type}=${gte},${lte}`;
    } else if (filter.type === FilterTypes.SORT) {
      if (!sort) {
        query += `${filter.type}=${filter.slugs[0].value}`;
      }
    } else {
      // eslint-disable-next-line
      filter.slugs.forEach((slug, idx) => {
        if (idx === 0) query += `${filter.type}=${slug}`;
        else query += `,${slug}`;
      });
    }

    query += '&';
  }

  if (sort && fetchFuncName !== 'count') query += `sort=${sort}&`;
  if (skip && fetchFuncName !== 'count') query += `skip=${skip}&`;
  if (limit && fetchFuncName !== 'count') query += `limit=${limit}&`;
  if (near) query += `near=${near.slug}`;

  return query.substr(0, query.length - 1);
};
