import React from 'react';
import classnames from 'classnames';

import AppIcon from "components/shared/AppIcon";

import './AppImg.scss';

const AppImg = function({ icon, img, notifications, size, mr, className, bgColor, btn, active }) {
    return (
        <div
            style={{
                marginRight: mr
            }}
            className={classnames(
                    "app-img",
                    {
                        "app-img--icon": icon,
                        "app-img--bg-color-red": notifications,
                        [`app-img--size-${size}`]: size,
                        [`app-img--bg-color-${bgColor}`]: bgColor,
                    },
                    className,
                )}
        >
            {
                notifications
                    ?
                        <div className="app-img__notifications">
                            { notifications }
                        </div>
                    : ''
            }
            {
                icon
                    ? <AppIcon icon={icon} />
                    : ''
            }
            {
                img
                    ? <img src={img.src} alt={img.alt} />
                    : ''
            }
            {
                btn
                    ?
                        <button className="app-img__btn" onClick={btn.onClick}>
                            <AppIcon color={btn.iconColor} icon={btn.icon} />
                        </button>
                    : ''
            }
            {
                active
                    ?
                        <div className="app-img__active-icon">
                            <AppIcon color={active.iconColor} icon={active.icon} />
                        </div>
                    : ''
            }
        </div>
    );
}

export default AppImg;