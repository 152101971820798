export const remoteSlug = {
    name: "Remote/Online",
    lon: 0,
    lat: 0,
    country: null,
    region: null,
    timezone: null,
    population: null,
    geonameid: null,
    alias_region: null,
    slug: "remote-online",
    top: null,
    weight: 299,
    id: 1,
}