import thunk from 'redux-thunk';
import createRootReducer from 'reducers/rootReducer';
import { applyMiddleware, compose, createStore } from 'redux';
// import logger from 'redux-logger';
import { routerMiddleware } from 'connected-react-router';
import history from 'history.js';

const isDevelopment = process.env.NODE_ENV === 'development';
const middleware = [thunk, routerMiddleware(history)];

if (isDevelopment) {
  // middleware.push(logger);
}

const store = createStore(
  createRootReducer(history),
  compose(
    applyMiddleware(...middleware),
    isDevelopment && window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f
  )
);

export default store;
