import React, { Component } from 'react';

import AppJobsCardImg from 'components/shared/AppJobsCard/AppJobsCardImg';
import AppFlexLine from '../../../../../layout/AppFlexLine/index';

import './AppCardCompetitors.scss';

class AppCardCompetitors extends Component {
    render() {
        const {
            data: {
                displayName,
                id,
                logo: { url },
                shortDescription
            }
        } = this.props;

        return (
            <div className="app-card-competitors">
                <AppFlexLine>
                    <div className="app-card-competitors__img">
                        <AppJobsCardImg
                            size="small"
                            id={id}
                            img={url}
                            alt={displayName}
                        />
                    </div>

                    <div className="app-flex-line__right">
                        <div
                            title={displayName}
                            className="app-card-competitors__title"
                        >
                            {displayName}
                        </div>
                        <div className="app-card-competitors__descr">
                            {shortDescription}
                        </div>
                    </div>
                </AppFlexLine>
            </div>
        );
    }
}

export default AppCardCompetitors;
