import React from 'react';
import cn from 'classnames';

import './AppButtonToggle.scss';

const AppButtonToggle = ({ onClick, isActive, children, title }) => (
  <button
    className={cn('app-button-toggle', {
      '--active': isActive,
    })}
    onClick={onClick}
    type="button"
  >
    {title || children}
  </button>
);

export default AppButtonToggle;
