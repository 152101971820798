import React, { Component } from 'react';
import { debounce } from 'lodash';

// import './AppDebounceInput.scss';

class AppDebounceInput extends Component {
  constructor(props) {
    super(props);
    this.state = { searchValue: props.value || '', prevProps: {} };
  }

  static getDerivedStateFromProps(props, state) {
    const { prevProps } = state;

    if (prevProps.value !== props.value) {
      return {
        searchValue: props.value,
        prevProps: props,
      };
    }

    return {
      prevProps: props,
    };
  }

  onChangeDebounce = (event) => {
    this.setState({ searchValue: event.target.value }, () => {
      this.debounceSearch();
    });
  };

  debounceSearch = debounce(() => {
    this.props.onChange(this.state.searchValue);
  }, 300);

  onChange = (event) => {
    this.setState({ searchValue: event.target.value }, () => {
      this.props.onChange(this.state.searchValue);
    });
  };

  render() {
    const { isDebounce, ...otherProps } = this.props;
    return (
      <input
        {...otherProps}
        onChange={isDebounce === false ? this.onChange : this.onChangeDebounce}
        value={this.state.searchValue}
      />
    );
  }
}

export default AppDebounceInput;
