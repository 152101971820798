import Moment from 'moment';
import MomentTZ from 'moment-timezone';

import { BOOK_DATA_FORMATTER } from 'constants/CoachingProfileTypes';

export const formatDate = ({ date, formatter = 'D-MM' }) => {
  if (!date) return '';
  return Moment(date).format(formatter);
};

export const formatDateWithTimezone = ({ date, timezone, duration }) => {
  if (!date) return '';

  const tzDate = MomentTZ.utc(date).tz(timezone);
  const dayKey = tzDate.format(BOOK_DATA_FORMATTER);

  const displayStartTime = tzDate.format('h:mma');
  const displayEndTime = tzDate.add(duration, 'minutes').format('h:mma');

  return {
    key: dayKey,
    time: `${displayStartTime} - ${displayEndTime}`,
  };
};

export const getTimezoneDate = ({ date, timezone }) => {
  if (!date || !timezone) return '';
  return MomentTZ.utc(date).tz(timezone);
};

export const convertDateToRightFormat = (date, utc) => {
  if (utc) return date;
  return date.slice(0, date.length - 1);
};

export const renderTime = (date) => {
  if (!date) return '';

  return Moment(date).format('h:mmA');
};

export const renderEventDate = (date) => {
  if (!date) return '';

  return Moment(date).format('ddd MMM D');
};

export const renderEventDateAndTime = (date) => {
  if (!date) return '';

  return Moment(date).format('ddd MMM D, h:mmA');
};

export const checkIsTodayDate = (dateFromNow) => {
  if (
    dateFromNow.toLowerCase().indexOf('hour') >= 0 ||
    dateFromNow.toLowerCase().indexOf('minute') >= 0 ||
    dateFromNow.toLowerCase().indexOf('second') >= 0
  )
    return false;

  return false;
};

export const checkIsStartAndEndThisDay = (startDate, endDate) => {
  if (!startDate || !endDate) return '';

  if (
    Moment(startDate).format('MMM D ddd') ===
    Moment(endDate).format('MMM D ddd')
  )
    return true;
  else return false;
};
