import React, { useCallback } from 'react';

import AppCoachBookServiceType from './AppCoachBookServiceType';

// import * as UserTypes from 'constants/UserTypes';

import './AppCoachBookService.scss';

const AppCoachBookService = ({ role, offers, handleService, active }) => {
  const onServiceClick = useCallback(
    (service) => {
      active && active.id === service.id
        ? handleService(null)
        : handleService(service);
    },
    [active]
  );

  return (
    <div className="app-coach-book-service">
      <div className="app-coach-book-service__role">{role.name}</div>
      <ul className="app-coach-book-service__types">
        {offers.map((service) => (
          <li key={service.id}>
            <AppCoachBookServiceType
              {...service}
              isActive={active && active.id === service.id}
              onClick={() => onServiceClick(service)}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

AppCoachBookService.propTypes = {
  // company: PropTypes.object,
  // name: PropTypes.string,
};

export default AppCoachBookService;
