import React, { Component } from 'react';
import classnames from 'classnames';
import onClickOutside from 'react-onclickoutside';

import history from 'history.js';

import './AppDropdown.scss';

class AppDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      menuIsActive: false,
    };

    this.unlisten = null;
  }

  componentDidMount() {
    this.unlisten = history.listen(() => {
      this.handleClickOutside();
    });
  }

  handleToggleMenu = (e) => {
    e && e.stopPropagation();
    this.setState({ menuIsActive: !this.state.menuIsActive });
    if (this.props.onToggle) this.props.onToggle();
  };

  handleClickOutside = (e) => {
    if (this.state.menuIsActive) {
      this.setState({ menuIsActive: false });
      if (this.props.onToggle) this.props.onToggle();
    }
  };

  componentWillUnmount() {
    this.unlisten();
  }

  render() {
    let toggleMenu = React.cloneElement(this.props.children[0], {
      state: this.state,
    });
    let DropdownMenu = React.cloneElement(this.props.children[1], {
      toggleDropdown: this.handleToggleMenu,
      active: this.state.menuIsActive,
    });

    return (
      <div className={classnames('app-dropdown', this.props.className)}>
        <div className="app-dropdown-toggle" onClick={this.handleToggleMenu}>
          {toggleMenu}
        </div>
        {DropdownMenu}
      </div>
    );
  }
}

export default onClickOutside(AppDropdown);
