import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter, Redirect } from 'react-router-dom';
import { useMatomo } from '@datapunt/matomo-tracker-react';
import FullStory, { FullStoryAPI } from 'react-fullstory';

import App from 'components/App.js';
import AppSuspenseLoader from 'components/AppSuspenseLoader';
import AppJobDetailsPage from 'components/pages/AppJobDetailsPage';
import AppEventPage from 'components/pages/AppEventPage';
import AppCoachPage from 'components/pages/AppCoachPage';

import * as PathTypes from 'constants/PathTypes';
import history from 'history.js';
import store from 'store';

const ORG_ID = process.env.REACT_APP_FULLSTORY_ORG; // Fullstory org id
const isProd = true;

function checkIsFullStoryPages(path) {
  const pathsForFullStory = [
    PathTypes.COACHES,
    PathTypes.COACHING,
    PathTypes.COACHING,
    PathTypes.COACHES_HOME,
  ];

  return pathsForFullStory.some((p) => path.indexOf(p) >= 0);
}

const AppHomeMain = React.lazy(() => import('components/pages/AppHomeMain'));
const AppJobsHome = React.lazy(() => import('components/pages/AppJobsHome'));
const ErrorPage = React.lazy(() => import('components/pages/ErrorPage'));
const AppLoginPage = React.lazy(() => import('components/pages/AppLoginPage'));
const AppSignupPage = React.lazy(
  () => import('components/pages/AppSignupPage')
);
const AppEmailConfirmPage = React.lazy(
  () => import('components/pages/AppEmailConfirmPage')
);
const AppTermsPage = React.lazy(() => import('components/pages/AppTermsPage'));
const AppPrivacyPolicyPage = React.lazy(
  () => import('components/pages/AppPrivacyPolicyPage')
);
const AppAboutPage = React.lazy(() => import('components/pages/AppAboutPage'));
const AppNewPasswordPage = React.lazy(
  () => import('components/pages/AppNewPasswordPage')
);
const AppConfirmEmailChangePage = React.lazy(
  () => import('components/pages/AppConfirmEmailChangePage')
);

const AppUserConnectPage = React.lazy(
  () => import('components/pages/AppUserConnectPage')
);
const AppUserProfilePage = React.lazy(
  () => import('components/pages/AppUserProfilePage')
);
const AppEmployerDashboardPage = React.lazy(
  () => import('components/pages/AppEmployerDashboardPage')
);
const AppPostAJobPage = React.lazy(
  () => import('components/pages/AppPostAJobPage')
);
const AppPostEventPage = React.lazy(
  () => import('components/pages/AppPostEventPage')
);
const AppEmployerPage = React.lazy(
  () => import('components/pages/AppEmployerPage')
);
const AppJobsPage = React.lazy(() => import('components/pages/AppJobsPage'));
const AppEventsHome = React.lazy(
  () => import('components/pages/AppEventsHome')
);
const AppEvents = React.lazy(() => import('components/pages/AppEvents'));
const AppGuidesHome = React.lazy(
  () => import('components/pages/AppGuidesHome')
);
const AppGuidesArticle = React.lazy(
  () => import('components/pages/AppGuidesArticle')
);
const AppSlackBotPage = React.lazy(
  () => import('components/pages/AppSlackBotPage')
);
const AppCoachesHome = React.lazy(
  () => import('components/pages/AppCoachesTalentsHome/AppCoachesHome')
);
const AppTalentsHome = React.lazy(
  () => import('components/pages/AppCoachesTalentsHome/AppTalentsHome')
);
const AppCoaches = React.lazy(() => import('components/pages/AppCoaches'));
const AppCoachingSetup = React.lazy(
  () => import('components/pages/AppCoachingSetup')
);
const AppCoachingTermsPage = React.lazy(
  () => import('components/pages/AppCoachingTermsPage')
);
const AppCoachingClientTermsPage = React.lazy(
  () => import('components/pages/AppCoachingClientTermsPage')
);

const mapStateToProps = ({ initState, user }) => ({
  initState,
  user,
});

function PrivateRoute({
  component: Component,
  auth,
  componentProps = {},
  ...rest
}) {
  return (
    <Route
      {...rest}
      render={(props) =>
        auth === true ? (
          <Component {...props} {...componentProps} />
        ) : (
          <Redirect
            to={{
              pathname: PathTypes.LOGIN,
              state: {
                from: history.location.pathname,
                search: history.location.search,
              },
            }}
          />
        )
      }
    />
  );
}

const GTMI = process.env.REACT_APP_GTM;

const Routes = ({ location, user, notifications, initState }) => {
  const { trackPageView, pushInstruction } = useMatomo();
  const [firstLoad, setFirstLoad] = React.useState(true);
  const [fullstoryInit, setFulstoryInit] = React.useState(false);
  const [fullstoryShutdown, setFulstoryShutdown] = React.useState(false);
  const [clientId, setClientId] = React.useState(null);
  const [matomoClientId, setMatomoClientId] = React.useState(user.profile ? user.profile.id : null);
  const pageInitState = {
    previousLocation: location,
    prevProps: {},
  };

  const [state, setState] = React.useState(pageInitState);
  const [isModal, setModal] = React.useState(checkModal(state));

  const onUnload = () => window.scrollTo(0, 0);

  React.useEffect(() => {
    window.addEventListener('beforeunload', onUnload);
    return () => window.removeEventListener('beforeunload', onUnload);
  }, []);

  React.useEffect(() => {
    if (initState.isLoaded && isProd) {
      loadGtm();
      loadGoogleAnalitics();
      getClientId();
    }
  }, [initState.isLoaded]);


  React.useEffect(() => {
    if (matomoClientId && !user.profile) {
      pushInstruction('setUserId', null);
      setMatomoClientId(null)
    } else if (user.profile && user.profile.id && matomoClientId === null) {
      pushInstruction('setUserId', user.profile.id);
      setMatomoClientId(user.profile.id)
    }
  }, [user.profile, matomoClientId]);

  const loadGtm = () => {
    var s = document.createElement('script');
    s.src = `https://www.googletagmanager.com/gtag/js?id=${GTMI}`;
    document.body.appendChild(s);
  };

  const loadGoogleAnalitics = () => {
    var s = document.createElement('script');
    s.innerHTML = `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${GTMI}');`;
    document.body.appendChild(s);
  };

  const getClientId = () => {
    if (user.profile) {
      setClientId(user.profile.id);
    } else if (!clientId) {
      window.gtag('get', GTMI, 'client_id', (cId) => {
        setClientId(`anonymous_gtmcid_${cId}`);
      });
    }
  };

  function trackGTMPageView() {
    if (window.gtag) {
      window.gtag('config', GTMI, { page_path: window.location.pathname });
    }
  }

  // fullstory: listen location and ON/OFF recording
  React.useEffect(() => {
    if (clientId && location && isProd) {
      const isRecordPaths = checkIsFullStoryPages(location.pathname);
      const { profile } = user;

      if (isRecordPaths) {
        if (!fullstoryInit) {
          setFulstoryInit(true);
          FullStoryAPI('identify', clientId, {
            email: profile ? profile.email : '',
            firstName: profile ? profile.name : '',
            lastName: profile ? profile.surname : '',
          });
        } else if (fullstoryInit && fullstoryShutdown) {
          FullStoryAPI('restart');
          setFulstoryShutdown(false);
        }
      } else if (fullstoryInit && !fullstoryShutdown) {
        FullStoryAPI('shutdown');
        setFulstoryShutdown(true);
      }
    }
  }, [clientId, location, fullstoryInit, fullstoryShutdown]);

  React.useEffect(() => {
    if (
      history.action !== 'POP' &&
      (!location.state || !location.state.modal)
    ) {
      setState({
        previousLocation: location,
        prevProps: state,
      });
    } else setState({ ...state, prevProps: state });

    if (location.pathname !== state.previousLocation.pathname) {
      // track page change analitics
      if (isProd) {
        trackPageView();
        trackGTMPageView();
      }

      if (firstLoad) setFirstLoad(false);
      if (
        !location.state ||
        (!location.state.modal && !location.state.frommodal)
      ) {
        window.scrollTo(0, 0);
      }
    }

    setModal(checkModal(state));
  }, [location]);

  function checkModal(state) {
    return !!(
      !firstLoad &&
      location.state &&
      location.state.modal &&
      state.previousLocation !== history.location
    );
  }

  return (
    <App notifications={notifications}>
      <Suspense fallback={<AppSuspenseLoader />}>
        <Switch location={isModal ? state.previousLocation : location}>
          <Route
            exact
            path={PathTypes.HOME}
            render={() => <AppHomeMain firstLoad={firstLoad} />}
          />
          <Route path={PathTypes.JOBSHOME} component={AppJobsHome} />
          {/*
          <Route path={PathTypes.EVENTSHOME} component={AppEventsHome} />
          <Route
            exact={!checkModal(state)}
            path={PathTypes.EVENTS}
            component={AppEvents}
          />
          */}
          <Route
            exact={!checkModal(state)}
            path={PathTypes.JOBS}
            component={AppJobsPage}
          />
          <Route path={PathTypes.LOGIN} component={AppLoginPage} />
          <Route path={PathTypes.SIGNUP} component={AppSignupPage} />
          <Route path={PathTypes.CONFIRM} component={AppEmailConfirmPage} />
          <PrivateRoute
            auth={store.getState().user.auth}
            path={PathTypes.USER_PROFILE_MAIN}
            component={AppUserProfilePage}
          />
          <Route path={PathTypes.ABOUT} component={AppAboutPage} />
          <Route path={PathTypes.TERMS} component={AppTermsPage} />
          <Route
            path={PathTypes.PRIVACY_POLICY}
            component={AppPrivacyPolicyPage}
          />
          <Route
            exact
            path={PathTypes.EMPLOYER_HOME}
            component={AppEmployerPage}
          />
          <PrivateRoute
            auth={store.getState().user.auth}
            path={PathTypes.EMPLOYER_DASHBOARD}
            component={AppEmployerDashboardPage}
            onUpdate={() => window.scrollTo(0, 0)}
          />
          <PrivateRoute
            exact
            auth={store.getState().user.auth}
            path={PathTypes.EMPLOYER_POSTAJOB}
            component={AppPostAJobPage}
          />
          <PrivateRoute
            auth={store.getState().user.auth}
            componentProps={{ edit: true }}
            path={PathTypes.EMPLOYER_EDITAJOBID}
            component={AppPostAJobPage}
          />
          <PrivateRoute
            exact
            auth={store.getState().user.auth}
            path={PathTypes.EMPLOYER_POSTEVENT}
            component={AppPostEventPage}
          />
          <PrivateRoute
            auth={store.getState().user.auth}
            path={PathTypes.EMPLOYER_EDITEVENTBYID}
            componentProps={{ edit: true }}
            component={AppPostEventPage}
          />
          <PrivateRoute
            exact
            auth={store.getState().user.auth}
            path={PathTypes.COACHING_SETUP}
            component={AppCoachingSetup}
          />

          <Route path={PathTypes.NEW_PASSWORD} component={AppNewPasswordPage} />
          <Route exact path={PathTypes.GUIDES_HOME} component={AppGuidesHome} />
          <Route
            exact
            path={PathTypes.SLACK_BOT_PAGE}
            component={AppSlackBotPage}
          />
          <Route path={PathTypes.GUIDESID} component={AppGuidesArticle} />
          <Route
            exact
            path={PathTypes.SLACK_BOT_PAGE}
            component={AppSlackBotPage}
          />
          <Route
            path={PathTypes.CONFIRM_EMAIL_CHANGE}
            component={AppConfirmEmailChangePage}
          />
          <Route path={PathTypes.USER_CONNECT} component={AppUserConnectPage} />
          <Route path={PathTypes.COACHING} component={AppTalentsHome} />
          {/* <Route path={PathTypes.COACHES_HOME} component={AppCoachesHome} /> */}
          <Route path={PathTypes.COACHES_HOME} component={AppCoachesHome} />
          <Route
            path={PathTypes.COACHING_TERMS}
            component={AppCoachingTermsPage}
          />
          <Route
            path={PathTypes.COACHING_CLIENT_TERMS}
            component={AppCoachingClientTermsPage}
          />
          <Route
            exact={!checkModal(state)}
            path={PathTypes.COACHES}
            component={AppCoaches}
          />
          {/* <Route
            exact={!checkModal(state)}
            path="/error"
            render={(props) => <ErrorPage {...props} code={500} />}
          /> */}

          {location.pathname.indexOf('/jobs/') < 0 &&
          location.pathname.indexOf('/events/') < 0 &&
          location.pathname.indexOf('/coaches/') < 0 ? (
            <Route render={(props) => <ErrorPage {...props} code={404} />} />
          ) : (
            ''
          )}
        </Switch>
      </Suspense>
      <Route
        path={PathTypes.JOBSID}
        render={() => <AppJobDetailsPage staticPage={!checkModal(state)} />}
      />
      <Route
        exact
        path={PathTypes.EVENTSID}
        render={() => <AppEventPage staticPage={!checkModal(state)} />}
      />
      <Route
        exact
        path={PathTypes.COACHESID}
        render={() => <AppCoachPage staticPage={!checkModal(state)} />}
      />
      <FullStory org={ORG_ID} />
    </App>
  );
};

export default connect(mapStateToProps, null)(withRouter(Routes));
