import React from 'react';
import cn from 'classnames';
import Moment from 'moment';

import AppIcon from 'components/shared/AppIcon';

import './AppJobsCardDate.scss';

function displayDataCreatedFromNow(date) {
    let dateFromNow = Moment(date).fromNow();

    if (dateFromNow.toLowerCase().indexOf('hour') >= 0 ||
        dateFromNow.toLowerCase().indexOf('minute') >= 0 ||
        dateFromNow.toLowerCase().indexOf('second') >= 0) {
            return dateFromNow = 'today';
    }

    return dateFromNow[0].toUpperCase() + dateFromNow.slice(1);
}


export const AppJobsCardDate = function({ created, saved, applied, status }) {
    let date;

    if (applied) date = 'Applied ' + Moment(applied).fromNow();
    else if (saved) date = status === 'expired' ? 'Expired' : 'Saved ' + Moment(saved).fromNow();
    else if (status === 'expired') date = 'Expired'
    else if (status === 'draft') date = 'Draft';
    else if (status === 'paused') date = 'Paused';
    else date = displayDataCreatedFromNow(created);

    return (
        <div className={cn(
                'app-jobs-card-date',
                {'--acsent': date === 'today'},
                {'--acsent': status === 'expired' && !applied},
            )}
        >
            { date === 'today' && <AppIcon icon="fire" color="red" /> }
            { (status === 'expired') && !applied && <AppIcon icon="hourglass" color="red" /> }
            { (status === 'paused') && !applied && <AppIcon icon="pause" /> }
            <span className="app-jobs-card-date__title">{date}</span>
        </div>
    )
}

export default AppJobsCardDate;
