import React from 'react';
import classnames from 'classnames';

import AppIcon from 'components/shared/AppIcon';
import './AppTooltip.scss';

const AppTooltip = function ({
  right,
  top,
  children,
  text,
  img,
  alt,
  className,
}) {
  return (
    <div
      className={classnames(
        'app-tooltip',
        { '--pos-right': right, '--top': top },
        className
      )}
    >
      <div className="app-tooltip-icon">
        <AppIcon icon="question-circle" size="small" />
      </div>
      <div className={classnames('app-tooltip-content', { '--flex': img })}>
        {img ? (
          <div className="app-tooltip-content__img">
            <img src={img} alt={alt} />
          </div>
        ) : (
          ''
        )}
        <div className="app-tooltip-text">{children || text}</div>
      </div>
    </div>
  );
};

export default AppTooltip;
