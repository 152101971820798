import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';

import { AppPanelList, AppPanelListItem } from 'components/shared/AppPanel';
import AppSearchTag from 'components/shared/AppSearch/AppSearchTag';
import './AppSearchDropdown.scss';

class AppSearchDropdown extends Component {
  handleClickOutside = (e) => {
    const { onClickOutside } = this.props;

    onClickOutside && onClickOutside();
  };

  render() {
    const { list, isFetching, handlerClickTag, callback } = this.props;

    return (
      <div className="app-search-panel-dropdown">
        <div className="app-search__dropdown-inner">
          <AppPanelList column={true} isFetching={isFetching}>
            {list && list.length > 0
              ? list.map((item) => (
                  <AppPanelListItem margin="false" key={item.id}>
                    <AppSearchTag
                      data={item}
                      onClick={() => handlerClickTag(item, callback)}
                      defaultStyle="list"
                    />
                  </AppPanelListItem>
                ))
              : ''}
          </AppPanelList>
        </div>
      </div>
    );
  }
}

export default onClickOutside(AppSearchDropdown);
