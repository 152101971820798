import React, { Component } from 'react';
import classnames from 'classnames';

import AppJobsCardImg from "components/shared/AppJobsCard/AppJobsCardImg";

import './AppSearchTag.scss';

class AppSearchTag extends Component {
    constructor(props) {
        super(props);

        this.state = {
            remove: false,
            isActive: this.props.isActive || false,
        }
    }

    render() {
        const {
            data,
            defaultStyle,
            onClick,
            modalClose,
            double,
            onRemove,
            modal,
            removeSlug,
            className,
            stop,
            bgColor,
            img,
        } = this.props;

        const { isActive, remove } = this.state;

        return (
            <div
                key={data.id}
                onClick={(e) => {
                    if (stop) return false;
                    e.stopPropagation();

                    if (removeSlug) {
                        removeSlug(data);
                        return false;
                    }

                    if (onClick && !double) onClick();
                    if (modal) modalClose();
                    if (double && onRemove) onRemove();
                    this.setState({ remove: !remove })
                }}
                className={classnames(
                    "app-search-tag",
                    {
                        '--active': isActive,
                        '--img': img,
                        '--switched': double,
                        [`app-search-tag-${defaultStyle}`] : defaultStyle,
                        [`--bg-${bgColor}`] : bgColor,
                    },
                    className,
                )}
            >
            {
                img
                    ?
                        <div className="app-search-tag__img">
                            <AppJobsCardImg
                                id={data.name.length || (Math.random() * 10)}
                                img={img}
                                alt={data.name}
                            />
                        </div>
                    :
                        <span className="app-search-tag__title">{data.name}{data.alias_region ? `, ${data.alias_region}` : '' }</span>

            }
            </div>
        );
    }
}

export default AppSearchTag;