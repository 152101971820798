import axios from 'axios';

import * as ActionTypes from 'constants/ActionTypes';
import { API_URL } from 'constants/PathTypes';
// import { setNotification } from "./SystemActions";
import { queryKeywordsSearch } from './querys';

export function clearRoles() {
  return {
    type: ActionTypes.CLEAR_ROLES,
  };
}

export function receiveRoles(payload) {
  return {
    type: ActionTypes.RECEIVE_ROLES,
    payload,
  };
}

export function receiveRolesByType({ payload, type }) {
  return {
    type,
    payload,
  };
}

export function setFetchingRoles(payload) {
  return {
    type: ActionTypes.SET_FETCHING_ROLES,
    payload,
  };
}

export function setFetchingTopRoles(payload) {
  return {
    type: ActionTypes.SET_FETCHING_POPULAR_ROLES,
    payload,
  };
}

export function receiveTopRoles(payload) {
  return {
    type: ActionTypes.RECEIVE_INIT_STATE_POPULAR_ROLES,
    payload,
  };
}

export function setFetchingInitStatePopularRoles(payload) {
  return {
    type: ActionTypes.SET_FETCHING_INIT_STATE_POPULAR_ROLES,
    payload,
  };
}

export function fetchTopRoles(count = 10) {
  return async (dispatch) => {
    dispatch(setFetchingTopRoles(true));

    try {
      const response = await axios.get(
        `${API_URL}/vacancy_roles/getTop?count=${count}`
      );

      dispatch(receiveTopRoles(response.data));
      dispatch(setFetchingTopRoles(false));
      return response.data;
    } catch (error) {
      dispatch(setFetchingTopRoles(false));
      // dispatch(setNotification({ message: error.message, type: 'info', status: 'error' }));
      throw error;
    }
  };
}

export function fetchRolesByName({ name, num }) {
  return async (dispatch) => {
    dispatch(setFetchingRoles(true));

    const query = queryKeywordsSearch({ name, num });

    try {
      const response = await axios.get(
        `${API_URL}/vacancy_roles?filter=${encodeURIComponent(query)}`
      );

      dispatch(receiveRoles(response.data));
      dispatch(setFetchingRoles(false));
      return response.data;
    } catch (error) {
      dispatch(setFetchingRoles(false));
      // dispatch(setNotification({ message: error.message, type: 'info', status: 'error' }));
      throw error;
    }
  };
}

export function fetchRolesByNameFilterAndType({ name, num, filter, type }) {
  return async (dispatch) => {
    dispatch(setFetchingRoles(true));

    const query = queryKeywordsSearch({ name, num });

    try {
      const response = await axios.get(
        `${API_URL}/${filter}?filter=${encodeURIComponent(query)}`
      );

      dispatch(receiveRolesByType({ payload: response.data, type }));
      dispatch(setFetchingRoles(false));
      return response.data;
    } catch (error) {
      dispatch(setFetchingRoles(false));
      // dispatch(setNotification({ message: error.message, type: 'info', status: 'error' }));
      throw error;
    }
  };
}
