export const firstNotifications = 'Welcome to ProductHired,';

export const loginNotifications = [
    'Welcome back,',
    'Glad to see you,',
    'Enjoy job hunting,',
];

export const logoutNotifications = [
    'Take care,',
    'See you soon,',
    'Good day,',
];