import React, { Component } from 'react';
import classnames from 'classnames';
import './AppSwitcher.scss';

export default class AppSwitcher extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isSwitchedOn: this.props.isOn,
            prevProps: {}
        };
    }

    static getDerivedStateFromProps(props, state) {
        const { prevProps } = state;

        if (prevProps.isSwitchedOn !== props.isOn) {
            return {
                isSwitchedOn: props.isOn,
                prevProps: props,
            }
        }

        return {
           prevProps: props
        }
    }

    handleSwitch = () => {
        const { handlerClickOn, handlerClickOff } = this.props;

        if (this.state.isSwitchedOn) handlerClickOff();
        else handlerClickOn()
    }

    render() {
        const { size, label, className, on, off } = this.props;

        return (
            <button
                type="button"
                className={classnames(
                    'app-switcher',
                    {
                        'app-switcher--on': this.state.isSwitchedOn,
                        [`--size-${size}`]: size,
                    },
                    className,
                )}
                onClick={this.handleSwitch}
            >
                <div className="app-switcher__inner">
                    <div className="app-switcher__toggle">
                        { label === false
                            ? ''
                            : <span>{this.state.isSwitchedOn ? on || 'on' : off || 'off'}</span>
                        }
                    </div>
                </div>
            </button>
        );
    }
}