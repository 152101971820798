function objectKeysStringToArray(object) {
    for (const key in object) {
        if (object.hasOwnProperty(key)) {
            const string = object[key];
            const arr = string.split(',');
            object[key] = arr;
        }
    }

    return object;
}

export default objectKeysStringToArray;
