import React from 'react';
import cn from 'classnames';

import AppIcon from 'components/shared/AppIcon';
import AppUserAvatar from 'components/shared/AppUserAvatar';
import AppFlexLine from 'components/layout/AppFlexLine';
import { AppContainer } from 'components/layout';
import AppTooltip from '../shared/AppTooltip';

import * as PathTypes from 'constants/PathTypes';
import * as UserTypes from 'constants/UserTypes';

import history from 'history.js';

import './AppUserNav.scss';

const AppUserNav = (props) => {
  const [location, setLocation] = React.useState(null);

  const renderSavedVacanciesCount = (saved) => {
    if (Array.isArray(saved) && saved.length > 0) {
      return saved.filter((item) => item.vacancy.saved === true).length;
    }

    return 0;
  };

  React.useEffect(() => {
    setLocation(history.location.pathname);
  }, []);

  const toggleLocation = (pathname) => {
    history.push(pathname);
    setLocation(pathname);
  };

  const { user, path } = props;
  const { saved, profile, applied, alarms } = user;

  return (
    <div className="app-user-nav">
      <AppContainer>
        <AppFlexLine jc="sb" ai="center">
          <div
            onClick={() => toggleLocation(PathTypes.USER_PROFILE_MAIN)}
            className={cn('app-user-nav-tab app-user-nav-info', {
              '--active': location === PathTypes.USER_PROFILE_MAIN,
            })}
          >
            <AppFlexLine jc="fs" ai="center">
              <div className="app-flex-line__left --small">
                <AppUserAvatar
                  img={user.profile.image.url}
                  icon={UserTypes.PERSONS[user.profile.image.person].icon}
                  color={UserTypes.COLORS[user.profile.image.color].value}
                  defaultPlaceholder={
                    UserTypes.PERSONS[user.profile.image.person]
                      .defaultPlaceholder
                  }
                />
              </div>

              <div>
                <AppFlexLine ai="center" lh="1" marginBottom="medium">
                  <span className="app-menu-title app-user-nav-info-title">
                    {profile.name} {profile.surname}
                  </span>
                  {profile.job_title && <span className="app-text-dot"></span>}
                  <span className="app-menu-title --regular --dark">
                    {profile.job_title}
                  </span>
                </AppFlexLine>

                <AppFlexLine ai="center" group="true">
                  {profile.location && (
                    <div className="app-menu-title">
                      <AppFlexLine ai="center" lh="1">
                        <AppIcon icon="geo" size="small" margin="right" />
                        <span>{profile.location.name}</span>
                      </AppFlexLine>
                    </div>
                  )}
                  {path !== PathTypes.USER_PROFILE_MAIN ? (
                    <AppFlexLine ai="center" group="true" lh="1">
                      <span className="app-menu-link --active --second">
                        Edit profile
                      </span>
                      <AppTooltip
                        className="app-tooltip"
                        alt="location"
                        text="Updating your profile to create a better and customized experience."
                      />
                    </AppFlexLine>
                  ) : (
                    ''
                  )}
                </AppFlexLine>
              </div>
            </AppFlexLine>
          </div>

          <div className="app-user-nav-links">
            <div
              onClick={() =>
                toggleLocation(PathTypes.USER_PROFILE_SEARCH_ALARMS)
              }
              className={cn('app-user-nav-link', {
                '--active': location === PathTypes.USER_PROFILE_SEARCH_ALARMS,
              })}
            >
              <div className="app-menu-title --big">{alarms.length}</div>
              <div className="app-menu-title-descr">Job Alerts</div>
            </div>
            <div
              onClick={() => toggleLocation(PathTypes.USER_PROFILE_SAVED_JOBS)}
              className={cn('app-user-nav-link', {
                '--active': location === PathTypes.USER_PROFILE_SAVED_JOBS,
              })}
            >
              <div className="app-menu-title --big">
                {renderSavedVacanciesCount(saved)}
              </div>
              <div className="app-menu-title-descr">Saved Jobs</div>
            </div>
            <div
              onClick={() =>
                toggleLocation(PathTypes.USER_PROFILE_APPLIED_JOBS)
              }
              className={cn('app-user-nav-link', {
                '--active': location === PathTypes.USER_PROFILE_APPLIED_JOBS,
              })}
            >
              <div className="app-menu-title --big">{applied.length}</div>
              <div className="app-menu-title-descr">Applied Jobs</div>
            </div>

            <div
              onClick={() => toggleLocation(PathTypes.USER_PROFILE_COACHING)}
              className={cn('app-user-nav-link', {
                '--active': location === PathTypes.USER_PROFILE_COACHING,
              })}
            >
              <div className="app-menu-title --nav">Coaching</div>
              <div className="app-menu-title-descr">My dashboard</div>
            </div>
          </div>
        </AppFlexLine>
      </AppContainer>
    </div>
  );
};

export default AppUserNav;
