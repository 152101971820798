import React from 'react';

import AppCoachReviewsList from 'components/AppCoachReviewsList';
import { CardReviewHead } from 'components/Card';

import './AppCoachReviews.scss';

const AppCoachReviews = ({ reviews = [], rating = 2.5, title = '' }) => (
  <div className="coach-details-reviews">
    {rating && title && (
      <CardReviewHead
        fs="big"
        rating={rating}
        reviewsCount={reviews.length}
        title="Ratings & Reviews"
      />
    )}
    <div className="coach-details-reviews__list">
      <AppCoachReviewsList reviews={reviews} />
    </div>
  </div>
);

export default AppCoachReviews;
