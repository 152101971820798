import * as ActionTypes from 'constants/ActionTypes';
import * as PathTypes from 'constants/PathTypes';

import { LOCATION_CHANGE } from 'connected-react-router';

const initialState = {
  moduleName: 'events',
  routeName: PathTypes.EVENTS,
  list: [],
  related: [],
  keywords: [''],
  motivated: [],
  isFetching: {
    list: false,
    populars: false,
    related: false,
    count: false,
    eventById: false,
  },
  count: 0,
  eventById: null,
};

export default function events(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      if (
        action.payload &&
        action.payload.location.state &&
        action.payload.location.state.filters &&
        window.location.pathname.includes("/events/")
      ) {
        return Object.assign({}, state, {
          keywords: action.payload.location.state.filters.keywords,
        });
      } else return state;

    case ActionTypes.SET_FETCHING_EVENTS:
      return Object.assign({}, state, {
        isFetching: {
          ...state.isFetching,
          list: action.payload,
        },
      });

    case ActionTypes.RECEIVE_EVENTS:
      return Object.assign({}, state, {
        list: action.payload,
      });

    case ActionTypes.UPDATE_EVENTS:
      return Object.assign({}, state, {
        list: [...state.list.slice(), ...action.payload],
      });

    case ActionTypes.SET_FETCHING_EVENTS_COUNT_ALL:
      return Object.assign({}, state, {
        isFetching: {
          ...state.isFetching,
          count: action.payload,
        },
      });

    case ActionTypes.RECEIVE_EVENTS_COUNT_ALL:
      return Object.assign({}, state, {
        count: action.payload,
      });

    case ActionTypes.ADD_EVENTS_KEYWORD_SLUG:
      return Object.assign({}, state, {
        keywords: [action.payload],
      });

    case ActionTypes.SET_FETCHING_EVENT_BY_ID:
      return Object.assign({}, state, {
        isFetching: {
          ...state.isFetching,
          eventById: action.payload,
        },
      });

    case ActionTypes.RECEIVE_EVENT_BY_ID:
      return Object.assign({}, state, {
        eventById: action.payload,
      });

    case ActionTypes.SET_RELATED_EVENTS:
      return {
        ...state,
        related: action.payload,
      };

    case ActionTypes.SET_LOADING_RELATED_EVENTS:
      return {
        ...state,
        isFetching: {
          ...state.isFetching,
          related: action.payload,
        },
      };

    default:
      return state;
  }
}
