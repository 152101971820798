// socials icons
import { ReactComponent as IconLinkedIn } from 'assets/images/svg/socials/icon-linkedin.svg';
import { ReactComponent as IconBehance } from 'assets/images/svg/socials/icon-behance.svg';
import { ReactComponent as IconDribbble } from 'assets/images/svg/socials/icon-dribbble.svg';
import { ReactComponent as IconGithub } from 'assets/images/svg/socials/icon-github.svg';
import { ReactComponent as IconWebsite } from 'assets/images/svg/socials/icon-website.svg';

// visibility icons
import { ReactComponent as IconVisible } from 'assets/images/svg/icon-eye.svg';
import { ReactComponent as IconHidden } from 'assets/images/svg/icon-eye-hide.svg';
import { ReactComponent as IconPaused } from 'assets/images/svg/icon-pause.svg';
import { ReactComponent as IconOff } from 'assets/images/svg/icon-stop.svg';

export const SERVICE_AMOUNT_PERCENT = 0.3;
export const SERVICE_DURATION = 60;
export const BOOK_DATA_FORMATTER = 'YYYY-M-D';
export const SESSION_STARTS_FORMATTER = 'ddd, MMM DD - hh:mma ([GMT]Z)';
export const DASBOARD_PAYOUT_DATA_FORMATTER = 'MMM DD, YYYY';
export const DASBOARD_PAYOUT_SESSION_DATA_FORMATTER = 'ddd, MMM DD, hh:mm';

export const COACH_SESSION_ROLES = {
  coach: 'coach',
  user: 'user',
};

export const INTRO_CALL_SLUG = 'intro-call';

export const COACHING_SESSION_FILTERS = {
  SUCCESS: 'success',
  CANCELLED: 'cancelled',
  OCCURRED: 'occurred',
  OCCURRED_SUCCESSUFUL: 'occurred_successful',
  OCCURRED_NO_SHOW: 'occurred_no_show',
  NO_SHOW_USER_CONFIRMED: 'no_show_user_confirmed',
  NO_SHOW_COACH_CONFIRMED: 'no_show_coach_confirmed',
  SCHEDULED: 'scheduled',
  RESCHEDULING_REQUEST: 'rescheduling_request',
};

export const COACHING_PAYOUTS_STATUSES = {
  SUCCESS: 'success',
  DISPUTABLE: 'disputable',
  ERROR: 'error',
  PENDING: 'pending',
};

export const COACHING_SESSION_STATUSES = {
  ALL: 'all',
  UPCOMING: 'upcoming',
  PAST: 'past',
  CANCELED: 'canceled',
};

export const COACH_SOCIALS_MODEL = {
  linkedin: null,
  behance: null,
  dribbble: null,
  github: null,
  website: null,
};

export const coachingProfileInitState = {
  agreeTerms: null,
  coachName: '',
  coachSurname: '',
  coverImage: null,
  coachHeadline: '',
  coachDetailsHtml: '',
  coachCurrentCompany: null,
  coachCurrentCompanyTitle: '',
  coachPreviousCompanies: [],
  coachPreviousCompaniesTitle: '',
  coachLocation: null,
  coachUrl: '',
  coachCachedUrl: '',
  coachServicesOffered: [],
  coachSocials: COACH_SOCIALS_MODEL,
  status: 'off',
  id: null,
  rolesTree: [],
  types_active: [],
  calendars: [],
  isCreated: false,
  isLoaded: false,
  modified: null,
  paypal: '',
};

export const COACH_SOCIALS_DATA = {
  linkedin: {
    prefix: 'https://www.linkedin.com/in/',
    icon: IconLinkedIn,
  },
  behance: {
    prefix: 'https://www.behance.net/',
    icon: IconBehance,
  },
  dribbble: {
    prefix: 'https://dribbble.com/',
    icon: IconDribbble,
  },
  github: {
    prefix: 'https://github.com/',
    icon: IconGithub,
  },
  website: {
    prefix: '',
    icon: IconWebsite,
  },
};

export const COACH_INIT_AVAILABILITY_SETTINGS = {
  BUFFER: 15,
  MAX_PD: 4,
  MAX_PW: 10,
  LEAD_TIME: 24,
  DAYS_OUT: 15,
  CANCELLATION_HOURS: 24,
};

export const SERVICES_DURATIONS_OPTIONS = [
  { id: 15, label: '15 mins.', value: '15 mins.' },
  { id: 30, label: '30 mins.', value: '30 mins.' },
  { id: 45, label: '45 mins.', value: '45 mins.' },
  { id: 60, label: '60 mins.', value: '60 mins.' },
];

export const INIT_TIMESTAP = {
  id: 0,
  start: null,
  end: null,
};

export const DEFAULT_TIMESTAP = {
  id: 0,
  start: '09:00',
  end: '17:00',
};

export const CALENDAR_WEEK = [
  {
    id: 'sun',
    label: 'Sunday',
    active: false,
    timestaps: [{ ...INIT_TIMESTAP }],
  },
  {
    id: 'mon',
    label: 'Monday',
    active: false,
    timestaps: [{ ...INIT_TIMESTAP }],
  },
  {
    id: 'tue',
    label: 'Tuesday',
    active: false,
    timestaps: [{ ...INIT_TIMESTAP }],
  },
  {
    id: 'wed',
    label: 'Wednesday',
    active: false,
    timestaps: [{ ...INIT_TIMESTAP }],
  },
  {
    id: 'thu',
    label: 'Thursday',
    active: false,
    timestaps: [{ ...INIT_TIMESTAP }],
  },
  {
    id: 'fri',
    label: 'Friday',
    active: false,
    timestaps: [{ ...INIT_TIMESTAP }],
  },
  {
    id: 'sat',
    label: 'Saturday',
    active: false,
    timestaps: [{ ...INIT_TIMESTAP }],
  },
];

export const COACH_STATUSES = {
  VISIBLE: 'visible',
  HIDDEN: 'hidden',
  PAUSED: 'paused',
  OFF: 'off',
};

export const COACH_VISIBILITY_TIPES = [
  {
    id: COACH_STATUSES.VISIBLE,
    label: 'Visible',
    style: 'success',
    icon: IconVisible,
    text: 'Your profile will be visible within search.',
  },
  {
    id: COACH_STATUSES.HIDDEN,
    label: 'Hidden',
    style: 'warning',
    icon: IconHidden,
    text: 'Your profile is hidden within search, but people can directly go to your profile page to make bookings.',
  },
  {
    id: COACH_STATUSES.PAUSED,
    label: 'Paused',
    style: 'primary',
    icon: IconPaused,
    text: 'Your profile is hidden within search; however, people can go directly to your profile page but not make any bookings.',
  },
  {
    id: COACH_STATUSES.OFF,
    label: 'Off',
    style: 'danger',
    icon: IconOff,
    text: 'Your profile is hidden from search and anyone else. It is shutdown for viewing with your URL being reserved.',
  },
];
