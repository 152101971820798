import moment from 'moment';
import * as PathTypes from 'constants/PathTypes';
import { SERVICE_AMOUNT_PERCENT } from 'constants/CoachingProfileTypes';

export const getServiceWithPriceWIthPercents = (price) => {
  return Math.round(price * (1 + SERVICE_AMOUNT_PERCENT));
};

export const getCalendarExportTemplate = ({
   user,
   coach,
   type,
   area,
   clientUrl,
   start,
   renderDate,
   duration,
   isCoach
}) => {
  const startTime = moment(start).utc().format()
  const endTime = moment(start).add(duration, 'minutes').utc().format()
  const termsLink = isCoach ? PathTypes.COACHING_TERMS : PathTypes.COACHING_CLIENT_TERMS

  return {
    title: `${user.name} <> ${coach.name} - ${type} Session (ProductHired)`,
    description: `<h2>Hi ${user.name || ''},</h2><p></p>Here are the details of your session.</p><h3>${area} - ${type}<br/>${renderDate}<br/>with ${
      coach.name || ''
    } ${
      coach.surname || ''
    }</h3><p>Here's your video link<br/><a target="_blank" href="${clientUrl}">${clientUrl}</a><br/><br/>The video link will only work during the time of the event.</p><h3>BE ON TIME</h3><p>Please be on time to avoid a no-show. No shows can be declared by the attending party if the other party doesn't show-up in the first 5 minutes.</p><h3>CANCELLATIONS and RESCHEDULING</h3><p>You may cancel or reschedule this session from the coaching dashboard <a target="_blank" href="${
      PathTypes.SERVER_URL
    }${PathTypes.USER_PROFILE_COACHING}">${PathTypes.SERVER_URL}${
      PathTypes.USER_PROFILE_COACHING
    }</a>.<br/><br/> You are not allowed to cancel or reschedule a session within 24 hours of it starting.<br/><br/>For all terms and details about coaching sessions please see the terms <a target="_blank" href="${
      PathTypes.SERVER_URL
    }${termsLink}">Terms Link</a></p>`,
    startTime,
    endTime,
    location: clientUrl,
  };
}

export const checkWebsiteUrl = (url = '') => {
  if (!url || typeof url !== 'string') return ''
  
  if (url.indexOf('http') < 0 && url.indexOf('https') < 0) {
    return `https://${url}`;
  } else {
    return url;
  }
};
