import axios from 'axios';

import * as ActionTypes from 'constants/ActionTypes';
import { API_URL, STRIPEID } from 'constants/PathTypes';
import {
  setNotification,
  clearNotification,
  setLoading,
} from './SystemActions';
import { unauthUser } from './UserActions';

export function setUserDashboardJobList(payload) {
  return {
    type: ActionTypes.SET_USER_DASHBOARD_JOB_LIST,
    payload,
  };
}

export function addUserPostJobToDashboard(payload) {
  return {
    type: ActionTypes.ADD_USER_POST_JOB_TO_DASHBOARD,
    payload,
  };
}

export function updateUserDashboardVacancieById(payload) {
  return {
    type: ActionTypes.UPDATE_USER_DASHBOARD_VACANCIE_BY_ID,
    payload,
  };
}

export function setDashboardEditVacancieById(payload) {
  return {
    type: ActionTypes.SET_DASHBOARD_EDIT_VACANCIE_BY_ID,
    payload,
  };
}

export function setPostJob(payload) {
  return {
    type: ActionTypes.SET_POST_JOB,
    payload,
  };
}

export function setFetchingUserDashboardJobListById(payload) {
  return {
    type: ActionTypes.SET_FETCHING_USER_DASHBOARD_JOB_LIST_BY_ID,
    payload,
  };
}

export function deleteUserDashboardVacancieFromListById(payload) {
  return {
    type: ActionTypes.DELETE_USER_DASHBOARD_JOB_FROM_LIST_BY_ID,
    payload,
  };
}

export function setDashboardPublishingCoasts(payload) {
  return {
    type: ActionTypes.SET_DASHBOARD_PUBLISHING_COASTS,
    payload,
  };
}

export function setDashboardJobUserStatusById(payload) {
  return {
    type: ActionTypes.SET_DASHBOARD_JOB_USER_STATUS_BY_ID,
    payload,
  };
}

export function setFetchingDashboardJobUserStatusById(payload) {
  return {
    type: ActionTypes.SET_FETCHING_USER_DASHBOARD_USER_STATUS,
    payload,
  };
}

export function togglePublishStatusDashboardPublishedJobById(payload) {
  return {
    type: ActionTypes.TOGGLE_PUBLISH_STATUS_DASHBOARD_PUBLISHED_JOB_BY_ID,
    payload,
  };
}

export function fetchUserDashboardJobListById(userId) {
  return async (dispatch) => {
    dispatch(setFetchingUserDashboardJobListById(true));

    dispatch({
      type: 'FETCH_USER_DASHBOARD_LIST',
    });

    try {
      const response = await axios.get(`${API_URL}/users/${userId}/vacancies`, {
        headers: { authorization: localStorage.getItem('token') },
      });

      dispatch(setUserDashboardJobList(response.data));
      dispatch(setFetchingUserDashboardJobListById(false));
      return response.data;
    } catch (error) {
      const { message, statusCode } = error.payload.data.error;
      if (statusCode && statusCode === 401) {
        dispatch(unauthUser());
        dispatch(
          setNotification({
            message: 'Something went wrong.',
            type: 'info',
            status: 'info',
          })
        );
      } else
        dispatch(
          setNotification({
            message: message || 'Something went wrong.',
            type: 'info',
            status: 'info',
          })
        );
      dispatch(setFetchingUserDashboardJobListById(false));
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function fetchAddUserPostJobToDashboard(data) {
  return async (dispatch) => {
    dispatch(setFetchingUserDashboardJobListById(true));

    try {
      const response = await axios.post(`${API_URL}/vacancies/newJob`, data, {
        headers: { authorization: localStorage.getItem('token') },
      });

      dispatch(addUserPostJobToDashboard(response.data));
      dispatch(setFetchingUserDashboardJobListById(false));
      dispatch(
        setNotification({
          message: 'Your job saved as a draft.',
          type: 'info',
          status: 'info',
        })
      );
      setTimeout(() => dispatch(clearNotification()), 5000);

      return response.data;
    } catch (error) {
      const { message, statusCode } = error.payload.data.error;
      if (statusCode && statusCode === 401) {
        dispatch(unauthUser());
        dispatch(
          setNotification({
            message: 'Something went wrong.',
            type: 'info',
            status: 'info',
          })
        );
      } else
        dispatch(setNotification({ message, type: 'info', status: 'info' }));
      dispatch(setFetchingUserDashboardJobListById(false));
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function fetchEditUserDashboardVacancie({ data, jobId }) {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const response = await axios.post(
        `${API_URL}/vacancies/${jobId}/updateJob`,
        data,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(
        updateUserDashboardVacancieById({ id: jobId, vacancy: response.data })
      );
      dispatch(setLoading(false));
      dispatch(
        setNotification({
          message: 'Your job saved!',
          type: 'job',
          status: 'info',
          data: response.data,
        })
      );

      setTimeout(() => dispatch(clearNotification()), 5000);

      return response.data;
    } catch (error) {
      dispatch(setLoading(false));
      const { message, statusCode } = error.payload.data.error;
      if (statusCode && statusCode === 401) {
        dispatch(unauthUser());
        dispatch(
          setNotification({
            message: 'Something went wrong.',
            type: 'info',
            status: 'info',
          })
        );
      } else
        dispatch(setNotification({ message, type: 'info', status: 'info' }));
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function fetchEditAndMarkUserDashboardVacancie({ data, jobId }) {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const response = await axios.post(
        `${API_URL}/vacancies/${jobId}/updateAndMarkJob`,
        data,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(
        updateUserDashboardVacancieById({ id: jobId, vacancy: response.data })
      );
      dispatch(setLoading(false));
      dispatch(
        setNotification({
          message: 'Your job saved!',
          type: 'job',
          status: 'info',
          data: response.data,
        })
      );

      setTimeout(() => dispatch(clearNotification()), 5000);

      return response.data;
    } catch (error) {
      dispatch(setLoading(false));
      const { message, statusCode } = error.payload.data.error;
      if (statusCode && statusCode === 401) {
        dispatch(unauthUser());
        dispatch(
          setNotification({
            message: 'Something went wrong.',
            type: 'info',
            status: 'info',
          })
        );
      } else
        dispatch(setNotification({ message, type: 'info', status: 'info' }));
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function fetchDeleteUserDashboardVacancie(jobId) {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const response = await axios.delete(`${API_URL}/vacancies/${jobId}`, {
        headers: { authorization: localStorage.getItem('token') },
      });

      dispatch(setLoading(false));
      dispatch(deleteUserDashboardVacancieFromListById(jobId));
      dispatch(
        setNotification({
          message: 'Delete Job success',
          type: 'info',
          status: 'info',
        })
      );
      setTimeout(() => dispatch(clearNotification()), 5000);

      return response.data;
    } catch (error) {
      dispatch(setLoading(false));
      const { message, statusCode } = error.payload.data.error;
      if (statusCode && statusCode === 401) {
        dispatch(unauthUser());
        dispatch(
          setNotification({
            message: 'Something went wrong.',
            type: 'info',
            status: 'info',
          })
        );
      } else
        dispatch(setNotification({ message, type: 'info', status: 'info' }));
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function fetchDashboardPublishingCoasts() {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(`${API_URL}/plans/available`, {
        headers: { authorization: localStorage.getItem('token') },
      });

      dispatch(setLoading(false));
      dispatch(setDashboardPublishingCoasts(response.data));

      return response.data;
    } catch (error) {
      dispatch(setLoading(false));
      // dispatch(setNotification({ message: error.message, type: 'info', status: 'error'}));
      // setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function fetchSetDashboardJobUserStatus({ jobId, userId, status }) {
  return async (dispatch) => {
    try {
      const response = await axios.put(
        `${API_URL}/vacancies/${jobId}/applied-jobs/${userId}`,
        { status },
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(setDashboardJobUserStatusById({ jobId, userId, status }));

      return response.data;
    } catch (error) {
      const { message, statusCode } = error.payload.data.error;
      if (statusCode && statusCode === 401) {
        dispatch(unauthUser());
        dispatch(
          setNotification({
            message: 'Something went wrong.',
            type: 'info',
            status: 'info',
          })
        );
      } else
        dispatch(setNotification({ message, type: 'info', status: 'info' }));
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function fetchTogglePublishStatusDashboardPublishedJobById(id) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${API_URL}/vacancies/${id}/toggle`,
        {},
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(
        togglePublishStatusDashboardPublishedJobById({
          id,
          status: response.data.status,
        })
      );

      return response.data;
    } catch (error) {
      const { message, statusCode } = error.payload.data.error;
      if (statusCode && statusCode === 401) {
        dispatch(unauthUser());
        dispatch(
          setNotification({
            message: 'Something went wrong.',
            type: 'info',
            status: 'info',
          })
        );
      } else
        dispatch(setNotification({ message, type: 'info', status: 'info' }));
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function fetchUpgradePublishPlanDashboardJobById({
  jobId,
  planId,
  price,
  code = null
}) {
  const data = {}

  if (code) {
    data.code = code
  }

  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${API_URL}/vacancies/${jobId}/publish/${planId}`,
        data,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      if (response.data.stripeSession) {
        localStorage.setItem('stripeSessionId', response.data.stripeSession);
        localStorage.setItem('stripeSessionPrice', price);

        if (!window.Stripe) {
          let script = document.createElement('script');

          script.src = 'https://js.stripe.com/v3/';
          document.body.appendChild(script);
          script.onload = () => stripeInit();
        } else return stripeInit();

        function stripeInit() {
          var stripe = window.Stripe(STRIPEID);

          stripe
            .redirectToCheckout({
              sessionId: response.data.stripeSession,
            })
            .then(function (result) {
              if (result.error.message) {
                dispatch(
                  setNotification({
                    message: result.error.message,
                    type: 'info',
                    status: 'error',
                  })
                );
                setTimeout(() => dispatch(clearNotification()), 5000);
                throw result.error.message;
              }

              return result;
            });
        }
      } else if (response.data.vacancy) {
        dispatch(
          updateUserDashboardVacancieById({
            id: response.data.vacancy.id,
            vacancy: response.data.vacancy,
          })
        );
        return response.data;
      }

      return response.data;
    } catch (error) {
      const { message, statusCode } = error.payload.data.error;

      if (statusCode && statusCode === 401) {
        dispatch(unauthUser());
        dispatch(
          setNotification({
            message: 'Something went wrong.',
            type: 'info',
            status: 'info',
          })
        );
      } else
        dispatch(setNotification({ message, type: 'info', status: 'info' }));

      setTimeout(() => dispatch(clearNotification()), 5000);
      throw error;
    }
  };
}

export function fetchDashboardJobGetReceipt(id) {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${API_URL}/payments/getReceipt/${id}`,
        {},
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      if (response.status === 204) {
        dispatch(
          setNotification({
            message: 'Your receipt was delivered to your email',
            type: 'info',
            status: 'info',
          })
        );
        setTimeout(() => dispatch(clearNotification()), 5000);
      }

      return response.data;
    } catch (error) {
      const { message, statusCode } = error.payload.data.error;
      if (statusCode && statusCode === 401) {
        dispatch(unauthUser());
        dispatch(
          setNotification({
            message: 'Something went wrong.',
            type: 'info',
            status: 'info',
          })
        );
      } else
        dispatch(setNotification({ message, type: 'info', status: 'info' }));
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}
