import React from 'react';
import cn from 'classnames';

import AppButton from 'components/shared/AppButton';
import AppCoachSocials from '../AppCoachDetailsCard/AppCoachSocials';
import AppUserAvatar from 'components/shared/AppUserAvatar';

import { COACH_STATUSES } from 'constants/CoachingProfileTypes';
import * as UserTypes from 'constants/UserTypes';

import {
  Card,
  CardAddress,
  CardTitle,
  CardRow,
  CardCoachExperience,
  CardNextAvailability,
} from 'components/Card';

import './AppCoachDetailsCardMobile.scss';

const AppCoachDetailsCardMobile = ({
  coach,
  handleSharedModalToggle,
  bookToggleHandler,
}) => {
  const { general, next, status } = coach;

  // const date = Moment(convertDateToRightFormat(next_availability)).format('ddd, MMMM D - HH:mm');

  return (
    <Card center>
      <CardRow flex jc="center" marged>
        {general.image.url !== null ? (
          <AppUserAvatar
            img={general.image.url}
            icon={UserTypes.PERSONS[0].icon}
            color={UserTypes.COLORS[0].value}
            defaultPlaceholder={UserTypes.PERSONS[0].defaultPlaceholder}
            size="big"
          />
        ) : (
          <AppUserAvatar
            img={general.image.url}
            icon={UserTypes.PERSONS[general.image.person].icon}
            color={UserTypes.COLORS[general.image.color].value}
            defaultPlaceholder={
              UserTypes.PERSONS[general.image.person].defaultPlaceholder
            }
            size="big"
          />
        )}
      </CardRow>
      <CardRow flex column ai="center" marged>
        <CardTitle center title={general.name}>
          {general.name} {general.surname}
        </CardTitle>
      </CardRow>
      <CardRow flex column ai="center">
        {coach.headline}
      </CardRow>
      <CardRow flex column ai="center">
        <AppCoachSocials links={coach.links} />
      </CardRow>
      <CardRow flex column ai="center">
        <CardCoachExperience experience={general.company.name} />
      </CardRow>
      <CardRow flex column ai="center">
        <CardAddress address={general.location.name} />
      </CardRow>
      <CardRow flex column ai="center">
        <CardNextAvailability
          date={status === COACH_STATUSES.PAUSED ? null : next}
        />
      </CardRow>

      {status === COACH_STATUSES.PAUSED && (
        <CardRow flex column ai="center">
          {status === COACH_STATUSES.PAUSED && (
            <span
              className={cn('coach-status', {
                [`--${status}`]: status,
              })}
            >
              {COACH_STATUSES.PAUSED.toUpperCase()}
            </span>
          )}
        </CardRow>
      )}

      <CardRow flex jc="center" childrenFlex>
        <AppButton
          onClick={handleSharedModalToggle}
          defaultStyle="secondary"
          icon="share"
          height="medium"
          title="Share"
        />
        <AppButton
          target="_blank"
          title="Book a Session"
          defaultStyle="primary"
          height="medium"
          onClick={bookToggleHandler}
          isDisabled={status === COACH_STATUSES.PAUSED}
        />
      </CardRow>
    </Card>
  );
};

export default AppCoachDetailsCardMobile;
