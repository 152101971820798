import React, { Component } from 'react';
import onClickOutside from 'react-onclickoutside';
import Select from 'react-select';
import classnames from 'classnames';

import './AppSelect.scss';

const initOptions = [
  { value: 'value 1', label: 'option 1' },
  { value: 'value 2', label: 'option 2' },
];

class AppSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: props.options || initOptions,
      selectedOption: props.selectedOption || initOptions[0],
      menuIsOpen: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.selectedOption && props.selectedOption !== state.selectedOption) {
      return {
        ...state,
        selectedOption: props.selectedOption,
      };
    }

    return state;
  }

  toggleMenuIsOpen = () => {
    if (!this.select.props.isDisabled) {
      this.setState((state) => ({ menuIsOpen: !state.menuIsOpen }));
      if (this.select)
        return !this.state.menuIsOpen ? this.onFocus() : this.onBlur();
    }
  };

  handleClickOutside = (e) => this.setState({ menuIsOpen: false });

  onFocus = () => this.select.focus();

  onBlur = () => this.select.blur();

  handleChange = (selectedOption) => {
    const { handleChange } = this.props;

    this.setState({ selectedOption });
    if (handleChange) handleChange(selectedOption);
  };

  render() {
    const { drop, className, hideSelectedOptions = false } = this.props;
    const { selectedOption, menuIsOpen, options } = this.state;

    return (
      <div
        className={classnames(
          'app-select-wrap',
          { [`--menu-${drop}`]: drop },
          className
        )}
        onClick={() => this.toggleMenuIsOpen()}
      >
        <Select
          ref={(ref) => (this.select = ref)}
          id={this.props.id || ''}
          value={selectedOption}
          onChange={this.handleChange}
          // onClick={this.toggleMenuIsOpen}
          options={options}
          menuIsOpen={menuIsOpen}
          className="app-select"
          classNamePrefix="app-select"
          closeMenuOnScroll={true}
          isSearchable={this.props.isSearchable || false}
          isDisabled={this.props.disabled}
          hideSelectedOptions={hideSelectedOptions}
        />
      </div>
    );
  }
}

export default onClickOutside(AppSelect);
