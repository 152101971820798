import React, { useState, useEffect, useMemo, useCallback } from 'react';
import Cookies from 'js-cookie';
import { connect } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import _ from 'lodash';
import MediaQuery from 'react-responsive';

import AppCheckbox from 'components/shared/AppCheckbox';
import AppButton from 'components/shared/AppButton';
import AppUserAvatar from 'components/shared/AppUserAvatar/index';
import AppCoachBookCalendar from 'components/AppCoachBookCalendar';
import AppCoachBookService from 'components/AppCoachBookService';
import AppCoachBookTimes from 'components/AppCoachBookTimes';
import AppFlexLine from 'components/layout/AppFlexLine';
import AppTimezonesSelect from 'components/AppTimezonesSelect';
import AppSpinner from 'components/shared/AppSpinner';
import AppTooltip from 'components/shared/AppTooltip/AppTooltip';
import AppTextarea from 'components/shared/AppTextarea/AppTextarea';

import * as PathTypes from 'constants/PathTypes';
import * as UserTypes from 'constants/UserTypes';
import { BOOK_DATA_FORMATTER } from 'constants/CoachingProfileTypes';
import { getTimezoneDate } from 'utils/Dates';

import {
  setBookSessionService,
  setBookSessionDate,
  setBookSessionTime,
  clearBookSession,
  fetchGetBookingTimes,
  fetchBookNewSessionWithCoach,
} from 'actions/CoachesActions';
import { setModal } from 'actions/SystemActions';

import * as MediaTypes from 'constants/MediaTypes';
import { validationSchemaCoachBooking } from 'constants/ValidationSchema';
import { analiticsCategoriesTypes, trackAnaliticsEvent } from 'utils/analitics';
import { getServiceWithPriceWIthPercents } from 'utils/Coach';
import { smoothScrollTo } from 'utils';

import './AppCoachBookForm.scss';

const mapStateToProps = ({ system, user }) => ({
  timezone: system.timezone,
  user,
});

const dispatchStateToProps = (dispatch) => ({
  setBookSessionService: (payload) => dispatch(setBookSessionService(payload)),
  setBookSessionDate: (payload) => dispatch(setBookSessionDate(payload)),
  setBookSessionTime: (payload) => dispatch(setBookSessionTime(payload)),
  clearBookSession: (payload) => dispatch(clearBookSession(payload)),
  fetchGetBookingTimes: (serviceId) =>
    dispatch(fetchGetBookingTimes(serviceId)),
  setModal: (payload) => dispatch(setModal(payload)),
  fetchBookNewSessionWithCoach: (payload) =>
    dispatch(fetchBookNewSessionWithCoach(payload)),
});

const AppCoachBookForm = (props) => {
  const {
    coach,
    timezone,
    handlerGoBack,
    user,
    clearSelectedService,
    selectedService,
  } = props;
  const { cancellation_hours: cancellationHours } = coach.availability_settings;

  const [service, setService] = useState(
    selectedService ? selectedService.service : null
  );
  const [isFetchingTimes, setFetchingTinmes] = useState(false);
  const [dates, setDates] = useState([]);
  const [day, setDay] = useState(null);
  const [time, setTime] = useState(null);

  const coachGeneral = coach.general;
  const coachImage = coachGeneral.image;

  useEffect(() => {
    if (selectedService) scrollToSection('section-calendar');

    return () => {
      if (selectedService !== null) clearSelectedService();
    };
  }, []);

  useEffect(() => {
    handleGetBookingTimes(service);

    // track selected service
    if (service) {
      if (service.price === 0) {
        trackAnaliticsEvent({
          action: 'bookcoach_coach_free_session_selected',
          category: analiticsCategoriesTypes.COACHING,
          name: service.type.name,
        });
      } else {
        trackAnaliticsEvent({
          action: 'bookcoach_coach_paid_session_selected',
          category: analiticsCategoriesTypes.COACHING,
          name: service.type.name,
          value: getServiceWithPriceWIthPercents(service.price / 100)
        });
      }
    }
  }, [service]);

  useEffect(() => {
    if (timezone) onTimezoneChange();
  }, [timezone]);

  const services = useMemo(() => {
    if (coach && coach.services) {
      return _.values(_.groupBy(coach.services, 'role_id'));
    } else {
      return null;
    }
  }, [coach]);

  const handleGetBookingTimes = (service) => {
    if (service) {
      setFetchingTinmes(true);
      props
        .fetchGetBookingTimes(service.id)
        .then((res) => {
          setFetchingTinmes(false);
          setDates(res);
        })
        .catch(() => {
          setFetchingTinmes(false);
          onServiceChange(null);
        });
    } else {
      if (isFetchingTimes) setFetchingTinmes(false);
      onServiceChange(null);
    }
  };

  const handleBookSession = (setSubmitting, values) => {
    if (service.price) {
      props.setModal({
        active: 'payBookSession',
        data: {
          coach,
          user: user.profile,
          day,
          time,
          note: values.note,
          service,
          timezone,
          handlerGoBack,
          handleModalClose: (service) => {
            setSubmitting(false);

            if (service) {
              scrollToSection('section-calendar');
            }

            setDay(null);
            setTime(null);
            handleGetBookingTimes(service);
          },
        },
      });
    } else {
      props
        .fetchBookNewSessionWithCoach({
          serviceId: service.id,
          start: time.id,
          tz: timezone.id,
          note: values.note || null,
        })
        .then((res) => {
          setSubmitting(false);

          trackAnaliticsEvent({
            category: analiticsCategoriesTypes.COACHING,
            action: 'bookcoach_free_session_booked',
            name: res.type,
          });

          handlerGoBack();
          handlerGoBack();

          props.setModal({
            active: 'createBookSession',
            data: {
              ...res,
              user: user.profile,
            },
          });
        })
        .catch(() => {
          setSubmitting(false);
        });
    }
  };

  const onTimezoneChange = () => {
    setTime(null);
    setDay(null);
  };

  const onServiceChange = (service) => {
    if (service) {
      scrollToSection('section-calendar');
    }

    setDay(null);
    setTime(null);
    setService(service);
  };

  const mappedDates = useMemo(() => {
    if (service && timezone) {
      return dates.reduce((daysObj, next) => {
        const tzDate = getTimezoneDate({ date: next, timezone: timezone.id });
        const dayKey = tzDate.format(BOOK_DATA_FORMATTER);
        const displayTime = `${tzDate.format('h:mma')} - ${tzDate
          .add(service.duration, 'minutes')
          .format('h:mma')}`;

        const dayData = { id: next, date: displayTime };
        const dayKeyIsExist = daysObj[dayKey];

        if (dayKeyIsExist) dayKeyIsExist.push(dayData);
        else daysObj[dayKey] = [dayData];
        return daysObj;
      }, {});
    }
    return [];
  }, [dates, timezone, service]);

  const handleDay = (activeDay, newDay) => {
    if (activeDay === newDay) setDay(null);
    else {
      setDay(newDay);

      if (window.innerWidth < MediaTypes.SM) {
        scrollToSection('section-terms');
      }
    }
  };

  const handleTime = (activeTime, newTime, isTerms) => {
    if (activeTime && newTime && activeTime.id === newTime.id) setTime(null);
    else {
      setTime(newTime);
      scrollToSection('section-terms');

      trackAnaliticsEvent({
        action: 'bookcoach_coach_time_selected',
        category: analiticsCategoriesTypes.COACHING,
        name: new Date(newTime.id).toUTCString()
      });

      if (window.innerWidth < MediaTypes.SM && isTerms) {
        scrollToSection('section-timeslots');
      } else {
        scrollToSection('section-terms');
      }
    }
  };

  const handleUserAuthModal = (setSubmitting) => {
    var inFifteenMinutes = new Date(new Date().getTime() + 15 * 60 * 1000);

    Cookies.set('ph_action_modal_close', true, {
      expires: inFifteenMinutes,
    });

    props.setModal({
      active: 'signup',
      notification: true,
      data: { redirectOptions: { bookIsActive: true } },
      title:
        'You need to be signed in to book a session. Sign in or Sign up now.',
    });

    setTimeout(() => {
      setSubmitting(false);
    }, 500);
  };

  const validateFormHandler = (validate, setSubmitting, values) => {
    validate().then((errors) => {
      if (Object.keys(errors).length === 0) {
        if (user.profile) {
          handleBookSession(setSubmitting, values);

          // book auth pressed
          trackAnaliticsEvent({
            action: 'bookcoach_cta_selected_session',
            category: analiticsCategoriesTypes.COACHING,
          });
        } else {
          handleUserAuthModal(setSubmitting);
          
          // book not auth pressed
          trackAnaliticsEvent({
            action: 'bookcoach_login_if_selection_made',
            category: analiticsCategoriesTypes.COACHING,
          });
        }
      } else {
        for (const key in errors) {
          if (errors.hasOwnProperty(key)) {
            let elementOffsetTop;
            const element = document.querySelector(`[name="${key}"]`);

            if (element) elementOffsetTop = element.getBoundingClientRect().top;

            if (elementOffsetTop && elementOffsetTop !== 0) {
              smoothScrollTo(elementOffsetTop + window.pageYOffset - 130, 1);
              break;
            }
          }
        }
      }
    });
  };

  const scrollToSection = (elementId) => {
    const target = document.getElementById(elementId);
    if (target) target.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const renderMessageAndCancellation = useCallback(
    (noteError) => (
      <>
        <div className="app-coach-book-form__col-left">
          <div className="app-form-group">
            <div className="app-form-field-title">Message to the coach</div>
            <div className="app-form-field-wrap">
              <Field
                className={cn('app-form-field --textarea-s', {
                  error: noteError,
                })}
                component={AppTextarea}
                name="note"
                placeholder=""
              />
            </div>
            {noteError && (
              <small className="app-form-field-notification">{noteError}</small>
            )}
          </div>
        </div>
        <div className="app-coach-book-form__col-right">
          <div className="app-coach-book-form-block__title --cancellation">
            <AppFlexLine ai="center" group>
              <span>
                Cancellation period:{' '}
                {!!cancellationHours ? `${cancellationHours} hrs` : 'None'}
              </span>
              <AppTooltip
                top
                text="The cancellation period is the time before a session that the Coach does not allow cancellations. No refunds are given after this period starts unless there is a coach no-show."
              />
            </AppFlexLine>
          </div>
        </div>
        </>
    ),
    [cancellationHours]
  );

  return (
    <div className="app-coach-book-form">
      <Formik
        initialValues={{
          terms: false,
          note: '',
        }}
        onSubmit={() => {}}
        validationSchema={validationSchemaCoachBooking}
      >
        {({
          touched,
          errors,
          validateForm,
          setSubmitting,
          isSubmitting,
          handleChange,
          values,
          isValid,
        }) => {
          const formIsInvalid =
            isSubmitting || !time || isFetchingTimes || !isValid;

          return (
            <Form>
              <div className="app-coach-book-form-block">
                <div className="app-coach-book-form-block__head">
                  <div className="app-coach-book-form__heading">
                    <div className="app-coach-book-form__avatar">
                      <AppUserAvatar
                        size="medium"
                        img={coachImage.url || null}
                        defaultPlaceholder={
                          UserTypes.PERSONS[coachImage.person || 0]
                            .defaultPlaceholder
                        }
                        icon={UserTypes.PERSONS[coachImage.person || 0].icon}
                        color={UserTypes.COLORS[coachImage.color || 0].value}
                      />
                    </div>
                    <h2 className="app-coach-book-form__heading-title app-heading-section --small">
                      Book a Session with {coachGeneral.name}{' '}
                      {coachGeneral.surname}
                    </h2>
                    <p className="app-coach-book-form-block__title">
                      1. Select a Session you’d like to be coached in
                    </p>
                  </div>
                </div>
                <div className="app-coach-book-form-block__body">
                  <ul className="app-coach-book-form__services-list">
                    {services.map((offersByRole, idx) => (
                      <li
                        key={idx}
                        className="app-coach-book-form__services-list-item"
                      >
                        <AppCoachBookService
                          role={offersByRole[0].role}
                          offers={offersByRole}
                          handleService={onServiceChange}
                          active={service}
                        />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>

              <div
                className={cn('app-coach-book-form-block', {
                  '--disabled': service === null,
                })}
              >
                <div
                  id="section-calendar"
                  className="app-coach-book-form-anchor"
                ></div>
                <div className="app-coach-book-form-block__head">
                  <div className="app-coach-book-form__heading --flex">
                    <p className="app-coach-book-form-block__title">
                      2. Select a date and timeslot
                    </p>
                    <div className="app-coach-book-form__timezone">
                      <AppTimezonesSelect />
                    </div>
                  </div>
                </div>
                <div className="app-coach-book-form-block__body">
                  <div className="app-coach-book-form__row">
                    <div className="app-coach-book-form__col-left">
                      {isFetchingTimes && (
                        <div className="app-coach-book-form__calendar-preloader">
                          <AppSpinner
                            size={25}
                            spinnerColor={'#333'}
                            spinnerWidth={2}
                            visible={true}
                          />
                        </div>
                      )}
                      <AppCoachBookCalendar
                        dates={dates}
                        activeDays={Object.keys(mappedDates)}
                        handleDay={handleDay}
                        activeDay={day}
                        timezone={timezone}
                        service={service}
                      />
                    </div>
                    <div className="app-coach-book-form__col-right">
                      <MediaQuery maxWidth={MediaTypes.SM}>
                        {(matches) => {
                          if (matches) {
                            return (
                              <div className="app-coach-book-form__policy-mob">
                              <div>
                                {renderMessageAndCancellation(errors.note)}
                              </div>
                                <div
                                  id="section-terms"
                                  className="app-coach-book-form-anchor"
                                ></div>
                                <div className="app-form-field-wrap">
                                  <AppFlexLine ai="center">
                                    <Field
                                      name="terms"
                                      component={AppCheckbox}
                                      className="app-form-checkbox"
                                      onChange={(e) => {
                                        if (e.target.value === 'false') {
                                          scrollToSection('section-timeslots');
                                        }
                                        handleChange(e);
                                      }}
                                    />
                                    <p className="app-form-text">
                                      I have read & agreed the{' '}
                                      <Link
                                        target="_blank"
                                        className="app-form-link"
                                        to={PathTypes.PRIVACY_POLICY}
                                      >
                                        terms and conditions.
                                      </Link>
                                    </p>
                                  </AppFlexLine>
                                  {touched.terms && errors.terms && (
                                    <small className="app-form-field-notification">
                                      {errors.terms}
                                    </small>
                                  )}
                                </div>
                              </div>
                            );
                          } else return false;
                        }}
                      </MediaQuery>

                      {day && (
                        <AppCoachBookTimes
                          activeDay={day}
                          activeTime={time}
                          mappedDates={mappedDates}
                          handleTime={(activeTime, newTime) =>
                            handleTime(activeTime, newTime, values.terms)
                          }
                          timezone={timezone}
                          onSubmit={() =>
                            validateFormHandler(
                              validateForm,
                              setSubmitting,
                              values
                            )
                          }
                          submitIsDisabled={formIsInvalid}
                          loader={isSubmitting}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <MediaQuery maxWidth={MediaTypes.SM}>
                {(matches) => {
                  if (!matches)
                    return (
                      <div className="app-coach-book-form__submit">
                        <div
                          id="section-terms"
                          className="app-coach-book-form-anchor --big"
                        ></div>
                        <div className="app-coach-book-form-block">
                          <div className="app-coach-book-form-block__body">
                            <div className="app-coach-book-form__row --ai-bottom --mb">
                              {renderMessageAndCancellation(errors.note)}
                            </div>
                            <div className="app-coach-book-form__row">
                              <div className="app-coach-book-form__col-left">
                                <div className="app-form-field-wrap">
                                  <AppFlexLine ai="center">
                                    <Field
                                      name="terms"
                                      component={AppCheckbox}
                                      className="app-form-checkbox"
                                    />
                                    <p className="app-form-text">
                                      I have read & agreed the{' '}
                                      <Link
                                        target="_blank"
                                        className="app-form-link"
                                        to={PathTypes.COACHING_CLIENT_TERMS}
                                      >
                                        terms and conditions.
                                      </Link>
                                    </p>
                                  </AppFlexLine>
                                  {touched.terms && errors.terms && (
                                    <small className="app-form-field-notification">
                                      {errors.terms}
                                    </small>
                                  )}
                                </div>
                              </div>
                              <div className="app-coach-book-form__col-right">
                                <AppButton
                                  fullWidth
                                  type="submit"
                                  title="Book This Session"
                                  onClick={() =>
                                    validateFormHandler(
                                      validateForm,
                                      setSubmitting,
                                      values
                                    )
                                  }
                                  isDisabled={formIsInvalid}
                                  loader={isSubmitting}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  else return '';
                }}
              </MediaQuery>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

AppCoachBookForm.propTypes = {
  // company: PropTypes.object,
  // name: PropTypes.string,
};

export default connect(mapStateToProps, dispatchStateToProps)(AppCoachBookForm);
