import axios from 'axios';

import * as types from 'constants/ActionTypes';
import { API_URL } from 'constants/PathTypes';
import { unauthUser } from './UserActions';
import { setNotification, clearNotification } from './SystemActions';
import { queryKeywordsSearch } from './querys';

export function clearCompanies() {
  return {
    type: types.CLEAR_COMPANIES,
  };
}

export function receiveCompanies(payload) {
  return {
    type: types.RECEIVE_COMPANIES,
    payload,
  };
}

export function setFetchingCompanies(payload) {
  return {
    type: types.SET_FETCHING_COMPANIES,
    payload,
  };
}

export function setFetchingPopularCompanies(payload) {
  return {
    type: types.SET_FETCHING_POPULAR_COMPANIES,
    payload,
  };
}

export function receiveInitStatePopularCompanies(payload) {
  return {
    type: types.RECEIVE_INIT_STATE_POPULAR_COMPANIES,
    payload,
  };
}

export function setFetchingInitStatePopularCompanies(payload) {
  return {
    type: types.SET_FETCHING_INIT_STATE_POPULAR_COMPANIES,
    payload,
  };
}

export function fetchInitStatePopularCompanies() {
  return function (dispatch) {
    dispatch(setFetchingPopularCompanies(true));

    return (async () => {
      try {
        const response = await axios.get(`${API_URL}/companies/getTop`);

        dispatch(receiveInitStatePopularCompanies(response.data));
        dispatch(setFetchingPopularCompanies(false));
      } catch (error) {
        dispatch(setFetchingPopularCompanies(false));
        // dispatch(setNotification({message: error.message, type: 'info', status: 'error'}));
        throw error;
      }
    })();
  };
}

export function fetchCompaniesByName({ name, num }) {
  return function (dispatch) {
    dispatch(setFetchingCompanies(true));

    return (async ({ name, num }) => {
      const query = queryKeywordsSearch({ name, num });

      try {
        const response = await axios.get(
          `${API_URL}/companies?filter=${encodeURIComponent(query)}`
        );

        dispatch(receiveCompanies(response.data));
        dispatch(setFetchingCompanies(false));
      } catch (error) {
        dispatch(setFetchingCompanies(false));
        // dispatch(setNotification({message: error.message, type: 'info', status: 'error'}));
        throw error;
      }
    })({ name, num });
  };
}

export function setFetchingGetCompaniesByName(payload) {
  return {
    type: types.SET_FETCHING_GET_COMPANIES_BY_NAME,
    payload,
  };
}

export function fetchGetCompaniesByName(name) {
  return function (dispatch) {
    dispatch(setFetchingGetCompaniesByName(true));

    return (async () => {
      try {
        const response = await axios.get(
          `${API_URL}/companies/getByName?name=${name}`,
          {
            headers: { authorization: localStorage.getItem('token') },
          }
        );

        dispatch(setFetchingGetCompaniesByName(false));
        return Promise.resolve(response.data);
      } catch (error) {
        const { statusCode, message } = error.payload.data.error;

        dispatch(setFetchingGetCompaniesByName(false));
        if (statusCode && statusCode === 401) {
          dispatch(unauthUser());
          dispatch(
            setNotification({
              message,
              type: 'info',
              status: 'error',
            })
          );
        } else {
          dispatch(
            setNotification({
              message: 'Something went wrong.',
              type: 'info',
              status: 'error',
            })
          );
        }
        setTimeout(() => dispatch(clearNotification()), 5000);

        throw error;
      }
    })();
  };
}
