import axios from 'axios';

import * as types from 'constants/ActionTypes';
import { API_URL } from 'constants/PathTypes';
import { setNotification, clearNotification } from './SystemActions';
import { queryKeywordsSearch } from './querys';

export function setNearestLocation(payload) {
  return {
    type: types.SET_NEAREST_LOCATION,
    payload,
  };
}

export function clearLocations() {
  return {
    type: types.CLEAR_LOCATIONS,
  };
}

export function receiveLocations(payload) {
  return {
    type: types.RECEIVE_LOCATIONS,
    payload,
  };
}

export function setFetchingLocations(payload) {
  return {
    type: types.SET_FETCHING_LOCATIONS,
    payload,
  };
}

export function setFetchingNearestLocations(payload) {
  return {
    type: types.SET_FETCHING_NEAREST_LOCATION,
    payload,
  };
}

export function setFetchingPopularLocations(payload) {
  return {
    type: types.SET_FETCHING_POPULAR_LOCATIONS,
    payload,
  };
}

export function receiveInitStatePopularLocations(payload) {
  return {
    type: types.RECEIVE_INIT_STATE_POPULAR_LOCATIONS,
    payload,
  };
}

export function setFetchingInitStatePopularLocations(payload) {
  return {
    type: types.SET_FETCHING_INIT_STATE_POPULAR_LOCATIONS,
    payload,
  };
}

export const fetchNearestLocationByCoords = () => {
  return async (dispatch) => {
    if (navigator.geolocation) {
      return new Promise((resolve, reject) => {
        dispatch(setFetchingNearestLocations(true));
        navigator.geolocation.getCurrentPosition(
          successFunction,
          errorFunction
        );

        //Get latitude and longitude;
        async function successFunction(position) {
          const lat = position.coords.latitude;
          const lon = position.coords.longitude;
          const response = await axios.get(
            `${API_URL}/locations/nearest?lat=${lat}&lon=${lon}`
          );

          dispatch(setFetchingNearestLocations(false));
          dispatch(setNearestLocation(response.data));
          resolve(response.data);
        }

        async function errorFunction(error) {
          const { message } = error;

          reject();
          dispatch(setFetchingNearestLocations(false));
          dispatch(setNearestLocation(null));
          dispatch(setNotification({ message, type: 'info', status: 'info' }));
          setTimeout(() => dispatch(clearNotification()), 5000);

          throw error;
        }
      });
    } else {
      dispatch(
        setNotification({
          message: "Your brouser dosn't support geolocation",
          type: 'info',
          status: 'info',
        })
      );
      setTimeout(() => dispatch(clearNotification()), 5000);
    }
  };
};

export function fetchInitStatePopularLocations() {
  return async (dispatch) => {
    dispatch(setFetchingPopularLocations(true));

    try {
      const response = await axios.get(`${API_URL}/locations/getTop`);

      dispatch(receiveInitStatePopularLocations(response.data));
      dispatch(setFetchingPopularLocations(false));
    } catch (error) {
      dispatch(setFetchingPopularLocations(false));
      // dispatch(setNotification({message: error.message, type: 'info', status: 'error'}));
      throw error;
    }
  };
}

export function fetchLocationsByName({ name, num }) {
  return async (dispatch) => {
    dispatch(setFetchingLocations(true));

    const query = queryKeywordsSearch({ name, num });

    try {
      const response = await axios.get(
        `${API_URL}/locations?filter=${encodeURIComponent(query)}`
      );

      dispatch(receiveLocations(response.data));
      dispatch(setFetchingLocations(false));
    } catch (error) {
      dispatch(setFetchingLocations(false));
      // dispatch(setNotification({ message: error.message, type: 'info', status: 'error'}));
      throw error;
    }
  };
}
