import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import cn from 'classnames';

import AppLogo from 'components/shared/AppLogo';
import AppFlexLine from 'components/layout/AppFlexLine';
import AppButton from 'components/shared/AppButton';
import {
  AppSearchPanelKeywords,
  AppEventsSearchPanelKeywords,
  AppCoachesSearchPanelKeywords,
} from 'components/shared/AppSearch';
import AppSidebarMenu from 'components/AppSidebarMenu';

import { setModal } from 'actions/SystemActions';
import { signOut } from 'actions/UserActions';
import { addAllFilters } from 'actions/FiltersActions';
import { setNearestLocation } from 'actions/LocationsActions';

import * as FilterTypes from 'constants/FilterTypes';
import * as PathTypes from 'constants/PathTypes';

import history from 'history.js';
import store from 'store';
import { buildUrl, getStateFilters } from 'utils';

import './AppHeaderMobile.scss';

const mapDispatchToProps = (dispatch) => ({
  signOut: () => dispatch(signOut()),
  setModal: (payload) => dispatch(setModal(payload)),

  setNearestLocation: (payload) => dispatch(setNearestLocation(payload)),
  addAllFilters: (payload) => dispatch(addAllFilters(payload)),
});

const mapStateToProps = (state) => ({
  filters: state.filters.filters,
  user: state.user,
});

class AppHeaderMobile extends Component {
  header = null;
  headerTop = null;
  content = null;
  lastScrollTop = 0;
  scrollDirection = null;

  constructor(props) {
    super(props);

    this.state = {
      menuIsOpen: false,
      isSticky: false,
    };

    this.unlisten = null;
    this.unlisten = history.listen(() => {
      if (this.state.menuIsOpen) {
        this.setState({ menuIsOpen: false });
        document.body.classList.remove('noscroll');
      }
    });
  }

  componentDidMount() {
    this.header = document.querySelector('.app-header');
    this.headerTop = this.header.querySelector('.app-header__top');
    this.content = document.querySelector('.app-content');

    if (!this.props.absolute) {
      window.addEventListener('scroll', this.headerScrollHandler);
      setTimeout(() => this.headerScrollHandler(), 300);
    }
  }

  componentWillUnmount() {
    if (!this.props.absolute) {
      window.removeEventListener('scroll', this.headerScrollHandler);
    }
    this.unlisten();
  }

  showHeaderHandler = () => {
    this.header.style.transform = 'translateY(0)';
  };

  hideHeaderHandler = () => {
    this.header.style.transform = `translateY(-${
      this.headerTop.getBoundingClientRect().height
    }px`;
  };

  addParamsToFilters = (params, sort) => {
    const filters = [];

    for (const key in params) {
      let filter = {
        type: '',
        label: '',
        slugs: [],
      };

      if (
        params.hasOwnProperty(key) &&
        (params[key][0] !== '' || key === 'keywords')
      ) {
        filter.type = key;
        filter.label =
          key.substr(0, 1).toUpperCase() + key.substr(1, key.length - 1);
        filter.slugs = params[key];
        filters.push(filter);
      }
    }

    if (sort) {
      const filter = {
        type: FilterTypes.SORT,
        label:
          FilterTypes.SORT.substr(0, 1).toUpperCase() +
          FilterTypes.SORT.substr(1, FilterTypes.SORT.length - 1),
        slugs: sort,
      };

      filters.push(filter);
    }

    return filters;
  };

  headerScrollHandler = () => {
    const scrollTop = this.getScrollTopPosition();

    if (scrollTop > 0) {
      this.content.style.paddingTop =
        this.header.getBoundingClientRect().height + 'px';
      this.header.classList.add('--sticky');
      this.setState({ isSticky: true });
    } else {
      this.content.style.paddingTop = 0 + 'px';
      this.header.classList.remove('--sticky');
      this.setState({ isSticky: false });
    }

    if (this.props.panelFilters) {
      if (this.lastScrollTop - scrollTop < 0 && scrollTop > 100) {
        if (this.scrollDirection !== 'down') {
          this.hideHeaderHandler();
          this.scrollDirection = 'down';
        }
      } else {
        if (this.scrollDirection === 'down') {
          this.showHeaderHandler();
          this.scrollDirection = 'up';
        }
      }
    }

    this.lastScrollTop = scrollTop;
  };

  getScrollTopPosition = () => {
    return window.pageYOffset || document.documentElement.scrollTop;
  };

  handleSubmit = (e) => {
    this.handleFetch();
    e.preventDefault();

    this.setState({ searchIsFocused: false });
    document.querySelector('.app-search-field input').blur();
  };

  handleFetch = () => {
    const { moduleName } = this.props;
    setTimeout(() => {
      const filters = getStateFilters(store.getState(), moduleName);

      history.push(buildUrl(filters, PathTypes[moduleName.toUpperCase()]), {
        filters,
        fetch: true,
      });
    }, 300);
  };

  handleFiltersModalShow = () => {
    this.showHeaderHandler();
    this.scrollDirection = 'up';
    this.props.handleFiltersModalShow();
  };

  toggleMenuHandler = () => {
    this.setState({ menuIsOpen: !this.state.menuIsOpen });
    document.body.classList.toggle('noscroll');
  };

  toggleModalHandler = (modal, notification) =>
    this.props.setModal({ active: modal, notification: notification });

  renderPanelSearch() {
    const {
      panelSearch,
      moduleName,
      dropdownPopularOff,
      searchIsFocused,
      handleSearchModalShow,
    } = this.props;
    if (searchIsFocused || !panelSearch) return undefined;
    switch (moduleName) {
      case 'jobs':
        return (
          <div className="app-header__bottom">
            <AppSearchPanelKeywords
              moduleName={moduleName}
              dropdownMenu={true}
              dropdownPopularOff={dropdownPopularOff}
              inputSize={'header'}
              handleSubmit={this.handleSubmit}
              placeholder={'Search Jobs'}
              btn={true}
              isInputFocused={searchIsFocused}
              onFocus={handleSearchModalShow}
            />
          </div>
        );
      case 'events':
        return (
          <div className="app-header__bottom">
            <AppEventsSearchPanelKeywords
              moduleName={moduleName}
              dropdownMenu={false}
              dropdownPopularOff={true}
              inputSize={'header'}
              handleSubmit={this.handleSubmit}
              placeholder={'Search Events'}
              btn={true}
              btnIsFetching={{}}
              isInputFocused={searchIsFocused}
              onFocus={handleSearchModalShow}
            />
          </div>
        );
      case 'coaches':
        return (
          <div className="app-header__bottom">
            <AppCoachesSearchPanelKeywords
              moduleName={moduleName}
              dropdownMenu={true}
              dropdownPopularOff={dropdownPopularOff}
              inputSize="header"
              handleSubmit={this.handleSubmit}
              placeholder="Search Coaches"
              btn={true}
              isInputFocused={searchIsFocused}
              onFocus={handleSearchModalShow}
            />
          </div>
        );
      default:
        return undefined;
    }
  }

  render() {
    const {
      panelFilters,
      user,
      signOut,
      isEmployer,
      filters,
      moduleName,
      bg,
      absolute = false,
    } = this.props;
    const { isSticky } = this.state;

    const alertIsActive =
      filters &&
      filters.length > 0 &&
      filters[0].type !== 'keywords' &&
      filters[0].slugs[0] !== '';

    return (
      <header
        className={cn('app-header app-header-mobile', {
          [`--bg-${bg}`]: bg,
          '--absolute': absolute,
        })}
      >
        <div className="app-header__top">
          <div className="app-header__nav">
            <AppSidebarMenu
              signOut={signOut}
              user={user}
              isOpen={this.state.menuIsOpen}
              toggleMenuHandler={this.toggleMenuHandler}
              isEmployer={isEmployer}
              moduleName={moduleName}
            />

            <div className="app-container">
              <div className="app-header__inner">
                <div
                  className={
                    this.state.menuIsOpen
                      ? 'app-nav-toggle app-nav-toggle--show'
                      : 'app-nav-toggle'
                  }
                  onClick={this.toggleMenuHandler}
                >
                  <div className="app-nav-toggle__inner">
                    <div className="app-nav-toggle__sep"></div>
                    <div className="app-nav-toggle__sep"></div>
                    <div className="app-nav-toggle__sep"></div>
                  </div>
                </div>

                <div className="app-header__left">
                  <Link className="app-link" to="/">
                    <AppLogo isSticky={isSticky} bg={bg} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {this.renderPanelSearch()}
        </div>

        {panelFilters ? (
          <React.Fragment>
            <div className="app-header__filters-panel">
              <div className="app-container">
                <AppFlexLine jc="sb" ai="center">
                  <AppButton
                    title="Filter Results"
                    defaultStyle="tag"
                    onClick={this.handleFiltersModalShow}
                  />

                  {moduleName === 'jobs' ? (
                    <AppButton
                      title="+ Job Alert"
                      defaultStyle="warn"
                      icon="alarm"
                      onClick={() => this.toggleModalHandler('searchAlarm')}
                      isDisabled={!alertIsActive}
                    />
                  ) : (
                    ''
                  )}
                </AppFlexLine>
              </div>
            </div>
          </React.Fragment>
        ) : (
          ''
        )}
      </header>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AppHeaderMobile));
