export const logoColors = {
    a: {
        color: '#ce9797',
        backgroundColor: '#ffdcdc',
    },
    b: {
        color: '#ce9797',
        backgroundColor: '#ffdcdc',
    },
    c: {
        color: '#d5ab8f',
        backgroundColor: '#ffeadc',
    },
    d: {
        color: '#d5ab8f',
        backgroundColor: '#ffeadc',
    },
    e: {
        color: '#ccb38e',
        backgroundColor: '#fff1dc',
    },
    f: {
        color: '#ccb38e',
        backgroundColor: '#fff1dc',
    },
    g: {
        color: '#9cbb75',
        backgroundColor: '#dff1c8',
    },
    h: {
        color: '#9cbb75',
        backgroundColor: '#dff1c8',
    },
    i: {
        color: '#79a68a',
        backgroundColor: '#d2f5df',
    },
    j: {
        color: '#79a68a',
        backgroundColor: '#d2f5df',
    },
    k: {
        color: '#8198ab',
        backgroundColor: '#d9ecfc',
    },
    l: {
        color: '#8198ab',
        backgroundColor: '#d9ecfc',
    },
    m: {
        color: '#8885b4',
        backgroundColor: '#dedcff',
    },
    n: {
        color: '#8885b4',
        backgroundColor: '#dedcff',
    },
    o: {
        color: '#a788e3',
        backgroundColor: '#e8dcff',
    },
    p: {
        color: '#a788e3',
        backgroundColor: '#e8dcff',
    },
    q: {
        color: '#bc8ab2',
        backgroundColor: '#f1deed',
    },
    r: {
        color: '#bc8ab2',
        backgroundColor: '#f1deed',
    },
    s: {
        color: '#a3979c',
        backgroundColor: '#ebe4e7',
    },
    t: {
        color: '#a3979c',
        backgroundColor: '#ebe4e7',
    },
    u: {
        color: '#a3b3af',
        backgroundColor: '#e8efed',
    },
    v: {
        color: '#a3b3af',
        backgroundColor: '#e8efed',
    },
    w: {
        color: '#aba9a9',
        backgroundColor: '#eaeaea',
    },
    x: {
        color: '#aba9a9',
        backgroundColor: '#eaeaea',
    },
    y: {
        color: '#b2b2b2',
        backgroundColor: '#f1f2f1',
    },
    z: {
        color: '#b2b2b2',
        backgroundColor: '#f1f2f1',
    },
}