import React, { Component } from 'react';
import PropTypes from 'prop-types';

import AppSearchTag from 'components/shared/AppSearch/AppSearchTag';
import AppButton from 'components/shared/AppButton';
import AppJobsCardDate from 'components/shared/AppJobsCard/AppJobsCardDate';
import AppJobsCardSave from 'components/shared/AppJobsCard/AppJobsCardSave';
import AppJobsCardImg from 'components/shared/AppJobsCard/AppJobsCardImg';
import AppJobsCardLocations from 'components/shared/AppJobsCard/AppJobsCardLocations';
import AppJobsCardExperience from 'components/shared/AppJobsCard/AppJobsCardExperience';

import './AppJobsDetailsCardMobile.scss';

class AppJobsDetailsCardMobile extends Component {
  state = {
    isFetchingApply: false,
  };

  render() {
    const {
      data: {
        company,
        skills,
        name,
        locations,
        created,
        experience_from,
        experience_up,
        application_link,
        id,
        saved,
        applied,
        status,
      },
      undo,
      search,
      details,
      applyToggleHandler,
      applyIsActive,
      applyCardDetails,
      owner,
      onDeleteHandler,
      onEditHandler,
      animationBackHandler,
      setModal,
      motivatedList,
      isIndeed,
    } = this.props;

    return (
      <div className="app-jobs-card app-jobs-details-card app-jobs-details-card-mobile">
        <div className="app-jobs-card__row --flex --center --column">
          <div className="app-jobs-details-card-mobile__img">
            {company ? (
              <AppJobsCardImg
                size="small"
                id={id}
                img={company.logo}
                alt={company.name}
              />
            ) : (
              ''
            )}
          </div>

          <div title={name} className="app-jobs-card-title --center --vacancie">
            {name}
          </div>

          <div className="app-jobs-card-title --company">
            <span>{company.name}</span>
            {company.motivated ? (
              <span className="app-jobs-card__label --inline">
                <span>Motivated Company</span>
              </span>
            ) : (
              ''
            )}
          </div>
        </div>

        <div className="app-jobs-card__row --flex --center --column">
          <AppJobsCardExperience from={experience_from} to={experience_up} />
          <AppJobsCardLocations locations={locations} />
        </div>

        {!applyIsActive ? (
          <React.Fragment>
            <div className="app-jobs-details-card-mobile__data">
              <AppJobsCardDate status={status} created={created} />
            </div>

            <div className="app-jobs-details-card__actions app-jobs-details-card-mobile__actions">
              {!applyCardDetails && !owner ? (
                application_link ? (
                  <AppButton
                    link={true}
                    href={application_link}
                    onClick={() => {
                      if (window.gtag) {
                        window.gtag('event', 'Apply Job', { label: id });
                      }
                    }}
                    target="_blank"
                    title={isIndeed ? 'Apply with Indeed' : 'Apply Job'}
                    defaultStyle="primary"
                    height="medium"
                    icon="share"
                    reverse
                  />
                ) : (
                  <AppButton
                    title={applied ? 'Applied' : 'Apply Job'}
                    onClick={applyToggleHandler}
                    className={applied ? '--applied' : ''}
                    defaultStyle="primary"
                    height="medium"
                    isDisabled={applyCardDetails || applied}
                    icon={applied ? 'paper-plane' : false}
                  />
                )
              ) : (
                ''
              )}

              <div className="app-jobs-details-card-mobile__actions-line">
                {owner ? (
                  <React.Fragment>
                    <AppButton
                      title="Edit"
                      defaultStyle="secondary"
                      height="medium"
                      icon="pencil"
                      onClick={() => onEditHandler(id)}
                    />
                    <AppButton
                      title="Remove"
                      onClick={() => {
                        setModal({
                          active: 'confirm',
                          onYes: () => {
                            onDeleteHandler(id).then(() =>
                              animationBackHandler()
                            );
                          },
                          title: 'Are you sure you want to remove the Job?',
                        });
                      }}
                      defaultStyle="danger"
                      height="medium"
                      icon="trash"
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <AppButton
                      onClick={this.props.handleSharedModalToggle}
                      defaultStyle="secondary"
                      icon="share"
                      height="medium"
                      title="Share"
                    />
                    {!applyCardDetails ? (
                      <AppJobsCardSave
                        mobile={true}
                        defaultStyle={'button'}
                        iconSaved="check"
                        title={saved ? 'Saved' : 'Save'}
                        className="app-button--medium app-jobs-card-save-details"
                        vacancieId={id}
                        saved={saved}
                        undo={undo}
                        search={search}
                        details={details}
                        motivated={motivatedList}
                      />
                    ) : application_link ? (
                      <AppButton
                        link={true}
                        href={application_link}
                        onClick={() => {
                          if (window.gtag) {
                            window.gtag('event', 'Apply Job', { label: id });
                          }
                        }}
                        target="_blank"
                        title={isIndeed ? 'Apply with Indeed' : 'Apply Job'}
                        defaultStyle="primary"
                        height="medium"
                        icon="share"
                        reverse
                      />
                    ) : (
                      <AppButton
                        title={'Applied'}
                        className={'--applied'}
                        defaultStyle="primary"
                        height="medium"
                        isDisabled={true}
                        icon="paper-plane"
                      />
                    )}
                  </React.Fragment>
                )}
              </div>
            </div>
          </React.Fragment>
        ) : (
          ''
        )}

        {!applyIsActive ? (
          <div className="app-jobs-card__row --flex --jc-center js-app-skills">
            <ul className="app-jobs-card__skills app-jobs-details-card-mobile__skills">
              {skills &&
                skills.map((skill) => (
                  <li className="app-jobs-card__skills-item" key={skill.id}>
                    <AppSearchTag
                      defaultStyle="card"
                      data={skill}
                      addSlug={() => {
                        // history.push(`/jobs?${FilterTypes.SKILLS}=${skill.slug}`);
                      }}
                      removeSlug={() => {}}
                    />
                  </li>
                ))}
            </ul>
          </div>
        ) : (
          ''
        )}
      </div>
    );
  }
}

AppJobsDetailsCardMobile.propTypes = {
  company: PropTypes.object,
  name: PropTypes.string,
};

export default AppJobsDetailsCardMobile;
