import axios from 'axios';

import * as types from 'constants/ActionTypes';
// import { setNotification } from "./SystemActions";
import { API_URL } from 'constants/PathTypes';
import { queryKeywordsSearch } from './querys';

export function clearSeniority() {
    return {
        type: types.CLEAR_SENIORITY,
    };
};

export function receiveSeniorities(payload) {
    return {
        type: types.RECEIVE_SENIORITIES,
        payload
    };
};

export function setFetchingSenioritiesListByName(payload) {
    return {
        type: types.SET_FETCHING_SENIORITIES,
        payload
    };
};

export function setFetchingSenioritiesAll(payload) {
    return {
        type: types.SET_FETCHING_SENIORITIES_ALL,
        payload
    };
};

export function receiveSenioritiesAll(payload) {
    return {
        type: types.RECEIVE_SENIORITIES_ALL,
        payload
    };
};

export function setFetchingPopularSeniorities(payload) {
    return {
        type: types.SET_FETCHING_POPULAR_SENIORITIES,
        payload
    };
};

export function receiveInitStatePopularSeniorities(payload) {
    return {
        type: types.RECEIVE_INIT_STATE_POPULAR_SENIORITIES,
        payload
    };
};

export function setFetchingInitStatePopularSeniorities(payload) {
    return {
        type: types.SET_FETCHING_INIT_STATE_POPULAR_SKILLS,
        payload
    };
};

export function fetchgetSenioritiesAll() {
    return async (dispatch) => {
        dispatch(setFetchingSenioritiesAll(true));

        try {
            const response = await axios.get(`${API_URL}/vacancy_seniorities`);

            dispatch(receiveSenioritiesAll(response.data));
            dispatch(setFetchingSenioritiesAll(false));
        } catch (error) {
            dispatch(setFetchingSenioritiesAll(false));
            // dispatch(setNotification({ message: error.message, type: 'info', status: 'error' }));
            throw error;
        }
    }
}

export function fetchInitStatePopularSeniorities() {
    return async (dispatch) => {
        dispatch(setFetchingPopularSeniorities(true));

        try {
            const response = await axios.get(`${API_URL}/vacancy_seniorities/getTop`);

            dispatch(receiveInitStatePopularSeniorities(response.data));
            dispatch(setFetchingPopularSeniorities(false));
        } catch (error) {
            dispatch(setFetchingPopularSeniorities(false));
            // dispatch(setNotification({ message: error.message, type: 'info', status: 'error' }));
            throw error;
        }
    }
}

export function fetchSenioritiesListByName({name, num}) {
    return async (dispatch) => {
        dispatch(setFetchingSenioritiesListByName(true));

        const query = queryKeywordsSearch({name, num});

        try {
            const response = await axios.get(`${API_URL}/vacancy_seniorities?filter=${encodeURIComponent(query)}`);

            dispatch(receiveSeniorities(response.data));
            dispatch(setFetchingSenioritiesListByName(false));
        } catch (error) {
            dispatch(setFetchingSenioritiesListByName(false));
            // dispatch(setNotification({ message: error.message, type: 'info', status: 'error' }));
            throw error;
        }
    }
}
