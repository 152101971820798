import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';

import { AppPanelContainer } from 'components/shared/AppPanel';
import AppFiltersPanelLocations from 'components/AppFiltersPanel/AppFiltersPanelLocations';
import AppFiltersPanelExperience from 'components/AppFiltersPanel/AppFiltersPanelExperience';
import AppFiltersPanelPricerange from 'components/AppFiltersPanel/AppFiltersPanelPricerange';
import AppFiltersPanelDate from 'components/AppFiltersPanel/AppFiltersPanelDate';
import AppFiltersPanelDropdown from 'components/AppFiltersPanel/AppFiltersPanelDropdown';
import AppButton from 'components/shared/AppButton';

import { fetchSkillsByName } from 'actions/SkillsActions';
import { fetchCompaniesByName } from 'actions/CompaniesActions';
import { fetchLocationsByName } from 'actions/LocationsActions';

import { setModal } from 'actions/SystemActions';

import * as FilterTypes from 'constants/FilterTypes';
import * as PathTypes from 'constants/PathTypes';
import * as SlugsTypes from 'constants/SlugsTypes';

import { isAN } from 'utils/Numbers';
import { buildUrl, OutsideAlerter, getStateFilters } from 'utils';
import { firstLetterUppercase } from 'utils/Strings';
import { renderEventDate } from 'utils/Dates';
import store from 'store';
import history from 'history.js';

import './AppFiltersPanel.scss';

const mapDispatchToProps = (dispatch) => ({
  fetchSkillsByName: (payload) => dispatch(fetchSkillsByName(payload)),
  fetchCompaniesByName: (payload) => dispatch(fetchCompaniesByName(payload)),
  fetchLocationsByName: (payload) => dispatch(fetchLocationsByName(payload)),

  setModal: (payload) => dispatch(setModal(payload)),
});

const mapStateToProps = (state) => ({
  vacancies: state.vacancies,
  roles: state.roles,
  coachingroles: state.coachingroles,
  skills: state.skills,
  seniorities: state.seniorities,
  companies: state.companies,
  eventtypes: state.eventtypes,
  locations: state.locations,
  experience: state.experience,
  price: state.price,
  fromUTC: state.fromUTC,
  filters: state.filters.filters,
  user: state.user,
  coachingtypes: state.coachingtypes,
});

const AppFiltersPanel = (props) => {
  const [activePanel, setActivePanel] = React.useState(null);
  const {
    locations: { nearest },
    filters,
    moduleName,
  } = props;
  const alertIsActive =
    filters &&
    filters.length > 0 &&
    filters[0].type !== 'keywords' &&
    filters[0].slugs[0] !== '';

  const handleSubmit = () => {
    setTimeout(() => {
      const filters = getStateFilters(store.getState(), moduleName);

      window.scrollTo(0, 0);
      history.push(buildUrl(filters, PathTypes[moduleName.toUpperCase()]), {
        filters,
        fetch: true,
      });
    }, 300);
  };

  const analiticsTrigger = (label) => {
    if (window.gtag) {
      window.gtag('event', 'Filter Job', {
        category: 'Filter',
        label: firstLetterUppercase(label),
      });
    }
  };

  const renderDropdownPanel = (activePanel) => {
    const {
      roles,
      skills,
      eventtypes,
      coachingroles,
      companies,
      locations,
      experience,
      price,
      seniorities,
      fromUTC,
      coachingtypes,
    } = props;

    switch (activePanel) {
      case FilterTypes.LOCATIONS:
        return (
          <AppFiltersPanelLocations
            slugName={SlugsTypes.LOCATIONS}
            data={locations}
            fetchSearchList={props.fetchLocationsByName}
            closeFilterPanel={closeFilterPanel}
            handleSubmit={() => handleSubmit(FilterTypes.LOCATIONS_LABEL)}
            analiticsTrigger={() => analiticsTrigger(SlugsTypes.LOCATIONS)}
          />
        );

      case FilterTypes.COMPANIES:
        return (
          <AppFiltersPanelDropdown
            slugName={SlugsTypes.COMPANIES}
            data={companies}
            search={true}
            top={false}
            fetchSearchList={props.fetchCompaniesByName}
            closeFilterPanel={closeFilterPanel}
            handleSubmit={() => handleSubmit(FilterTypes.COMPANIES_LABEL)}
            analiticsTrigger={() => analiticsTrigger(SlugsTypes.COMPANIES)}
          />
        );

      case FilterTypes.EXPERIENCE:
        return (
          <AppFiltersPanelExperience
            data={experience}
            closeFilterPanel={closeFilterPanel}
            handleSubmit={() => handleSubmit(FilterTypes.EXPERIENCE_LABEL)}
            analiticsTrigger={() => analiticsTrigger(SlugsTypes.EXPERIENCE)}
          />
        );

      case FilterTypes.PRICE:
        return (
          <AppFiltersPanelPricerange
            data={price}
            closeFilterPanel={closeFilterPanel}
            handleSubmit={() => handleSubmit(FilterTypes.PRICE_LABEL)}
            analiticsTrigger={() => analiticsTrigger(SlugsTypes.PRICE)}
          />
        );

      case FilterTypes.SKILLS:
        return (
          <AppFiltersPanelDropdown
            data={skills}
            title={`Top ${SlugsTypes.SKILLS}`}
            search={true}
            slugName={SlugsTypes.SKILLS}
            fetchSearchList={props.fetchSkillsByName}
            closeFilterPanel={closeFilterPanel}
            handleSubmit={() => handleSubmit(FilterTypes.SKILLS_LABEL)}
            analiticsTrigger={() => analiticsTrigger(SlugsTypes.SKILLS)}
          />
        );

      case FilterTypes.SENIORITIES:
        return (
          <AppFiltersPanelDropdown
            data={seniorities}
            title={SlugsTypes.SENIORITIES}
            slugName={SlugsTypes.SENIORITIES}
            closeFilterPanel={closeFilterPanel}
            handleSubmit={() => handleSubmit(FilterTypes.SENIORITY_LABEL)}
            analiticsTrigger={() => analiticsTrigger(SlugsTypes.SENIORITIES)}
          />
        );

      case FilterTypes.ROLES:
        return (
          <AppFiltersPanelDropdown
            data={roles}
            title={SlugsTypes.ROLES}
            slugName={SlugsTypes.ROLES}
            closeFilterPanel={closeFilterPanel}
            handleSubmit={() => handleSubmit(FilterTypes.ROLES_LABEL)}
            analiticsTrigger={() => analiticsTrigger(SlugsTypes.ROLES)}
          />
        );

      case FilterTypes.COACHINGROLES:
        return (
          <AppFiltersPanelDropdown
            data={coachingroles}
            title={FilterTypes.COACHINGROLES_LABEL}
            slugName={SlugsTypes.COACHINGROLES}
            closeFilterPanel={closeFilterPanel}
            handleSubmit={() => handleSubmit(FilterTypes.COACHINGROLES_LABEL)}
            analiticsTrigger={() => analiticsTrigger(SlugsTypes.COACHINGROLES)}
          />
        );

      case FilterTypes.EVENTTYPES:
        return (
          <AppFiltersPanelDropdown
            title="Event type"
            slugName={SlugsTypes.EVENTTYPES}
            data={eventtypes}
            closeFilterPanel={closeFilterPanel}
            handleSubmit={() => handleSubmit(FilterTypes.EVENTTYPES_LABEL)}
            analiticsTrigger={() => analiticsTrigger(SlugsTypes.EVENTTYPES)}
          />
        );

      case FilterTypes.FROMUTC:
        return (
          <AppFiltersPanelDate
            title="Date & Time"
            slugName={SlugsTypes.FROMUTC}
            data={fromUTC}
            closeFilterPanel={closeFilterPanel}
            handleSubmit={() => handleSubmit(FilterTypes.FROMUTC_LABEL)}
            analiticsTrigger={() => analiticsTrigger(SlugsTypes.FROMUTC)}
          />
        );

      case FilterTypes.COACHINGTYPES:
        return (
          <AppFiltersPanelDropdown
            title="Services"
            slugName={SlugsTypes.COACHINGTYPES}
            data={coachingtypes}
            closeFilterPanel={closeFilterPanel}
            handleSubmit={() => handleSubmit(FilterTypes.COACHINGTYPES_LABEL)}
            analiticsTrigger={() => analiticsTrigger(SlugsTypes.COACHINGTYPES)}
          />
        );

      default:
        break;
    }
  };

  const handleClickOutside = (e) => activePanel && setActivePanel(null);

  const closeFilterPanel = () => setActivePanel(null);

  const renderFiltersTitle = (slugs = [], label) => {
    if (slugs.length === 1 && slugs[0] !== undefined)
      return `${label}: ${slugs[0].name}`;
    else if (slugs.length > 1 && slugs[0] !== undefined)
      return `${label}: ${slugs[0].name} +${slugs.length - 1}`;
    else return `+ ${label}`;
  };

  const renderLocationTitle = (slugs = [], label, nearest) => {
    if (nearest)
      return `${label}: near ${nearest.name}${
        nearest.alias_region ? ', ' + nearest.alias_region : ''
      }`;
    else {
      if (slugs.length === 1 && slugs[0] !== undefined)
        return `${label}: ${slugs[0].name}${
          slugs[0].alias_region ? `,  ${slugs[0].alias_region}` : ''
        }`;
      if (slugs.length > 1 && slugs[0] !== undefined)
        return `${label}: ${slugs[0].name}${
          slugs[0].alias_region
            ? `,  ${slugs[0].alias_region} +${slugs.length - 1}`
            : ''
        }`;
      return `+ ${label}`;
    }
  };

  const renderFiltersExperienceTitle = (slugs = [], label) => {
    if (isAN(slugs[0] && slugs[1])) {
      if ((slugs[0] !== 0 || slugs[1] !== 10) && slugs[0] !== slugs[1])
        return `${label}: ${slugs[0]}-${slugs[1]} years`;
      if (slugs[0] === slugs[1] && slugs[1] !== 1)
        return `${label}: ${slugs[0]} years`;
      if (slugs[0] === 1 && slugs[1] === 1) return `${label}: ${slugs[0]} year`;
    }
    return `+ ${label}`;
  };

  const renderFiltersPriceTitle = (slugs = [], label) => {
    const [min, max] = slugs;

    if (isAN(min) && isAN(max) && (min !== 0 || max !== 300)) {
      if (min === 0 && max === 0) return `${label}: Free`;
      else if (max === 300 && min !== 0) return `${label}: $${min}+`;
      else if (min === max) return `${label}: $${max}`;
      else {
        return `${label}: $${min} - $${max}`;
      }
    }

    return `+ ${label}`;
  };

  const renderFiltersDateTitle = (slugs = [], label) => {
    if (!slugs.length) {
      return `+ ${label}`;
    } else {
      return `${label}: ${renderEventDate(slugs[0])}`;
    }
  };

  const renderFiltersClasses = (field) => {
    if (
      (field !== FilterTypes.EXPERIENCE &&
        field !== FilterTypes.PRICE &&
        props[field] &&
        props[field].slugs &&
        props[field].slugs.length > 0) ||
      (field === FilterTypes.LOCATIONS && props.locations.nearest) ||
      (field === FilterTypes.EXPERIENCE &&
        (+props[field].slugs[0] !== 0 || +props[field].slugs[1] !== 10)) ||
      (field === FilterTypes.PRICE &&
        (+props[field].slugs[0] !== 0 || +props[field].slugs[1] !== 300))
    ) {
      return true;
    }
    return false;
  };

  const renderCommonFiltersTitle = (slugs, label, field, nearest) => {
    if (field === FilterTypes.FROMUTC)
      return renderFiltersDateTitle(slugs, label);
    else if (field === FilterTypes.LOCATIONS)
      return renderLocationTitle(slugs, label, nearest);
    else if (field === FilterTypes.EXPERIENCE)
      return renderFiltersExperienceTitle(slugs, label);
    else if (field === FilterTypes.PRICE)
      return renderFiltersPriceTitle(slugs, label);
    else return renderFiltersTitle(slugs, label);
  };

  const toggleModalHandler = (modal, notification) =>
    props.setModal({ active: modal, notification: notification });

  return (
    <OutsideAlerter
      className="app-filters-panel"
      onClickOutside={handleClickOutside}
    >
      <div className="app-filters-panel__inner">
        <div className="app-filters-panel__filters">
          {FilterTypes[moduleName].map(({ label, field }) => (
            <div
              key={label}
              className={cn('app-filter', {
                '--used': renderFiltersClasses(field),
              })}
              onClick={(e) => {
                if (
                  e.target.classList.contains('app-filter') &&
                  activePanel === field
                )
                  setActivePanel(null);
                else if (activePanel === null || activePanel !== field)
                  setActivePanel(field);
              }}
            >
              <div className="app-filter__title">
                {renderCommonFiltersTitle(
                  props[field].slugs,
                  label,
                  field,
                  nearest
                )}
              </div>

              <div
                className={cn('app-filters-panel__dropdown', {
                  'app-filters-panel__dropdown--show': activePanel === field,
                  '--initial': 'fromUTC' === field,
                })}
              >
                {activePanel === field ? (
                  <AppPanelContainer>
                    {renderDropdownPanel(activePanel)}
                  </AppPanelContainer>
                ) : (
                  ''
                )}
              </div>
            </div>
          ))}
        </div>

        {moduleName === 'jobs' ? (
          <AppButton
            title="+ Job Alert"
            defaultStyle="warn"
            icon="alarm"
            onClick={() => toggleModalHandler('searchAlarm')}
            isDisabled={!alertIsActive}
          />
        ) : (
          ''
        )}
      </div>
    </OutsideAlerter>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(AppFiltersPanel);
