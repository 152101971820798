import React, { useMemo } from 'react';

import AppButton from 'components/shared/AppButton';

import {
  Card,
  CardEventDate,
  CardImg,
  CardAddress,
  CardLabel,
  CardTitle,
  CardRow,
  CardSkills,
} from 'components/Card';

import './AppEventDetailsCardMobile.scss';

const AppEventDetailsCardMobile = (props) => {
  const {
    data: {
      types,
      logo,
      skills,
      name,
      address,
      date_start,
      date_end,
      utc_start,
      utc_end,
      locations,
      registration_link,
      id,
    },
    handleSharedModalToggle,
  } = props;

  const isOnlineLocation = useMemo(() =>
    locations.find((location) => location.id === 1)
  );

  return (
    <Card center>
      <CardRow flex jc="center" marged>
        <CardImg id={id} img={logo} alt={name} />
      </CardRow>
      <CardRow flex column ai="center" marged>
        <CardTitle center title={name}>
          {name}
        </CardTitle>
      </CardRow>
      <CardRow>
        {types
          ? types.map((type) => <CardLabel key={type.id} title={type.name} />)
          : ''}
      </CardRow>

      <CardRow flex column ai="center">
        <CardAddress address={address} />
      </CardRow>
      <CardRow flex column ai="center">
        <CardEventDate
          online={isOnlineLocation}
          dateStart={isOnlineLocation ? utc_start : date_start}
          dateEnd={isOnlineLocation ? utc_end : date_end}
        />
      </CardRow>

      <CardRow flex jc="center" childrenFlex>
        <AppButton
          onClick={handleSharedModalToggle}
          defaultStyle="secondary"
          icon="share"
          height="medium"
          title="Share"
        />
        {registration_link ? (
          <AppButton
            link={true}
            href={registration_link}
            onClick={() => {
              if (window.gtag) {
                window.gtag('event', 'Register Event', { label: id });
              }
            }}
            target="_blank"
            title="Register"
            defaultStyle="primary"
            height="medium"
            icon="share"
            reverse
          />
        ) : (
          ''
        )}
      </CardRow>

      {skills && skills.length ? (
        <CardRow>
          <CardSkills
            center
            skills={skills}
            activeSkills={false}
            moduleName="events"
          />
        </CardRow>
      ) : (
        ''
      )}
    </Card>
  );
};

export default AppEventDetailsCardMobile;
