export const ALL = 'ALL';
export const ROLES = 'ROLES';
export const SKILLS = 'SKILLS';
export const SENIORITIES = 'SENIORITIES';
export const COMPANIES = 'COMPANIES';
export const LOCATIONS = 'LOCATIONS';
export const EXPERIENCE = 'EXPERIENCE';
export const PRICE = 'PRICE';
export const EVENTTYPES = 'EVENTTYPES';
export const FROMUTC = 'FROMUTC';
export const COACHINGTYPES = 'COACHINGTYPES';
export const COACHINGROLES = 'COACHINGROLES';
