import * as types from 'constants/ActionTypes';

export function addAllFilters(payload) {
  return {
    type: types.ADD_ALL_FILTERS,
    payload,
  };
}

export function removeAllFilters(payload) {
  return {
    type: types.REMOVE_ALL_FILTERS,
    payload,
  };
}
