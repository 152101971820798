import React, { useMemo } from 'react';

import logo from 'assets/images/svg/logo.svg';
import logoBlue from 'assets/images/svg/logo-blue.svg';
import logoGreen from 'assets/images/svg/logo-green.svg';

import './AppLogo.scss';

const AppLogo = function ({ src, onClick, bg, isSticky }) {
  const renderLogo = useMemo(() => {
    if (src) return src;
    else if (!bg || isSticky) return logo;
    else if (bg === 'acsent') {
      return logoBlue;
    } else if (bg === 'dark-blue') {
      return logoGreen;
    }
  }, [logo, src, bg, isSticky]);

  return (
    <div onClick={onClick} className="app-logo">
      <img src={renderLogo} alt="Producthaired" />
    </div>
  );
};

export default AppLogo;
