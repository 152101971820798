import React from 'react';
import cn from 'classnames';

import { formatDate } from 'utils/Dates';

import './CardDateFormated.scss';

export const CardDateFormated = function ({
  date,
  formatter = 'DD MMM, YYYY',
}) {
  return (
    <div className={cn('card-date-f')}>{formatDate({ date, formatter })}</div>
  );
};

export default CardDateFormated;
