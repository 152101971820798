import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import cn from 'classnames';
import Helmet from 'react-helmet';

import AppButton from 'components/shared/AppButton';
import {
  AppModal,
  AppModalHead,
  AppModalContent,
} from 'components/shared/AppModal';
import AppSearchTag from 'components/shared/AppSearch/AppSearchTag';
import AppFooter from 'components/AppFooter';
import AppIcon from 'components/shared/AppIcon';
import AppSpinner from 'components/shared/AppSpinner';
import AppJobsCardSave from 'components/shared/AppJobsCard/AppJobsCardSave';
import AppJobsCardInterest from 'components/shared/AppJobsCardInterest';
import AppJobsSmallCard from 'components/shared/AppJobsSmallCard';
import AppJobsDetailsCard from './AppJobsDetailsCard';
import AppJobsDetailsCardMobile from './AppJobsDetailsCardMobile';
import AppJobDetailsApplyForm from './AppJobDetailsApplyForm';
import AppSharedMobile from 'components/shared/AppSharedMobile';
import AppJobCraft from './AppJobCraft';
import AppHeader from 'components/AppHeader';
import AppHeaderMobile from 'components/AppHeaderMobile';
import AdWrapper from 'components/AdWrapper';

import { getInitState, setInitStateLoaded } from 'actions/StateActions';

import {
  receiveVacancieById,
  fetchVacancieById,
  uploadVacancyApplyResume,
  fetchApplyJobUserVacancieByIdAndData,
  toggleVacancieByIdAppliedProp,
  addAppliedVacancieToUserAppliedJobs,
  toggleVacancieSearchListAppliedPropById,
  fetchRelatedJobsByData,
  setRelatedJobs,
  toggleVacancieMotivatedListSavedPropById,
  fetchJobDetailsCraftDataById,
} from 'actions/VacanciesActions';
import { fetchDeleteUserDashboardVacancie } from 'actions/DashboardActions';
import {
  setUploadProgress,
  setModal,
  setNotification,
  clearNotification,
} from 'actions/SystemActions';

import * as MediaTypes from 'constants/MediaTypes';
import * as PathTypes from 'constants/PathTypes';

import history from 'history.js';
import store from 'store';
import { getStateFilters, buildUrl, getFirstNumberInString } from 'utils';

import 'components/shared/AppJobsCard/AppJobsCard.scss';

const mapDispatchToProps = (dispatch) => ({
  getInitState: (payload) => dispatch(getInitState(payload)),
  setInitStateLoaded: (payload) => dispatch(setInitStateLoaded(payload)),

  setModal: (payload) => dispatch(setModal(payload)),
  setNotification: (payload) => dispatch(setNotification(payload)),
  clearNotification: (payload) => dispatch(clearNotification(payload)),
  fetchVacancieById: (payload) => dispatch(fetchVacancieById(payload)),
  receiveVacancieById: (payload) => dispatch(receiveVacancieById(payload)),
  uploadVacancyApplyResume: (payload) =>
    dispatch(uploadVacancyApplyResume(payload)),
  setUploadProgress: (payload) => dispatch(setUploadProgress(payload)),
  fetchApplyJobUserVacancieByIdAndData: (payload) =>
    dispatch(fetchApplyJobUserVacancieByIdAndData(payload)),
  toggleVacancieByIdAppliedProp: (payload) =>
    dispatch(toggleVacancieByIdAppliedProp(payload)),
  addAppliedVacancieToUserAppliedJobs: (payload) =>
    dispatch(addAppliedVacancieToUserAppliedJobs(payload)),
  toggleVacancieSearchListAppliedPropById: (payload) =>
    dispatch(toggleVacancieSearchListAppliedPropById(payload)),
  toggleVacancieMotivatedListSavedPropById: (payload) =>
    dispatch(toggleVacancieMotivatedListSavedPropById(payload)),
  fetchJobDetailsCraftDataById: (payload) =>
    dispatch(fetchJobDetailsCraftDataById(payload)),

  fetchDeleteUserDashboardVacancie: (payload) =>
    dispatch(fetchDeleteUserDashboardVacancie(payload)),
  fetchRelatedJobsByData: (payload) =>
    dispatch(fetchRelatedJobsByData(payload)),
  setRelatedJobs: (payload) => dispatch(setRelatedJobs(payload)),
});

const mapStateToProps = (state) => ({
  vacancies: state.vacancies,
  user: state.user,
  location: state.location,
  progressUpload: state.system.loadFileProgress,
  initState: state.initState,
  isLoaded: state.initState.isLoaded,
});

class AppJobDetailsPage extends Component {
  constructor(props) {
    super(props);

    this.initState = {
      fixed: false,
      paddingTop: 0,
      headOffsetRight: 0,
      headOffsetTop: 0,
      navFixed: false,
      sharedModalIsOpen: true,
      applyIsActive: false,
      isFetchingApply: false,
      craftData: null,
      craftLoading: false,
      metaJson: null,
      animationStartHandler: this.handlePageStartAnimation,
    };

    this.state = {
      ...this.initState,
    };

    this.contentScrolled = null;
    this.browserScrollbarWidth = null;
    this.sidebar = null;
    this.header = null;
    this.card = null;
    this.page = null;
    this.lastScrollTop = 0;
    this.id = null;
    this.prevTitle = document.title;
    this.prevDescription = document
      .querySelector('meta[name="description"]')
      .getAttribute('content');
    this.unlisten = null;
    this.firstLoadRelated = null;
  }

  componentDidMount() {
    if (this.props.staticPage) {
      this.initStateHandler();
      this.handlePageOutAnimation();
    } else {
      this.handlePageStartAnimation();
    }

    this.handleVacancieById();

    this.unlisten = history.listen((params, action) => {
      this.setState(this.initState);

      if (action === 'POP') {
        const vacancieById = params.state && params.state.vacancieById;
        const prevRelated = params.state && params.state.prevRelated;

        if (vacancieById) {
          this.props.receiveVacancieById(null);
          this.props.receiveVacancieById(vacancieById);
        }

        if (prevRelated) {
          this.props.setRelatedJobs([]);
          this.props.setRelatedJobs(prevRelated);
        } else {
          if (this.firstLoadRelated) {
            this.props.setRelatedJobs([]);
            this.props.setRelatedJobs(this.firstLoadRelated);
          }
        }
      }
    });
  }

  static getDerivedStateFromProps(props, state) {
    const { prevProps } = state;

    if (
      prevProps &&
      prevProps.staticPage !== props.staticPage &&
      props.staticPage === false
    ) {
      state.animationStartHandler();
      return {
        ...state,
        prevProps: props,
      };
    }

    return {
      prevProps: props,
    };
  }

  componentWillUnmount() {
    this.handlePageOutAnimation();
    this.props.receiveVacancieById(null);
    document.title = this.prevTitle;
    document
      .querySelector('meta[name="description"]')
      .setAttribute('content', this.prevDescription);
    this.unlisten();
  }

  initStateHandler = () => {
    const { setInitStateLoaded, getInitState, isLoaded } = this.props;
    if (!isLoaded) getInitState().then(() => setInitStateLoaded());
  };

  handlePageStartAnimation = () => {
    this.browserScrollbarWidth = this.calcScrollbarBrowserWidth();

    document.querySelector('.js-blocker').classList.add('show');
    document.querySelector('.app-job-details__inner').classList.add('animate');
    document.body.classList.add('noscroll');

    if (this.contentScrolled !== null && this.contentScrolled.scroll)
      this.contentScrolled.addEventListener('scroll', this.headerScrollHandler);
  };

  handlePageOutAnimation = () => {
    document.body.classList.remove('noscroll');
    if (this.contentScrolled !== null)
      this.contentScrolled.removeEventListener(
        'scroll',
        this.headerScrollHandler
      );
  };

  renderPageTitle = (job) => {
    const { locations, company } = job;
    let title = `${job.name} at ${company.name}`;

    if (locations) {
      if (locations.length === 1) {
        title +=
          locations[0].id === 1
            ? `, ${locations[0].name}`
            : ` in ${locations[0].name}`;
      } else if (locations.length === 2) {
        title +=
          locations[0].id === 1
            ? ` in ${locations[1].name} or ${locations[0]}`
            : ` in ${locations[0].name} or ${locations[1].name}`;
      }
    }

    title += ' - ProductHired';

    return title;
  };

  handleVacancieById = () => {
    const {
      fetchVacancieById,
      receiveVacancieById,
      fetchRelatedJobsByData,
      fetchJobDetailsCraftDataById,
    } = this.props;
    const { location } = history;
    const vacancieId = (this.id = getFirstNumberInString(location.pathname));

    vacancieId
      ? fetchVacancieById(vacancieId).then((job) => {
          if (job.status === 'public') {
            this.setJobMetaScript(job);
          }

          document.title = this.renderPageTitle(job);
          document
            .querySelector('meta[name="description"]')
            .setAttribute('content', job.description);

          receiveVacancieById(job);
          this.setState({ craftLoading: true });
          fetchJobDetailsCraftDataById(job.company.id)
            .then((response) => {
              this.setState({
                craftData: response.company || null,
                craftLoading: false,
              });
            })
            .catch(() => {
              this.setState({ craftLoading: false });
            });
          fetchRelatedJobsByData({ data: job }).then((related) => {
            if (this.firstLoadRelated === null) this.firstLoadRelated = related;
          });
          this.contentScrolled && this.contentScrolled.scrollTo(0, 0);
        })
      : history.push(PathTypes.PAGE404);
  };

  setJobMetaScript = (job) => {
    let remote = false;
    let skills = '';
    let locations =
      job.locations.length > 0
        ? job.locations.filter((location) => {
            if (location.id === 1) {
              remote = true;
              return false;
            }
            return true;
          })
        : [];

    if (locations.length > 0) {
      locations = [
        ...locations.map((location) => ({
          '@type': 'Place',
          address: {
            '@type': 'PostalAddress',
            addressCountry: location.country,
            addressLocality: location.name,
            addressRegion: location.alias_region || '',
          },
        })),
      ];
    }

    if (job.skills.length > 0) {
      job.skills.forEach((skill, idx) => {
        skills += idx === 0 ? skill.name : `, ${skill.name}`;
      });
    }

    const json = {
      '@context': 'http://schema.org/',
      '@type': 'JobPosting',
      title: job.name,
      skills: skills,
      identifier: {
        '@type': 'PropertyValue',
        name: job.company.name,
        value: job.slug,
      },
      employmentType: 'FULL_TIME',
      hiringOrganization: {
        '@type': 'Organization',
        name: job.company.name,
      },
      description: job.details || '',
      datePosted: job.published,
      jobLocation: locations,
    };

    if (remote) json.jobLocationType = 'TELECOMMUTE';
    this.setState({ metaJson: json });
  };

  getMarkupIdArray = (vacancies) => {
    const reg = /id="([\d\w\s-]+)"/g;
    let idArr = [];

    if (vacancies && vacancies.vacancieById) {
      const str = vacancies.vacancieById.details;
      let match = reg.exec(str);

      while (match != null) {
        idArr.push(match[1]);
        match = reg.exec(str);
      }
    }

    return idArr;
  };

  handleSharedModalToggle = () =>
    this.setState({ sharedModalIsOpen: !this.state.sharedModalIsOpen });

  calcScrollbarBrowserWidth = () => {
    var div = document.createElement('div');

    div.style.overflowY = 'scroll';
    div.style.width = '50px';
    div.style.height = '50px';
    div.style.visibility = 'hidden';

    document.body.appendChild(div);
    var scrollWidth = div.offsetWidth - div.clientWidth;
    document.body.removeChild(div);

    return scrollWidth;
  };

  headerScrollHandler = (e) => {
    const { fixed } = this.state;
    const scrollTop = this.contentScrolled.scrollTop;
    const bannerHeight = this.banner.getBoundingClientRect().height;

    if (window.innerWidth > MediaTypes.LPX) {
      if (
        (scrollTop >= bannerHeight && !fixed) ||
        (scrollTop <= bannerHeight && fixed)
      ) {
        const paddingContent = !fixed
          ? this.card.getBoundingClientRect().height + scrollTop
          : 0;

        const headOffsetRight = !fixed
          ? window.innerWidth > 1100
            ? this.sidebar.getBoundingClientRect().width +
              this.browserScrollbarWidth
            : this.browserScrollbarWidth + 1
          : 0;

        this.handlerHeadFixed(paddingContent, headOffsetRight, -bannerHeight);
      }
    } else {
      if (scrollTop >= this.header.getBoundingClientRect().height) {
        this.setState({ navFixed: true });
      } else {
        this.setState({ navFixed: false, headOffsetTop: 0 });
      }
    }

    this.lastScrollTop = scrollTop;
  };

  handlerHeadFixed = (paddingTop, headOffsetRight, headOffsetTop) => {
    this.setState({
      fixed: !this.state.fixed,
      paddingTop,
      headOffsetRight,
      headOffsetTop: !this.state.fixed ? headOffsetTop : 0,
    });
  };

  animationBackHandler = () => {
    this.page.classList.add('hide');
    document
      .querySelector('.app-job-details__inner')
      .classList.add('animateBack');
    document.querySelector('.js-blocker').classList.add('showOut');
    document.body.classList.remove('noscroll');
    if (this.contenScrolle != null)
      this.contentScrolled.removeEventListener(
        'scroll',
        this.headerScrollHandler
      );
  };

  handlerGoBack = (e) => {
    const { location } = history;

    const state = getStateFilters(
      store.getState(),
      this.props.vacancies.moduleName
    );

    if (e) e.stopPropagation();

    if (!this.props.staticPage) {
      this.page.classList.add('hide');
      document
        .querySelector('.app-job-details__inner')
        .classList.add('animateBack');
      document.querySelector('.js-blocker').classList.add('showOut');
    } else {
      if (location.state && location.state.from) {
        history.push(buildUrl(state, PathTypes[location.state.from]), {
          frommodal: true,
          fetch: true,
          prevListState: location.state.prevListState || null,
        });
      } else {
        history.push(buildUrl(state, PathTypes.JOBS), {
          frommodal: false,
          fetch: true,
          filters: [],
          prevListState: location.state.prevListState || null,
        });
      }
    }

    setTimeout(() => {
      if (location.state && location.state.from) {
        if (
          location.state.from === PathTypes.FROMDASHBOARD ||
          location.state.from === PathTypes.FROMHOME
        )
          history.push(PathTypes[location.state.from], { frommodal: true });
        else
          history.push(buildUrl(state, PathTypes[location.state.from]), {
            frommodal: true,
            fetch: false,
            filters: state,
            prevListState: location.state.prevListState || null,
          });
      } else
        history.push(buildUrl(state, PathTypes.JOBS), {
          frommodal: true,
          fetch: false,
          filters: state,
        });
    }, 1000);
  };

  navAnchorHandler = (e) => {
    const target = e.target;
    const targetSectionId = target.getAttribute('data-target');
    const targetSection = document.getElementById(targetSectionId);

    targetSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  renderHTMLMarkup = (markup) => ({ __html: markup });

  renderVacancieApplyLink = () => {
    const { vacancies } = this.props;

    if (
      vacancies &&
      vacancies.vacancieById &&
      vacancies.vacancieById.application_link
    )
      return vacancies.vacancieById.application_link;
    return false;
  };

  isIndeedCompany = (link) => {
    if (link && link.indexOf('indeed') >= 0) return true;
    return false;
  };

  applyToggleHandler = () => {
    this.contentScrolled.scrollTop = 0;
    this.setState({ applyIsActive: !this.state.applyIsActive });
  };

  onEditHandler = (id) => history.push(`${PathTypes.EMPLOYER_EDITAJOB}/${id}`);

  onDeleteHandler = (id) => {
    return this.props
      .fetchDeleteUserDashboardVacancie(id)
      .then(() => this.handlerGoBack());
  };

  checkCorrectUrl = (string) => {
    if (string.indexOf('http') === 0) return string;
    else return PathTypes.SERVER_URL + string;
  };

  render() {
    const {
      vacancies,
      location,
      uploadVacancyApplyResume,
      user,
      progressUpload,
      setUploadProgress,
      fetchApplyJobUserVacancieByIdAndData,
      toggleVacancieByIdAppliedProp,
      addAppliedVacancieToUserAppliedJobs,
      toggleVacancieSearchListAppliedPropById,
      setModal,
      setNotification,
      clearNotification,
      toggleVacancieMotivatedListSavedPropById,
      staticPage,
    } = this.props;
    const { fixed, applyIsActive, craftData, craftLoading, sharedModalIsOpen } =
      this.state;

    const motivatedList =
      location.state && location.state.from && location.state.motivated;
    const applyCardDetails =
      location.state &&
      location.state.from &&
      location.state.from === PathTypes.FROMAPPLIEDJOBS;
    const ownerDetails =
      location.state &&
      location.state.from &&
      (location.state.from === PathTypes.FROMDASHBOARD || location.state.owner);
    const saved =
      vacancies && vacancies.vacancieById && vacancies.vacancieById.saved;
    const applied =
      vacancies && vacancies.vacancieById && vacancies.vacancieById.applied;
    // const anchors = this.getMarkupIdArray(vacancies)
    const anchors = [{ id: 'overview', label: 'Overview' }];

    return (
      <React.Fragment>
        {staticPage ? (
          <MediaQuery maxWidth={MediaTypes.HEADER}>
            {(matches) => {
              if (matches) return <AppHeaderMobile />;
              else
                return (
                  <AppHeader
                    moduleName={vacancies.moduleName}
                    panelSearch={true}
                  />
                );
            }}
          </MediaQuery>
        ) : (
          ''
        )}
        <div
          className={cn('app-job-details', {
            '--static': staticPage,
          })}
          ref={(ref) => (this.page = ref)}
        >
          <div
            className="app-job-details__backdoor js-blocker"
            onClick={this.handlerGoBack}
          ></div>
          <div className="app-job-details__inner">
            {this.state.metaJson ? (
              <Helmet
                script={[
                  {
                    type: 'application/ld+json',
                    innerHTML: `${JSON.stringify(this.state.metaJson)}`,
                  },
                ]}
              />
            ) : (
              ''
            )}
            <MediaQuery maxWidth={MediaTypes.LT}>
              {!applyIsActive ? (
                <React.Fragment>
                  <div
                    className={
                      'app-job-details-mobile__nav' +
                      (this.state.navFixed ? ' show' : '')
                    }
                  >
                    <div className="app-job-details-mobile-nav">
                      <ul className="app-job-details-mobile-nav__list">
                        {anchors &&
                          anchors.map((anchor) => {
                            return (
                              <li
                                key={anchor.id}
                                className="app-job-details-mobile-nav__list-item"
                                onClick={this.navAnchorHandler}
                                data-target={anchor.id}
                              >
                                <a
                                  href={'#' + anchor.id}
                                  className="app-job-details-mobile-nav__list-item-btn"
                                >
                                  {anchor.label}
                                </a>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>

                  <AppModal
                    id="modal-shared"
                    className="app-modal-shared"
                    isActive={!sharedModalIsOpen}
                  >
                    <div className="app-shared-menu-mobile">
                      <AppModalHead>
                        <AppButton
                          defaultStyle="round-icon"
                          className="app-modal-mobile-head__back app-modal-mobile-head__back--round"
                          onClick={this.handleSharedModalToggle}
                          icon="close-cross"
                        />

                        <div className="app-shared-menu-mobile__title">
                          Share Job
                        </div>
                      </AppModalHead>

                      <AppModalContent>
                        <div className="app-shared-menu-mobile__card">
                          {vacancies && vacancies.vacancieById ? (
                            <AppJobsSmallCard data={vacancies.vacancieById} />
                          ) : (
                            ''
                          )}
                        </div>
                        <AppSharedMobile
                          user={user && user.auth}
                          setNotification={setNotification}
                          clearNotification={clearNotification}
                          jobTitle={
                            vacancies.vacancieById &&
                            vacancies.vacancieById.name
                          }
                        />
                      </AppModalContent>
                    </div>
                  </AppModal>

                  <div
                    className={
                      'app-job-details-mobile__actions-fixed' +
                      (this.state.navFixed ? ' show' : '')
                    }
                  >
                    {!ownerDetails && (
                      <AppButton
                        onClick={this.handleSharedModalToggle}
                        defaultStyle="secondary"
                        icon="share"
                        height="medium"
                        title="Share"
                      />
                    )}
                    {!applyCardDetails && !ownerDetails ? (
                      <AppJobsCardSave
                        mobile={true}
                        defaultStyle={'button'}
                        iconSaved="check"
                        height="medium"
                        title={saved ? 'Saved' : 'Save'}
                        className="app-jobs-card-save-details"
                        vacancieId={
                          vacancies &&
                          vacancies.vacancieById &&
                          vacancies.vacancieById.id
                        }
                        saved={saved}
                        search={true}
                        details={true}
                        motivated={motivatedList}
                        undo={
                          location.state &&
                          location.state.from &&
                          location.state.from === PathTypes.FROMSAVEDJOBS
                        }
                      />
                    ) : (
                      ''
                    )}
                    {!ownerDetails ? (
                      vacancies.vacancieById &&
                      vacancies.vacancieById.application_link ? (
                        <AppButton
                          link={true}
                          href={this.renderVacancieApplyLink()}
                          onClick={() => {
                            if (window.gtag) {
                              window.gtag('event', 'Apply Now', {
                                label: vacancies.vacancieById.id,
                              });
                            }
                          }}
                          target="_blank"
                          title={
                            this.isIndeedCompany(
                              vacancies.vacancieById.application_link
                            )
                              ? 'Apply with Indeed'
                              : 'Apply Now'
                          }
                          defaultStyle="primary"
                          height="medium"
                          icon="share"
                          reverse
                        />
                      ) : (
                        <AppButton
                          title={
                            applyCardDetails || applied
                              ? 'Applied'
                              : 'Apply Now'
                          }
                          onClick={this.applyToggleHandler}
                          className={
                            applyCardDetails || applied ? '--applied' : ''
                          }
                          defaultStyle="primary"
                          height="medium"
                          isDisabled={applyCardDetails || applied}
                          icon={
                            applyCardDetails || applied ? 'paper-plane' : ''
                          }
                        />
                      )
                    ) : (
                      ''
                    )}
                    {ownerDetails && (
                      <React.Fragment>
                        <AppButton
                          title="Edit"
                          onClick={() => this.onEditHandler(this.id)}
                          defaultStyle="secondary"
                          height="medium"
                          icon="pencil"
                        />
                        <AppButton
                          title="Remove"
                          onClick={() => {
                            setModal({
                              active: 'confirm',
                              onYes: () => {
                                this.onDeleteHandler(this.id).then(() =>
                                  this.animationBackHandler()
                                );
                              },
                              title: 'Are you sure you want to remove the Job?',
                            });
                          }}
                          defaultStyle="danger"
                          height="medium"
                          icon="trash"
                        />
                      </React.Fragment>
                    )}
                  </div>
                </React.Fragment>
              ) : (
                ''
              )}
            </MediaQuery>

            <div
              className={cn('app-job-details__close', {
                '--fixed': this.state.navFixed && !applyIsActive,
              })}
              onClick={
                applyIsActive ? this.applyToggleHandler : this.handlerGoBack
              }
            >
              <MediaQuery maxWidth={MediaTypes.LT}>
                {(matches) => {
                  if (matches) {
                    return <AppIcon icon="arrow-left" />;
                  } else {
                    return <AppIcon icon="close" />;
                  }
                }}
              </MediaQuery>
            </div>

            {vacancies &&
            vacancies.isFetching &&
            vacancies.isFetching.vacancieById ? (
              <div className="app-job-details__preloader">
                <AppSpinner
                  size={25}
                  spinnerColor={'#333'}
                  spinnerWidth={2}
                  visible={true}
                />
              </div>
            ) : (
              <div
                className={
                  'app-job-details__content' +
                  (vacancies.isFetching.vacancieById ? ' show' : '')
                }
                ref={(ref) => (this.contentScrolled = ref)}
                style={{ paddingTop: this.state.paddingTop }}
              >
                {vacancies.vacancieById && (
                  <React.Fragment>
                    <div className="app-job-details__content-left">
                      {staticPage ? (
                        <button
                          onClick={() =>
                            history.push(PathTypes.JOBS, { fetch: true })
                          }
                          className="app-button-back --top --p-left"
                        >
                          <AppIcon
                            className="app-button-back__icon"
                            color="acsent"
                            icon="arrow-left"
                          />
                          <div className="app-button-back__title">
                            Go to job search
                          </div>
                        </button>
                      ) : (
                        ''
                      )}
                      <div
                        style={{
                          right: this.state.headOffsetRight,
                          top: this.state.headOffsetTop,
                        }}
                        className={cn('app-job-details-head', {
                          show: vacancies.isFetching.vacancieById,
                          fixed: fixed,
                        })}
                        ref={(ref) => (this.header = ref)}
                      >
                        <div
                          className={cn('app-job-details-head__banner', {
                            show:
                              vacancies.vacancieById.company.cover &&
                              !applyIsActive &&
                              vacancies.vacancieById.plan_id &&
                              vacancies.vacancieById.plan_id > 1,
                          })}
                          ref={(ref) => (this.banner = ref)}
                          style={{
                            backgroundImage: `url(${
                              vacancies.vacancieById.company.cover &&
                              vacancies.vacancieById.plan_id &&
                              vacancies.vacancieById.plan_id > 1
                                ? this.checkCorrectUrl(
                                    vacancies.vacancieById.company.cover
                                  )
                                : null
                            })`,
                          }}
                        ></div>

                        <div
                          className="app-job-details__card"
                          ref={(ref) => (this.card = ref)}
                        >
                          <MediaQuery maxWidth={MediaTypes.LT}>
                            {(matches) => {
                              if (matches) {
                                return (
                                  <React.Fragment>
                                    {(vacancies.vacancieById.company
                                      .motivated === 1 ||
                                      vacancies.vacancieById.plan_id > 1) &&
                                    !applyIsActive ? (
                                      <div className="app-notification app-notification-motivated app-jobs-details-card-mobile__notification">
                                        <span>Motivated Company</span>
                                      </div>
                                    ) : (
                                      ''
                                    )}

                                    <AppJobsDetailsCardMobile
                                      applyToggleHandler={
                                        this.applyToggleHandler
                                      }
                                      applyIsActive={applyIsActive}
                                      handleSharedModalToggle={
                                        this.handleSharedModalToggle
                                      }
                                      hide={!fixed}
                                      data={vacancies.vacancieById}
                                      details={true}
                                      search={true}
                                      undo={
                                        location.state &&
                                        location.state.from &&
                                        location.state.from ===
                                          PathTypes.FROMSAVEDJOBS
                                      }
                                      applyCardDetails={applyCardDetails}
                                      owner={ownerDetails}
                                      onDeleteHandler={this.onDeleteHandler}
                                      onEditHandler={this.onEditHandler}
                                      animationBackHandler={
                                        this.animationBackHandler
                                      }
                                      setModal={setModal}
                                      motivatedList={motivatedList}
                                      isIndeed={this.isIndeedCompany(
                                        vacancies.vacancieById.application_link
                                      )}
                                    />
                                  </React.Fragment>
                                );
                              } else {
                                return (
                                  <AppJobsDetailsCard
                                    location={location}
                                    applyToggleHandler={this.applyToggleHandler}
                                    applyIsActive={applyIsActive}
                                    fixed={fixed}
                                    data={vacancies.vacancieById}
                                    details={true}
                                    search={true}
                                    undo={
                                      location.state &&
                                      location.state.from &&
                                      location.state.from ===
                                        PathTypes.FROMSAVEDJOBS
                                    }
                                    applyCardDetails={applyCardDetails}
                                    owner={ownerDetails}
                                    onDeleteHandler={this.onDeleteHandler}
                                    onEditHandler={this.onEditHandler}
                                    setModal={setModal}
                                    animationBackHandler={
                                      this.animationBackHandler
                                    }
                                    motivatedList={motivatedList}
                                    isIndeed={this.isIndeedCompany(
                                      vacancies.vacancieById.application_link
                                    )}
                                  />
                                );
                              }
                            }}
                          </MediaQuery>
                        </div>
                      </div>

                      <div className="app-job-details__content-left-wr">
                        {applyIsActive ? (
                          <AppJobDetailsApplyForm
                            user={user}
                            id={
                              vacancies.vacancieById &&
                              vacancies.vacancieById.id
                            }
                            jobName={
                              vacancies.vacancieById &&
                              vacancies.vacancieById.name
                            }
                            company={
                              vacancies.vacancieById &&
                              vacancies.vacancieById.company
                            }
                            uploadVacancyApplyResume={uploadVacancyApplyResume}
                            progressUpload={progressUpload}
                            setUploadProgress={setUploadProgress}
                            applyVacancie={fetchApplyJobUserVacancieByIdAndData}
                            applyToggleHandler={this.applyToggleHandler}
                            motivatedList={motivatedList}
                            toggleVacancieByIdAppliedProp={
                              toggleVacancieByIdAppliedProp
                            }
                            addAppliedVacancieToUserAppliedJobs={
                              addAppliedVacancieToUserAppliedJobs
                            }
                            toggleVacancieSearchListAppliedPropById={
                              toggleVacancieSearchListAppliedPropById
                            }
                            toggleVacancieMotivatedListSavedPropById={
                              toggleVacancieMotivatedListSavedPropById
                            }
                          />
                        ) : (
                          <React.Fragment>
                            <MediaQuery minWidth={MediaTypes.LT + 1}>
                              <div className="app-jobs-card__row">
                                <ul className="app-jobs-card__skills">
                                  {vacancies.vacancieById.skills &&
                                    vacancies.vacancieById.skills.map(
                                      (skill) => (
                                        <li
                                          className="app-jobs-card__skills-item"
                                          key={skill.id}
                                        >
                                          <AppSearchTag
                                            defaultStyle="card"
                                            data={skill}
                                            addSlug={() => {
                                              // history.push(`/jobs?${FilterTypes.SKILLS}=${skill.slug}`);
                                            }}
                                            removeSlug={() => {}}
                                          />
                                        </li>
                                      )
                                    )}
                                </ul>
                              </div>

                              {vacancies.vacancieById.company &&
                                (vacancies.vacancieById.company.motivated ===
                                  1 ||
                                  vacancies.vacancieById.plan_id > 1) && (
                                  <div className="app-notification__wr js-app-notification">
                                    <div className="app-notification app-notification-motivated">
                                      <div className="app-notification__inner">
                                        <div className="app-notification__emojy">
                                          <AppIcon icon="emojy-happy" />
                                        </div>
                                        <div className="app-notification__label">
                                          <p>
                                            <span className="app-notification__title">
                                              Motivated Job{' '}
                                            </span>{' '}
                                            <span className="app-text-dot app-text-dot-notification"></span>{' '}
                                            {
                                              vacancies.vacancieById.company
                                                .name
                                            }{' '}
                                            is aggressively looking for talent!
                                            Apply soon!
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                )}
                            </MediaQuery>

                            <div className="app-job-details__info">
                              <div className="app-job-details-info-section">
                                <div
                                  id="overview"
                                  className="app-job-details-info-section-anchor"
                                ></div>
                                <div
                                  className="app-job-details-info-section__text"
                                  dangerouslySetInnerHTML={this.renderHTMLMarkup(
                                    vacancies.vacancieById.details
                                  )}
                                ></div>
                              </div>
                              {vacancies.vacancieById &&
                              craftData &&
                              !craftLoading ? (
                                <AppJobCraft
                                  companyName={
                                    vacancies.vacancieById.company.name
                                  }
                                  data={craftData}
                                  loading={craftLoading}
                                />
                              ) : (
                                ''
                              )}
                              {craftLoading ? (
                                <div className="app-craft-preloader">
                                  <AppSpinner
                                    size={25}
                                    spinnerColor={'#333'}
                                    spinnerWidth={2}
                                    visible={true}
                                  />
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          </React.Fragment>
                        )}
                      </div>
                    </div>

                    <div
                      id="interested"
                      ref={(ref) => (this.sidebar = ref)}
                      className={cn('app-job-details__content-right', {
                        hide: applyIsActive,
                      })}
                    >
                      {vacancies &&
                      vacancies.isFetching &&
                      vacancies.isFetching.related ? (
                        <div className="app-job-details__preloader">
                          <AppSpinner
                            size={25}
                            spinnerColor={'#333'}
                            spinnerWidth={2}
                            visible={true}
                          />
                        </div>
                      ) : vacancies.related.length > 0 ? (
                        <div
                          className="app-job-details__interest-list --relative"
                          style={{ marginTop: -this.state.paddingTop }}
                        >
                          <div className="app-jobs-interest-list__wr">
                            <AdWrapper
                              showMobileViewAlways={true}
                              sourceFilters={{
                                skills: vacancies.vacancieById.skills,
                                locations: vacancies.vacancieById.locations,
                                roles: [vacancies.vacancieById.role],
                                companies: [vacancies.vacancieById.company],
                              }}
                            />
                          </div>
                          <div className="app-jobs-interest-list__wr">
                            <div className="app-jobs-interest-list__title">
                              You also may be interested in
                            </div>
                            <ul className="app-jobs-interest-list">
                              {vacancies.related.map((item) => (
                                <li
                                  className="app-jobs-interest-list__item"
                                  key={item.id}
                                >
                                  <AppJobsCardInterest
                                    data={item}
                                    onClick={() => {
                                      const from =
                                        (history.location.state &&
                                          history.location.state.from) ||
                                        'FROMJOBDETAILS';
                                      history.push(
                                        `${PathTypes.JOBS}/${item.slug}`,
                                        {
                                          modal: true,
                                          related: true,
                                          from,
                                          vacancieById: item,
                                          prevRelated: vacancies.related,
                                        }
                                      );

                                      this.props.receiveVacancieById(null);
                                      this.props.setRelatedJobs([]);
                                      this.setState({ applyIsActive: false });
                                      this.handleVacancieById();
                                    }}
                                  />
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="app-job-details__interest-list"
                          style={{ right: this.browserScrollbarWidth || 0 }}
                        >
                          <div className="app-jobs-interest-list__wr">
                            <AdWrapper
                              showMobileViewAlways={true}
                              sourceFilters={{
                                skills: vacancies.vacancieById.skills,
                                locations: vacancies.vacancieById.locations,
                                roles: [vacancies.vacancieById.role],
                                companies: [vacancies.vacancieById.company],
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    {!applyIsActive && !this.props.staticPage ? (
                      <MediaQuery maxWidth={MediaTypes.LT}>
                        <AppFooter />
                      </MediaQuery>
                    ) : (
                      ''
                    )}
                  </React.Fragment>
                )}
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(AppJobDetailsPage));
