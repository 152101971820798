import React from 'react';
import cn from 'classnames';

import AppIcon from 'components/shared/AppIcon';
import AppButton from 'components/shared/AppButton';
import AppDebounceInput from 'components/shared/AppDebounceInput';

import './AppSerachField.scss';

const AppSerachField = (props) => {
  const [isFocused, setIsFocused] = React.useState(false);

  React.useEffect(() => {
    if (props.modal)
      document
        .querySelector('.app-modal-search .app-search-field input')
        .focus();
  }, []);

  return (
    <div
      className={cn(
        'app-search-field app-input-field',
        {
          [`app-input-field--${props.inputSize}`]: props.inputSize,
          'app-input-field--disabled': props.inputDisabled,
          'app-input-field--fixed': props.isInputFocused,
          '--dropdown': isFocused && props.value,
        },
        props.className
      )}
    >
      <div className="app-input-field__icon">
        <AppIcon icon={props.icon || 'search'} />
      </div>

      <AppDebounceInput
        className={cn('app-input', 'app-input-search')}
        type="Search"
        isDebounce={props.isDebounce}
        value={props.value}
        onChange={props.onChangeHandler}
        onKeyDown={props.onKeyDown}
        onFocus={() => {
          props.onInputFocus();
          setIsFocused(true);
        }}
        onBlur={(e) => {
          props.onInputBlur();
          setIsFocused(false);
        }}
        placeholder={
          !isFocused
            ? props.inputPlaceholder
            : props.deviceSize === 'mobile'
            ? props.inputPlaceholder
            : props.inputPlaceholderOnFocus
        }
        disabled={props.inputDisabled}
      />

      {props.btn &&
        ((window.innerWidth <= 768 && !isFocused) ||
          window.innerWidth > 768) && (
          <div className="app-search__btn">
            <AppButton
              title={props.btnTitle || 'Search'}
              type="submit"
              onClick={props.handleSubmit}
              defaultStyle="primary"
              loader={true}
              isDisabled={
                props.btnIsFetching &&
                (props.btnIsFetching.roles ||
                  props.btnIsFetching.skills ||
                  props.btnIsFetching.companies ||
                  props.btnIsFetching.locations ||
                  props.btnIsFetching.eventtypes)
              }
            />
          </div>
        )}
    </div>
  );
};

export default AppSerachField;
