import React, { Component } from 'react';
import { connect } from 'react-redux';

import { AppPanelRow, AppPanelTitle } from 'components/shared/AppPanel';
import AppDatepicker from 'components/AppDatepicker';

import { removeAllSlugs, addSlug } from 'actions/SlugsActions';

import * as SlugsTypes from 'constants/SlugsTypes';

import './AppFiltersPanelDate.scss';

const mapDispatchToProps = (dispatch) => ({
  removeAllSlugs: (slug) => dispatch(removeAllSlugs(slug)),
  addSlug: (slug) => dispatch(addSlug(slug)),
});

class AppFiltersPanelDate extends Component {
  onClearFilter = () => {
    const {
      removeAllSlugs,
      closeFilterPanel,
      handleSubmit,
      handleCount,
    } = this.props;

    removeAllSlugs(SlugsTypes.FROMUTC);
    closeFilterPanel && closeFilterPanel();
    handleSubmit && setTimeout(() => handleSubmit(), 500);
    handleCount && handleCount();
  };

  onDateChange = (slug) => {
    const {
      addSlug,
      analiticsTrigger,
      closeFilterPanel
    } = this.props;

    addSlug({ slugName: SlugsTypes.FROMUTC, slug });
    analiticsTrigger && analiticsTrigger();
    closeFilterPanel && closeFilterPanel();
  };

  render() {
    const {
      data,
      handleSubmit,
      handleCount,
    } = this.props;

    return (
      <div className="app-filters-panel-dropdown">
        <AppPanelRow mt={15} mb={15}>
          <AppDatepicker
            date={data.slugs[0]}
            handleCount={handleCount}
            handleSubmit={handleSubmit}
            handlerDate={this.onDateChange}
          />
        </AppPanelRow>

        {data && data.slugs && data.slugs[0] !== '' ? (
          <AppPanelRow mt={0}>
            <button onClick={this.onClearFilter}>
              <AppPanelTitle color="warn">Clear selection</AppPanelTitle>
            </button>
          </AppPanelRow>
        ) : (
            ''
          )}
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(AppFiltersPanelDate);
