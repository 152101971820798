import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

// import search from './search';
import initState from './initState';
import vacancies from './vacancies';
import roles from './roles';
import skills from './skills';
import companies from './companies';
import locations from './locations';
import seniorities from './seniorities';
import experience from './experience';
import filters from './filters';
import user from './user';
import system from './system';
import events from './events';
import eventtypes from './eventtypes';
import fromUTC from './fromUTC';
import coaches from './coaches';
import coachingtypes from './coachingtypes';
import coachingroles from './coachingroles';
import price from './price';

export default (history) =>
  combineReducers({
    initState,
    vacancies,
    roles,
    skills,
    seniorities,
    companies,
    locations,
    experience,
    filters,
    user,
    system,
    eventtypes,
    events,
    fromUTC,
    coaches,
    coachingroles,
    price,
    coachingtypes,
    router: connectRouter(history),
  });
