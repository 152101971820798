import axios from 'axios';

import * as types from 'constants/ActionTypes';
// import { setNotification } from "./SystemActions";
import { API_URL } from 'constants/PathTypes';
import { querySkillsKeywordsSearch } from './querys';

export function clearSkills() {
    return {
        type: types.CLEAR_SKILLS,
    };
};

export function receiveSkills(payload) {
    return {
        type: types.RECEIVE_SKILLS,
        payload
    };
};

export function setFetchingSkills(payload) {
    return {
        type: types.SET_FETCHING_SKILLS,
        payload
    };
};

export function setFetchingPopularSkills(payload) {
    return {
        type: types.SET_FETCHING_POPULAR_SKILLS,
        payload
    };
};

export function setFetchingInitStatePopularSkills(payload) {
    return {
        type: types.SET_FETCHING_INIT_STATE_POPULAR_SKILLS,
        payload
    };
};

export function receiveInitStatePopularSkills(payload) {
    return {
        type: types.RECEIVE_INIT_STATE_POPULAR_SKILLS,
        payload
    };
};

export function fetchInitStatePopularSkills() {
    return async (dispatch) => {
        dispatch(setFetchingPopularSkills(true));

        try {
            const response = await axios.get(`${API_URL}/skills/getTop`);

            dispatch(receiveInitStatePopularSkills(response.data));
            dispatch(setFetchingPopularSkills(false));
        } catch (error) {
            dispatch(setFetchingPopularSkills(false));
            // dispatch(setNotification({ message: error.message, type: 'info', status: 'error' }));
            throw error;
        }
    }
}

export function fetchSkillsByName({name, num}) {
    return async (dispatch) => {
        dispatch(setFetchingSkills(true));

        const query = querySkillsKeywordsSearch({name, num});

        try {
            const response = await axios.get(`${API_URL}/skills?filter=${encodeURIComponent(query)}`);

            dispatch(receiveSkills(response.data));
            dispatch(setFetchingSkills(false));
        } catch (error) {
            dispatch(setFetchingSkills(false));
            // dispatch(setNotification({ message: error.message, type: 'info', status: 'error' }));
            throw error;
        }
    }
}
