import React from 'react';
import './AppPanelRow.scss';

const AppPanelRow = (props) => {
    const { children, layout, mt, mb, pb, pt, margin } = props;
    
    return (
        <div className={ 'app-panel-row' + (margin ? ` app-panel-row--margin` : '') + (layout ? ` app-panel-row--${layout}` : '') + (mt !== undefined ? ` app-panel-row--mt-${mt}` : '') + (mb !== undefined ? ` app-panel-row--mb-${mb}` : '') + (pt !== undefined ? ` app-panel-row--pt-${pt}` : '') + (pb !== undefined ? ` app-panel-row--pb-${pb}` : '') }>
            { children }
        </div>
    ); 
}

export default AppPanelRow;
