import moment from 'moment-timezone';

// export const timezoneUtcOffset = moment().utcOffset() / 60;
// export const currentTimezone = moment.tz.guess();
// export const timezonesNames = moment.tz.names();

export const TIMEZONES = [
  ['UTC', 'Coordinated Universal Time'],
  ['Pacific/Niue', 'Niue Time'],
  ['Pacific/Pago_Pago', 'Samoa Standard Time'],
  ['Pacific/Honolulu', 'Hawaii-Aleutian Standard Time'],
  ['Pacific/Rarotonga', 'Cook Islands Standard Time'],
  ['Pacific/Tahiti', 'Tahiti Time'],
  ['Pacific/Marquesas', 'Marquesas Time'],
  ['America/Adak', 'Hawaii-Aleutian Time (Adak)'],
  ['Pacific/Gambier', 'Gambier Time'],
  ['America/Anchorage', 'Alaska Time - Anchorage'],
  ['America/Juneau', 'Alaska Time - Juneau'],
  ['America/Metlakatla', 'Alaska Time - Metlakatla'],
  ['America/Nome', 'Alaska Time - Nome'],
  ['America/Sitka', 'Alaska Time - Sitka'],
  ['America/Yakutat', 'Alaska Time - Yakutat'],
  ['Pacific/Pitcairn', 'Pitcairn Time'],
  ['America/Creston', 'Mountain Standard Time - Creston'],
  ['America/Dawson', 'Mountain Standard Time - Dawson'],
  ['America/Dawson_Creek', 'Mountain Standard Time - Dawson Creek'],
  ['America/Fort_Nelson', 'Mountain Standard Time - Fort Nelson'],
  ['America/Hermosillo', 'Mexican Pacific Standard Time'],
  ['America/Los_Angeles', 'Pacific Time - Los Angeles'],
  ['America/Phoenix', 'Mountain Standard Time - Phoenix'],
  ['America/Tijuana', 'Pacific Time - Tijuana'],
  ['America/Vancouver', 'Pacific Time - Vancouver'],
  ['America/Whitehorse', 'Mountain Standard Time - Whitehorse'],
  ['America/Belize', 'Central Standard Time - Belize'],
  ['America/Boise', 'Mountain Time - Boise'],
  ['America/Cambridge_Bay', 'Mountain Time - Cambridge Bay'],
  ['America/Chihuahua', 'Mexican Pacific Time - Chihuahua'],
  ['America/Costa_Rica', 'Central Standard Time - Costa Rica'],
  ['America/Denver', 'Mountain Time - Denver'],
  ['America/Edmonton', 'Mountain Time - Edmonton'],
  ['America/El_Salvador', 'Central Standard Time - El Salvador'],
  ['America/Guatemala', 'Central Standard Time - Guatemala'],
  ['America/Inuvik', 'Mountain Time - Inuvik'],
  ['America/Managua', 'Central Standard Time - Managua'],
  ['America/Mazatlan', 'Mexican Pacific Time - Mazatlan'],
  ['America/Ojinaga', 'Mountain Time - Ojinaga'],
  ['America/Regina', 'Central Standard Time - Regina'],
  ['America/Swift_Current', 'Central Standard Time - Swift Current'],
  ['America/Tegucigalpa', 'Central Standard Time - Tegucigalpa'],
  ['America/Yellowknife', 'Mountain Time - Yellowknife'],
  ['Pacific/Galapagos', 'Galapagos Time'],
  ['America/Atikokan', 'Eastern Standard Time - Atikokan'],
  ['America/Bahia_Banderas', 'Central Time - Bahia Banderas'],
  ['America/Bogota', 'Colombia Standard Time'],
  ['America/Cancun', 'Eastern Standard Time - Cancun'],
  ['America/Chicago', 'Central Time - Chicago'],
  ['America/Eirunepe', 'Acre Standard Time - Eirunepe'],
  ['America/Guayaquil', 'Ecuador Time'],
  ['America/Indiana/Knox', 'Central Time - Knox, Indiana'],
  ['America/Indiana/Tell_City', 'Central Time - Tell City, Indiana'],
  ['America/Jamaica', 'Eastern Standard Time - Jamaica'],
  ['America/Lima', 'Peru Standard Time'],
  ['America/Matamoros', 'Central Time - Matamoros'],
  ['America/Menominee', 'Central Time - Menominee'],
  ['America/Merida', 'Central Time - Merida'],
  ['America/Mexico_City', 'Central Time - Mexico City'],
  ['America/Monterrey', 'Central Time - Monterrey'],
  ['America/North_Dakota/Beulah', 'Central Time - Beulah, North Dakota'],
  ['America/North_Dakota/Center', 'Central Time - Center, North Dakota'],
  ['America/North_Dakota/New_Salem', 'Central Time - New Salem, North Dakota'],
  ['America/Panama', 'Eastern Standard Time - Panama'],
  ['America/Rainy_River', 'Central Time - Rainy River'],
  ['America/Rankin_Inlet', 'Central Time - Rankin Inlet'],
  ['America/Resolute', 'Central Time - Resolute'],
  ['America/Rio_Branco', 'Acre Standard Time - Rio Branco'],
  ['America/Winnipeg', 'Central Time - Winnipeg'],
  ['Pacific/Easter', 'Easter Island Time'],
  ['America/Asuncion', 'Paraguay Time'],
  ['America/Barbados', 'Atlantic Standard Time - Barbados'],
  ['America/Blanc-Sablon', 'Atlantic Standard Time - Blanc-Sablon'],
  ['America/Boa_Vista', 'Amazon Standard Time - Boa Vista'],
  ['America/Campo_Grande', 'Amazon Standard Time - Campo Grande'],
  ['America/Caracas', 'Venezuela Time'],
  ['America/Cuiaba', 'Amazon Standard Time - Cuiaba'],
  ['America/Curacao', 'Atlantic Standard Time - Curaçao'],
  ['America/Detroit', 'Eastern Time - Detroit'],
  ['America/Grand_Turk', 'Eastern Time - Grand Turk'],
  ['America/Guyana', 'Guyana Time'],
  ['America/Havana', 'Cuba Time'],
  ['America/Indiana/Indianapolis', 'Eastern Time - Indianapolis'],
  ['America/Indiana/Marengo', 'Eastern Time - Marengo, Indiana'],
  ['America/Indiana/Petersburg', 'Eastern Time - Petersburg, Indiana'],
  ['America/Indiana/Vevay', 'Eastern Time - Vevay, Indiana'],
  ['America/Indiana/Vincennes', 'Eastern Time - Vincennes, Indiana'],
  ['America/Indiana/Winamac', 'Eastern Time - Winamac, Indiana'],
  ['America/Iqaluit', 'Eastern Time - Iqaluit'],
  ['America/Kentucky/Louisville', 'Eastern Time - Louisville'],
  ['America/Kentucky/Monticello', 'Eastern Time - Monticello, Kentucky'],
  ['America/La_Paz', 'Bolivia Time'],
  ['America/Manaus', 'Amazon Standard Time - Manaus'],
  ['America/Martinique', 'Atlantic Standard Time - Martinique'],
  ['America/Nassau', 'Eastern Time - Nassau'],
  ['America/New_York', 'Eastern Time - New York'],
  ['America/Nipigon', 'Eastern Time - Nipigon'],
  ['America/Pangnirtung', 'Eastern Time - Pangnirtung'],
  ['America/Port-au-Prince', 'Eastern Time - Port-au-Prince'],
  ['America/Port_of_Spain', 'Atlantic Standard Time - Port of Spain'],
  ['America/Porto_Velho', 'Amazon Standard Time - Porto Velho'],
  ['America/Puerto_Rico', 'Atlantic Standard Time - Puerto Rico'],
  ['America/Santo_Domingo', 'Atlantic Standard Time - Santo Domingo'],
  ['America/Thunder_Bay', 'Eastern Time - Thunder Bay'],
  ['America/Toronto', 'Eastern Time - Toronto'],
  ['America/Araguaina', 'Brasilia Standard Time - Araguaina'],
  ['America/Argentina/Buenos_Aires', 'Argentina Standard Time - Buenos Aires'],
  ['America/Argentina/Catamarca', 'Argentina Standard Time - Catamarca'],
  ['America/Argentina/Cordoba', 'Argentina Standard Time - Cordoba'],
  ['America/Argentina/Jujuy', 'Argentina Standard Time - Jujuy'],
  ['America/Argentina/La_Rioja', 'Argentina Standard Time - La Rioja'],
  ['America/Argentina/Mendoza', 'Argentina Standard Time - Mendoza'],
  ['America/Argentina/Rio_Gallegos', 'Argentina Standard Time - Rio Gallegos'],
  ['America/Argentina/Salta', 'Argentina Standard Time - Salta'],
  ['America/Argentina/San_Juan', 'Argentina Standard Time - San Juan'],
  ['America/Argentina/San_Luis', 'Argentina Standard Time - San Luis'],
  ['America/Argentina/Tucuman', 'Argentina Standard Time - Tucuman'],
  ['America/Argentina/Ushuaia', 'Argentina Standard Time - Ushuaia'],
  ['America/Bahia', 'Brasilia Standard Time - Bahia'],
  ['America/Belem', 'Brasilia Standard Time - Belem'],
  ['America/Cayenne', 'French Guiana Time'],
  ['America/Fortaleza', 'Brasilia Standard Time - Fortaleza'],
  ['America/Glace_Bay', 'Atlantic Time - Glace Bay'],
  ['America/Goose_Bay', 'Atlantic Time - Goose Bay'],
  ['America/Halifax', 'Atlantic Time - Halifax'],
  ['America/Maceio', 'Brasilia Standard Time - Maceio'],
  ['America/Moncton', 'Atlantic Time - Moncton'],
  ['America/Montevideo', 'Uruguay Standard Time'],
  ['America/Paramaribo', 'Suriname Time'],
  ['America/Punta_Arenas', 'Punta Arenas Time'],
  ['America/Recife', 'Brasilia Standard Time - Recife'],
  ['America/Santarem', 'Brasilia Standard Time - Santarem'],
  ['America/Santiago', 'Chile Time'],
  ['America/Sao_Paulo', 'Brasilia Standard Time - Sao Paulo'],
  ['America/Thule', 'Atlantic Time - Thule'],
  ['Antarctica/Palmer', 'Palmer Time'],
  ['Antarctica/Rothera', 'Rothera Time'],
  ['Atlantic/Bermuda', 'Atlantic Time - Bermuda'],
  ['Atlantic/Stanley', 'Falkland Islands Standard Time'],
  ['America/St_Johns', 'Newfoundland Time'],
  ['America/Miquelon', 'St. Pierre & Miquelon Time'],
  ['America/Noronha', 'Fernando de Noronha Standard Time'],
  ['America/Nuuk', 'West Greenland Time'],
  ['Atlantic/South_Georgia', 'South Georgia Time'],
  ['Atlantic/Cape_Verde', 'Cape Verde Standard Time'],
  ['Africa/Abidjan', 'Greenwich Mean Time - Abidjan'],
  ['Africa/Accra', 'Greenwich Mean Time - Accra'],
  ['Africa/Bissau', 'Greenwich Mean Time - Bissau'],
  ['Africa/Monrovia', 'Greenwich Mean Time - Monrovia'],
  ['Africa/Sao_Tome', 'Greenwich Mean Time - São Tomé'],
  ['America/Danmarkshavn', 'Greenwich Mean Time - Danmarkshavn'],
  ['America/Scoresbysund', 'East Greenland Time'],
  ['Atlantic/Azores', 'Azores Time'],
  ['Atlantic/Reykjavik', 'Greenwich Mean Time - Reykjavik'],
  ['Etc/GMT', 'Greenwich Mean Time'],
  ['Africa/Algiers', 'Central European Standard Time - Algiers'],
  ['Africa/Casablanca', 'Morocco Time'],
  ['Africa/El_Aaiun', 'Western Sahara Time'],
  ['Africa/Lagos', 'West Africa Standard Time - Lagos'],
  ['Africa/Ndjamena', 'West Africa Standard Time - Ndjamena'],
  ['Africa/Tunis', 'Central European Standard Time - Tunis'],
  ['Atlantic/Canary', 'Western European Time - Canary'],
  ['Atlantic/Faroe', 'Western European Time - Faroe'],
  ['Atlantic/Madeira', 'Western European Time - Madeira'],
  ['Europe/Dublin', 'Ireland Time'],
  ['Europe/Lisbon', 'Western European Time - Lisbon'],
  ['Europe/London', 'United Kingdom Time'],
  ['Africa/Cairo', 'Eastern European Standard Time - Cairo'],
  ['Africa/Ceuta', 'Central European Time - Ceuta'],
  ['Africa/Johannesburg', 'South Africa Standard Time'],
  ['Africa/Khartoum', 'Central Africa Time - Khartoum'],
  ['Africa/Maputo', 'Central Africa Time - Maputo'],
  ['Africa/Tripoli', 'Eastern European Standard Time - Tripoli'],
  ['Africa/Windhoek', 'Central Africa Time - Windhoek'],
  ['Antarctica/Troll', 'Troll Time'],
  ['Europe/Amsterdam', 'Central European Time - Amsterdam'],
  ['Europe/Andorra', 'Central European Time - Andorra'],
  ['Europe/Belgrade', 'Central European Time - Belgrade'],
  ['Europe/Berlin', 'Central European Time - Berlin'],
  ['Europe/Brussels', 'Central European Time - Brussels'],
  ['Europe/Budapest', 'Central European Time - Budapest'],
  ['Europe/Copenhagen', 'Central European Time - Copenhagen'],
  ['Europe/Gibraltar', 'Central European Time - Gibraltar'],
  ['Europe/Kaliningrad', 'Eastern European Standard Time - Kaliningrad'],
  ['Europe/Luxembourg', 'Central European Time - Luxembourg'],
  ['Europe/Madrid', 'Central European Time - Madrid'],
  ['Europe/Malta', 'Central European Time - Malta'],
  ['Europe/Monaco', 'Central European Time - Monaco'],
  ['Europe/Oslo', 'Central European Time - Oslo'],
  ['Europe/Paris', 'Central European Time - Paris'],
  ['Europe/Prague', 'Central European Time - Prague'],
  ['Europe/Rome', 'Central European Time - Rome'],
  ['Europe/Stockholm', 'Central European Time - Stockholm'],
  ['Europe/Tirane', 'Central European Time - Tirane'],
  ['Europe/Vienna', 'Central European Time - Vienna'],
  ['Europe/Warsaw', 'Central European Time - Warsaw'],
  ['Europe/Zurich', 'Central European Time - Zurich'],
  ['Africa/Juba', 'East Africa Time - Juba'],
  ['Africa/Nairobi', 'East Africa Time - Nairobi'],
  ['Antarctica/Syowa', 'Syowa Time'],
  ['Asia/Amman', 'Eastern European Time - Amman'],
  ['Asia/Baghdad', 'Arabian Standard Time - Baghdad'],
  ['Asia/Beirut', 'Eastern European Time - Beirut'],
  ['Asia/Damascus', 'Eastern European Time - Damascus'],
  ['Asia/Famagusta', 'Famagusta Time'],
  ['Asia/Gaza', 'Eastern European Time - Gaza'],
  ['Asia/Hebron', 'Eastern European Time - Hebron'],
  ['Asia/Jerusalem', 'Israel Time'],
  ['Asia/Nicosia', 'Eastern European Time - Nicosia'],
  ['Asia/Qatar', 'Arabian Standard Time - Qatar'],
  ['Asia/Riyadh', 'Arabian Standard Time - Riyadh'],
  ['Europe/Athens', 'Eastern European Time - Athens'],
  ['Europe/Bucharest', 'Eastern European Time - Bucharest'],
  ['Europe/Chisinau', 'Eastern European Time - Chisinau'],
  ['Europe/Helsinki', 'Eastern European Time - Helsinki'],
  ['Europe/Istanbul', 'Turkey Time'],
  ['Europe/Kiev', 'Eastern European Time - Kiev'],
  ['Europe/Kirov', 'Kirov Time'],
  ['Europe/Minsk', 'Moscow Standard Time - Minsk'],
  ['Europe/Moscow', 'Moscow Standard Time - Moscow'],
  ['Europe/Riga', 'Eastern European Time - Riga'],
  ['Europe/Simferopol', 'Moscow Standard Time - Simferopol'],
  ['Europe/Sofia', 'Eastern European Time - Sofia'],
  ['Europe/Tallinn', 'Eastern European Time - Tallinn'],
  ['Europe/Uzhgorod', 'Eastern European Time - Uzhhorod'],
  ['Europe/Vilnius', 'Eastern European Time - Vilnius'],
  ['Europe/Zaporozhye', 'Eastern European Time - Zaporozhye'],
  ['Asia/Baku', 'Azerbaijan Standard Time'],
  ['Asia/Dubai', 'Gulf Standard Time'],
  ['Asia/Tbilisi', 'Georgia Standard Time'],
  ['Asia/Yerevan', 'Armenia Standard Time'],
  ['Europe/Astrakhan', 'Astrakhan Time'],
  ['Europe/Samara', 'Samara Standard Time'],
  ['Europe/Saratov', 'Saratov Time'],
  ['Europe/Ulyanovsk', 'Ulyanovsk Time'],
  ['Europe/Volgograd', 'Volgograd Standard Time'],
  ['Indian/Mahe', 'Seychelles Time'],
  ['Indian/Mauritius', 'Mauritius Standard Time'],
  ['Indian/Reunion', 'Réunion Time'],
  ['Asia/Kabul', 'Afghanistan Time'],
  ['Asia/Tehran', 'Iran Time'],
  ['Antarctica/Mawson', 'Mawson Time'],
  ['Asia/Aqtau', 'West Kazakhstan Time - Aqtau'],
  ['Asia/Aqtobe', 'West Kazakhstan Time - Aqtobe'],
  ['Asia/Ashgabat', 'Turkmenistan Standard Time'],
  ['Asia/Atyrau', 'West Kazakhstan Time - Atyrau'],
  ['Asia/Dushanbe', 'Tajikistan Time'],
  ['Asia/Karachi', 'Pakistan Standard Time'],
  ['Asia/Oral', 'West Kazakhstan Time - Oral'],
  ['Asia/Qyzylorda', 'West Kazakhstan Time - Qyzylorda'],
  ['Asia/Samarkand', 'Uzbekistan Standard Time - Samarkand'],
  ['Asia/Tashkent', 'Uzbekistan Standard Time - Tashkent'],
  ['Asia/Yekaterinburg', 'Yekaterinburg Standard Time'],
  ['Indian/Kerguelen', 'French Southern & Antarctic Time'],
  ['Indian/Maldives', 'Maldives Time'],
  ['Asia/Colombo', 'India Standard Time - Colombo'],
  ['Asia/Kolkata', 'India Standard Time - Kolkata'],
  ['Asia/Kathmandu', 'Nepal Time'],
  ['Antarctica/Vostok', 'Vostok Time'],
  ['Asia/Almaty', 'East Kazakhstan Time - Almaty'],
  ['Asia/Bishkek', 'Kyrgyzstan Time'],
  ['Asia/Dhaka', 'Bangladesh Standard Time'],
  ['Asia/Omsk', 'Omsk Standard Time'],
  ['Asia/Qostanay', 'East Kazakhstan Time - Kostanay'],
  ['Asia/Thimphu', 'Bhutan Time'],
  ['Asia/Urumqi', 'Urumqi Time'],
  ['Indian/Chagos', 'Indian Ocean Time'],
  ['Asia/Yangon', 'Myanmar Time'],
  ['Indian/Cocos', 'Cocos Islands Time'],
  ['Antarctica/Davis', 'Davis Time'],
  ['Asia/Bangkok', 'Indochina Time - Bangkok'],
  ['Asia/Barnaul', 'Barnaul Time'],
  ['Asia/Ho_Chi_Minh', 'Indochina Time - Ho Chi Minh City'],
  ['Asia/Hovd', 'Hovd Standard Time'],
  ['Asia/Jakarta', 'Western Indonesia Time - Jakarta'],
  ['Asia/Krasnoyarsk', 'Krasnoyarsk Standard Time - Krasnoyarsk'],
  ['Asia/Novokuznetsk', 'Krasnoyarsk Standard Time - Novokuznetsk'],
  ['Asia/Novosibirsk', 'Novosibirsk Standard Time'],
  ['Asia/Pontianak', 'Western Indonesia Time - Pontianak'],
  ['Asia/Tomsk', 'Tomsk Time'],
  ['Indian/Christmas', 'Christmas Island Time'],
  ['Antarctica/Casey', 'Australian Western Standard Time - Casey'],
  ['Asia/Brunei', 'Brunei Darussalam Time'],
  ['Asia/Choibalsan', 'Ulaanbaatar Standard Time - Choibalsan'],
  ['Asia/Hong_Kong', 'Hong Kong Standard Time'],
  ['Asia/Irkutsk', 'Irkutsk Standard Time'],
  ['Asia/Kuala_Lumpur', 'Malaysia Time - Kuala Lumpur'],
  ['Asia/Kuching', 'Malaysia Time - Kuching'],
  ['Asia/Macau', 'China Standard Time - Macao'],
  ['Asia/Makassar', 'Central Indonesia Time'],
  ['Asia/Manila', 'Philippine Standard Time'],
  ['Asia/Shanghai', 'China Standard Time - Shanghai'],
  ['Asia/Singapore', 'Singapore Standard Time'],
  ['Asia/Taipei', 'Taipei Standard Time'],
  ['Asia/Ulaanbaatar', 'Ulaanbaatar Standard Time - Ulaanbaatar'],
  ['Australia/Perth', 'Australian Western Standard Time - Perth'],
  ['Australia/Eucla', 'Australian Central Western Standard Time'],
  ['Asia/Chita', 'Yakutsk Standard Time - Chita'],
  ['Asia/Dili', 'East Timor Time'],
  ['Asia/Jayapura', 'Eastern Indonesia Time'],
  ['Asia/Khandyga', 'Yakutsk Standard Time - Khandyga'],
  ['Asia/Pyongyang', 'Korean Standard Time - Pyongyang'],
  ['Asia/Seoul', 'Korean Standard Time - Seoul'],
  ['Asia/Tokyo', 'Japan Standard Time'],
  ['Asia/Yakutsk', 'Yakutsk Standard Time - Yakutsk'],
  ['Pacific/Palau', 'Palau Time'],
  ['Australia/Adelaide', 'Central Australia Time - Adelaide'],
  ['Australia/Broken_Hill', 'Central Australia Time - Broken Hill'],
  ['Australia/Darwin', 'Australian Central Standard Time'],
  ['Antarctica/DumontDUrville', 'Dumont-d’Urville Time'],
  ['Asia/Ust-Nera', 'Vladivostok Standard Time - Ust-Nera'],
  ['Asia/Vladivostok', 'Vladivostok Standard Time - Vladivostok'],
  ['Australia/Brisbane', 'Australian Eastern Standard Time - Brisbane'],
  ['Australia/Currie', 'Eastern Australia Time - Currie'],
  ['Australia/Hobart', 'Eastern Australia Time - Hobart'],
  ['Australia/Lindeman', 'Australian Eastern Standard Time - Lindeman'],
  ['Australia/Melbourne', 'Eastern Australia Time - Melbourne'],
  ['Australia/Sydney', 'Eastern Australia Time - Sydney'],
  ['Pacific/Chuuk', 'Chuuk Time'],
  ['Pacific/Guam', 'Chamorro Standard Time'],
  ['Pacific/Port_Moresby', 'Papua New Guinea Time'],
  ['Australia/Lord_Howe', 'Lord Howe Time'],
  ['Antarctica/Macquarie', 'Macquarie Island Time'],
  ['Asia/Magadan', 'Magadan Standard Time'],
  ['Asia/Sakhalin', 'Sakhalin Standard Time'],
  ['Asia/Srednekolymsk', 'Srednekolymsk Time'],
  ['Pacific/Bougainville', 'Bougainville Time'],
  ['Pacific/Efate', 'Vanuatu Standard Time'],
  ['Pacific/Guadalcanal', 'Solomon Islands Time'],
  ['Pacific/Kosrae', 'Kosrae Time'],
  ['Pacific/Norfolk', 'Norfolk Island Time'],
  ['Pacific/Noumea', 'New Caledonia Standard Time'],
  ['Pacific/Pohnpei', 'Ponape Time'],
  ['Asia/Anadyr', 'Anadyr Standard Time'],
  ['Asia/Kamchatka', 'Petropavlovsk-Kamchatski Standard Time'],
  ['Pacific/Auckland', 'New Zealand Time'],
  ['Pacific/Fiji', 'Fiji Time'],
  ['Pacific/Funafuti', 'Tuvalu Time'],
  ['Pacific/Kwajalein', 'Marshall Islands Time - Kwajalein'],
  ['Pacific/Majuro', 'Marshall Islands Time - Majuro'],
  ['Pacific/Nauru', 'Nauru Time'],
  ['Pacific/Tarawa', 'Gilbert Islands Time'],
  ['Pacific/Wake', 'Wake Island Time'],
  ['Pacific/Wallis', 'Wallis & Futuna Time'],
  ['Pacific/Chatham', 'Chatham Time'],
  ['Pacific/Apia', 'Apia Time'],
  ['Pacific/Enderbury', 'Phoenix Islands Time'],
  ['Pacific/Fakaofo', 'Tokelau Time'],
  ['Pacific/Tongatapu', 'Tonga Standard Time'],
  ['Pacific/Kiritimati', 'Line Islands Time'],
];

export const ZONES_OPTIONS = TIMEZONES.map((zone) => {
  const utc = moment.tz(zone[0]).format('Z');

  return {
    id: zone[0],
    value: `(GMT ${utc}) ${zone[1]}`,
    name: zone[1],
    label: `(GMT ${utc}) ${zone[1]}`,
    utc,
  };
});
