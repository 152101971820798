import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  EmailShareButton,
} from 'react-share';

import AppIcon from 'components/shared/AppIcon';

import { setNotification, clearNotification } from 'actions/SystemActions';

import './AppShared.scss';

const mapDispatchToProps = (dispatch) => ({
  setNotification: (payload) => dispatch(setNotification(payload)),
  clearNotification: (payload) => dispatch(clearNotification(payload)),
});

const AppShared = ({
  list,
  setNotification,
  clearNotification,
  defaultStyle,
}) => {
  const copyTextToClipboard = (text) => {
    const textArea = document.createElement('textarea');

    textArea.style.position = 'fixed';
    textArea.style.top = '-9999px';
    textArea.style.left = '-9999px';
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = 0;
    textArea.style.background = 'rgba(0, 0, 0, 0)';
    textArea.value = text;
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand('copy');
      setNotification({
        message: 'Copy Link success',
        type: 'info',
        status: 'info',
      });
    } catch (err) {
      setNotification({
        message: 'Copy Link error',
        type: 'info',
        status: 'info',
      });
    }

    setTimeout(() => clearNotification(), 5000);
    document.body.removeChild(textArea);
  };

  const renderShareBtn = (item) => {
    switch (item.title) {
      case 'Facebook':
        return (
          <FacebookShareButton quote={item.shareTitle} url={item.url}>
            <SharedButton item={item} />
          </FacebookShareButton>
        );

      case 'Twitter':
        return (
          <TwitterShareButton title={item.shareTitle} url={item.url}>
            <SharedButton item={item} />
          </TwitterShareButton>
        );

      case 'Linkedin':
        return (
          <LinkedinShareButton url={item.url}>
            <SharedButton item={item} />
          </LinkedinShareButton>
        );

      case 'E-mail':
        return (
          <EmailShareButton subject={item.shareTitle} url={item.url}>
            <SharedButton item={item} />
          </EmailShareButton>
        );

      case 'Copy Link':
        return (
          <SharedButton
            onClick={() => copyTextToClipboard(item.url)}
            item={item}
          />
        );

      default:
        break;
    }
  };

  return (
    <div
      className={cn('app-shared-menu', { [`--${defaultStyle}`]: defaultStyle })}
    >
      <div className="app-shared-menu__title">share on</div>

      <ul className="app-shared-menu__list">
        {list.map((item, idx) => (
          <li key={idx}>{renderShareBtn(item)}</li>
        ))}
      </ul>
    </div>
  );
};

const SharedButton = ({ item, onClick }) => (
  <div
    onClick={() => {
      if (window.gtag) {
        window.gtag('event', 'Share Job', { label: item.title });
      }
      onClick && onClick();
    }}
    className={`app-shared-menu__item app-shared-menu__item--${item.title}`}
    style={{ color: item.color }}
  >
    <span className="app-shared-menu__item-icon">
      <AppIcon icon={item.icon} />
    </span>
    <span className="app-shared-menu__item-title">{item.title}</span>
  </div>
);

export default connect(null, mapDispatchToProps)(AppShared);
