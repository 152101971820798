import * as ActionTypes from 'constants/ActionTypes';
import { LOCATION_CHANGE } from 'connected-react-router';

const initialState = {
  name: 'companies',
  list: [],
  populars: [],
  motivated: [],
  slugs: [],
  isFetching: {
    list: false,
    populars: false,
    slugs: false,
    copmpanyByName: false,
  },
};

export default function companies(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      if (
        action.payload &&
        action.payload.location.state &&
        action.payload.location.state.filters
      ) {
        return Object.assign({}, state, {
          ...state,
          slugs: action.payload.location.state.filters.companies,
        });
      }

      return state;

    case ActionTypes.RECEIVE_INIT_STATE_POPULAR_COMPANIES:
      return Object.assign({}, state, {
        ...state,
        populars: action.payload,
      });

    case ActionTypes.SET_FETCHING_INIT_STATE_POPULAR_COMPANIES:
      return Object.assign({}, state, {
        ...state,
        isFetching: {
          ...state.isFetching,
          populars: action.payload,
        },
      });

    case ActionTypes.SET_FILTER_STATE:
      return Object.assign({}, state, {
        ...state,
        slugs: action.payload.companies,
      });

    case ActionTypes.SET_FETCHING_COMPANIES:
      return Object.assign({}, state, {
        isFetching: {
          ...state.isFetching,
          list: action.payload,
        },
      });

    case ActionTypes.SET_FETCHING_GET_COMPANIES_BY_NAME:
      return Object.assign({}, state, {
        isFetching: {
          ...state.isFetching,
          copmpanyByName: action.payload,
        },
      });

    case ActionTypes.SET_FETCHING_POPULAR_COMPANIES:
      return Object.assign({}, state, {
        ...state,
        isFetching: {
          ...state.isFetching,
          populars: action.payload,
        },
      });

    case ActionTypes.RECEIVE_COMPANIES:
      return Object.assign({}, state, {
        list: action.payload,
      });

    case ActionTypes.CLEAR_COMPANIES:
      return Object.assign({}, state, {
        list: [],
      });

    case ActionTypes.ADD_COMPANY_SLUG:
      const slug = state.slugs.slice();

      if (!slug.some((slug) => action.payload.id === slug.id))
        slug.push(action.payload);

      return Object.assign({}, state, {
        slugs: slug,
      });

    case ActionTypes.REMOVE_COMPANY_SLUG:
      return Object.assign({}, state, {
        slugs: state.slugs.filter((item) => item.id !== action.payload.id),
      });

    case ActionTypes.REMOVE_ALL_COMPANIES_SLUGS:
    case ActionTypes.REMOVE_ALL_SLUGS:
      return Object.assign({}, state, {
        slugs: [],
      });

    default:
      return state;
  }
}
