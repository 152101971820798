import React from 'react';
import cn from 'classnames';

import AppIcon from 'components/shared/AppIcon';

import './CardAddress.scss';

export const CardAddress = function ({ address, truncate = false }) {
  return (
    <div className="card-address" title={address}>
      <div className="app-jobs-card__icon">
        <AppIcon icon="geo" color="gray" />
      </div>
      <div className={cn("card-address-item", { "--truncate": truncate })} >{address}</div>
    </div>
  );
};

export default CardAddress;
