import axios from 'axios';
import Cookies from 'js-cookie';

import { unauthUser } from 'actions/UserActions';
import {
  setNotification,
  clearNotification,
  setUploadProgress,
  setLoading,
  setModal,
} from 'actions/SystemActions';

import { API_URL } from 'constants/PathTypes';
import * as ActionTypes from 'constants/ActionTypes';

import * as PathTypes from 'constants/PathTypes';

import { createSearchQuery, queryKeywordsSearch } from 'actions/querys';

import history from 'history.js';

export function updateCoaches(payload) {
  return {
    type: ActionTypes.UPDATE_COACHES,
    payload,
  };
}

export function receiveCoaches(payload) {
  return {
    type: ActionTypes.RECEIVE_COACHES,
    payload,
  };
}

export function setFetchingCoaches(payload) {
  return {
    type: ActionTypes.SET_FETCHING_COACHES,
    payload,
  };
}

export function fetchCoachesByFilters(params, isSetStore = true) {
  return async (dispatch) => {
    dispatch(setFetchingCoaches(true));

    const query = createSearchQuery(params, 'filters');

    try {
      const response = await axios.get(
        `${API_URL}/coaches/search?query=${encodeURIComponent(query)}`,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      if (isSetStore) {
        dispatch(receiveCoaches(response.data));
      }
      dispatch(setFetchingCoaches(false));

      return response.data;
    } catch (error) {
      const { message, statusCode } = error.payload.data.error;

      if (statusCode === 401) dispatch(unauthUser());

      dispatch(setFetchingCoaches(false));
      dispatch(setNotification({ message, type: 'info', status: 'info' }));
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function setFetchingCoachesCountAll(payload) {
  return {
    type: ActionTypes.SET_FETCHING_COACHES_COUNT_ALL,
    payload,
  };
}

export function receiveCoachesCountAll(payload) {
  return {
    type: ActionTypes.RECEIVE_COACHES_COUNT_ALL,
    payload,
  };
}

export function fetchCoachesCountAll(params) {
  return async (dispatch) => {
    dispatch(setFetchingCoachesCountAll(true));

    const query = createSearchQuery(params, 'count');

    try {
      const response = await axios.get(
        `${API_URL}/coaches/searchCount?query=${encodeURIComponent(query)}`,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(receiveCoachesCountAll(response.data.count));
      dispatch(setFetchingCoachesCountAll(false));

      return response.data.count;
    } catch (error) {
      const { statusCode } = error.payload.data;

      if (statusCode === 401) dispatch(unauthUser());
      dispatch(setFetchingCoachesCountAll(false));

      throw error;
    }
  };
}

export function fetchMoreCoachesByFilters(params) {
  return async (dispatch) => {
    dispatch(setFetchingCoaches(true));

    const query = createSearchQuery(params, 'filters');

    try {
      const response = await axios.get(
        `${API_URL}/coaches/search?query=${encodeURIComponent(query)}`,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(updateCoaches(response.data));
      dispatch(setFetchingCoaches(false));
    } catch (error) {
      dispatch(setFetchingCoaches(false));
      const { statusCode } = error.payload.data.error;

      if (statusCode === 401) dispatch(unauthUser());
      throw error;
    }
  };
}

export function setFetchingCoachById(payload) {
  return {
    type: ActionTypes.SET_FETCHING_COACH_BY_ID,
    payload,
  };
}

export function receiveCoachById(payload) {
  return {
    type: ActionTypes.RECEIVE_COACH_BY_ID,
    payload,
  };
}

export function fetchCoachById(id) {
  return async (dispatch) => {
    dispatch(setFetchingCoachById(true));

    try {
      const response = await axios.get(`${API_URL}/coaches/${id}`, {
        headers: { authorization: localStorage.getItem('token') },
      });

      dispatch(setFetchingCoachById(false));
      return response.data;
    } catch (error) {
      const { message, statusCode } = error.payload.data.error;

      if (statusCode === 401) dispatch(unauthUser());
      if (statusCode === 404) history.push(PathTypes.PAGE404);

      dispatch(setFetchingCoachById(false));
      dispatch(setNotification({ message, type: 'info', status: 'info' }));
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function setFetchingCoachByIdUpdate(payload) {
  return {
    type: ActionTypes.SET_FETCHING_COACH_BY_ID_UPDATE,
    payload,
  };
}

export function receiveCoachByIdUpdate(payload) {
  return {
    type: ActionTypes.RECEIVE_COACH_BY_ID_UPDATE,
    payload,
  };
}

export function fetchCoachByIdUpdate(id) {
  return async (dispatch) => {
    dispatch(setFetchingCoachByIdUpdate(true));

    try {
      const response = await axios.get(`${API_URL}/coaches/${id}/update`, {
        headers: { authorization: localStorage.getItem('token') },
      });

      dispatch(setFetchingCoachByIdUpdate(false));
      return response.data;
    } catch (error) {
      const { statusCode } = error.payload.data.error;

      if (statusCode === 401) dispatch(unauthUser());
      dispatch(setFetchingCoachByIdUpdate(false));
      throw error;
    }
  };
}

export function setLoadingRelatedCoaches(payload) {
  return {
    type: ActionTypes.SET_LOADING_RELATED_COACHES,
    payload,
  };
}

export function setRelatedCoaches(payload) {
  return {
    type: ActionTypes.SET_RELATED_COACHES,
    payload,
  };
}

export function fetchRelatedCoachesByData({ data }) {
  return async (dispatch) => {
    dispatch(setLoadingRelatedCoaches(true));

    try {
      const response = await axios.post(`${API_URL}/coaches/related`, data, {
        headers: { authorization: localStorage.getItem('token') },
      });

      dispatch(setRelatedCoaches(response.data));
      dispatch(setLoadingRelatedCoaches(false));

      return response.data;
    } catch (response) {
      const { error } = response.payload.data;

      if (error.statusCode === 401) dispatch(unauthUser());

      dispatch(setLoadingRelatedCoaches(false));
      dispatch(
        setNotification({
          message: error.message,
          status: 'info',
          type: 'info',
        })
      );
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function setFetchingCoachByUrl(payload) {
  return {
    type: ActionTypes.SET_FETCHING_COACH_BY_ID,
    payload,
  };
}

export function fetchCoachByUrl(url) {
  return async (dispatch) => {
    dispatch(setFetchingCoachByUrl(true));

    try {
      const response = await axios.get(`${API_URL}/coaches/findByUrl/${url}`, {
        headers: { authorization: localStorage.getItem('token') },
      });

      dispatch(setFetchingCoachByUrl(false));
      return response.data;
    } catch (error) {
      const { message, statusCode } = error.payload.data.error;

      if (statusCode === 401) dispatch(unauthUser());
      if (statusCode === 404) history.push(PathTypes.PAGE404);

      dispatch(setFetchingCoachByUrl(false));
      dispatch(setNotification({ message, type: 'info', status: 'info' }));
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function setCoachHeadline(payload) {
  return {
    type: ActionTypes.SET_COACH_HEADLINE,
    payload,
  };
}

export function setCoachName(payload) {
  return {
    type: ActionTypes.SET_COACH_NAME,
    payload,
  };
}

export function setCoachCachedUrl(payload) {
  return {
    type: ActionTypes.SET_COACH_CAHCED_URL,
    payload,
  };
}

export function setCoachSurname(payload) {
  return {
    type: ActionTypes.SET_COACH_SURNAME,
    payload,
  };
}

export function setCoachLocation(payload) {
  return {
    type: ActionTypes.SET_COACH_LOCATION,
    payload,
  };
}

export function setUserStoreCoachCoverImage(payload) {
  return {
    type: ActionTypes.SET_USER_COACH_COVER_IMAGE,
    payload,
  };
}

export const uploadCoachCoverLogo = (file) => {
  return async (dispatch) => {
    dispatch(clearNotification());
    dispatch(setLoading(true));

    try {
      const response = await axios.post(`${API_URL}/coaches/cover`, file, {
        headers: {
          authorization: localStorage.getItem('token'),
        },
        onUploadProgress: (progressEvent) => {
          const total = progressEvent.total;
          const loaded = progressEvent.loaded;
          const percent = (100 / total) * loaded;

          dispatch(setUploadProgress(percent));
        },
      });

      dispatch(setLoading(false));
      dispatch(
        setNotification({
          message: 'File uploaded!',
          type: 'info',
          status: 'info',
        })
      );

      setTimeout(() => dispatch(clearNotification()), 5000);
      return response.data;
    } catch (error) {
      const { message, statusCode } = error.payload.data.error;

      if (statusCode === 401) dispatch(unauthUser());

      dispatch(setLoading(false));
      if (message) {
        dispatch(
          setNotification({ message: message, type: 'info', status: 'info' })
        );
        setTimeout(() => dispatch(clearNotification()), 5000);
      }

      throw error;
    }
  };
};

export function setCoachDetailsHtml(payload) {
  return {
    type: ActionTypes.SET_COACH_DETAILS_HTML,
    payload,
  };
}

export function setCoachCurrentCompany(payload) {
  return {
    type: ActionTypes.SET_COACH_CURRENT_COMPANY,
    payload,
  };
}

export function setCoachCurrentCompanyTitle(payload) {
  return {
    type: ActionTypes.SET_COACH_CURRENT_COMPANY_TITLE,
    payload,
  };
}

export function removeCoachPreviousCompany(payload) {
  return {
    type: ActionTypes.REMOVE_COACH_PREVIOUS_COMPANY,
    payload,
  };
}

export function setCoachPreviousCompaniesTitle(payload) {
  return {
    type: ActionTypes.SET_COACH_PREVIOUS_COMPANIES_TITLE,
    payload,
  };
}

export function addCoachPreviousCompany(payload) {
  return {
    type: ActionTypes.ADD_COACH_PREVIOUS_COMPANY,
    payload,
  };
}

export function setCoachUrl(payload) {
  return {
    type: ActionTypes.SET_COACH_URL,
    payload,
  };
}

export function setCoachSocialLinks(payload) {
  return {
    type: ActionTypes.SET_COACH_SOCIAL_LINKS,
    payload,
  };
}

export function receiveIsCoachUrlExist(payload) {
  return {
    type: ActionTypes.RECEIVE_IS_COACH_URL_EXIST,
    payload,
  };
}

export function setFetchingIsCoachUrlExist(payload) {
  return {
    type: ActionTypes.SET_FETCHING_IS_COACH_URL_EXIST,
    payload,
  };
}

export function fetchCoachIsExist(value) {
  return async (dispatch) => {
    dispatch(setFetchingIsCoachUrlExist(true));

    try {
      const response = await axios.get(
        `${API_URL}/coaches/existsByUrl/${value}`,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(receiveIsCoachUrlExist(response.data));
      dispatch(setFetchingIsCoachUrlExist(false));
      return response.data;
    } catch (error) {
      const { statusCode } = error.payload.data.error;

      if (statusCode === 401) dispatch(unauthUser());

      dispatch(setFetchingIsCoachUrlExist(false));
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function setCoachRolesTree(payload) {
  return {
    type: ActionTypes.SET_COACH_ROLES_TREE,
    payload,
  };
}

export function setCoachSetup(payload) {
  return {
    type: ActionTypes.SET_COACH_SETUP,
    payload,
  };
}

export function setCoachInitState(payload) {
  return {
    type: ActionTypes.SET_COACH_INIT_STATE,
    payload,
  };
}

export function fetchCoachCreateSuccess(payload) {
  return {
    type: ActionTypes.FETCH_COACH_CREATE_SUCCESS,
    payload,
  };
}

export function setFetchingCoachCreate(payload) {
  return {
    type: ActionTypes.SET_FETCHING_COACH_CREATE,
    payload,
  };
}

export function fetchCoachCreate(data) {
  return async (dispatch) => {
    dispatch(setFetchingCoachCreate(true));

    try {
      const response = await axios.post(`${API_URL}/coaches`, data, {
        headers: { authorization: localStorage.getItem('token') },
      });

      dispatch(fetchCoachCreateSuccess(response.data.id));
      dispatch(setFetchingCoachCreate(false));
      return response.data;
    } catch (error) {
      const { statusCode } = error.payload.data.error;

      if (statusCode === 401) dispatch(unauthUser());

      dispatch(setFetchingCoachCreate(false));
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function fetchCoachUpdateSuccess(payload) {
  return {
    type: ActionTypes.FETCH_COACH_UPDATE_SUCCESS,
    payload,
  };
}

export function setFetchingCoachUpdate(payload) {
  return {
    type: ActionTypes.SET_FETCHING_COACH_UPDATE,
    payload,
  };
}

export function setCoachData(payload) {
  return {
    type: ActionTypes.SET_COACH_DATA,
    payload,
  };
}

export function fetchCoachUpdate(data, coachId, isCreation) {
  return async (dispatch) => {
    dispatch(setFetchingCoachUpdate(true));

    try {
      const response = await axios.post(
        `${API_URL}/coaches/${coachId}/update`,
        data,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(fetchCoachUpdateSuccess(response.data));
      if (isCreation) {
        dispatch(
          setNotification({
            message: 'Your coach profile is created!',
            type: 'info',
            status: 'info',
          })
        );
        setTimeout(() => dispatch(clearNotification()), 5000);
      }
      dispatch(setFetchingCoachUpdate(false));
      return response.data;
    } catch (error) {
      const { statusCode } = error.payload.data.error;

      if (statusCode === 401) dispatch(unauthUser());

      dispatch(setFetchingCoachUpdate(false));
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function setFetchingCoachServicesUpdate(payload) {
  return {
    type: ActionTypes.SET_FETCHING_COACHES_SERVICES_UPDATE,
    payload,
  };
}

export function fetchCoachServicesUpdateSuccess(payload) {
  return {
    type: ActionTypes.FETCH_COACHES_SERVICES_UPDATE_SUCCESS,
    payload,
  };
}

export function fetchCoachServicesUpdate(data) {
  return async (dispatch) => {
    dispatch(setFetchingCoachServicesUpdate(true));

    try {
      const response = await axios.post(`${API_URL}/coach_services`, data, {
        headers: { authorization: localStorage.getItem('token') },
      });

      dispatch(fetchCoachServicesUpdateSuccess(response.data.services));
      dispatch(setFetchingCoachServicesUpdate(false));
      return response.data;
    } catch (error) {
      const { statusCode } = error.payload.data.error;

      if (statusCode === 401) dispatch(unauthUser());
      dispatch(setFetchingCoachServicesUpdate(false));
      throw error;
    }
  };
}

export function setBookSessionService(payload) {
  return {
    type: ActionTypes.SET_BOOK_SESSION_SERVICE,
    payload,
  };
}

export function setBookSessionDate(payload) {
  return {
    type: ActionTypes.SET_BOOK_SESSION_DATE,
    payload,
  };
}

export function setBookSessionTime(payload) {
  return {
    type: ActionTypes.SET_BOOK_SESSION_TIME,
    payload,
  };
}

export function clearBookSession(payload) {
  return {
    type: ActionTypes.CLEAR_BOOK_SESSION,
    payload,
  };
}

export function setFetchingRoles(payload) {
  return {
    type: ActionTypes.SET_FETCHING_COACHES_ROLES,
    payload,
  };
}

export function clearRoles() {
  return {
    type: ActionTypes.CLEAR_COACHES_ROLES,
  };
}

export function receiveRoles(payload) {
  return {
    type: ActionTypes.RECEIVE_COACHES_ROLES,
    payload,
  };
}

export function fetchRolesByName({ name, num }) {
  return async (dispatch) => {
    dispatch(setFetchingRoles(true));

    const query = queryKeywordsSearch({ name, num });

    try {
      const response = await axios.get(
        `${API_URL}/vacancy_roles?filter=${encodeURIComponent(query)}`
      );

      dispatch(receiveRoles(response.data));
      dispatch(setFetchingRoles(false));
      return response.data;
    } catch (error) {
      dispatch(setFetchingRoles(false));
      // dispatch(setNotification({ message: error.message, type: 'info', status: 'error' }));
      throw error;
    }
  };
}

export function setFetchingSelectCoachCalendarById() {
  return {
    type: ActionTypes.SET_FETCHING_SELECT_COACH_CALENDAR_SUCCESS,
  };
}

export function fetchSelectCoachCalendarByIdSuccess(payload) {
  return {
    type: ActionTypes.FETCH_SELECT_COACH_CALENDAR_SUCCESS,
    payload,
  };
}

export function fetchSelectCoachCalendarById(id, calendarId) {
  return async (dispatch) => {
    dispatch(setFetchingSelectCoachCalendarById(true));

    try {
      const response = await axios.post(
        `${API_URL}/coach_calendars/${id}/setCurrentFromList`,
        { calendarId },
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(fetchSelectCoachCalendarByIdSuccess(response.data));
      dispatch(setFetchingSelectCoachCalendarById(false));
      return response.data;
    } catch (error) {
      dispatch(setFetchingSelectCoachCalendarById(false));
      dispatch(
        setNotification({
          message: error.message,
          type: 'info',
          status: 'error',
        })
      );
      throw error;
    }
  };
}

export function setFetchingDeleteCoachCalendarById() {
  return {
    type: ActionTypes.SET_FETCHING_DELETE_COACH_CALENDAR,
  };
}

export function fetchDeleteCoachCalendarByIdSuccess(payload) {
  return {
    type: ActionTypes.FETCH_DELETE_COACH_CALENDAR_SUCCESS,
    payload,
  };
}

export function addCoachCalendar(payload) {
  return {
    type: ActionTypes.ADD_COACH_CALENDAR,
    payload,
  };
}

export function fetchDeleteCoachCalendarById(id) {
  return async (dispatch) => {
    dispatch(setFetchingDeleteCoachCalendarById(true));

    try {
      const response = await axios.delete(`${API_URL}/coach_calendars/${id}`, {
        headers: { authorization: localStorage.getItem('token') },
      });

      dispatch(
        setNotification({
          message: 'Calendar removed successfully',
          status: 'info',
          type: 'info',
        })
      );
      dispatch(fetchDeleteCoachCalendarByIdSuccess(id));
      dispatch(setFetchingDeleteCoachCalendarById(false));
      return response.data;
    } catch (error) {
      dispatch(setFetchingDeleteCoachCalendarById(false));
      dispatch(
        setNotification({
          message: error.message,
          type: 'info',
          status: 'error',
        })
      );
      throw error;
    }
  };
}

export function fetchRefreshCoachCalendarListSuccess(payload) {
  return {
    type: ActionTypes.FETCH_REFRESH_COACH_CALENDAR_LIST_SUCCESS,
    payload,
  };
}

export function setFetchingRefreshCoachCalendarList(payload) {
  return {
    type: ActionTypes.SET_FETCHING_REFRESH_COACH_CALENDAR_LIST,
    payload,
  };
}

export function fetchRefreshCoachCalendarList(id) {
  return async (dispatch) => {
    dispatch(setFetchingRefreshCoachCalendarList(true));

    try {
      const response = await axios.get(
        `${API_URL}/coach_calendars/${id}/fetchCalendars`,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(fetchRefreshCoachCalendarListSuccess(response.data));
      dispatch(setFetchingRefreshCoachCalendarList(false));
      return response.data;
    } catch (error) {
      dispatch(setFetchingRefreshCoachCalendarList(false));
      dispatch(
        setNotification({
          message: error.message,
          type: 'info',
          status: 'error',
        })
      );
      throw error;
    }
  };
}

export function fetchCoachSetupSetStatusSuccess(payload) {
  return {
    type: ActionTypes.FETCH_COACH_SETUP_STATUS_SUCCESS,
    payload,
  };
}

export function setFetchingCoachSetupSetStatus(payload) {
  return {
    type: ActionTypes.SET_FETCHING_COACH_SETUP_STATUS,
    payload,
  };
}

export function fetchCoachSetupSetStatus(coachId, status) {
  return async (dispatch) => {
    dispatch(setFetchingCoachSetupSetStatus(true));

    try {
      const response = await axios.post(
        `${API_URL}/coaches/${coachId}/status/${status}`,
        {},
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(fetchCoachSetupSetStatusSuccess(response.data.status));
      dispatch(
        setNotification({
          message: `Coaching profile status ${status.toUpperCase()} now!`,
          type: 'info',
          status: 'info',
        })
      );
      setTimeout(() => dispatch(clearNotification()), 5000);
      dispatch(setFetchingCoachSetupSetStatus(false));
      return response.data;
    } catch (error) {
      dispatch(setFetchingCoachSetupSetStatus(false));
      if (error.statusCode && error.statusCode === 401) dispatch(unauthUser());

      dispatch(
        setNotification({
          message:
            error && error.message ? error.message : 'Change status error',
          type: 'info',
          status: 'error',
        })
      );
      setTimeout(() => dispatch(clearNotification()), 5000);
      throw error;
    }
  };
}

export function fetchGetCoachBookingTimesSuccess(payload) {
  return {
    type: ActionTypes.FETCH_COACH_GET_BOOKING_TIMES_SUCCESS,
    payload,
  };
}

export function setFetchingGetCoachBookingTimes(payload) {
  return {
    type: ActionTypes.SET_FETCHING_COACH_GET_BOOKING_TIMES,
    payload,
  };
}

export function fetchGetBookingTimes(serviceId) {
  return async (dispatch) => {
    dispatch(setFetchingGetCoachBookingTimes(true));

    try {
      const response = await axios.get(
        `${API_URL}/coaches/service/${serviceId}/getBookingTimes`,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(fetchGetCoachBookingTimesSuccess(response.data.status));
      dispatch(setFetchingGetCoachBookingTimes(false));
      return response.data;
    } catch ({ payload }) {
      const { error } = payload.data;
      dispatch(setFetchingGetCoachBookingTimes(false));
      if (error.statusCode && error.statusCode === 401) {
        var inFifteenMinutes = new Date(new Date().getTime() + 15 * 60 * 1000);

        Cookies.set('ph_action_modal_close', true, {
          expires: inFifteenMinutes,
        });

        dispatch(
          setModal({
            active: 'signup',
            notification: true,
            data: { redirectOptions: { bookIsActive: true } },
            title:
              'You need to be signed in to book a session. Sign in or Sign up now.',
          })
        );
        dispatch(unauthUser());
      } else {
        dispatch(
          setNotification({
            message:
              error && error.message
                ? error.message
                : 'Get coach booking times error',
            type: 'info',
            status: 'error',
          })
        );
        setTimeout(() => dispatch(clearNotification()), 5000);
      }
      throw error;
    }
  };
}
export function fetchBookingSessionTimes(sessionId) {
  return async (dispatch) => {
    dispatch(setFetchingGetCoachBookingTimes(true));

    try {
      const response = await axios.get(
        `${API_URL}/coach_sessions/${sessionId}/getBookingTimes`,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(fetchGetCoachBookingTimesSuccess(response.data.status));
      dispatch(setFetchingGetCoachBookingTimes(false));
      return response.data;
    } catch ({ payload }) {
      const { error } = payload.data;

      dispatch(setFetchingGetCoachBookingTimes(false));
      if (error.statusCode && error.statusCode === 401) {
        dispatch(unauthUser());
      } else {
        dispatch(
          setNotification({
            message:
              error && error.message
                ? error.message
                : 'Get session booking times error',
            type: 'info',
            status: 'error',
          })
        );
        setTimeout(() => dispatch(clearNotification()), 5000);
      }
      throw error;
    }
  };
}

export function fetchCancelBookingSessionSuccess(payload) {
  return {
    type: ActionTypes.FETCH_CANCEL_BOOKING_SESSION_SUCCESS,
    payload,
  };
}

export function setFetchingCancelBooking(payload) {
  return {
    type: ActionTypes.SET_FETCHING_CANCEL_BOOKING_SESSION,
    payload,
  };
}

export function fetchCancelBookingSession(sessionId, data) {
  return async (dispatch) => {
    dispatch(setFetchingCancelBooking(true));

    try {
      const response = await axios.post(
        `${API_URL}/coach_sessions/${sessionId}/cancelSession`,
        data,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(fetchCancelBookingSessionSuccess(response.data));
      dispatch(setFetchingCancelBooking(false));
      return response.data;
    } catch ({ payload }) {
      const { error } = payload.data;
      dispatch(setFetchingCancelBooking(false));

      if (error.statusCode && error.statusCode === 401) {
        dispatch(unauthUser());
      } else {
        dispatch(
          setNotification({
            message: 'Cancel session error',
            type: 'info',
            status: 'error',
          })
        );
        setTimeout(() => dispatch(clearNotification()), 5000);
      }
      throw error;
    }
  };
}

export function fetchRequestRescheduleBookingSuccess(payload) {
  return {
    type: ActionTypes.FETCH_REQUEST_RESCHEDULE_BOOKING_SESSION_SUCCESS,
    payload,
  };
}

export function setFetchingRequestRescheduleBooking(payload) {
  return {
    type: ActionTypes.SET_FETCHING_REQUEST_RESCHEDULE_BOOKING_SESSION,
    payload,
  };
}

export function fetchRequestRescheduleBooking(sessionId, data) {
  return async (dispatch) => {
    dispatch(setFetchingRequestRescheduleBooking(true));

    try {
      const response = await axios.post(
        `${API_URL}/coach_sessions/${sessionId}/reschedulingRequest`,
        data,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(fetchRequestRescheduleBookingSuccess(response.data));
      dispatch(setFetchingRequestRescheduleBooking(false));
      return response.data;
    } catch ({ payload }) {
      const { error } = payload.data;
      dispatch(setFetchingRequestRescheduleBooking(false));

      if (error.statusCode && error.statusCode === 401) {
        dispatch(unauthUser());
      } else {
        dispatch(
          setNotification({
            message: 'Request Reschedule session error',
            type: 'info',
            status: 'error',
          })
        );
        setTimeout(() => dispatch(clearNotification()), 5000);
      }
      throw error;
    }
  };
}

export function fetchRescheduleBookingSessionSuccess(payload) {
  return {
    type: ActionTypes.FETCH_RESCHEDULE_BOOKING_SESSION_SUCCESS,
    payload,
  };
}

export function setFetchingRescheduleBookingSession(payload) {
  return {
    type: ActionTypes.SET_FETCHING_RESCHEDULE_BOOKING_SESSION,
    payload,
  };
}

export function fetchRescheduleBookingSession({ start, sessionId, note }) {
  return async (dispatch) => {
    dispatch(setFetchingRescheduleBookingSession(true));

    try {
      const response = await axios.post(
        `${API_URL}/coach_sessions/${sessionId}/rescheduleSession`,
        { start, note },
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(fetchRescheduleBookingSessionSuccess(response.data));
      dispatch(setFetchingRescheduleBookingSession(false));
      dispatch(
        setNotification({
          message: 'Session rescheduled successfully',
          type: 'info',
          status: 'info',
        })
      );
      setTimeout(() => dispatch(clearNotification()), 5000);
      return response.data;
    } catch ({ payload }) {
      const { error } = payload.data;
      dispatch(setFetchingRescheduleBookingSession(false));

      if (error.statusCode && error.statusCode === 401) {
        dispatch(unauthUser());
      } else {
        dispatch(
          setNotification({
            message: 'Request Reschedule session error',
            type: 'info',
            status: 'error',
          })
        );
        setTimeout(() => dispatch(clearNotification()), 5000);
      }
      throw error;
    }
  };
}

export function fetchReportNoShowBookingSessionSuccess(payload) {
  return {
    type: ActionTypes.FETCH_REPORT_NO_SHOW_BOOKING_SESSION_SUCCESS,
    payload,
  };
}

export function setFetchingReportNoShowBookingSession(payload) {
  return {
    type: ActionTypes.SET_FETCHING_REPORT_NO_SHOW_BOOKING_SESSION,
    payload,
  };
}

export function fetchReportNoShowBookingSession(sessionId) {
  return async (dispatch) => {
    dispatch(setFetchingReportNoShowBookingSession(true));

    try {
      const response = await axios.post(
        `${API_URL}/coach_sessions/${sessionId}/reportNoShowFromProfile`,
        {},
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(fetchReportNoShowBookingSessionSuccess(response.data));
      dispatch(setFetchingReportNoShowBookingSession(false));
      return response.data;
    } catch ({ payload }) {
      const { error } = payload.data;
      dispatch(setFetchingReportNoShowBookingSession(false));

      if (error.statusCode && error.statusCode === 401) {
        dispatch(unauthUser());
      } else {
        dispatch(
          setNotification({
            message: 'Request Reschedule session error',
            type: 'info',
            status: 'error',
          })
        );
        setTimeout(() => dispatch(clearNotification()), 5000);
      }
      throw error;
    }
  };
}

export function fetchBookNewSessionWithCoach({
  serviceId,
  start,
  tz,
  note = null,
  code = null,
  nonce = null,
}) {
  const data = { start, tz, nonce };

  if (code) {
    data.code = code;
  }

  if (note) {
    data.note = note;
  }

  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${API_URL}/coach_sessions/service/${serviceId}/newSession`,
        data,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      return response.data;
    } catch (error) {
      const { message, statusCode } = error.payload.data.error;

      if (statusCode && statusCode === 401) {
        dispatch(unauthUser());
        dispatch(
          setNotification({
            message: 'Something went wrong.',
            type: 'info',
            status: 'info',
          })
        );
      } else {
        dispatch(setNotification({ message, type: 'info', status: 'info' }));
      }

      setTimeout(() => dispatch(clearNotification()), 5000);
      throw error;
    }
  };
}

export function fetchCoachTermsUpdateSuccess(payload) {
  return {
    type: ActionTypes.FETCH_COACH_TERMS_UPDATE_SUCCESS,
    payload,
  };
}

export function setFetchingCoachTermsUpdate(payload) {
  return {
    type: ActionTypes.SET_FETCHING_COACH_TERMS_UPDATE,
    payload,
  };
}

export function fetchCoachTermsUpdate(coachId) {
  return async (dispatch) => {
    dispatch(setFetchingCoachTermsUpdate(true));

    try {
      const response = await axios.post(
        `${API_URL}/coaches/${coachId}/agreeTerms`,
        {},
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(setFetchingCoachTermsUpdate(false));
      dispatch(fetchCoachTermsUpdateSuccess(response.data.agreeTerms));
      return response.data;
    } catch (error) {
      const { message, statusCode } = error.payload.data.error;
      dispatch(setFetchingCoachTermsUpdate(false));

      if (statusCode && statusCode === 401) {
        dispatch(unauthUser());
        dispatch(
          setNotification({
            message: 'Something went wrong.',
            type: 'info',
            status: 'info',
          })
        );
      } else {
        dispatch(setNotification({ message, type: 'info', status: 'info' }));
      }

      setTimeout(() => dispatch(clearNotification()), 5000);
      throw error;
    }
  };
}

export function fetchCoachPayoutsSuccess(payload) {
  return {
    type: ActionTypes.FETCH_COACH_PAYOUTS_SUCCESS,
    payload,
  };
}

export function setFetchingCoachPayouts(payload) {
  return {
    type: ActionTypes.SET_FETCHING_COACH_PAYOUTS,
    payload,
  };
}

export function fetchCoachPayouts(coachId) {
  return async (dispatch) => {
    dispatch(setFetchingCoachPayouts(true));

    try {
      const response = await axios.get(
        `${API_URL}/coaches/${coachId}/payouts`,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(setFetchingCoachPayouts(false));
      dispatch(fetchCoachPayoutsSuccess(response.data));
      return response.data;
    } catch (error) {
      const { message, statusCode } = error.payload.data.error;
      dispatch(setFetchingCoachPayouts(false));

      if (statusCode && statusCode === 401) {
        dispatch(unauthUser());
        dispatch(
          setNotification({
            message: 'Something went wrong.',
            type: 'info',
            status: 'info',
          })
        );
      } else {
        dispatch(setNotification({ message, type: 'info', status: 'info' }));
      }

      setTimeout(() => dispatch(clearNotification()), 5000);
      throw error;
    }
  };
}

export function fetchCoachWidgetsInfoSuccess(payload) {
  return {
    type: ActionTypes.FETCH_COACH_WIDGETS_INFO_SUCCESS,
    payload,
  };
}

export function setFetchingCoachWidgetsInfo(payload) {
  return {
    type: ActionTypes.SET_FETCHING_COACH_WIDGETS_INFO,
    payload,
  };
}

export function fetchCoachWidgetsInfo(coachId) {
  return async (dispatch) => {
    dispatch(setFetchingCoachWidgetsInfo(true));

    try {
      const response = await axios.get(
        `${API_URL}/coaches/${coachId}/getWidgetsInfo`,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(setFetchingCoachWidgetsInfo(false));
      dispatch(fetchCoachWidgetsInfoSuccess(response.data));
      return response.data;
    } catch (error) {
      const { message, statusCode } = error.payload.data.error;
      dispatch(setFetchingCoachWidgetsInfo(false));

      if (statusCode && statusCode === 401) {
        dispatch(unauthUser());
        dispatch(
          setNotification({
            message: 'Something went wrong.',
            type: 'info',
            status: 'info',
          })
        );
      } else {
        dispatch(setNotification({ message, type: 'info', status: 'info' }));
      }

      setTimeout(() => dispatch(clearNotification()), 5000);
      throw error;
    }
  };
}

export function fetchCoachesByKeywordSuccess(payload) {
  return {
    type: ActionTypes.FETCH_COACHES_BY_KEYWORD_SUCCESS,
    payload,
  };
}

export function setFetchingCoachesByKeyword(payload) {
  return {
    type: ActionTypes.SET_FETCHING_COACHES_BY_KEYWORD,
    payload,
  };
}

export function clearCoachesByKeyword(payload) {
  return {
    type: ActionTypes.CLEAR_COACHES_BY_KEYWORD,
    payload,
  };
}

export function fetchCoachesByKeyword(value, count = 5) {
  return async (dispatch) => {
    dispatch(setFetchingCoachesByKeyword(true));

    try {
      const response = await axios.get(
        `${API_URL}/coaches/suggestion?keywords=${value}&count=${count}`,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(setFetchingCoachesByKeyword(false));
      dispatch(fetchCoachesByKeywordSuccess(response.data));
      return response.data;
    } catch (error) {
      const { message, statusCode } = error.payload.data.error;
      dispatch(setFetchingCoachesByKeyword(false));

      if (statusCode && statusCode === 401) {
        dispatch(unauthUser());
        dispatch(
          setNotification({
            message: 'Something went wrong.',
            type: 'info',
            status: 'info',
          })
        );
      } else {
        dispatch(setNotification({ message, type: 'info', status: 'info' }));
      }

      setTimeout(() => dispatch(clearNotification()), 5000);
      throw error;
    }
  };
}

export function fetchSendSessionReviewSuccess(payload) {
  return {
    type: ActionTypes.FETCH_SEND_SESSION_REVIEW_SUCCESS,
    payload,
  };
}

export function setFetchingSendSessionReview(payload) {
  return {
    type: ActionTypes.SET_FETCHING_SEND_SESSION_REVIEW,
    payload,
  };
}

export function fetchSendSessionReview(data) {
  return async (dispatch) => {
    dispatch(setFetchingSendSessionReview(true));

    try {
      const response = await axios.post(`${API_URL}/reviews/newReview`, data, {
        headers: { authorization: localStorage.getItem('token') },
      });

      dispatch(setFetchingSendSessionReview(false));
      dispatch(fetchSendSessionReviewSuccess(response.data));
      dispatch(
        setNotification({
          message: 'Reviewed successfully!',
          type: 'info',
          status: 'info',
        })
      );
      setTimeout(() => dispatch(clearNotification()), 5000);

      return response.data;
    } catch (error) {
      const { message, statusCode } = error.payload.data.error;
      dispatch(setFetchingSendSessionReview(false));

      if (statusCode && statusCode === 401) {
        dispatch(unauthUser());
        dispatch(
          setNotification({
            message: 'Something went wrong.',
            type: 'info',
            status: 'info',
          })
        );
      } else {
        dispatch(setNotification({ message, type: 'info', status: 'info' }));
      }

      setTimeout(() => dispatch(clearNotification()), 5000);
      throw error;
    }
  };
}

export function fetchGetCoachReviewsByIdSuccess(payload) {
  return {
    type: ActionTypes.FETCH_GET_COACH_REVIEWS_BY_ID_SUCCESS,
    payload,
  };
}

export function setFetchingGetCoachReviewsById(payload) {
  return {
    type: ActionTypes.SET_FETCHING_GET_COACH_REVIEWS_BY_ID,
    payload,
  };
}

export function fetchGetCoachReviewsById(id) {
  return async (dispatch) => {
    dispatch(setFetchingGetCoachReviewsById(true));

    try {
      const response = await axios.get(`${API_URL}/coaches/${id}/reviews`, {
        headers: { authorization: localStorage.getItem('token') },
      });

      dispatch(setFetchingGetCoachReviewsById(false));
      dispatch(fetchGetCoachReviewsByIdSuccess(response.data));
      return response.data;
    } catch (error) {
      const { message, statusCode } = error.payload.data.error;
      dispatch(setFetchingGetCoachReviewsById(false));

      if (statusCode && statusCode === 401) {
        dispatch(unauthUser());
        dispatch(
          setNotification({
            message: 'Something went wrong.',
            type: 'info',
            status: 'info',
          })
        );
      } else {
        dispatch(setNotification({ message, type: 'info', status: 'info' }));
      }

      setTimeout(() => dispatch(clearNotification()), 5000);
      throw error;
    }
  };
}

export function fetchCheckBookSessionPromocodeSuccess(payload) {
  return {
    type: ActionTypes.FETCH_CHECK_BOOK_SESSION_PROMOCODE_SUCCESS,
    payload,
  };
}

export function setFetchingCheckBookSessionPromocode(payload) {
  return {
    type: ActionTypes.SET_FETCHING_CHECK_BOOK_SESSION_PROMOCODE,
    payload,
  };
}

export function fetchCheckBookSessionPromocode(serviceId, code) {
  return async (dispatch) => {
    dispatch(setFetchingCheckBookSessionPromocode(true));

    try {
      const response = await axios.get(
        `${API_URL}/coach_services/${serviceId}/applyCode/${code}`,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(setFetchingCheckBookSessionPromocode(false));
      dispatch(fetchCheckBookSessionPromocodeSuccess(response.data));
      return response.data;
    } catch (error) {
      const { message, statusCode } = error.payload.data.error;
      dispatch(setFetchingCheckBookSessionPromocode(false));

      if (statusCode && statusCode === 401) {
        dispatch(unauthUser());
        dispatch(
          setNotification({
            message: 'Something went wrong.',
            type: 'info',
            status: 'info',
          })
        );
        setTimeout(() => dispatch(clearNotification()), 5000);
      }

      throw message;
    }
  };
}

export function setCoachesSort(payload) {
  return {
    type: ActionTypes.SET_COACHES_SORT,
    payload,
  };
}
