import * as FilterTypes from 'constants/FilterTypes';

const buildUrl = (slugs, path = '/', nearest) => {
  let url = '';
  let params = '';

  for (let key in slugs) {
    let slugsType = slugs[key];

    if (
      (key === FilterTypes.EXPERIENCE &&
        slugsType[0] === 0 &&
        slugsType[1] === 10) ||
      (key === FilterTypes.PRICE && slugsType[0] === 0 && slugsType[1] === 300)
    )
      continue;

    if (slugsType && slugsType.length > 0 && slugsType[0] !== '') {
      if (key === FilterTypes.EVENTTYPES) params += `${FilterTypes.TYPES}=`;
      else if (key === FilterTypes.COACHINGTYPES)
        params += `${FilterTypes.TYPES}=`;
      else if (key === FilterTypes.COACHINGROLES)
        params += `${FilterTypes.ROLES}=`;
      else params += `${key}=`;

      for (let idx = 0; idx < slugsType.length; idx++) {
        if (typeof slugsType[0] === 'object') params += slugsType[idx].slug;
        else params += String(slugsType[idx]);

        if (slugsType.length - 1 !== idx) params += ',';
      }
      params += '&';
    }
  }

  params = params.substr(0, params.length - 1);

  return (url += `${path}?${params}`);
};

export default buildUrl;
