import classnames from 'classnames';
import React from 'react';

import AppIcon from 'components/shared/AppIcon';

import './AppUserNotificationsToggle.scss';

const AppUserNotificationsToggle = function({ newCount }) {
    return (
        <div className={classnames("app-menu-notifications-toggle", { "--active": newCount })}>
            { newCount ? <div className="app-menu-notifications-toggle__count">{ newCount }</div> : '' }
            <AppIcon icon="bell" />
        </div>
    );
}

export default AppUserNotificationsToggle;
