import React, { Component } from 'react';
import {
  AppPanelList,
  AppPanelListItem,
  AppPanelTitle,
} from 'components/shared/AppPanel';
import { AppSearchTag } from 'components/shared/AppSearch';
import './AppSearchListPopular.scss';

class AppSearchListPopular extends Component {
  renderTagCondition = (picks, slug) => {
    if (slug && picks[0] !== undefined)
      return picks.some((item) => item.id === slug.id);
    else return false;
  };

  render() {
    const {
      list,
      isFetching,
      handlerClickTag,
      title = null,
      myPicks,
      handlerRemoveTag,
    } = this.props;

    return (
      <div className="app-search-list app-search-list-popular">
        {title && <AppPanelTitle>{title}</AppPanelTitle>}
        <AppPanelList offset={true} isFetching={isFetching}>
          {list && list.length > 0
            ? list.map((item) => (
                <AppPanelListItem key={item.id}>
                  <AppSearchTag
                    picks={myPicks}
                    data={item}
                    onClick={() => handlerClickTag(item)}
                    double={item && this.renderTagCondition(myPicks, item)}
                    onRemove={() => item && handlerRemoveTag(item)}
                  />
                </AppPanelListItem>
              ))
            : ''}
        </AppPanelList>
      </div>
    );
  }
}

export default AppSearchListPopular;
