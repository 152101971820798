import React from 'react';

import AppIcon from 'components/shared/AppIcon';

import './AppCheckbox.scss';

const AppCheckbox = ({
  field: { name, value, onChange, onBlur = () => {} },
  id,
  className,
  hint,
  data,
  textMod,
  onChangeHandler,
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) => {
  const changeHandler = (e) => {
    onChange(e);
    onChangeHandler && onChangeHandler();
  };

  return (
    <div>
      <label>
        <input
          name={name}
          type="checkbox"
          onChange={changeHandler}
          value={value}
          checked={value}
          onBlur={onBlur}
          className={`${className}--cloned`}
          {...props}
        />
        <div className={`${className}__wrap`}>
          <div className={className}>
            {value ? <AppIcon icon="check" /> : ''}
          </div>
          {hint ? (
            <p className={`app-form-text ${textMod || ''}`}>{hint}</p>
          ) : (
            ''
          )}
        </div>
      </label>
    </div>
  );
};

export default AppCheckbox;
