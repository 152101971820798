import * as ActionTypes from 'constants/ActionTypes';
import { LOCATION_CHANGE } from 'connected-react-router';

const initialState = {
  name: 'coachingtypes',
  list: [],
  all: [],
  populars: [],
  slugs: [],
  isFetching: {
    all: false,
    list: false,
    populars: false,
    slugs: false,
  },
};

export default function coachingtypes(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE: {
      if (
        action.payload &&
        action.payload.location.state &&
        action.payload.location.state.filters &&
        action.payload.location.state.filters.coachingtypes
      ) {
        return Object.assign({}, state, {
          ...state,
          slugs: action.payload.location.state.filters.coachingtypes,
        });
      }
      return state;
    }

    case ActionTypes.SET_FETCHING_INIT_STATE_POPULAR_COACHINGTYPES:
      return Object.assign({}, state, {
        isFetching: {
          ...state.isFetching,
          populars: action.payload,
        },
      });

    case ActionTypes.RECEIVE_INIT_STATE_POPULAR_COACHINGTYPES:
      return Object.assign({}, state, {
        populars: action.payload,
      });

    case ActionTypes.RECEIVE_COACHINGTYPES_ALL:
      return Object.assign({}, state, {
        all: action.payload,
      });

    case ActionTypes.SET_FETCHING_COACHINGTYPES_ALL:
      return Object.assign({}, state, {
        isFetching: {
          ...state.isFetching,
          all: action.payload,
        },
      });

    case ActionTypes.SET_FILTER_STATE:
      return Object.assign({}, state, {
        slugs: action.payload.types,
      });

    case ActionTypes.SET_FETCHING_COACHINGTYPES:
      return Object.assign({}, state, {
        isFetching: {
          ...state.isFetching,
          list: action.payload,
        },
      });

    case ActionTypes.SET_FETCHING_POPULAR_COACHINGTYPES:
      return Object.assign({}, state, {
        isFetching: {
          ...state.isFetching,
          populars: action.payload,
        },
      });

    case ActionTypes.RECEIVE_COACHINGTYPES:
      return Object.assign({}, state, {
        list: action.payload,
      });

    case ActionTypes.CLEAR_COACHINGTYPES:
      return Object.assign({}, state, {
        list: [],
      });

    case ActionTypes.REMOVE_COACHINGTYPE_SLUG:
      return Object.assign({}, state, {
        slugs: state.slugs.filter((item) => item.id !== action.payload.id),
      });

    case ActionTypes.REMOVE_ALL_COACHINGTYPES_SLUGS:
    case ActionTypes.REMOVE_ALL_SLUGS:
      return Object.assign({}, state, {
        slugs: [],
      });

    case ActionTypes.ADD_COACHINGTYPE_SLUG:
      const slug = state.slugs.slice();

      if (!slug.some((slug) => action.payload.id === slug.id))
        slug.push(action.payload);

      return Object.assign({}, state, {
        slugs: slug,
      });

    default: {
      return state;
    }
  }
}
