export const ROLES = 'roles';
export const SKILLS = 'skills';
export const SENIORITY = 'seniority';
export const SENIORITIES = 'seniorities';
export const COMPANIES = 'companies';
export const KEYWORDS = 'keywords';
export const LOCATIONS = 'locations';
export const EXPERIENCE = 'experience';
export const EVENTTYPES = 'eventtypes';
export const FROMUTC = 'fromUTC';
export const COACHINGROLES = 'coachingroles';
export const COACHINGTYPES = 'coachingtypes';
export const TYPES = 'types';
export const PRICE = 'price';

export const ROLES_LABEL = 'Roles';
export const COACHINGROLES_LABEL = 'Areas';
export const SKILLS_LABEL = 'Skills';
export const SENIORITIES_LABEL = 'Seniorities';
export const SENIORITY_LABEL = 'Seniority';
export const COMPANIES_LABEL = 'Companies';
export const KEYWORDS_LABEL = 'Keywords';
export const LOCATIONS_LABEL = 'Locations';
export const EXPERIENCE_LABEL = 'Experience';
export const COMPANY_LABEL = 'Company';
export const LOCATION_LABEL = 'Location';
export const FROMUTC_LABEL = 'Start date';
export const EVENT_LABEL = 'Event';
export const EVENTTYPES_LABEL = 'Event Type';
export const COACHINGTYPES_LABEL = 'Services';
export const PRICE_LABEL = 'Price';

export const SORT = 'sort';
export const NEAR = 'near';

export const jobs = [
  {
    field: ROLES,
    label: ROLES_LABEL,
    active: false,
  },
  {
    field: SENIORITIES,
    label: SENIORITY_LABEL,
    active: false,
  },
  {
    field: LOCATIONS,
    label: LOCATION_LABEL,
    active: false,
  },
  {
    field: SKILLS,
    label: SKILLS_LABEL,
    active: false,
  },
  {
    field: EXPERIENCE,
    label: EXPERIENCE_LABEL,
    active: false,
  },
  {
    field: COMPANIES,
    label: COMPANY_LABEL,
    active: false,
  },
];

export const events = [
  {
    field: LOCATIONS,
    label: LOCATION_LABEL,
    active: false,
  },
  {
    field: SKILLS,
    label: SKILLS_LABEL,
    active: false,
  },
  {
    field: EVENTTYPES,
    label: EVENTTYPES_LABEL,
    active: false,
  },
  {
    field: FROMUTC,
    label: FROMUTC_LABEL,
    active: false,
  },
];

export const coaches = [
  {
    field: COACHINGROLES,
    label: COACHINGROLES_LABEL,
    active: false,
  },
  {
    field: COACHINGTYPES,
    label: COACHINGTYPES_LABEL,
    active: false,
  },
  {
    field: COMPANIES,
    label: COMPANY_LABEL,
    active: false,
  },
  {
    field: PRICE,
    label: PRICE_LABEL,
    active: false,
  },
];

export const filtersResetState = {
  roles: [],
  skills: [],
  companies: [],
  locations: [],
  experience: [0, 10],
  keywords: [''],
  seniorities: [],
  eventtypes: [],
  fromUTC: [],
  coachingroles: [],
  price: [0, 300],
};
