import axios from 'axios';

import { unauthUser } from 'actions/UserActions';
import { setNotification, clearNotification } from 'actions/SystemActions';

import { API_URL } from 'constants/PathTypes';
import * as ActionTypes from 'constants/ActionTypes';
// import * as ErrorsTypes from 'constants/ErrorsTypes';
import * as PathTypes from 'constants/PathTypes';

import { createSearchQuery } from 'actions/querys';

import history from 'history.js';
// import store from 'store';

export function updateEvents(payload) {
  return {
    type: ActionTypes.UPDATE_EVENTS,
    payload,
  };
}

export function receiveEvents(payload) {
  return {
    type: ActionTypes.RECEIVE_EVENTS,
    payload,
  };
}

export function setFetchingEvents(payload) {
  return {
    type: ActionTypes.SET_FETCHING_EVENTS,
    payload,
  };
}

export function fetchEventsByFilters(params) {
  return async (dispatch) => {
    dispatch(setFetchingEvents(true));

    const query = createSearchQuery(params, 'filters');

    try {
      const response = await axios.get(
        `${API_URL}/events/search?query=${encodeURIComponent(query)}`,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(receiveEvents(response.data));
      dispatch(setFetchingEvents(false));
    } catch (error) {
      const { message, statusCode } = error.payload.data.error;

      if (statusCode === 401) dispatch(unauthUser());

      dispatch(setFetchingEvents(false));
      dispatch(setNotification({ message, type: 'info', status: 'info' }));
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function setFetchingEventsCountAll(payload) {
  return {
    type: ActionTypes.SET_FETCHING_EVENTS_COUNT_ALL,
    payload,
  };
}

export function receiveEventsCountAll(payload) {
  return {
    type: ActionTypes.RECEIVE_EVENTS_COUNT_ALL,
    payload,
  };
}

export function fetchEventsCountAll(params) {
  return async (dispatch) => {
    dispatch(setFetchingEventsCountAll(true));

    const query = createSearchQuery(params, 'count');

    try {
      const response = await axios.get(
        `${API_URL}/events/searchCount?query=${encodeURIComponent(query)}`,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(receiveEventsCountAll(response.data.count));
      dispatch(setFetchingEventsCountAll(false));

      return response.data.count;
    } catch (error) {
      const { statusCode } = error.payload.data;

      if (statusCode === 401) dispatch(unauthUser());
      dispatch(setFetchingEventsCountAll(false));

      throw error;
    }
  };
}

export function fetchMoreEventsByFilters(params) {
  return async (dispatch) => {
    dispatch(setFetchingEvents(true));

    const query = createSearchQuery(params, 'filters');

    try {
      const response = await axios.get(
        `${API_URL}/events/search?query=${encodeURIComponent(query)}`,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(updateEvents(response.data));
      dispatch(setFetchingEvents(false));
    } catch (error) {
      dispatch(setFetchingEvents(false));
      const { statusCode } = error.payload.data.error;

      if (statusCode === 401) dispatch(unauthUser());
      throw error;
    }
  };
}

export function setFetchingEventById(payload) {
  return {
    type: ActionTypes.SET_FETCHING_EVENT_BY_ID,
    payload,
  };
}

export function receiveEventById(payload) {
  return {
    type: ActionTypes.RECEIVE_EVENT_BY_ID,
    payload,
  };
}

export function fetchEventById(id) {
  return async (dispatch) => {
    dispatch(setFetchingEventById(true));

    try {
      const response = await axios.get(`${API_URL}/events/${id}`, {
        headers: { authorization: localStorage.getItem('token') },
      });

      dispatch(setFetchingEventById(false));
      return response.data;
    } catch (error) {
      const { message, statusCode } = error.payload.data.error;

      if (statusCode === 401) dispatch(unauthUser());
      if (statusCode === 404) history.push(PathTypes.PAGE404);

      dispatch(setFetchingEventById(false));
      dispatch(setNotification({ message, type: 'info', status: 'info' }));
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function setLoadingRelatedEvents(payload) {
  return {
    type: ActionTypes.SET_LOADING_RELATED_EVENTS,
    payload,
  };
}

export function setRelatedEvents(payload) {
  return {
    type: ActionTypes.SET_RELATED_EVENTS,
    payload,
  };
}

export function fetchRelatedEventsByData({ data }) {
  return async (dispatch) => {
    dispatch(setLoadingRelatedEvents(true));

    try {
      const response = await axios.post(`${API_URL}/events/related`, data, {
        headers: { authorization: localStorage.getItem('token') },
      });

      dispatch(setRelatedEvents(response.data));
      dispatch(setLoadingRelatedEvents(false));

      return response.data;
    } catch (response) {
      const { error } = response.payload.data;

      if (error.statusCode === 401) dispatch(unauthUser());

      dispatch(setLoadingRelatedEvents(false));
      dispatch(
        setNotification({
          message: error.message,
          status: 'info',
          type: 'info',
        })
      );
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}
