import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { AppPanelRow, AppPanelTitle } from 'components/shared/AppPanel';
import AppRangeSliderPricerange from 'components/shared/AppRangeSliderPricerange';
import AppFlexLine from 'components/layout/AppFlexLine/AppFlexLine';
import AppCheckbox from 'components/shared/AppCheckbox/AppCheckbox';

import { removeSlug, addSlug } from 'actions/SlugsActions';

import * as SlugsTypes from 'constants/SlugsTypes';

import './AppFiltersPanelPricerange.scss';

const AppFiltersPanelPricerange = (props) => {
  const [cachedSlugs, setCachedSlugs] = useState(null);
  const dispatch = useDispatch();
  const {
    closeFilterPanel,
    handleSubmit,
    handleCount,
    data,
    analiticsTrigger,
  } = props;

  const onClearFilter = () => {
    dispatch(removeSlug({ slugName: SlugsTypes.PRICE }));
    closeFilterPanel && closeFilterPanel();
    handleSubmit && setTimeout(() => handleSubmit(), 500);
    handleCount && handleCount();
  };

  const handlePriceRangeChange = useCallback(
    (slug) => {
      analiticsTrigger && analiticsTrigger();
      dispatch(addSlug({ slugName: SlugsTypes.PRICE, slug }));
    },
    [dispatch, analiticsTrigger]
  );

  const handleFreePriceChange = useCallback(() => {
    if (data.slugs) {
      if (data.slugs[0] !== 0 || data.slugs[1] !== 0) {
        setCachedSlugs(data.slugs);
        handlePriceRangeChange([0, 0]);
      } else if (data.slugs[0] === 0 || data.slugs[1] === 0) {
        handlePriceRangeChange(cachedSlugs || [0, 300]);
        setCachedSlugs(null);
      }
      handleSubmit && setTimeout(() => handleSubmit(), 500);
    }
  }, [handleSubmit, setCachedSlugs, data, cachedSlugs, handlePriceRangeChange]);

  return (
    <div className="app-filters-panel-price">
      <div className="app-filters-panel-price__dropdown">
        <AppPanelRow mb={15}>
          <AppRangeSliderPricerange
            range={data.slugs}
            handleCount={handleCount}
            handleSubmit={handleSubmit}
            handlerChange={handlePriceRangeChange}
          />
        </AppPanelRow>
        <AppPanelRow mb={15}>
          <AppFlexLine ai="center">
            <AppCheckbox
              field={{
                name: 'price-free',
                value: data.slugs && data.slugs[0] === 0 && data.slugs[1] === 0,
                onChange: handleFreePriceChange,
              }}
              form={{ touched: false, errors: false }}
              className="app-form-checkbox"
              hint="Free Only"
              // value={}
            />
          </AppFlexLine>
        </AppPanelRow>
        {data &&
        data.slugs &&
        (data.slugs[0] !== 0 || data.slugs[1] !== 300) ? (
          <AppPanelRow>
            <button onClick={onClearFilter}>
              <AppPanelTitle color="warn">Clear selection</AppPanelTitle>
            </button>
          </AppPanelRow>
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default AppFiltersPanelPricerange;
