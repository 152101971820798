import React, { Component } from 'react';
import cn from 'classnames';

import AppSpinner from 'components/shared/AppSpinner';
import AppIcon from 'components/shared/AppIcon';
import './AppButton.scss';

export default class AppButton extends Component {
  render() {
    const {
      defaultStyle,
      type = 'button',
      onClick,
      isDisabled = false,
      loader = false,
      title,
      icon,
      size,
      fullWidth,
      minWidthOff,
      color,
      bgColor,
      hover,
      fullHeight,
      height,
      children,
      target,
      link,
      href,
      className,
      spinnerSize,
      dataTarget,
      dataAnchorTarget,
      width,
      reverse,
      capitalize,
      padded,
      download = false,
      style,
    } = this.props;

    return (
      <React.Fragment>
        {!link ? (
          <button
            className={cn(
              'app-button',
              {
                [` app-button-${defaultStyle}`]: defaultStyle,
                [`--${height}`]: height,
                [`--${size}`]: size,
                [`--w-${width}`]: width,
                [`--hover-${hover}`]: hover,
                [`--bg-color-${bgColor}`]: bgColor,
                [`--color-${color}`]: color,
                'app-button-icon': !title,
                '--fullwidth': fullWidth,
                '--no-minwidth': minWidthOff,
                '--fullheight': fullHeight,
                '--reverse': reverse,
                '--capitalize': capitalize,
                '--padded': padded,
              },
              className
            )}
            value={title || 'button'}
            type={type}
            onClick={onClick}
            disabled={isDisabled}
            data-target={dataTarget}
            data-nav-id={dataAnchorTarget || null}
            style={style}
          >
            {(!loader || !isDisabled) && icon ? (
              <div className="app-button__icon">
                <AppIcon icon={icon} />
              </div>
            ) : (
              ''
            )}
            {title && (
              <span
                className={cn('app-button__title', {
                  '--hidden': loader && isDisabled,
                })}
              >
                {title}
              </span>
            )}
            {isDisabled && loader && (
              <AppSpinner
                size={spinnerSize || 20}
                spinnerColor={'#333'}
                spinnerWidth={2}
                visible={true}
              />
            )}
            {children ? children : ''}
          </button>
        ) : (
          <a
            target={target || ''}
            href={href || ''}
            download={download}
            className={cn(
              'app-button',
              {
                [` app-button-${defaultStyle}`]: defaultStyle,
                [`--${height}`]: height,
                [`--${size}`]: size,
                [`--${width}`]: width,
                [`--hover-${hover}`]: hover,
                [`--bg-color-${bgColor}`]: bgColor,
                [`--color-${color}`]: color,
                'app-button-icon': !title,
                '--fullwidth': fullWidth,
                '--no-minwidth': minWidthOff,
                '--fullheight': fullHeight,
                '--reverse': reverse,
              },
              className
            )}
            value={title || 'button'}
            type={type}
            onClick={onClick}
            disabled={isDisabled}
            style={style}
          >
            {(!loader || !isDisabled) && icon ? (
              <div className="app-button__icon">
                <AppIcon icon={icon} />
              </div>
            ) : (
              ''
            )}
            {(!loader || !isDisabled) && title ? (
              <span className="app-button__title">{title}</span>
            ) : (
              ''
            )}
            {isDisabled && loader && (
              <AppSpinner
                size={20}
                spinnerColor={'#333'}
                spinnerWidth={2}
                visible={true}
              />
            )}
            {children ? children : ''}
          </a>
        )}
      </React.Fragment>
    );
  }
}
