import React from 'react';
import { connect } from 'react-redux';
import cn from 'classnames';
import PropTypes from 'prop-types';

import AppButton from 'components/shared/AppButton';

import {
    addSavedVacancieToUserSavedJobs,
    removeSavedVacancieFromUserSavedJobsById,
    fetchSaveUserSavedVacancieById,
    fetchUnsaveUserSavedVacancieById,
    toggleVacancieSearchListSavedPropById,
    toggleUserSavedJobsVacancieSavedPropById,
    toggleVacancieByIdSavedProp,
    toggleVacancieMotivatedListSavedPropById,
} from 'actions/VacanciesActions';
import {
    toggleTopMatchesVacancieByIdSavedProp
} from 'actions/UserActions';

import './AppJobsCardSave.scss';

const mapDispatchToProps = dispatch => ({
    addSavedVacancieToUserSavedJobs: (payload) => dispatch(addSavedVacancieToUserSavedJobs(payload)),
    removeSavedVacancieFromUserSavedJobsById: (payload) => dispatch(removeSavedVacancieFromUserSavedJobsById(payload)),
    fetchSaveUserSavedVacancieById: (payload) => dispatch(fetchSaveUserSavedVacancieById(payload)),
    fetchUnsaveUserSavedVacancieById: (payload) => dispatch(fetchUnsaveUserSavedVacancieById(payload)),
    toggleVacancieSearchListSavedPropById: (payload) => dispatch(toggleVacancieSearchListSavedPropById(payload)),
    toggleUserSavedJobsVacancieSavedPropById: (payload) => dispatch(toggleUserSavedJobsVacancieSavedPropById(payload)),
    toggleVacancieByIdSavedProp: (payload) => dispatch(toggleVacancieByIdSavedProp(payload)),
    toggleVacancieMotivatedListSavedPropById: (payload) => dispatch(toggleVacancieMotivatedListSavedPropById(payload)),
    toggleTopMatchesVacancieByIdSavedProp: (payload) => dispatch(toggleTopMatchesVacancieByIdSavedProp(payload)),
});

const mapStateToProps = state => ({
    initState: state.initState,
    user: state.user,
});

const AppJobsCardSave = ({
    vacancieId,
    saved,
    undo,
    details,
    search,
    motivated,
    height,
    defaultStyle,
    mobile,
    title,
    className,
    iconSaved,
    fetchSaveUserSavedVacancieById,
    fetchUnsaveUserSavedVacancieById,
    addSavedVacancieToUserSavedJobs,
    removeSavedVacancieFromUserSavedJobsById,
    toggleVacancieSearchListSavedPropById,
    toggleVacancieMotivatedListSavedPropById,
    toggleUserSavedJobsVacancieSavedPropById,
    toggleVacancieByIdSavedProp,
    toggleTopMatchesVacancieByIdSavedProp,
    style,
}) => {
    const [loading, setLoading] = React.useState(false);

    const handleClick = (e) => {
        e.stopPropagation();
        setLoading(true);

        if (saved) {
            fetchUnsaveUserSavedVacancieById(vacancieId)
                .then(() => handleFetch())
                .catch(() => setLoading(false))
        } else {
            fetchSaveUserSavedVacancieById(vacancieId)
                .then(response => handleFetch(response))
                .catch(() => setLoading(false));
        }

    }

    const handleFetch = (response) => {
        setLoading(false);

        const data = { id: vacancieId, saved: !saved }

        if (motivated) toggleVacancieMotivatedListSavedPropById(data);
        if (search) {
            toggleTopMatchesVacancieByIdSavedProp(data);
            toggleVacancieSearchListSavedPropById(data);
        }
        if (details) toggleVacancieByIdSavedProp();

        if (undo) toggleUserSavedJobsVacancieSavedPropById(data);
        else {
            saved
                ? removeSavedVacancieFromUserSavedJobsById(data.id)
                : addSavedVacancieToUserSavedJobs(response)
        }
    }

    return (
        <React.Fragment>
            {
                mobile
                    ?
                        <AppButton
                            defaultStyle={defaultStyle || 'none'}
                            onClick={handleClick}
                            className={cn(
                                'app-jobs-card-save --inline',
                                { '--active': saved },
                                className
                            )}
                            icon={saved ? (iconSaved || 'bookmark') : 'plus'}
                            title={saved ? 'Saved' : 'Save'}
                            color="blue"
                            loader={true}
                            height={height}
                            isDisabled={loading}
                            spinnerSize={15}
                            style={style}
                            />
                            :
                            <AppButton
                            onClick={handleClick}
                            className={cn(
                            'app-jobs-card-save --button',
                            { '--active': saved },
                            className,
                            )}
                            icon={(saved && iconSaved) || 'bookmark'}
                            title={title || null}
                            loader={true}
                            height={height}
                            isDisabled={loading}
                            spinnerSize={15}
                            style={style}
                        />
            }
        </React.Fragment>
    )
}

AppJobsCardSave.propTypes = {
    id: PropTypes.number,
    saved: PropTypes.bool,
    saveHandler: PropTypes.func,
    unsaveHandler: PropTypes.func,
}


export default connect(mapStateToProps, mapDispatchToProps)(AppJobsCardSave);
