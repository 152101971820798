import React from 'react';
import cn from 'classnames';

import AppSearchTag from 'components/shared/AppSearch/AppSearchTag';
import * as FilterTypes from 'constants/FilterTypes';
import history from 'history.js';
import store from 'store';
import { getStateFilters } from 'utils';

import './CardSkills.scss';

export const CardSkills = ({
  skills,
  center,
  moduleName,
  onTagClickStop,
  onTagAddClick,
  onTagRemoveClick,
  activeSkills,
}) => {
  const renderTagCondition = (activeSkills, slug) => {
    if (activeSkills) {
      const double = activeSkills.slugs.some((item) => {
        return item.id === slug.id;
      });

      return double;
    }
    return false;
  };

  return (
    <ul className={cn('card-skills', { '--center': center })}>
      {skills.map((skill) => (
        <li className="card-skills-item" key={skill.id}>
          <AppSearchTag
            stop={onTagClickStop}
            defaultStyle="card"
            data={skill}
            picks={skills.slugs || []}
            double={renderTagCondition(activeSkills, skill)}
            onClick={() => {
              if (onTagAddClick) onTagAddClick(skill);
              else
                history.push(
                  `/${moduleName}?${FilterTypes.SKILLS}=${skill.slug}`,
                  {
                    filters: getStateFilters(store.getState(), moduleName),
                    fetch: true,
                  }
                );
            }}
            onRemove={() => onTagRemoveClick(skill)}
          />
        </li>
      ))}
    </ul>
  );
};

export default CardSkills;
