import React from 'react';
import { MatomoProvider, createInstance } from '@datapunt/matomo-tracker-react';

const isProd = true;

const MatomoProviderContainer = ({ userId = undefined, children }) => {
  if (!isProd) {
    return children;
  }

  const instance = createInstance({
    urlBase: process.env.REACT_APP_SERVER_URL,
    siteId: process.env.REACT_APP_MATOMO_SITE_ID,
    userId: userId || undefined, // optional, default value: `undefined`.
    trackerUrl: process.env.REACT_APP_MATOMO_TRAKER, // optional, default value: `${urlBase}matomo.php`
    srcUrl: process.env.REACT_APP_MATOMO_SRC, // optional, default value: `${urlBase}matomo.js`
    // disabled: false, // optional, false by default. Makes all tracking calls no-ops if set to true.
    // heartBeat: { // optional, enabled by default
    // active: true, // optional, default value: true
    // seconds: 10 // optional, default value: `15
    // },
    // linkTracking: false, // optional, default value: true
    // configurations: { // optional, default value: {}
    // any valid matomo configuration, all below are optional
    // disableCookies: true,
    // setSecureCookie: true,
    // setRequestMethod: 'POST'
    // }
  });

  return <MatomoProvider value={instance}>{children}</MatomoProvider>;
};

export default MatomoProviderContainer;
