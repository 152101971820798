import React from 'react';
import Numeral from 'numeral';
import {
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    ResponsiveContainer,
    AreaChart,
    Area
} from 'recharts';

import './AppCraftChart.scss';

const MONTHS = [
    'JAN',
    'FEB',
    'MAR',
    'APR',
    'MAY',
    'JUN',
    'JUL',
    'AUG',
    'SEP',
    'OCT',
    'NOV',
    'DEC'
];

const AppCraftChart = ({ employees, type, founded, website }) => {
    let chartData = employees.map(employe => {
        let { date, employeeNumber } = employe;
        let dateArr = date.split('-');

        let year = dateArr[0];
        let monthId = Number(dateArr[1]) - 1;
        let monthLabel = MONTHS[monthId];

        return {
            date: `${monthLabel} ${year}`,
            employee: employeeNumber
        };
    });

    const extractHostname = (url) => {
        var hostname;

        if (url.indexOf("//") > -1) hostname = url.split('/')[2];
        else hostname = url.split('/')[0];

        hostname = hostname.split(':')[0];
        hostname = hostname.split('?')[0];

        return hostname;
    }

    return (
        <div className="app-craft">
            <div className="app-craft__head">
                <div className="app-craft__title">Employee Growth</div>
                <div className="app-craft__total">{Numeral(employees[employees.length - 1].employeeNumber).format("'0,0.[000]'")} employees</div>
            </div>
            <div className="app-craft-chart">
                <ResponsiveContainer>
                    <AreaChart
                        height={300}
                        data={chartData}
                        margin={{ left: 0, bottom: 15, right: 20, top: 15 }}
                    >
                        <defs>
                            <linearGradient
                                id="colorUv"
                                x1="0"
                                y1="0"
                                x2="0"
                                y2="1"
                            >
                                <stop
                                    offset="0%"
                                    stopColor="#95c9ff"
                                    stopOpacity={0.01}
                                />
                                <stop
                                    offset="100%"
                                    stopColor="#fff"
                                    stopOpacity={1}
                                />
                            </linearGradient>
                        </defs>
                        <CartesianGrid
                            horizontal={false}
                            strokeDasharray="3 3"
                        />
                        <XAxis
                            dy={15}
                            tick={{ fontSize: 12 }}
                            stroke="#a0a6b8"
                            tickLine={false}
                            minTickGap={60}
                            dataKey="date"
                        />
                        <YAxis
                            tick={{ fontSize: 12 }}
                            stroke="#a0a6b8"
                            dx={-15}
                            axisLine={false}
                            tickLine={false}
                            minTickGap={20}
                            tickFormatter={value => {
                                if (value === 0) return '';
                                return Numeral(value).format("0a").toUpperCase()
                            }}
                        />
                        <Tooltip />
                        <Area
                            type="monotone"
                            dataKey="employee"
                            fill="url(#colorUv)"
                            isAnimationActive={false}
                            strokeWidth={3}
                            stroke="#007cff"
                            activeDot={{ r: 6 }}
                        />
                    </AreaChart>
                </ResponsiveContainer>
            </div>

            <ul className="app-craft-metrics">
                {type ? (
                    <li>
                        <div className="app-craft-metrics-item">
                            <div className="app-craft-metrics-item__title">
                                Type
                            </div>
                            <div className="app-craft-metrics-item__descr --cap">
                                {type}
                            </div>
                        </div>
                    </li>
                ) : (
                    ''
                )}
                {founded ? (
                    <li>
                        <div className="app-craft-metrics-item">
                            <div className="app-craft-metrics-item__title">
                                Founded
                            </div>
                            <div className="app-craft-metrics-item__descr">
                                {founded}
                            </div>
                        </div>
                    </li>
                ) : (
                    ''
                )}
                {website ? (
                    <li>
                        <div className="app-craft-metrics-item">
                            <div className="app-craft-metrics-item__title">
                                Website
                            </div>
                            <div className="app-craft-metrics-item__descr --link">
                                <a href={website} rel="noopener noreferrer" target="_blank">{extractHostname(website)}</a>
                            </div>
                        </div>
                    </li>
                ) : (
                    ''
                )}
            </ul>
        </div>
    );
};

export default AppCraftChart;
