import React, { Component } from 'react';

import AppIcon from 'components/shared/AppIcon';

import './AppSocials.scss';

export default class AppSocials extends Component {
    render() {
        return (
            <ul className="app-socials">
                {this.props.socials && this.props.socials.map(({ link, title, icon }, idx) => {
                    return (
                        <li key={idx} className="app-socials__item">
                            <a href={link} rel="noopener noreferrer" target="_blank" title={title}>
                                <AppIcon icon={icon}/>
                            </a>
                        </li>
                    )
                })}
            </ul>
        );
    }
}
