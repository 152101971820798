export const isAN = (value) => {
  if (value instanceof Number) value = value.valueOf(); // Если это объект числа, то берём значение, которое и будет числом

  return isFinite(value) && value === parseInt(value, 10);
};

export const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getPercentFromValue = (part = 0, count = 0) => {
  return (100 * part) / count;
};
