import React from 'react';
import Truncate from 'react-truncate';

import AppIcon from 'components/shared/AppIcon';

import './AppJobsCardLocations.scss';

const renderCardLocationsTitle = (locations) => {
  let result = '';

  if (Array.isArray(locations)) {
    locations.forEach((location, idx) => {
      if (location.alias_region)
        result += `${location.name}, ${location.alias_region}`;
      else result += location.name;

      if (idx !== locations.length - 1 && locations.length > 0) result += '; ';
    });
  }

  return result;
};

export const AppJobsCardLocations = function ({ locations, truncate }) {
  const locationsTitle = locations ? renderCardLocationsTitle(locations) : null;

  return (
    <div className="app-jobs-card__locations" title={locationsTitle}>
      <div className="app-jobs-card__line">
        <div className="app-jobs-card__icon">
          <AppIcon icon="geo" color="gray" />
        </div>
        <div className="app-jobs-card__locations-item">
          {truncate && locationsTitle ? (
            <Truncate lines={1}>{locationsTitle}</Truncate>
          ) : (
            locationsTitle
          )}
        </div>
      </div>
    </div>
  );
};

export default AppJobsCardLocations;
