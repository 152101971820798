import React, { Component } from 'react'
import classnames from 'classnames';
import Slider from 'rc-slider';
import { debounce } from 'lodash';

import { AppPanelTitle } from 'components/shared/AppPanel';

import 'rc-slider/assets/index.css';
import './AppRangeSliderExperience.scss';

const Range = Slider.Range;
const marks = { 0: 0, 1: 1, 2: 2, 3: 3, 4: 4, 5: 5, 6: 6, 7: 7, 8: 8, 9: 9, 10: '+10' }

export default class AppRangeSliderExperience extends Component {
    constructor(props) {
        super(props);
        this.state = {
            range: props.range || [0, 10],
            prevProps: {},
        }
    }

    componentDidMount() {
        this.setState({ range: this.props.range });
    }

    static getDerivedStateFromProps(props, state) {
        const { prevProps } = state;

        if (prevProps.range !== props.range) {
            return {
                range: props.range,
                prevProps: props,
            }
        }

        return {
           prevProps: props
        }
    }

    handleOnChange = (value) => {
        this.props.handlerExperience(value);
        if (this.props.handleSubmit) this.debounceSubmit();
        if (this.props.handleCount) this.debounceCount();
        this.setState({ range: value })
    }

    debounceSubmit = debounce(() => this.props.handleSubmit(this.props.filters), 300)

    debounceCount = debounce(() => this.props.handleCount(this.props.filters), 300);

    isInitRange = (range) => {
        if (range) return range[0] === 0 && range[1] === 10;
    }

    renderRangeResult = (range) => {
        if (range) {
            let [min, max] = range;

            if (min === 0 && max === 10) return 'Any experience years';
            if (min === 1 && max === 1) return `${min} year of experience`;
            else if ( min === max ) return `${min} years of experience`;
            else return `${min}-${max} years of experience`;
        }
    }

    render() {
        const { range } = this.state;

        return (
            <div className={classnames(
                'app-range-slider',
                { '--init' : this.isInitRange(range) }
            )}>
                <div className="app-range-slider__inner">
                    <Range
                      range={true}
                      allowCross={false}
                      count={1}
                      min={0}
                      max={10}
                      value={range}
                      defaultValue={range}
                      marks={marks}
                      onChange={this.handleOnChange}
                    />
                </div>
                <div className="app-range-slider__result">
                    <AppPanelTitle defaultStyle={'second'} >{this.renderRangeResult(range)}</AppPanelTitle>
                </div>
            </div>
        )
    }
}
