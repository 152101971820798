import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import MediaQuery from 'react-responsive';
import AppLogo from 'components/shared/AppLogo';
import AppSocials from 'components/shared/AppSocials';
import * as MediaTypes from 'constants/MediaTypes';
import AppBannerEmployer from 'components/shared/AppBannerEmployer';

import logo from 'assets/images/svg/logo-white.svg';
import flagImg from 'assets/images/austin-flag.png';

import * as PathTypes from 'constants/PathTypes';
import history from 'history.js';

import './AppFooter.scss';

const employerShowPaths = [
  PathTypes.JOBS,
  PathTypes.JOBS + '/',
  PathTypes.LOGIN,
  PathTypes.LOGIN + '/',
  PathTypes.SIGNUP,
  PathTypes.SIGNUP + '/',
  PathTypes.TERMS,
  PathTypes.TERMS + '/',
  PathTypes.PRIVACY_POLICY,
  PathTypes.PRIVACY_POLICY + '/',
  PathTypes.ABOUT,
  PathTypes.ABOUT + '/',
  PathTypes.CONTACT,
  PathTypes.CONTACT + '/',
  PathTypes.USER_PROFILE_MAIN,
  PathTypes.USER_PROFILE_MAIN + '/',
  PathTypes.USER_PROFILE_NOTIFICATIONS,
  PathTypes.USER_PROFILE_NOTIFICATIONS + '/',
  PathTypes.USER_PROFILE_SEARCH_ALARMS,
  PathTypes.USER_PROFILE_SEARCH_ALARMS + '/',
  PathTypes.USER_PROFILE_SAVED_JOBS,
  PathTypes.USER_PROFILE_SAVED_JOBS + '/',
  PathTypes.USER_PROFILE_APPLIED_JOBS,
  PathTypes.USER_PROFILE_APPLIED_JOBS + '/',
];

const FOOTER_NAV = [
  {
    heading: 'Company',
    links: [
      {
        title: 'Terms',
        href: PathTypes.TERMS,
        blank: true,
      },
      {
        title: 'Privacy',
        href: PathTypes.PRIVACY_POLICY,
        blank: true,
      },
      {
        title: 'Jobs for your Slack',
        href: PathTypes.SLACK_BOT_PAGE,
        blank: true,
      },
      {
        title: 'Send us Feedback',
        href: `mailto:${PathTypes.APP_EMAIL}`,
        blank: true,
        mail: true,
      },
      {
        title: 'Contact Us',
        href: `mailto:${PathTypes.APP_EMAIL}`,
        blank: true,
        mail: true,
      },
    ],
  },
  {
    heading: 'Employer',
    links: [
      {
        title: 'Recruit with Us',
        href: PathTypes.EMPLOYER_POSTAJOB,
      },
      {
        title: 'Pricing',
        href: PathTypes.EMPLOYER_HOME,
      },
      {
        title: 'Advertise with Us',
        href: `mailto:${PathTypes.APP_EMAIL}`,
        blank: true,
        mail: true,
      },
    ],
  },
  {
    heading: 'Interview Guides',
    links: [
      {
        title: 'Google Interview',
        href: PathTypes.GOOGLE_GUIDE_LINK,
      },
      {
        title: 'Facebook Interview',
        href: PathTypes.FACEBOOK_GUIDE_LINK,
      },
      {
        title: 'Amazon Interview',
        href: PathTypes.AMAZON_GUIDE_LINK,
      },
      {
        title: 'Uber Interview',
        href: PathTypes.UBER_GUIDE_LINK,
      },
      {
        title: 'Microsoft Interview',
        href: PathTypes.MICROSOFT_GUIDE_LINK,
      },
    ],
  },
  {
    heading: 'Remote Jobs',
    links: [
      {
        title: 'Remote Product Manager Jobs',
        href: PathTypes.JOBS_LINK_REMOTE_PM,
        blank: true,
      },
      {
        title: 'Remote PMM Jobs',
        href: PathTypes.JOBS_LINK_REMOTE_PMM,
        blank: true,
      },
      {
        title: 'Remote Product Design Jobs',
        href: PathTypes.JOBS_LINK_REMOTE_PD,
        blank: true,
      },
      {
        title: 'Remote UX Research Jobs',
        href: PathTypes.JOBS_LINK_REMOTE_UXR,
        blank: true,
      },
      {
        title: 'Remote Data Science Jobs',
        href: PathTypes.JOBS_LINK_REMOTE_DS,
        blank: true,
      },
    ],
  },
  {
    heading: 'Coaches by Role',
    links: [
      {
        title: 'Product Management Coaches',
        href: PathTypes.COACHES_LINK_ROLE_PM,
        blank: true,
      },
      {
        title: 'PMM Coaches',
        href: PathTypes.COACHES_LINK_ROLE_PMM,
        blank: true,
      },
      {
        title: 'Product Design Coaches',
        href: PathTypes.COACHES_LINK_ROLE_PD,
        blank: true,
      },
      {
        title: 'UX Research Coaches',
        href: PathTypes.COACHES_LINK_ROLE_UXR,
        blank: true,
      },
      {
        title: 'Project Management Coaches',
        href: PathTypes.COACHES_LINK_ROLE_PRM,
        blank: true,
      },
      {
        title: 'Data Science Coaches',
        href: PathTypes.COACHES_LINK_ROLE_DS,
        blank: true,
      },
    ],
  },
  {
    heading: 'Coaches by Service',
    links: [
      {
        title: 'Portfolio Review Coaches',
        href: PathTypes.COACHES_LINK_SERVICE_TYPE_PR,
        blank: true,
      },
      {
        title: 'Resume Review Coaches',
        href: PathTypes.COACHES_LINK_SERVICE_TYPE_RR,
        blank: true,
      },
      {
        title: 'Career Coaching Coaches',
        href: PathTypes.COACHES_LINK_SERVICE_TYPE_CC,
        blank: true,
      },
      {
        title: 'Interview Prep Coaches',
        href: PathTypes.COACHES_LINK_SERVICE_TYPE_PREP,
        blank: true,
      },
    ],
  },
  {
    heading: 'Jobs by Location',
    links: [
      {
        title: 'Product Manager Jobs in Austin',
        href: PathTypes.JOBS_LINK_LOCATION_PM_AUSTIN,
        blank: true,
      },
      {
        title: 'Product Manager Jobs in SF',
        href: PathTypes.JOBS_LINK_LOCATION_PM_SF,
        blank: true,
      },
      {
        title: 'Product Manager Jobs in NY',
        href: PathTypes.JOBS_LINK_LOCATION_PM_NY,
        blank: true,
      },
      {
        title: 'UI/UX Designer Jobs in Austin',
        href: PathTypes.JOBS_LINK_LOCATION_UXD_AUSTIN,
        blank: true,
      },
      {
        title: 'UI/UX Designer Jobs in SF',
        href: PathTypes.JOBS_LINK_LOCATION_UXD_SF,
        blank: true,
      },
      {
        title: 'UI/UX Designer Jobs in NY',
        href: PathTypes.JOBS_LINK_LOCATION_UXD_NY,
        blank: true,
      },
    ],
  },
  {
    heading: 'Jobs by Role',
    links: [
      {
        title: 'Product Manager Jobs',
        href: PathTypes.JOBS_LINK_ROLE_PM,
        blank: true,
      },
      {
        title: 'PMM Jobs',
        href: PathTypes.JOBS_LINK_ROLE_PMM,
        blank: true,
      },
      {
        title: 'Product Design Jobs',
        href: PathTypes.JOBS_LINK_ROLE_PD,
        blank: true,
      },
      {
        title: 'UX Research Jobs',
        href: PathTypes.JOBS_LINK_ROLE_UXR,
        blank: true,
      },
      {
        title: 'Data Science Jobs',
        href: PathTypes.JOBS_LINK_ROLE_DS,
        blank: true,
      },
    ],
  },
];

const socials = [
  { title: 'LinkedIn', link: PathTypes.LINKEDIN, icon: 'in' },
  { title: 'Facebook', link: PathTypes.FACEBOOK, icon: 'fb' },
  { title: 'Twitter', link: PathTypes.TWITTER, icon: 'tw' },
];

const AppFooter = ({ location }) => (
  <footer className="app-footer">
    {location &&
    location.pathname &&
    employerShowPaths.some((item) => item === location.pathname) ? (
      <div className="app-footer__top">
        <AppBannerEmployer
          title="Are you an employer?"
          description={[
            'Posting a job on ProductHired gives you instant access to a focused marketplace of Product professionals.',
            'Start today by posting your position for free!',
          ]}
          btnTitle="Learn More"
          onClick={() => history.push(PathTypes.EMPLOYER_HOME)}
        />
      </div>
    ) : (
      ''
    )}
    <div className="app-footer__nav">
      <div className="app-container">
        <div className="app-footer__inner">
          <div className="app-footer__row --logo">
            <MediaQuery minWidth={MediaTypes.LT + 1}>
              <div className="app-footer__logo">
                <Link className="app-link" to={PathTypes.HOME}>
                  <AppLogo src={logo} />
                </Link>
              </div>
            </MediaQuery>

            <div className="app-footer__text">
              <p className="app-text">
                Made with{' '}
                <span style={{ color: 'red' }} aria-label="emojy" role="img">
                  &#10084;
                </span>{' '}
                in Austin, TX{' '}
                <img
                  className="--inline --offset-left"
                  src={flagImg}
                  alt="Austin, TX"
                />
              </p>
            </div>
          </div>

          <MediaQuery minWidth={MediaTypes.SM + 1}>
            <div className="app-divider app-divider--dark"></div>

            <div className="app-footer__row --nav">
              {FOOTER_NAV.map((block, idx) => (
                <div key={idx} className="app-footer__col">
                  <h4 className="app-title-nav">{block.heading}</h4>
                  <ul className="app-nav-list --small">
                    {block.links.map((link, i) => (
                      <li key={i} className="app-nav-list__item">
                        {link.mail ? (
                          <a
                            className="app-link --white --light-bold"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={link.href}
                          >
                            {link.title}
                          </a>
                        ) : (
                          <Link
                            className="app-link --white --light-bold"
                            target={link.blank ? '_blank' : null}
                            to={link.href}
                          >
                            {link.title}
                          </Link>
                        )}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </MediaQuery>
          <div className="app-divider app-divider--dark"></div>
          <div className="app-footer__bottom">
            <div className="app-footer__copyright">
              <p className="app-text">
                (c) { new Date().getFullYear() } ProductHired. All rights reserved
              </p>
            </div>
            <div className="app-footer__socials">
              <AppSocials socials={socials} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default withRouter(AppFooter);
