import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router';
import axios from 'axios';
import { API_URL } from 'constants/PathTypes';

import { addParamsToFilters, getJsonFromURL, objectKeysStringToArray } from 'utils';
import history from 'history.js';

import './AdWrapper.scss';

class AdWrapper extends Component {
  state = {
    pageConfig: null,
    pageConfigLoaded: false,
    campaigns: {}
  };

  queue = [];
  running = null;
  finished = false;
  timer = 0;

  componentDidMount() {
    this.startTimer();
  }

  componentWillUnmount() {
    this.stopTimer();
  }

  startTimer() {
    this.timer = setTimeout(() => {
      this.loadPageConfig();
    }, 100);
  }

  stopTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = 0;
    }
  }

  loadPageConfig = async () => {
    const response = await axios.get(`${API_URL}/campaigns/getPageConfig`, {
      headers: { authorization: localStorage.getItem('token') },
      params: {
        url: history.location.pathname.slice(1)
      }
    });

    this.setState({
      pageConfig: response.data && Object.keys(response.data).length ? response.data : null,
      pageConfigLoaded: true
    });
  };

  startLoading = async () => {
    const { pageConfig } = this.state;

    if (this.finished || typeof this.running === 'number' || !this.queue.length || !pageConfig) {
      return;
    }

    const key = this.getQueueItem();

    if (typeof key !== 'number' || this.state.campaigns[key]) {
      return;
    }

    try {
      const { sourceFilters, availableFilters = [] } = this.props;

      const urlParams = objectKeysStringToArray(
          getJsonFromURL(history.location.search)
      );
      const filters = sourceFilters
          ? Object.keys(sourceFilters).map(filterName => ({
            type: filterName,
            slugs: sourceFilters[filterName].map(filter => filter.slug)
          }))
          : addParamsToFilters(urlParams);
      const adFilters = {};
      (filters || []).forEach(filter => {
        adFilters[filter.type] = filter.slugs;
      });

      availableFilters.forEach((filterName) => {
        if (!adFilters[filterName]) {
          adFilters[filterName] = [];
        }
      });

      const response = await axios.get(`${API_URL}/campaigns/getProposal`, {
        headers: { authorization: localStorage.getItem('token') },
        params: {
          pageId: pageConfig.id,
          filters: adFilters,
          ignoreCampaigns: Object.values(this.state.campaigns).map(ad => ad.campaign_id)
        }
      });

      if (response.data && Object.keys(response.data).length) {
        this.setState({
          campaigns: Object.assign({}, this.state.campaigns, {
            [key]: response.data
          })
        }, () => {
          this.stopLoading(key);
        });
      } else {
        this.finished = true;

        this.stopLoading(key);
      }
    } catch (error) {
      this.stopLoading(key);
    }
  };

  addQueueItem = (value) => {
    if (this.running !== value && !this.queue.includes(value)) {
      this.queue.push(value);
    }

    this.startLoading();
  };

  getQueueItem = () => {
    if (typeof this.running === 'number') {
      return;
    }

    this.running = this.queue.shift();

    return this.running;
  };

  stopLoading = () => {
    this.running = null;

    this.startLoading();
  };

  sendLog = (campaignId) => {
    return axios.post(`${API_URL}/campaign_logs/log`, {
      action: 'click',
      url: window.location.href,
      campaign_id: campaignId,
    }, {
      headers: { authorization: localStorage.getItem('token') },
    });
  }

  onClick = (campaignId) => {
    this.sendLog(campaignId);

    return true;
  };

  renderAd = (key) => {
    const { pageConfig, campaigns } = this.state;

    if (!pageConfig || !campaigns[key]) {
      if (pageConfig && !campaigns[key] && this.running !== key && !this.queue.includes(key)) {
        this.addQueueItem(key);
      }

      return null;
    }

    const { showMobileViewAlways = false } = this.props;

    const adData = campaigns[key];

    return (
      <div className="ph-proposal">
        <a
          href={adData.link}
          target="_blank"
          rel="noopener noreferrer"
          className="ph-proposal__link ph-proposal__link--desktop"
          onClick={() => this.onClick(adData.campaign_id)}
        >
          <img
            src={!showMobileViewAlways ? adData.desktop_banner : adData.mobile_banner}
            alt={adData.title}
            className="ph-proposal__img"
          />
        </a>
        <a
          href={adData.link}
          target="_blank"
          rel="noopener noreferrer"
          className="ph-proposal__link ph-proposal__link--mobile"
          onClick={() => this.onClick(adData.campaign_id)}
        >
          <img
            src={adData.mobile_banner}
            alt={adData.title}
            className="ph-proposal__img"
          />
        </a>
      </div>
    );
  }

  render() {
    const { children, list } = this.props;
    const { pageConfig, pageConfigLoaded } = this.state;

    if (!pageConfigLoaded) {
      return null;
    }

    const step = pageConfig && pageConfig.step ? pageConfig.step : this.props.defaultStep || 10;

    if (typeof children === 'function') {
      if (Array.isArray(list)) {
        if (!pageConfig) {
          return (
            <Fragment>
              {
                list.map((item, index) => (
                  <Fragment key={index}>
                    {children.apply(children, [item, index])}
                  </Fragment>
                ))
              }
            </Fragment>
          );
        }

        return (
          <Fragment>
            {
              list.map((item, index) => (
                <Fragment key={index}>
                  {children.apply(children, [item, index])}
                  {
                    (
                      (index + 1) % step === 0 ||
                      (
                        step > list.length &&
                        index === list.length - 1
                      )
                    )
                      ? this.renderAd(index)
                      : null
                  }
                </Fragment>
              ))
            }
          </Fragment>
        );
      }

      if (!pageConfig) {
        return children.apply(children, [0]);
      }

      return (
        <Fragment>
          {children.apply(children, [0, step])}
          {this.renderAd(0)}
          {children.apply(children, [step])}
        </Fragment>
      );
    }

    return this.renderAd(0);
  }
}

export default withRouter(AdWrapper);
