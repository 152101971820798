import axios from 'axios';

import { unauthUser } from './UserActions';
import {
  setModal,
  setUploadProgress,
  setNotification,
  clearNotification,
  setLoading,
} from './SystemActions';

import { API_URL } from 'constants/PathTypes';
import * as ActionTypes from 'constants/ActionTypes';
import * as ErrorsTypes from 'constants/ErrorsTypes';
import * as PathTypes from 'constants/PathTypes';

import { createSearchQuery } from 'actions/querys';

import history from 'history.js';
import store from 'store';

export function receiveVacancies(payload) {
  return {
    type: ActionTypes.RECEIVE_VACANCIES,
    payload,
  };
}

export function updateVacancies(payload) {
  return {
    type: ActionTypes.UPDATE_VACANCIES,
    payload,
  };
}

export function setPostJobSelectedCompany(payload) {
  return {
    type: ActionTypes.SET_POST_JOB_SELECTED_COMPANY,
    payload,
  };
}

export function setPostJobId(payload) {
  return {
    type: ActionTypes.SET_POST_JOB_ID,
    payload,
  };
}

export function setPostJobCompanyTitle(payload) {
  return {
    type: ActionTypes.SET_POST_JOB_COMPANY_TITLE,
    payload,
  };
}

export function setPostJobRole(payload) {
  return {
    type: ActionTypes.SET_POST_JOB_ROLE,
    payload,
  };
}

export function setPostJobDetailsHtml(payload) {
  return {
    type: ActionTypes.SET_POST_JOB_DETAILS_HTML,
    payload,
  };
}

export function setPostJobDetailsExperience(payload) {
  return {
    type: ActionTypes.SET_POST_JOB_EXPERIENCE,
    payload,
  };
}

export function setPostJobTitle(payload) {
  return {
    type: ActionTypes.SET_POST_JOB_TITLE,
    payload,
  };
}

export function setPostJobSkillsTitle(payload) {
  return {
    type: ActionTypes.SET_POST_JOB_SKILLS_TITLE,
    payload,
  };
}

export function setPostJobRolesList(payload) {
  return {
    type: ActionTypes.SET_POST_JOB_ROLES_LIST,
    payload,
  };
}

export function setPostJobActiveRole(payload) {
  return {
    type: ActionTypes.SET_POST_JOB_ACTIVE_ROLE,
    payload,
  };
}

export function setPostJobApplicationLink(payload) {
  return {
    type: ActionTypes.SET_POST_JOB_APLICATION_LINK,
    payload,
  };
}

export function setPostJobLocationTitle(payload) {
  return {
    type: ActionTypes.SET_POST_JOB_LOCATION_TITLE,
    payload,
  };
}

export function addPostJobLocationSlug(payload) {
  return {
    type: ActionTypes.ADD_POST_JOB_LOCATION_SLUG,
    payload,
  };
}

export function removePostJobLocationSlug(payload) {
  return {
    type: ActionTypes.REMOVE_POST_JOB_LOCATION_SLUG,
    payload,
  };
}

export function addPostJobSkillSlug(payload) {
  return {
    type: ActionTypes.ADD_POST_JOB_SKILL_SLUG,
    payload,
  };
}

export function removePostJobSkillSlug(payload) {
  return {
    type: ActionTypes.REMOVE_POST_JOB_SKILL_SLUG,
    payload,
  };
}

export function setPostJobLocationRemoteByValueAndData(payload) {
  return {
    type: ActionTypes.SET_POST_JOB_LOCATION_REMOTE_BY_VALUE_AND_DATE,
    payload,
  };
}

export function toggleUserSavedJobsVacancieSavedPropById(payload) {
  return {
    type: ActionTypes.TOGGLE_SAVED_JOBS_VACANCIE_SAVED_PROP_BY_ID,
    payload,
  };
}

export function toggleVacancieByIdSavedProp() {
  return {
    type: ActionTypes.TOGGLE_VACANCIE_BY_ID_SAVED_PROP,
  };
}

export function toggleVacancieByIdAppliedProp() {
  return {
    type: ActionTypes.TOGGLE_VACANCIE_BY_ID_APPLIED_PROP,
  };
}

export function addSavedVacancieToUserSavedJobs(payload) {
  return {
    type: ActionTypes.ADD_SAVED_VACANCIE_TO_USER_SAVED_JOBS,
    payload,
  };
}

export function addAppliedVacancieToUserAppliedJobs(payload) {
  return {
    type: ActionTypes.ADD_APPLIED_VACANCIE_TO_USER_APPLIED_JOBS,
    payload,
  };
}

export function removeSavedVacancieFromUserSavedJobsById(payload) {
  return {
    type: ActionTypes.REMOVE_SAVED_VACANCIE_FROM_USER_SAVED_JOBS_BY_ID,
    payload,
  };
}

export function toggleVacancieSearchListSavedPropById(payload) {
  return {
    type: ActionTypes.TOGGLE_VACANCIE_SEARCH_LIST_SAVED_PROP_BY_ID,
    payload,
  };
}

export function toggleVacancieMotivatedListSavedPropById(payload) {
  return {
    type: ActionTypes.TOGGLE_VACANCIE_MOTIVATED_LIST_SAVED_PROP_BY_ID,
    payload,
  };
}

export function toggleVacancieSearchListAppliedPropById(payload) {
  return {
    type: ActionTypes.TOGGLE_VACANCIE_SEARCH_LIST_APPLIED_PROP_BY_ID,
    payload,
  };
}

export function toggleVacancieMotivatedListAppliedPropById(payload) {
  return {
    type: ActionTypes.TOGGLE_VACANCIE_MOTIVATED_LIST_APPLIED_PROP_BY_ID,
    payload,
  };
}

export function setUserSavedJobsList(payload) {
  return {
    type: ActionTypes.SET_USER_SAVED_JOBS,
    payload,
  };
}

export function setUserAppliedJobsList(payload) {
  return {
    type: ActionTypes.SET_USER_APPLIED_JOBS,
    payload,
  };
}

export function setUserStorePostMainLogo(payload) {
  return {
    type: ActionTypes.SET_USER_POST_MAIN_LOGO,
    payload,
  };
}

export function setUserStorePostCoverImage(payload) {
  return {
    type: ActionTypes.SET_USER_POST_COVER_IMAGE,
    payload,
  };
}

export function setFetcinghUserAppliedVacancieList(payload) {
  return {
    type: ActionTypes.SET_FETCHING_USER_APPLIED_JOBS_LIST,
    payload,
  };
}

export function setFetchingUserSavedJobsList(payload) {
  return {
    type: ActionTypes.SET_FETCHING_USER_SAVED_JOBS_LIST,
    payload,
  };
}

export function fetchUserSavedVacancieListById(userId) {
  return async (dispatch) => {
    dispatch(setFetchingUserSavedJobsList(true));

    dispatch({
      type: 'FETCH_USER_SAVED_JOBS_LIST_BY_ID',
    });

    try {
      const response = await axios.get(
        `${API_URL}/users/${userId}/saved-jobs`,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(setUserSavedJobsList(response.data));
      dispatch(setFetchingUserSavedJobsList(false));
      return response.data;
    } catch (error) {
      const { message, statusCode } = error.payload.data.error;

      if (statusCode === 401) dispatch(unauthUser());

      dispatch(setFetchingUserSavedJobsList(false));
      dispatch(setNotification({ message, type: 'info', status: 'info' }));
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function fetchUserAppliedVacancieListById(userId) {
  return async (dispatch) => {
    dispatch(setFetcinghUserAppliedVacancieList(true));

    dispatch({
      type: 'FETCH_USER_APPLIED_JOBS_LIST_BY_ID',
    });

    try {
      const response = await axios.get(
        `${API_URL}/users/${userId}/applied-jobs`,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(setUserAppliedJobsList(response.data));
      dispatch(setFetcinghUserAppliedVacancieList(false));
      return response.data;
    } catch (error) {
      const { message, statusCode } = error.payload.data.error;

      if (statusCode === 401) dispatch(unauthUser());

      dispatch(setFetcinghUserAppliedVacancieList(false));
      dispatch(setNotification({ message, type: 'info', status: 'info' }));
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function fetchSaveUserSavedVacancieById(id) {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(`${API_URL}/vacancies/${id}/save`, {
        headers: { authorization: localStorage.getItem('token') },
      });

      // analitics
      if (window.gtag) {
        window.gtag('event', 'Save Job', { id });
      }

      dispatch(
        setNotification({
          message: 'Job has been saved to your list',
          status: 'info',
          type: 'job',
          data: response.data.vacancy,
          link: {
            path: PathTypes.USER_PROFILE_SAVED_JOBS,
            text: 'See all Saved jobs',
          },
        })
      );

      setTimeout(() => dispatch(clearNotification()), 5000);
      dispatch(setLoading(false));
      return response.data;
    } catch (response) {
      const { error } = response.payload.data;

      if (error.statusCode === 401) dispatch(unauthUser());

      dispatch(setLoading(false));
      if (error.code === ErrorsTypes.ER_DUP_ENTRY) {
        dispatch(
          setNotification({
            message: 'Already Saved',
            status: 'info',
            type: 'info',
          })
        );
        setTimeout(() => dispatch(clearNotification()), 5000);
      } else if (error.code === ErrorsTypes.AUTHORIZATION_REQUIRED) {
        dispatch(
          setModal({
            active: 'signup',
            notification: true,
            title: 'In order to save a job, you need to sign-up first',
          })
        );

        const lastRequest = {
          name: 'saveJob',
          params: { id },
        };

        localStorage.setItem('ph_last_action', JSON.stringify(lastRequest));
      }

      throw error;
    }
  };
}

export function fetchApplyJobUserVacancieByIdAndData({
  id,
  data,
  loggedIn,
  jobName,
  company,
}) {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const response = await axios.post(
        `${API_URL}/vacancies/${id}/apply`,
        data,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      const notificationData = response.data.vacancy;

      // analitics
      if (window.gtag) {
        window.gtag('event', 'Submit Application', { label: id });
      }

      // apply flow
      if (loggedIn) {
        if (store.getState().system.notification) {
          setTimeout(() => {
            dispatch(
              setNotification({
                message: 'Job has been saved to your apply list',
                status: 'info',
                type: 'job',
                data: notificationData,
                link: {
                  path: PathTypes.USER_PROFILE_APPLIED_JOBS,
                  text: 'See all Applied jobs',
                },
              })
            );
            setTimeout(() => dispatch(clearNotification()), 5000);
          }, 4000);
        } else {
          dispatch(
            setNotification({
              message: 'Job has been saved to your apply list',
              status: 'info',
              type: 'job',
              data: notificationData,
              link: {
                path: PathTypes.USER_PROFILE_APPLIED_JOBS,
                text: 'See all Applied jobs',
              },
            })
          );
          setTimeout(() => dispatch(clearNotification()), 5000);
        }
      } else {
        dispatch(
          setModal({
            active: 'applyLogin',
            data: {
              name: data.name,
              jobName,
              company,
              email: data.email,
              notificationData,
            },
          })
        );
        setTimeout(() => dispatch(clearNotification()), 5000);
      }

      dispatch(setLoading(false));

      return response.data;
    } catch (response) {
      const { error } = response.payload.data;

      if (error.statusCode === 401) dispatch(unauthUser());

      if (error.code === ErrorsTypes.ER_DUP_ENTRY) {
        if (store.getState().system.notification) {
          setTimeout(() => {
            dispatch(
              setNotification({
                message: 'Already Applied',
                status: 'info',
                type: 'info',
              })
            );
            setTimeout(() => dispatch(clearNotification()), 5000);
          }, 4000);
        } else {
          dispatch(
            setNotification({
              message: 'Already Applied',
              status: 'info',
              type: 'info',
            })
          );
          setTimeout(() => dispatch(clearNotification()), 5000);
        }
      }

      dispatch(setLoading(false));
      throw error;
    }
  };
}

export const uploadVacancyApplyResume = (file) => {
  return async (dispatch) => {
    dispatch(clearNotification());
    dispatch(setLoading(true));

    try {
      const response = await axios.post(`${API_URL}/files/upload`, file, {
        onUploadProgress: (progressEvent) => {
          const total = progressEvent.total;
          const loaded = progressEvent.loaded;

          const percent = (100 / total) * loaded;

          dispatch(setUploadProgress(percent));
        },
      });

      dispatch(setLoading(false));
      dispatch(
        setNotification({
          message: 'Your resume is attached!',
          type: 'info',
          status: 'info',
        })
      );

      setTimeout(() => dispatch(clearNotification()), 5000);
      return response.data;
    } catch (error) {
      const { message, statusCode } = error.payload.data.error;

      if (statusCode === 401) dispatch(unauthUser());

      dispatch(setLoading(false));
      dispatch(
        setNotification({ message: message, type: 'info', status: 'info' })
      );
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
};

export const uploadJobMainLogo = (file) => {
  return async (dispatch) => {
    dispatch(clearNotification());
    dispatch(setLoading(true));

    try {
      const response = await axios.post(`${API_URL}/vacancies/logo`, file, {
        headers: {
          authorization: localStorage.getItem('token'),
        },
        onUploadProgress: ({ total, loaded }) => {
          const percent = (100 / total) * loaded;
          dispatch(setUploadProgress(percent));
        },
      });

      dispatch(setLoading(false));
      dispatch(
        setNotification({
          message: 'File uploaded!',
          type: 'info',
          status: 'info',
        })
      );

      setTimeout(() => dispatch(clearNotification()), 5000);
      return response.data;
    } catch (error) {
      const { message, statusCode } = error.payload.data.error;

      if (statusCode === 401) dispatch(unauthUser());
      else if (statusCode === 500) {
        dispatch(
          setNotification({
            message: 'Your file is the wrong format, only .JPG or .PNG allowed',
            type: 'info',
            status: 'info',
          })
        );
      } else {
        dispatch(setLoading(false));
        dispatch(
          setNotification({ message: message, type: 'info', status: 'info' })
        );
      }

      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
};

export const uploadJobCoverLogo = (file) => {
  return async (dispatch) => {
    dispatch(clearNotification());
    dispatch(setLoading(true));

    try {
      const response = await axios.post(`${API_URL}/vacancies/cover`, file, {
        headers: {
          authorization: localStorage.getItem('token'),
        },
        onUploadProgress: (progressEvent) => {
          const total = progressEvent.total;
          const loaded = progressEvent.loaded;
          const percent = (100 / total) * loaded;

          dispatch(setUploadProgress(percent));
        },
      });

      dispatch(setLoading(false));
      dispatch(
        setNotification({
          message: 'File uploaded!',
          type: 'info',
          status: 'info',
        })
      );

      setTimeout(() => dispatch(clearNotification()), 5000);
      return response.data;
    } catch (error) {
      const { message, statusCode } = error.payload.data.error;

      if (statusCode === 401) dispatch(unauthUser());

      dispatch(setLoading(false));
      dispatch(
        setNotification({ message: message, type: 'info', status: 'info' })
      );
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
};

export function fetchUnsaveUserSavedVacancieById(id) {
  return async (dispatch) => {
    dispatch(setLoading(true));

    try {
      const response = await axios.get(`${API_URL}/vacancies/${id}/unSave`, {
        headers: { authorization: localStorage.getItem('token') },
      });
      dispatch(setLoading(false));
      return response;
    } catch (error) {
      const { message, statusCode } = error.payload.data.error;

      if (statusCode === 401) dispatch(unauthUser());

      dispatch(setLoading(false));
      dispatch(setNotification({ message, type: 'info', status: 'info' }));
      setTimeout(() => dispatch(clearNotification()), 5000);
      throw error;
    }
  };
}

export function receiveVacancieById(payload) {
  return {
    type: ActionTypes.RECEIVE_VACANCIE_BY_ID,
    payload,
  };
}

export function receiveVacanciesCountAll(payload) {
  return {
    type: ActionTypes.RECEIVE_VACANCIES_COUNT_ALL,
    payload,
  };
}

export function setFetchingVacancies(payload) {
  return {
    type: ActionTypes.SET_FETCHING_VACANCIES,
    payload,
  };
}

export function setFetchingVacancieById(payload) {
  return {
    type: ActionTypes.SET_FETCHING_VACANCIE_BY_ID,
    payload,
  };
}

export function setVacanciesSort(payload) {
  return {
    type: ActionTypes.SET_VACANCIES_SORT,
    payload,
  };
}

export function setFetchingVacanciesCountAll(payload) {
  return {
    type: ActionTypes.SET_FETCHING_VACANCIES_COUNT_ALL,
    payload,
  };
}

export function setMotivatedVacancies(payload) {
  return {
    type: ActionTypes.RECEIVE_INIT_STATE_MOTIVATED_VACANCIES,
    payload,
  };
}

export function setFetchingMotivatedVacancies(payload) {
  return {
    type: ActionTypes.SET_FETCHING_INIT_STATE_MOTIVATED_VACANCIES,
    payload,
  };
}

export function setFetchingUserTopMatchesVacancies(payload) {
  return {
    type: ActionTypes.SET_FETCHING_USER_TOP_MATCHES_VACANCIES,
    payload,
  };
}

export function receiveUserTopMatchesVacancies(payload) {
  return {
    type: ActionTypes.RECEIV_USER_TOP_MATCHES_VACANCIES,
    payload,
  };
}

export function clearUserTopMatchesVacancies(payload) {
  return {
    type: ActionTypes.CLEAR_USER_TOP_MATCHES_VACANCIES,
    payload,
  };
}

export function clearUserStorePost(payload) {
  return {
    type: ActionTypes.CLEAR_USER_STORE_POST,
    payload,
  };
}

export function setPostJobSeniority(payload) {
  return {
    type: ActionTypes.SET_POST_JOB_SENIORITY,
    payload,
  };
}

export function setPostJobPlans(payload) {
  return {
    type: ActionTypes.SET_POST_JOB_PLANS,
    payload,
  };
}

export function receiveJobDetailsCraftData(payload) {
  return {
    type: ActionTypes.RECEIV_JOB_DETAILS_CRAFT_DATA,
    payload,
  };
}

export function setFetchingJobDetailsCraftData(payload) {
  return {
    type: ActionTypes.SET_FETCHING_JOB_DETAILS_CRAFT_DATA,
    payload,
  };
}

export function fetchMotivatedVacancies(count = 8) {
  return async (dispatch) => {
    dispatch(setFetchingMotivatedVacancies(true));
    dispatch(setMotivatedVacancies([]));

    try {
      const response = await axios.get(
        `${API_URL}/vacancies/motivated?count=${count}`,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(setMotivatedVacancies(response.data));
      dispatch(setFetchingMotivatedVacancies(false));
    } catch (error) {
      const { message, statusCode } = error.payload.data.error;

      if (statusCode === 401) dispatch(unauthUser());

      dispatch(setFetchingMotivatedVacancies(false));
      dispatch(
        setNotification({
          message: message || 'Fetch Motivated Companies error',
          type: 'info',
          status: 'info',
        })
      );
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function fetchVacancieById(id) {
  return async (dispatch) => {
    dispatch(setFetchingVacancieById(true));

    try {
      const response = await axios.get(`${API_URL}/vacancies/${id}`, {
        headers: { authorization: localStorage.getItem('token') },
      });

      dispatch(setFetchingVacancieById(false));
      return response.data;
    } catch (error) {
      const { message, statusCode } = error.payload.data.error;

      if (statusCode === 401) dispatch(unauthUser());
      if (statusCode === 404) history.push(PathTypes.PAGE404);

      dispatch(setFetchingVacancieById(false));
      dispatch(setNotification({ message, type: 'info', status: 'info' }));
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function fetchVacancieByIdForEdit(id) {
  return async (dispatch) => {
    dispatch(setFetchingVacancieById(true));

    try {
      const response = await axios.get(`${API_URL}/vacancies/${id}/forEdit`, {
        headers: { authorization: localStorage.getItem('token') },
      });

      dispatch(setFetchingVacancieById(false));
      return response.data;
    } catch (error) {
      const { message, statusCode } = error.payload.data.error;

      if (statusCode === 401) dispatch(unauthUser());
      else if (statusCode === 404) history.push();

      dispatch(setFetchingVacancieById(false));
      dispatch(setNotification({ message, type: 'info', status: 'info' }));
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function fetchVacanciesByFilters(params) {
  return async (dispatch) => {
    dispatch(setFetchingVacancies(true));

    const query = createSearchQuery(params, 'filters');

    try {
      const response = await axios.get(
        `${API_URL}/vacancies/search?query=${encodeURIComponent(query)}`,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(receiveVacancies(response.data));
      dispatch(setFetchingVacancies(false));
    } catch (error) {
      const { message, statusCode } = error.payload.data.error;

      if (statusCode === 401) dispatch(unauthUser());

      dispatch(setFetchingVacancies(false));
      dispatch(setNotification({ message, type: 'info', status: 'info' }));
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function fetchMoreVacanciesByFilters(params) {
  return async (dispatch) => {
    dispatch(setFetchingVacancies(true));

    const query = createSearchQuery(params, 'filters');

    try {
      const response = await axios.get(
        `${API_URL}/vacancies/search?query=${encodeURIComponent(query)}`,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(updateVacancies(response.data));
      dispatch(setFetchingVacancies(false));
    } catch (error) {
      const { message, statusCode } = error.payload.data.error;

      if (statusCode === 401) dispatch(unauthUser());

      dispatch(setFetchingVacancies(false));
      dispatch(setNotification({ message, type: 'info', status: 'info' }));
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function fetchVacanciesCountAll(params) {
  return async (dispatch) => {
    dispatch(setFetchingVacanciesCountAll(true));

    const query = createSearchQuery(params, 'count');

    try {
      const response = await axios.get(
        `${API_URL}/vacancies/searchCount?query=${encodeURIComponent(query)}`,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(receiveVacanciesCountAll(response.data.count));
      dispatch(setFetchingVacanciesCountAll(false));

      return response.data.count;
    } catch (error) {
      const { message, statusCode } = error.payload.data;

      if (statusCode === 401) dispatch(unauthUser());

      dispatch(setFetchingVacanciesCountAll(false));
      dispatch(setNotification({ message, type: 'info', status: 'info' }));
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function fetchCompanieJobCount(params) {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${API_URL}/vacancies/searchCount?query=${params}`,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      return response.data.count;
    } catch (error) {
      const { message, statusCode } = error.payload.data;

      if (statusCode === 401) dispatch(unauthUser());

      dispatch(setNotification({ message, type: 'info', status: 'info' }));
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function fetchUserTopMatchesVacanciesById(userId) {
  return async (dispatch) => {
    dispatch(setFetchingUserTopMatchesVacancies(true));

    try {
      const response = await axios.get(
        `${API_URL}/users/${userId}/matches-jobs`,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(receiveUserTopMatchesVacancies(response.data));
      dispatch(setFetchingUserTopMatchesVacancies(false));
    } catch (error) {
      const { message, statusCode } = error.payload.data;

      if (statusCode === 401) dispatch(unauthUser());

      dispatch(setFetchingUserTopMatchesVacancies(false));
      dispatch(setNotification({ message, type: 'info', status: 'info' }));
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function fetchJobDetailsCraftDataById(companyID) {
  return async (dispatch) => {
    dispatch(setFetchingJobDetailsCraftData(true));

    try {
      const response = await axios.get(
        `${API_URL}/companies/${companyID}/craftInfo`,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(receiveJobDetailsCraftData(response.data));
      dispatch(setFetchingJobDetailsCraftData(false));
      return response.data;
    } catch (error) {
      const { statusCode } = error.payload.data;

      if (statusCode === 401) dispatch(unauthUser());
      dispatch(setFetchingJobDetailsCraftData(false));
      throw error;
    }
  };
}

export function setLoadingRelatedJobs(payload) {
  return {
    type: ActionTypes.SET_LOADING_RELATED_JOBS,
    payload,
  };
}

export function setRelatedJobs(payload) {
  return {
    type: ActionTypes.SET_RELATED_JOBS,
    payload,
  };
}

export function fetchRelatedJobsByData({ data }) {
  return async (dispatch) => {
    dispatch(setLoadingRelatedJobs(true));

    try {
      const response = await axios.post(`${API_URL}/vacancies/related`, data, {
        headers: { authorization: localStorage.getItem('token') },
      });

      dispatch(setRelatedJobs(response.data));
      dispatch(setLoadingRelatedJobs(false));

      return response.data;
    } catch (response) {
      const { error } = response.payload.data;

      if (error.statusCode === 401) dispatch(unauthUser());

      dispatch(setLoadingRelatedJobs(false));
      dispatch(
        setNotification({
          message: error.message,
          status: 'info',
          type: 'info',
        })
      );
      setTimeout(() => dispatch(clearNotification()), 5000);

      throw error;
    }
  };
}

export function setJobsPublishingPlans(payload) {
  return {
    type: ActionTypes.SET_JOBS_PUBLISHING_PLANS,
    payload,
  };
}

export function fetchJobsPublishingPlans() {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${API_URL}/plans/available`, {
        headers: { authorization: localStorage.getItem('token') },
      });

      dispatch(setJobsPublishingPlans(response.data));

      return response.data;
    } catch (error) {
      throw error;
    }
  };
}


export function fetchCheckPostJobPromocodeSuccess(payload) {
  return {
    type: ActionTypes.FETCH_CHECK_POST_JOB_PROMOCODE_SUCCESS,
    payload,
  };
}

export function setFetchingCheckPostJobPromocode(payload) {
  return {
    type: ActionTypes.SET_FETCHING_CHECK_POST_JOB_PROMOCODE,
    payload,
  };
}

export function fetchCheckPostJobPromocode(jobId, code) {
  return async (dispatch) => {
    dispatch(setFetchingCheckPostJobPromocode(true));

    try {
      const response = await axios.get(
        `${API_URL}/vacancies/${jobId}/applyCode/${code}`,
        {
          headers: { authorization: localStorage.getItem('token') },
        }
      );

      dispatch(setFetchingCheckPostJobPromocode(false));
      dispatch(fetchCheckPostJobPromocodeSuccess(response.data));
      return response.data;
    } catch (error) {
      const { message, statusCode } = error.payload.data.error;
      dispatch(setFetchingCheckPostJobPromocode(false));

      if (statusCode && statusCode === 401) {
        dispatch(unauthUser());
        dispatch(
          setNotification({
            message: 'Something went wrong.',
            type: 'info',
            status: 'info',
          })
        );
        setTimeout(() => dispatch(clearNotification()), 5000);
      }

      throw message;
    }
  };
}
