import React from 'react';
import cn from 'classnames';

import AppButtonToggle from 'components/shared/AppButtonToggle';
import { getServiceWithPriceWIthPercents } from 'utils/Coach';

import './AppCoachBookServiceType.scss';

const AppCoachBookServiceType = ({
  type,
  price,
  onClick,
  isActive,
  duration,
}) => (
  <AppButtonToggle isActive={isActive} onClick={onClick}>
    {type.name} ({duration} mins.) -&nbsp;
    <span className={cn({ '--acsent': price === 0 })}>
      {price > 0 ? `$${getServiceWithPriceWIthPercents(price / 100)}` : 'Free'}
    </span>
  </AppButtonToggle>
);

AppCoachBookServiceType.propTypes = {
  // company: PropTypes.object,
  // name: PropTypes.string,
};

export default AppCoachBookServiceType;
