import React, { Component } from 'react';
import mapboxgl from "mapbox-gl/dist/mapbox-gl.js";

import marker from "./marker.png"

import "./AppMapBox.scss"

mapboxgl.accessToken = 'pk.eyJ1Ijoic3Rhc3podWsiLCJhIjoiY2s5ZTRzMWkyMDF0azNucDRzbzE0cGpqNSJ9.b-7Dd_IBdWAQE4TgbdB_kA';

class AppMapBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: props.isCollapsed,
      height: props.isCollapsed ? props.heightSmall : props.heightBig
    }

    this.map = null;
    this.navigation = null;
    this.container = props.container
  }

  componentDidMount() {
    const { center, zoom, style } = this.props;

    const mapProps = {
      container: this.container,
      style: style || 'mapbox://styles/mapbox/streets-v11',
      zoom: zoom || 10,
    }

    if (center) mapProps.center = center

    this.map = new mapboxgl.Map(mapProps);
    // Define a new navigation control.
    this.navigation = new mapboxgl.NavigationControl();

    this.map.scrollZoom.disable();

    this.map.on('load', () => {
      this.map.loadImage(marker,
        (error, image) => {
          if (error) throw error;
          this.map.addImage('marker', image);
          this.map.addSource('point', {
            'type': 'geojson',
            'data': {
              'type': 'FeatureCollection',
              'features': [
                {
                  'type': 'Feature',
                  'geometry': {
                    'type': 'Point',
                    'coordinates': center
                  }
                }
              ]
            }
          });
          this.map.addLayer({
            'id': 'points',
            'type': 'symbol',
            'source': 'point',
            'layout': {
              'icon-image': 'marker',
              'icon-size': 1
            }
          });
        }
      );
    });
  }

  changeHeightHandler = () => {
    if (this.state.collapsed) this.map.addControl(this.navigation);
    else this.map.removeControl(this.navigation);

    this.setState(function (state, props) {
      return {
        collapsed: !state.collapsed,
        height: !state.collapsed ? props.heightSmall : props.heightBig,
      }
    }, () => {
      this.resizeMap()
    })
  }


  resizeMap = () => this.map.resize()

  render() {
    const { height, collapsed } = this.state
    const { width } = this.props

    return (
      <div className="app-mapbox-wrapper">
        <button
          onClick={this.changeHeightHandler}
          aria-label="Map toggle"
          type="button"
          className="app-mapbox-collapse"
        >
          <span>{collapsed ? "Expand" : "Collapse"}</span>
        </button>
        <div className="app-mapbox" style={{ height, width }} id={this.container}>
        </div>
      </div>
    );
  }
};

export default AppMapBox;
