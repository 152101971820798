import React, { Component } from 'react';
import { connect } from 'react-redux';

import { AppPanelRow, AppPanelTitle } from 'components/shared/AppPanel';
import AppRangeSliderExperience from 'components/shared/AppRangeSliderExperience';

import { removeSlug, addSlug, } from 'actions/SlugsActions';

import * as SlugsTypes from 'constants/SlugsTypes';

import './AppFiltersPanelExperience.scss';

const mapDispatchToProps = (dispatch) => ({
  removeSlug: (payload) => dispatch(removeSlug(payload)),
  addSlug: (slug) => dispatch(addSlug(slug)),
});

class AppFiltersPanelExperience extends Component {
  onClearFilter = () => {
    const {
      removeSlug,
      closeFilterPanel,
      handleSubmit,
      handleCount,
    } = this.props;

    removeSlug({ slugName: SlugsTypes.EXPERIENCE });
    closeFilterPanel && closeFilterPanel();
    handleSubmit && setTimeout(() => handleSubmit(), 500);
    handleCount && handleCount();
  };

  render() {
    const {
      data,
      addSlug,
      handleSubmit,
      handleCount,
      analiticsTrigger,
    } = this.props;

    return (
      <div className="app-filters-panel-experience">
        <div className="app-filters-panel-experience__dropdown">
          <AppPanelRow mb={15}>
            <AppRangeSliderExperience
              range={data.slugs}
              handleCount={handleCount}
              handleSubmit={handleSubmit}
              handlerExperience={(slug) => {
                analiticsTrigger && analiticsTrigger();
                addSlug({ slugName: SlugsTypes.EXPERIENCE, slug });
              }}
            />
          </AppPanelRow>

          {data &&
            data.slugs &&
            (data.slugs[0] !== 0 || data.slugs[1] !== 10) ? (
              <AppPanelRow>
                <button onClick={this.onClearFilter}>
                  <AppPanelTitle color="warn">Clear selection</AppPanelTitle>
                </button>
              </AppPanelRow>
            ) : (
              ''
            )}
        </div>
      </div>
    );
  }
}

export default connect(null, mapDispatchToProps)(AppFiltersPanelExperience);
