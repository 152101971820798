import React from 'react';
import AppButton from "components/shared/AppButton";
import './AppBannerEmployer.scss';

export default function AppBannerEmployer({ title, description, btnTitle, onClick }) {
    return (
        <div className="app-banner-employer">
            <span className="app-banner-employer__bg"></span>
            <div className="app-container">
                <div className="app-banner-employer__inner">
                        <div className="app-banner-employer__title">{title}</div>
                        <div className="app-banner-employer__text">
                            {
                                description && description.map((t, idx) => {
                                    if (idx > 0) {
                                        return (
                                            <React.Fragment key={idx}>
                                                <br />
                                                <br />
                                                {t}
                                            </React.Fragment>
                                        )
                                    } else {
                                        return (
                                            <React.Fragment key={idx}>
                                                {t}
                                            </React.Fragment>
                                        )
                                    }
                                })
                            }
                        </div>
                    <div className="app-banner-employer__button">
                        <AppButton
                            defaultStyle="primary"
                            title={btnTitle || "Learn More"}
                            onClick={onClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}
