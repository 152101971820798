import * as ActionTypes from 'constants/ActionTypes';
import { LOCATION_CHANGE } from 'connected-react-router';

const initialState = {
  name: 'experience',
  min: 0,
  max: 10,
  slugs: [0, 10],
};

export default function experience(state = initialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      if (
        action.payload &&
        action.payload.location.state &&
        action.payload.location.state.filters
      ) {
        return Object.assign({}, state, {
          ...state,
          slugs: action.payload.location.state.filters.experience,
        });
      }

      return state;

    case ActionTypes.SET_MAX_EXPERIENCE:
      return Object.assign({}, state, {
        max: action.payload,
      });

    case ActionTypes.SET_FILTER_STATE:
      return Object.assign({}, state, {
        ...state,
        slugs: action.payload.experience,
      });

    case ActionTypes.SET_MIN_EXPERIENCE:
      return Object.assign({}, state, {
        min: action.payload,
      });

    case ActionTypes.SET_EXPERIENCE:
      return Object.assign({}, state, {
        slugs: action.payload,
      });

    case ActionTypes.CLEAR_EXPERIENCE:
    case ActionTypes.REMOVE_ALL_SLUGS:
      return Object.assign({}, state, {
        slugs: [0, 10],
      });

    default:
      return state;
  }
}
