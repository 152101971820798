import React, { Component } from 'react';
import classnames from 'classnames';
import Slider from 'rc-slider';
import { debounce } from 'lodash';

import { AppPanelTitle } from 'components/shared/AppPanel';

import 'rc-slider/assets/index.css';
import './AppRangeSliderPricerange.scss';

const Range = Slider.Range;
const marks = {
  0: 0,
  50: 50,
  100: 100,
  200: 200,
  300: '300+',
};

export default class AppRangeSliderPricerange extends Component {
  constructor(props) {
    super(props);
    this.state = {
      range: props.range || [0, 300],
      prevProps: {},
    };
  }

  componentDidMount() {
    this.setState({ range: this.props.range });
  }

  static getDerivedStateFromProps(props, state) {
    const { prevProps } = state;

    if (prevProps.range !== props.range) {
      return {
        range: props.range,
        prevProps: props,
      };
    }

    return {
      prevProps: props,
    };
  }

  handleOnChange = (value) => {
    this.props.handlerChange(value);
    if (this.props.handleSubmit) this.debounceSubmit();
    if (this.props.handleCount) this.debounceCount();
    this.setState({ range: value });
  };

  debounceSubmit = debounce(
    () => this.props.handleSubmit(this.props.filters),
    300
  );

  debounceCount = debounce(
    () => this.props.handleCount(this.props.filters),
    300
  );

  isInitRange = (range) => {
    if (range) return range[0] === 0 && range[1] === 300;
  };

  renderRangeResult = (range) => {
    if (range) {
      let [min, max] = range;

      if (min === 0 && max === 300) return 'Any price';
      if (min === 0 && max === 0) return 'Free price';
      else if (min !== 0 && max === 300) return `$${min}+`;
      else if (min === max) return `$${min}`;
      else return `$${min} - $${max}`;
    }
  };

  render() {
    const { range } = this.state;

    return (
      <div
        className={classnames('app-range-slider', {
          '--init': this.isInitRange(range),
        })}
      >
        <div className="app-range-slider__inner">
          <Range
            range={true}
            allowCross={false}
            count={1}
            min={0}
            max={300}
            value={range}
            defaultValue={range}
            marks={marks}
            step={null}
            onChange={this.handleOnChange}
          />
        </div>
        <div className="app-range-slider__result">
          <AppPanelTitle defaultStyle={'second'}>
            {this.renderRangeResult(range)}
          </AppPanelTitle>
        </div>
      </div>
    );
  }
}
