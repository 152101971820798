const getStateFilters = (storeState, moduleName) => {
  const {
    roles,
    experience,
    skills,
    locations,
    companies,
    vacancies,
    events,
    seniorities,
    eventtypes,
    fromUTC,
    coaches,
    coachingtypes,
    coachingroles,
    price,
  } = storeState;

  let keywords;

  switch (moduleName) {
    case 'jobs': {
      keywords = vacancies.keywords;
      break;
    }
    case 'events': {
      keywords = events.keywords;
      break;
    }
    case 'coaches': {
      keywords = coaches.keywords;
      break;
    }
    default: {
      keywords = [''];
    }
  }

  let data = {
    roles: roles.slugs,
    skills: skills.slugs,
    companies: companies.slugs,
    experience: experience.slugs,
    locations: locations.slugs,
    seniorities: seniorities.slugs,
    eventtypes: eventtypes.slugs,
    keywords,
    fromUTC: fromUTC.slugs,
    coachingtypes: coachingtypes.slugs,
    coachingroles: coachingroles.slugs,
    price: price.slugs,
  };

  if (locations.nearest !== null) data.near = [locations.nearest];

  return data;
};

export default getStateFilters;
