import React, { Component } from 'react';
import Moment from 'moment';
import classnames from 'classnames';
import Truncate from 'react-truncate';

import AppIcon from 'components/shared/AppIcon';
import AppJobsCardImg from 'components/shared/AppJobsCard/AppJobsCardImg';
import AppJobsCardLocations from 'components/shared/AppJobsCard/AppJobsCardLocations';

import './AppJobsCardInterest.scss';

class AppJobsCardInterest extends Component {
  render() {
    const {
      data: { company, name, locations, created, id },
      size,
      hoverOff,
      onClick,
    } = this.props;

    return (
      <div
        onClick={onClick}
        className={classnames('app-jobs-card', 'app-jobs-card-interest', {
          '--nohover': hoverOff,
          '--align-center': size,
        })}
      >
        <div className="app-jobs-card-interest__content">
          <div className="app-jobs-card-interest__content-top">
            <div className="app-jobs-card-interest__img">
              {company ? (
                <AppJobsCardImg
                  size="small"
                  id={id}
                  img={company.logo}
                  alt={company.name}
                />
              ) : (
                ''
              )}
            </div>

            <div className="app-jobs-card-interest__titles">
              <div title={name} className="app-jobs-card-title --vacancie">
                <Truncate lines={1}>{name}</Truncate>
              </div>
              <div
                title={company.name}
                className="app-jobs-card-title --company"
              >
                <Truncate lines={1}>{company.name}</Truncate>
              </div>
            </div>
          </div>

          {size === 'min' ? (
            ''
          ) : (
            <div className="app-jobs-card-interest__content-bottom">
              <div className="app-jobs-card__row --small">
                <AppJobsCardLocations locations={locations} truncate={1} />
              </div>
              <div className="app-jobs-card__row --small --flex-bw">
                <ul className="app-jobs-card__locations">
                  <div className="app-jobs-card__line">
                    <div className="app-jobs-card__icon">
                      <AppIcon icon="clock" color="gray" />
                    </div>
                    <div className="app-jobs-card__date">
                      {Moment(created).fromNow()}
                    </div>
                  </div>
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default AppJobsCardInterest;
