import React, { useCallback } from 'react';
import moment from 'moment';
import MediaQuery from 'react-responsive';

import AppButtonToggle from 'components/shared/AppButtonToggle';
import AppButton from 'components/shared/AppButton';

import * as MediaTypes from 'constants/MediaTypes';
import { BOOK_DATA_FORMATTER } from 'constants/CoachingProfileTypes';

import './AppCoachBookTimes.scss';

const AppCoachBookTimes = ({
  mappedDates,
  activeDay,
  handleTime,
  activeTime,
  timezone,
  submitIsDisabled,
  loader,
  onSubmit,
}) => {
  const renderTimes = useCallback(() => {
    if (
      Object.keys(mappedDates).length &&
      activeDay &&
      mappedDates[activeDay]
    ) {
      return mappedDates[activeDay].map((time) => {
        const isActive = activeTime && activeTime.id === time.id;
        
        return (
          <li key={time.id}>
            <AppButtonToggle
              isActive={isActive}
              onClick={() => handleTime(activeTime, time)}
            >
              {time.date}
            </AppButtonToggle>
            <MediaQuery maxWidth={MediaTypes.SM}>
              {(matches) => {
                if (matches && isActive)
                  return (
                    <AppButton
                      className="app-coach-book-times__submit"
                      fullWidth
                      type="submit"
                      title="Book This Session"
                      isDisabled={submitIsDisabled}
                      onClick={onSubmit}
                      loader={loader}
                    />
                  );
                else return false;
              }}
            </MediaQuery>
          </li>
        );
      });
    }
    return null;
  }, [mappedDates, activeDay, activeTime, timezone, submitIsDisabled]);

  return (
    <div  className="app-coach-book-times">
      <div
        id="section-timeslots"
        className="app-coach-book-form-anchor"
      ></div>
      <div className="app-coach-book-times__title">
        {moment(activeDay, BOOK_DATA_FORMATTER).format('dddd, MMM D')}
      </div>
      <ul className="app-coach-book-times__list">{renderTimes()}</ul>
    </div>
  );
};

AppCoachBookTimes.propTypes = {
  // company: PropTypes.object,
  // name: PropTypes.string,
};

export default AppCoachBookTimes;
